<template>
  <div id="InvoiceList" class="tableList">
    <table>
      <thead>
        <tr>
          <th v-if="!IsMobile">{{ $t("orders.category") }}</th>
          <th>{{ $t("orders.solution") }}</th>
          <th v-if="!IsMobile">{{ $t("orders.description") }}</th>
          <th colspan="2" v-if="!IsMobile">
            {{ $t("crossConnects.purchaseOrder") }}
          </th>
        </tr>
      </thead>
      <tbody
        v-if="IsMobile && this.viewLoader && this.list && this.list.length"
      >
        <MobileOrderTableRow
          :key="keyItem"
          v-for="(order, keyItem) in this.list"
          :order="order"
          :content="order.rows"
        />
      </tbody>
      <tbody
        v-else-if="
          !IsMobile && this.viewLoader && this.list && this.list.length
        "
      >
        <OrderTableRow
          :key="keyItem"
          v-for="(order, keyItem) in this.list"
          :order="order"
          :content="order.rows"
        />
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" style="text-align: center">
            <MyLoading
              :viewLoader="viewLoader"
              type=""
              :fixSmallTable="false"
            />
            <span v-if="viewLoader">{{ $t("orders.noOrders") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <br /><br />
      <MyError :viewError="viewError" :message="$t('tickets.error')" />
    </div>
  </div>
</template>

<script>
import MyError from "@/components/MyError.vue";
import MyLoading from "@/components/MyLoading.vue";
import OrderTableRow from "@/components/Order/OrderTableRow.vue";
import MobileOrderTableRow from "@/components/Order/MobileOrderTableRow.vue";
import "@/assets/scss/Tables.scss";

export default {
  name: "OrderList",
  components: { OrderTableRow, MobileOrderTableRow, MyError, MyLoading },
  props: {
    list: Array,
    viewLoader: Boolean,
    viewError: Boolean,
  },
  data() {
    return {
      IsMobile: this.$IsMobile(),
    };
  },
};
</script>
