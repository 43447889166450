import { defineStore } from 'pinia'

export const useBreadCrumbsStore = defineStore('BreadCrumbsStore', {
  state: () => {
    return {
      bread: {}
    }
  },
  getters: {},
  actions: {
    async setBreadCrumbs(from) {
      this.bread = "";
      if (from.path != "/") {
        if( from.params.id ) {
          from.meta.breadcrumbs[from.meta.breadcrumbs.length -1].ticket_id = from.params.id;
        }
        this.bread = from.meta.breadcrumbs;
      }
      return;
    }
  }
})
