<template>
  <div class="additional-content">
    <div class="field-title">
      {{
        $t("crossConnects.uploadLOA") +
        (isRedundant? datacenterStore.rbend?.is_my_rack ? "" : "*" : datacenterStore.bend?.is_my_rack ? "" : "*")
      }}
    </div>
    <div
      class="cloud-button-container"
      style="padding-bottom: 2rem"
      @click="clickUpload"
    >
      <div class="cloud-icon">
        <img
          v-if="!attachment?.name"
          src="@/assets/img/shipping/free_attach.png"
          alt="attach"
        />
        <img
          src="@/assets/img/shipping/complete_attach.png"
          alt="attach"
          v-if="attachment?.name"
        />
        <input
          type="file"
          ref="fileInput"
          @change="
            datacenterStore.handleFileChange(
              $event,
              isRedundant ? 'attachment_rbend' : 'attachment_b_end',
              isRedundant
            )
          "
          style="display: none"
        />
      </div>
      <span
        class="material-icons deleteAttach"
        v-if="attachment?.name"
        @click="removeAttach"
        style="margin-left: 0.75rem; margin-bottom: 2rem"
        >cancel</span
      >
      <div class="text-info">
        {{ $t("crossConnects.infoLOAReq") }}
      </div>
    </div>
    <p
      v-if="attachment?.name"
      class="fileName"
      style="margin-top: 4rem; float: left"
    >
      {{ attachment?.name }}
    </p>
    <div
      v-if="
        datacenterStore.isValidationCheckInitiated &&
        (!datacenterStore.rbend.is_my_rack ||
          datacenterStore.kindOfkit === 'loa')
      "
      class="error-message"
    >
      <span>{{ $t("crossConnects.requiredLOA") }}</span>
    </div>
    <div
      v-if="
        datacenterStore.wrongFileFormatR && !datacenterStore.rbend.is_my_rack
      "
      class="error-message"
    >
      <span>{{ $t("crossConnects.wrongLoaFormat") }}</span>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import {useDatacenterStore} from "@/stores/DataCenterStore";

const datacenterStore = useDatacenterStore();
const fileInput = ref(null);

const props = defineProps({
  isRedundant: Boolean,
});

const attachment = computed(() => {
  return props.isRedundant
    ? datacenterStore.attachment_rbend
    : datacenterStore.attachment_b_end;
});

const clickUpload = () => {
  fileInput.value.click();
};

const removeAttach = () => {
  if (props.isRedundant) {
    datacenterStore.attachment_rbend = {};
    datacenterStore.redunandLoaName = null;
  } else {
    datacenterStore.attachment_b_end = {};
    datacenterStore.loaName = null;
  }
};
</script>

<style>
/* Add your styles here */
</style>
