import { defineStore } from 'pinia'

export const useOrderDraftStore = defineStore('OrderDraft', {
  state: () => {
    return {
      draft: false
    }
  },
  getters: {

  },
  actions: {
    async setItems(value) {
      this.draft = value;
    }
  }
})