<template>
  <div
    :class="isMobile ? 'redundancy-component-mobile' : 'redundancy-component'"
  >
    <div class="this-text-section">
      <div :class="{ 'header-text-alone': !subText }" class="header-text">
        {{ $t(headerText) }}
      </div>
      <div v-if="subText !== ''" class="sub-text">{{ $t(subText) }}</div>
    </div>
    <div class="toggle-section">
      <div class="toggle-label">NO</div>
      <label class="toggle">
        <input type="checkbox" v-model="isToggled" @change="watchToggle" />
        <span class="slider"></span>
      </label>
      <div class="toggle-label">{{ $t("crossConnects.yes") }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "RedundancyComponent",
  props: {
    headerText: {
      type: String,
      default: "crossConnects.redundancyActivateQs",
    },
    togled: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    subText: {
      type: String,
      default: "crossConnects.otherCostsActivation",
    },
  },
  setup(props, { emit }) {
    const isToggled = ref(props.togled);
    console.log(isToggled.value);

    const watchToggle = () => {
      emit("toggle-changed", isToggled.value);
    };
    return { isToggled, watchToggle };
  },
};
</script>

<style scoped lang="scss">
.header-text-alone {
  // Adjust styles to position the header text above the toggle when alone
  margin-bottom: 10px; // Example value, adjust as needed
}

.redundancy-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* Adjust as needed */
}

.this-text-section {
  text-align: right;
  padding-right: 20px; /* Optional: for some spacing between text and toggle */
}

.header-text {
  text-align: left;
  letter-spacing: 0px;
  color: #002e5f;
  opacity: 1;
  font-family: OpenSansSemiBold;
  font-size: 18px;
  font-weight: bold;
}

.sub-text {
  width: 220px;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  font-family: OpenSansRegular;
}

.toggle-section {
  display: flex;
  align-items: center;
}

.toggle-label {
  color: #022448;
  margin: 1rem;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  background: 0% 0% no-repeat padding-box;
  border-radius: 17px; /* Adjusted for a more rounded container */
  opacity: 1;
}

.toggle input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border-radius: 17px; /* Adjusted for a more rounded container */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background: transparent linear-gradient(135deg, #3caea3 0%, #89d5d2 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 1px 1px 2px #0000001a;
  border: 1px solid #42aea3;
  border-radius: 13px;
  transition: 0.4s;
}

.toggle input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
