<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal">
      <button @click="toggleModal" class="close-btn">✕</button>
      <div class="modal-content">
        <h2>{{ $t("crossConnects.supportTicket") }}</h2>
        <br />
        <textarea
          type="text"
          name="card1-input3"
          id="card1-input3"
          :placeholder="$t('detailsTicket.description') + '*'"
          class="text-area-modal-element"
          v-model="textareaValue"
        ></textarea>
        <div class="button-container">
          <div class="cdlButtonGreen" @click="postTicket">
            {{ $t("crossConnects.modal.send") }}
          </div>
          <br />
          <router-link
            style="color: #002e5f"
            to="/cross-connects"
            @click="toggleModal"
            class="deleteOrder"
            >{{ $t("crossConnects.cancel") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-if="successModalOpen" class="modal-overlay">
    <div class="modal-success">
      <button @click="toggleSuccessModal" class="close-btn">✕</button>
      <div class="modal-content">
        <div class="contentModal">
          <h3 class="">{{ $t("crossConnects.modal.requestSent") }}!</h3>
        </div>
        <p class="subtitle" style="text-align: center">
          {{ $t("crossConnects.modal.requestSentSub") }}
        </p>
        <br />
        <div class="body">
          <h5>
            {{
              $t("crossConnects.modal.ticketId") + dataCenterStore.supportTicket
            }}
          </h5>
        </div>
        <div class="button-container">
          <router-link
            class="cdlButtonGreen"
            style="width: auto"
            :to="'/' + $t('nav.crossConnectsActiveUrl')"
            >{{ $t("crossConnects.backToList") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";

const props = defineProps({
  isOpen: Boolean,
});
const textareaValue = ref("");
const successModalOpen = ref(false);

const dataCenterStore = useDatacenterStore();

const postTicket = async () => {
  try {
    await dataCenterStore.postSupportTicket(textareaValue.value);
    successModalOpen.value = true; // Open the success modal
  } catch (error) {
    console.error("Error when posting support ticket:", error);
    // Optionally, handle the error here
  }
};
const toggleSuccessModal = () => {
  successModalOpen.value = !successModalOpen.value;
  emit("update:isOpen", !props.isOpen);
};

const emit = defineEmits(["update:isOpen"]);

const toggleModal = () => {
  emit("update:isOpen", !props.isOpen);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";

@import "@/assets/scss/Help.scss";

h2 {
  font-family: MontserratBold;
}

h3 {
  color: #001831;
  font-family: MontserratBold;
  font-size: 2.188rem;
  text-align: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  margin: 2rem;
}

.text-area-modal-element {
  padding: 1rem;
  font-family: OpenSansRegular;
  font-size: $font19_2;
}

.sectionTitelAddons {
  color: #b8c4ce;
}

.text-area-modal-element {
  width: 90%;
  height: 200px;
  background: #eef1f4 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  border: 1px none #b8c4ce;
}

.my-text {
  text-align: left;
  letter-spacing: 0px;
  color: #002e5f;
  opacity: 1;
  font-family: "OpenSansBold";
  font-size: 16px;
  line-height: 24px;
  font-stretch: normal;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* centers items horizontally */
  justify-content: center; /* centers items vertically */
  padding: 20px; /* add some spacing around the content */
  max-width: 80%;
  /* limit the width if needed */
  margin: 0 auto; /* center the content block if it's not taking full width */
}

.value {
  font-family: OpenSansBold;
}

.modal-content h2,
.modal-content p,
.modal-content ul {
  text-align: center; /* centers text within each element */
  width: 100%; /* take full width to ensure center alignment works */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: OpenSansRegular;
  font-size: 16px;

  .second {
    background-color: #fff;
    width: 770px;
    height: 897px;
    max-width: 90%; /* Responsive */
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    position: relative;
  }
}

.modal {
  background-color: #fff;
  background-image: url("@/assets/img/card_order.png");
  background-size: cover; /* This will make sure the image covers the entire element */
  background-repeat: no-repeat; /* This will prevent the image from repeating */

  width: 624px;
  height: 821px;
  max-width: 90%; /* Responsive */
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-success {
  background-color: #fff;
  background-image: url("@/assets/img/modal_success.png");
  background-size: cover; /* This will make sure the image covers the entire element */
  background-repeat: no-repeat; /* This will prevent the image from repeating */

  width: 624px;
  height: 821px;
  max-width: 90%; /* Responsive */
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  font-weight: bold;
  margin-bottom: 15px;
}

p {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

ul li {
  margin: 5px 0;
  font-size: 14px;
}

.request-btn,
.cart-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.request-btn {
  background-color: #3498db; /* Sample blue color */
  color: #fff;
  margin-right: 10px;
}

.cart-btn {
  background-color: #2ecc71; /* Sample green color */
  color: #fff;
}

.request-btn:hover,
.cart-btn:hover {
  background-color: darken(
    #3498db,
    10%
  ); /* You can adjust the percentage for a darker shade on hover */
}

/* Add the styles for the highlighted emails or links if any special styles are needed */
ul li strong {
  font-weight: bold;
}
</style>
