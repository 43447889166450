<template>
  <div id="ManagementPanel" :class="{ active: view, close: !view }">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--1-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
        <span class="material-icons closePanel" @click="closePanel()">close</span>
      </div>
      <div class="mdl-cell mdl-cell--11-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
        <h3>{{ $t('users.titleSearchUser') }}</h3>
        <p class="subTitle">{{ $t('users.subTitleSearchUser') }}</p>

        <div class="space"></div>
        <div class="mdl-cell mdl-cell--12-col">
          <div class="contentInputFilter">
            <div class="contentIcon"><span class="material-icons">search</span></div>
            <div class="contentInput"><input type="text" :placeholder="$t('users.searchUsers')" @keyup="searchByString"
                v-model="searchString" /></div>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col contentUsersSearch">
          <div v-for="(user, index) in dataFilter" :key="index" class="singleUser"
            :class="['user-' + index, { view: getView(user.id) }]" @click="setUser(index, user.id)"
            :id="['user-' + user.id]">
            <div>{{ user.last_name }} {{ user.first_name }}</div>
            <div class="material-icons">done</div>
          </div>
        </div>
        <br /><br /><br />
        <div class="contentButton" v-if="usersSelected.length > 0">
          <button class="cdlButtonGreen" @click="confirmUser()">
            {{ $t('users.confirmUser') }}
          </button>
        </div>
        <br /><br />
        <p class="cancel" @click="closePanel()">{{ $t('users.cancel') }}</p>

      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/ManagementPanel.scss"
import { useUserStore } from '@/stores/UserStore'

export default {
  name: 'SearchUser',
  props: {
    view: {
      type: Boolean,
      required: true,
    },
    allUsers: {
      type: Object,
      required: true
    },
    singleGroup: {
      type: Object,
      required: true
    },
    ctrlPanelUsersSearch: {
      type: Object,
      required: true
    },
    ctrlPanelUsersSearchFromGroup: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      usersSelected: [],
      dataFilter: [],
      searchString: '',
      hideIds: [],
      partialUsers: []
    }
  },
  methods: {
    closePanel() {
      this.usersSelected = [];
      let allInputs = document.getElementsByClassName("singleUser");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.remove('active');
      }
      this.confirmUser();
    },
    setUser(index, id) {
      if (document.getElementById("user-" + id).classList.contains('active')) {
        document.getElementById("user-" + id).classList.remove('active');
        if (this.usersSelected.length == 1) {
          this.usersSelected = [];
        }
        else {
          this.usersSelected.forEach((el, i) => {
            if (el.id == id) {
              this.usersSelected.splice(i, 1);
            }
          });
        }
      }
      else {
        document.getElementById("user-" + id).classList.add('active');
        this.usersSelected.push(this.allUsers[index]);
      }
    },
    confirmUser() {
      this.$emit('confirm-user-group', { users: this.usersSelected })
      if (this.ctrlPanelUsersSearch) {
        this.usersSelected = [];
        let allInputs = document.getElementsByClassName("singleUser");
        for (let i = 0; i < allInputs.length; i++) {
          allInputs[i].classList.remove('active');
        }
      }
    },
    searchByString() {

      if (this.searchString.length == 0) {
        this.buildList();
        return;
      }

      this.hideAll();

      this.partialUsers.forEach(elm => {
        if (
          elm.first_name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          elm.last_name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          elm.email.toLowerCase().includes(this.searchString.toLowerCase()) ||
          (elm.phone && elm.phone.toLowerCase().includes(this.searchString.toLowerCase()))
        ) {
          document.getElementById("user-" + elm.id).classList.add('view');
        }
      });

      this.hidePartials();
    },
    hideAll() {
      let allInputs = document.getElementsByClassName("singleUser");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.remove('view');
      }
    },
    viewAll() {
      let allInputs = document.getElementsByClassName("singleUser");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.add('view');
      }
    },
    hidePartials() {
      for (let i = 0; i < this.hideIds.length; i++) {
        document.getElementById("user-" + this.hideIds[i]).classList.remove('view');
      }
    },
    getView(id) {
      return !this.hideIds.find((element) => element == id);
    },
    buildList() {
      let userStore = useUserStore();
      this.partialUsers = this.dataFilter;
      if( Object.keys(this.singleGroup).length > 0 ) {
        this.dataFilter.forEach((elm) => {
          this.singleGroup.users.forEach((elm2) => {
            console.log(elm);
            console.log(userStore.user)
            if (elm.id == elm2.id || elm.email == userStore.user.email || elm2.email == userStore.user.email) {
              this.hideIds.push([elm.id]);
            }
          })
        });
      }

      this.viewAll();
      setTimeout(() => {
        this.hidePartials();
      }, 500);
    },
    updatePanel() {
      this.dataFilter = this.allUsers;
      this.hideIds = [];
      this.viewAll();
      this.buildList();
    }
  },
  watch: {
    ctrlPanelUsersSearch: {
      handler(newValue) {
        if (newValue) {
          this.updatePanel();
        }
      },
      deep: true
    },
    ctrlPanelUsersSearchFromGroup: {
      handler(newValue) {
        if (newValue) {
          this.updatePanel();
        }
      },
      deep: true
    },
    singleGroup: {
      handler() {
        let allInputs = document.getElementsByClassName("singleUser");
        for (let i = 0; i < allInputs.length; i++) {
          allInputs[i].classList.remove('active');
        }
        this.updatePanel();
      },
      deep: true
    }
  },
  mounted() {
    this.dataFilter = this.allUsers;
    this.buildList();
  }
}
</script>