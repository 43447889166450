export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Vue.js app"])}
  },
  "keycloak": {
    "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "buttonLogout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "nav": {
    "reportCloudUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cloud-report"])},
    "accessDcAddressBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directory"])},
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Customer Management"])},
    "remoteHandsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "ordersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solutions-requested"])},
    "historicalUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket log"])},
    "labelAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "companiesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end-customer-management"])},
    "accessLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesses"])},
    "listRemoteHands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands list"])},
    "incidentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident list"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "listLinesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active-lines"])},
    "incidentListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incident-list"])},
    "techSupportListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request-list"])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect"])},
    "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
    "remoteHandsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remote-hands"])},
    "listShippingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping-list"])},
    "accessDcHistorical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC entry log"])},
    "colocationUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colocation"])},
    "networkConnectivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity"])},
    "virtualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualization"])},
    "maintenanceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance Notifications"])},
    "currentUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
    "crossConnectsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Cross-Connects"])},
    "crossConnectUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cross-connects"])},
    "checkCoverageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coverage-check"])},
    "usersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user-management"])},
    "listRemoteHandsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remote-hands-list"])},
    "techSupportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Request List"])},
    "accessDcNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "maintenanceNotificationsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maintenance-notifications"])},
    "crossConnectsActiveUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active-cross-connects"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
    "invoiceUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoices"])},
    "crList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request list"])},
    "reportCloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "checkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check coverage"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "accessDc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries"])},
    "crossConnectNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Cross-Connect Solution"])},
    "settingUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "servicesOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Overview"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Report"])},
    "accessDcListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entry-request-list"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy consumption"])},
    "paidInterventionsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid-services"])},
    "paidInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid services"])},
    "shippingNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "accessDcList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry list"])},
    "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detail"])},
    "accessDcHistoricalUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dc-entry-log"])},
    "requestSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports requested"])},
    "listShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open shipping list"])},
    "listLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Lines"])},
    "crListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change-request-list"])},
    "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "managementOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Overview"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested solutions"])},
    "accessDcUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entry"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "virtualizationUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["virtualization"])},
    "colocationDetailsUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["colocation/", _interpolate(_named("item_id"))])},
    "incidentUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["incident/", _interpolate(_named("item_id")), "/", _interpolate(_named("asset_type"))])},
    "changeRequestUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["change-request/", _interpolate(_named("item_id")), "/", _interpolate(_named("asset_type"))])},
    "techSupportUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["help/", _interpolate(_named("item_id")), "/", _interpolate(_named("asset_type"))])},
    "linesIncidentUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["incident/", _interpolate(_named("item_id")), "/lines"])},
    "crossConnectsActiveNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "partner_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Area"])},
    "reportIncidentListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report/incident-list"])},
    "reportCrListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report/change-request-list"])}
  },
  "breadcrumbs": {
    "cross-connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cross-connect"])},
    "RemoteHandsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands open"])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "virtualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualization"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "summaryOrderXC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution summary"])},
    "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
    "colocation_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "detail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket ", _interpolate(_named("ticket_id"))])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket log"])},
    "shippingDeliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving goods"])},
    "shippingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Type"])},
    "linesDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "changeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Report"])},
    "address_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directory"])},
    "crossConnectList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connects active"])},
    "summaryOrderCrossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect solution summary"])},
    "paidInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid services"])},
    "crossConnectNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect request"])},
    "new_remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Remote Hand"])},
    "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
    "shippingPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods delivery"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "accessLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesses"])},
    "summaryOrderLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access solution summary"])},
    "shippingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping open"])},
    "newOrderLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New line order"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Cart"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested solutions"])},
    "entry_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "maintenanceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance notifications"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy consumption"])},
    "checkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check coverage"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
    "networkConnectivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity"])},
    "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Customer Management"])},
    "Change Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
    "requestSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports requested"])},
    "entry_request_historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC entry log"])},
    "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connects"])},
    "techSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Support"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
    "remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries"])},
    "Richieste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
    "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
    "lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active lines"])},
    "incidentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident list"])},
    "changeRequestList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request List"])}
  },
  "noPermission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Denied"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to view the content of this page. Contact the portal administrator."])},
    "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Home"])}
  },
  "banner": {
    "accessDcPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "accessDcInitTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can book the entry to one of our two data centers. Choose the desired date and time to ensure a quick, safe entry."])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Cross-Connect activation. Choose between the two available options: Local (point-to-point in C21 or E100) and Infracampus (from C21 to MIX, Irideos, Seeweb or junction box)."])},
    "accessDcHistoricalPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "accessDcAddressBookPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "RemoteHandsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can view the requests submitted, or request new on-site Remote Hands service conducted by CDLAN staff. These services are divided into Basic and Advanced according to the type of activity required."])},
    "checkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out which connectivity solution you can activate"])},
    "cartPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save time: you can enter multiple solutions in the same order by clicking New Request. Remember that each solution will remain in the Shopping Cart for 48 hours, after that it will be automatically deleted. Be sure to confirm your order on time."])},
    "shippingPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need to store your devices while awaiting installation or collection? Request our goods receipt and delivery service."])},
    "usersPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can easily add, manage and edit users, assigning them one of the available roles or creating a customised one for optimal portal management."])},
    "customersEndsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can manage your end customers: add, edit or delete customer companies and associate reference persons with them."])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can browse your invoices and credit notes by filtering them by period"])},
    "homePage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello ", _interpolate(_named("name")), ",<br />welcome to the CDLAN Customer Portal.<br />Here you can keep track of your active solutions, access services, place new orders, and request support."])}
  },
  "crossConnects": {
    "uploadLOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach LOA (Letter of Authorization)"])},
    "confirmValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution validation"])},
    "tipologia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
    "supportTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Ticket"])},
    "redundancyActivateQs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to activate the redundancy?"])},
    "otherCostsActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There will be additional costs added."])},
    "requiredRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room is required"])},
    "subOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOCAL Cross-Connects <b>are made within 1 business day of order placement</b> and can be requested in redundant mode.\n<br/>All C21 Cross-Connects are “single-mode bifiber”.\n<br/>To order a LOCAL Cross-Connect that is in the availability of another company it is necessary <strong>to attach the LOA in PDF format.</strong>"])},
    "thatLOADeclares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["issuing the LOA;"])},
    "island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "cardOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOCAL"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "TBD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The list of active Cross-Connects will be available soon"])},
    "purchaseOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference"])},
    "reqLOAFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA file requirements:"])},
    "inWhichConnectionTerminated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["where the Cross-Connect must be terminated;"])},
    "compileForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form below with all requested details"])},
    "anticipated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anticipated"])},
    "port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port"])},
    "subtitleTipologiaLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of LOCAL CROSS-CONNECT you would like to request"])},
    "uploadLOANoExp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach LOA"])},
    "monthlyPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Fee"])},
    "additionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
    "otherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can enter the code of the purchase order issued by your company."])},
    "redundancyOrderRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy Order Reference"])},
    "requestActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request activation of the Cross-Connect below, within the contractual terms envisaged"])},
    "ifActiveRedundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have enabled Redundancy, check the information on both forms"])},
    "requiredIsle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle is required"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xconnect Introductory information and application methods"])},
    "mandatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*required fields"])},
    "threemester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
    "infoLOAFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA file information:"])},
    "renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsequent renewals (months)"])},
    "deviceAndPort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device and Port"])},
    "totalMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order monthly fee"])},
    "modal": {
      "activationReferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the activation contact"])},
      "confirmActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm activation"])},
      "confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are canceling the transaction: choose “Continue” to continue entering data or click “Cancel Request” to delete all information entered.\n\n\n\n\n\n"])},
      "requestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Confirmed"])},
      "requestSentSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can monitor the ticket’s status"])},
      "newRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a new request"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
      "confirmCancelSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Cancel Request to delete all information entered."])},
      "cancelRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminate Cross-Connect request"])},
      "requestSentAreaAssistence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the Support area -> Cross-Connect Support Request List"])},
      "ticketId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ticket’s ID is"])},
      "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Shopping Cart"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "requestSentSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "cartArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the Shopping Cart area"])},
      "monitorRequestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can monitor the status of your request"])},
      "emailsToSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this Cross-Connect we will send the following emails"])},
      "requestSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Summary"])},
      "adminReferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the administrative contact"])},
      "areaTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the Ticket area"])},
      "confirmCancelSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you confirm the cancellation of your request, all information entered so far will be lost"])},
      "requestConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request confirmed!"])},
      "requestConfirmedSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "backToRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
    },
    "crossConnectInActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution being activated"])},
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
    "OpticCdlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" CDLAN Optical Drawer. You will receive the LOA to submit."])},
    "localC1C2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiber optics pair"])},
    "carefullyReadBothData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the information on both forms carefully"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First and Last Name"])},
    "requiredLOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA is required"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy active"])},
    "step2Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Line"])},
    "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "firstThreeMester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly in advance"])},
    "validateCrossConnectInfra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Solution Summary"])},
    "requiredRack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack is required"])},
    "formSubtitleCorrispettives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing details"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "step1Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center and Kit"])},
    "crossConnectReferrerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here you can enter the purchase order code issued by your company."])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "rackOfYourOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the rack under the direct use of your company?"])},
    "reqLOAFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the LOA (Letter of Authorization)?"])},
    "carefullyRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check carefully"])},
    "crossConnectReferral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect contact"])},
    "reqLOAtext3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The LOA is valid for 30 days from the time it is issued."])},
    "readWithAttention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the details of your service carefully"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Activation"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Shopping Cart"])},
    "btnNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request information"])},
    "activeCrossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect In Activation:"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "reqLOAtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The LOA must be produced on letterhead or bear the name, last name and signature of the individual signing the stamp of the issuing company."])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "priceMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly fee"])},
    "requiredBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building is required"])},
    "dataCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "isRedundancyMainLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change the Redundancy information it is necessary to visit the main line page. You can do it from "])},
    "isle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle"])},
    "andOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and of the "])},
    "crossConnectReferrerSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "activationPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation"])},
    "activateRedundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Redundancy?"])},
    "InfoReqLOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA Information and Requirements below"])},
    "updateItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update service"])},
    "aendbend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A - End / B - end"])},
    "crossConnectReferrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact for this Cross-Connect"])},
    "crossConnectReferrerMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "validateCrossConnectLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Solution Summary"])},
    "subtitleTipologia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of INFRACAMPUS CROSS-CONNECT you would like to request"])},
    "infoLOAReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements and information related to the LOA are provided below"])},
    "reqLOAtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It must contain:"])},
    "redundancyActivationCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
    "30daysValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The LOA is valid for 30 days from the time it is issued."])},
    "billingOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing frequency"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "patchPannelMMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port to MMR"])},
    "authLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The Letter of Authorization is a document that includes information on where and when a circuit is to be terminated or reassigned to another telecommunications operator. Included in the document is the indication and location of the Device in the B-END, with details of the Port at which the connection is to be terminated. You are required to obtain the LOA if you order a Cross-Connect that connects to a Device located in the B-END and managed by another company"])},
    "nameof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the "])},
    "costAndPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference"])},
    "isRedundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The solution you are modifying is the Redundancy of a main Cross-Connect"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months"])},
    "society": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
    "controlWithAttention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check all information entered carefully"])},
    "labelConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that I have read and agree to the terms of the documents"])},
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the list"])},
    "isRedundancyContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change the contact information you must visit the main line page. You can do it from here"])},
    "muffolaOfYourOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the drawer in the junction box directly used by your company?"])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No such solutions can be ordered at this time"])},
    "stepHalf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "step4Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Info"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required fields"])},
    "cardTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFRACAMPUS"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Caldera 21, 20153, Milan, Italy"])},
    "LOAExplenation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Letter of Authorization (LOA) is a document required to proceed with a Cross-Connect activation request. The LOA is a clear consent from the customer authorizing the Service Provider to make the requested connection."])},
    "locationLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Data Center where you would like to apply for activation of the LOCAL CROSS-CONNECT"])},
    "locationInfra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Data Center where you would like to apply for activation of the INFRACAMPUS CROSS-CONNECT"])},
    "notesAndTechDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes and Technical Details"])},
    "indicationAndPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Indication and location of the Device in the"])},
    "subTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFRACAMPUS Cross-Connects to MIX, Irideos, and Seeweb <strong>are made within 7 working days of the order</strong> and can be requested in redundant mode.\n<br/>All E100 Cross-Connects are “multimode bifiber”.\n<br/>To order an INFRACAMPUS Cross-Connect that is in the availability of another company, you must <strong>attach the LOA in PDF format.</strong>"])},
    "redundancyNotEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy not active for this solution"])},
    "sixmonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Six months"])},
    "anticipatedThrimester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly"])},
    "serviceDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Duration (months)"])},
    "deliveriesSubTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form below with all requested details"])},
    "ridondanza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy"])},
    "isRedundancyMainLineReadOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to view the Redundancy Line details "])},
    "isRedundancyMainLineReadOnlyPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For information on the required redundancy solution, see the details of the solution itself."])},
    "checkPrintPopUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you experience any problem dowloading the file, please check if pop-up are allowed on this site"])},
    "Click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click"])},
    "isMainLineReadOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view the main data you need to visit the main line page. You can do this from "])},
    "step3Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "wrongLoaFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The LOA must be in PDF format"])},
    "crossConnectReferrerPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "requiredWing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wing is required"])},
    "redundancyPossibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Redundancy with Path Differentiation can be requested for this Cross-Connect."])},
    "redundancyOrderItemRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy Product Reference"])},
    "wing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wing"])},
    "loaUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading file"])},
    "infoTitle_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before requesting a Cross-Connect, please read the introductory information carefully:"])},
    "infoTitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All <strong><u>E100 Cross-Connects</u></strong> are “multimode bifiber”"])},
    "infoTitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOCAL Cross-Connects can be requested in redundant mode."])},
    "infoTitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To order a LOCAL or INFRACAMPUS Cross-Connect to Customer Racks, it is essential to attach the <strong><u>LOA in PDF format.</u></strong>"])},
    "infoTitle_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong><u>LOCAL Cross-Connects</u></strong> are made within 1 working day of the request."])},
    "infoTitle_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong><u>INFRACAMPUS Cross-Connects</u></strong> to MIX, Irideos and Seeweb are completed within 7 working days of the request."])},
    "infoTitle_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All <strong><u>C21 Cross-Connects</u></strong> are “single-mode bifiber.”"])}
  },
  "fields": {
    "maxlength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["max length is ", _interpolate(_named("len")), " char"])}
  },
  "homePage": {
    "requestCrm": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending email"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Portal support"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your request"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "cardOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested solutions"])},
    "labelTwoMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main email"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit your profile"])},
    "ctaCrm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "ctaCrmNoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write to "])},
    "ctaCrmNoLinkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy address to clipboard"])},
    "ctaCrmLinkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address copied to clipboard."])},
    "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent"])},
    "noSolutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No order entered"])},
    "cardTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user profile"])},
    "subThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "readAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read all"])},
    "noMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No articles."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "subOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "labelThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "subMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read our latest articles"])},
    "labelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary email"])},
    "cardMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDLAN Magazine"])},
    "gotoMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the Magazine"])},
    "textCrm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need assistance with the portal?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your dashboard"])},
    "subTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cardThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiring solutions"])},
    "labelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "ctaCrmSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Portal Support"])}
  },
  "checkCoverage": {
    "speedUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload speed"])},
    "customerEndPIvaRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don’t enter a Tax Code, this field is required."])},
    "mobileTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The technician’s phone number is a required field."])},
    "nameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First and last name"])},
    "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coverage-check"])},
    "radiusAccountUsernameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username is a required field if you entered a Password."])},
    "statusStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
    "technicalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "emailTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The technician’s email is a required field."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the full address to see availability"])},
    "titleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Radius"])},
    "pricesKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing details"])},
    "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is a required field."])},
    "stepsButtonOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "speedDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download speed"])},
    "detailLabelCircuit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuit"])},
    "techRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical contact"])},
    "errorMessageAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The street name is a required field"])},
    "onSiteRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-site contact"])},
    "radiusAccountPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is a required field if you entered a Username."])},
    "timeSlotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building floor plan"])},
    "typeIPV4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPv4 subnet"])},
    "errorNoNetworkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no solutions available for the address selected"])},
    "goEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New solution validation"])},
    "saveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save the customer in the directory?"])},
    "titleReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End customer references"])},
    "stepsButtonThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "draftSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft saved"])},
    "emailAdministratorRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The administrative contact’s email is a required field."])},
    "migrationNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "placeholderSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for the solution"])},
      "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
      "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
      "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])}
    },
    "titleRelatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional options"])},
    "customerEndPIva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
    "subTitleRelatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of service according to the chosen technology"])},
    "titlePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
    "customerEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "buildingFloorRequiredTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building floor is a numeric field."])},
    "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for your kit..."])},
    "resultLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address entered"])},
    "cabinDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated distance from the cabin"])},
    "noteTechnicalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes or technical details"])},
    "buttonOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check coverage"])},
    "titleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End customer references"])},
    "subTitleMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to request migration of an existing access, enter the Donor Operator Migration code"])},
    "mobileAdministratorRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The administrative contact’s phone number is a required field."])},
    "titleMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olo2Olo migration"])},
    "tantum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-off"])},
    "subTitleReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your end customer, if already present, or enter a new one"])},
    "nameSurnameTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The technician’s first and last name is a required field."])},
    "emailOnSiteRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The on-site email is a required field."])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total activation"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the full address here"])},
    "priceMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly fee"])},
    "timeSlotEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evening"])},
    "timeSlotAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
    "updateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to update the customer in the directory?"])},
    "noteInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "titleStep5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olo2Olo migration"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not listed - add new"])},
    "buttonTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Solution"])},
    "errorMessageStreetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The house number is a required field"])},
    "timeSlotError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time slot is a required field if you have entered the building floor plan."])},
    "orderRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference"])},
    "clientPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphanumeric ID"])},
    "subTitleRadiusAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the radius account (optional)"])},
    "buildingFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building floor plan"])},
    "errorMessageProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province is a required field"])},
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "titleAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
    "statusStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Completion"])},
    "errorMessageCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City is a required field"])},
    "buildingFloorRequiredOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building floor is a required field if you have entered the time slot."])},
    "timeSlotMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning"])},
    "migrationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration code"])},
    "totalMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total monthly fee"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check coverage"])},
    "detailLabelTech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "isNotNumeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not a numerical value."])},
    "modalCustomerEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Customer"])},
    "noteRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "detailLabelOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution being activated"])},
    "priceTantum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation"])},
    "administratorRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative contact"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "meters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meters"])},
    "titleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Info"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required fields"])},
    "guaranteedMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum guaranteed bandwidth"])},
    "mobileOnSiteRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The on-site phone number is a required field."])},
    "detailLabelAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "stepsButtonFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and go to validation"])},
    "titleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional options"])},
    "nameSurnameOnSiteRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The on-site contact’s first and last name is a required field."])},
    "customerEndFiscalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax code"])},
    "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandwidth speed"])},
    "modalSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "timeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time slot"])},
    "forMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per month"])},
    "customerEndFiscalCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not enter the VAT number, this field is required."])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "timeSlotAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afternoon"])},
    "typeRouter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
    "nameSurnameAdministratorRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First and last name of the administrative contact is a required field."])},
    "nAnPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number must consist only of numbers"])},
    "helperSearchGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an address from those proposed"])},
    "detailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications of the solution at the address entered"])},
    "stepsButtonTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "statusStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed!"])},
    "searchKit": {
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Province"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. City"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Address"])},
      "civic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Civic"])}
    },
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])}
  },
  "checkCoverageSummary": {
    "renewalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal duration"])},
    "workingDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["working days"])},
    "subtitleAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information provided"])},
    "subTitleMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "titleRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of solution requested"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "subTitleReferencesSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the information entered carefully"])},
    "customerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer details"])},
    "subtitleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming the order I agree that it will be subject to the existing contractual terms and conditions"])},
    "titleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of solution being activated"])},
    "supplyActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution being activated"])},
    "offerInActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer being activated"])},
    "feesAndCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees and costs"])},
    "releaseTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release times"])},
    "subTitleRelatedServicesSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price details refer to the individual option"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Shopping Cart"])},
    "errorGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while saving. Try again or contact crm&#64;cdlan.it"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the information entered and confirm your request"])},
    "titleReadOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of solution requested"])},
    "minDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum duration"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
    "conditionsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Solution Summary"])}
  },
  "lines": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page shows the active lines"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrier"])},
    "backToLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to active lines"])},
    "linesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total active lines"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active lines found"])},
    "detail": {
      "orderInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
      "orderCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order code"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
      "cdLanActivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDLAN activation"])},
      "telecomActivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider activation"])},
      "summaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active line detail"])},
      "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
      "titleReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["References"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active lines"])},
    "detailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Line detail"])},
    "detailSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "additionalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional details"])},
    "list": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line ID"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Customer"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
    }
  },
  "cart": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm order"])},
    "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Shopping Cart"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel order"])},
    "totalMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order monthly fee"])},
    "priceActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation"])},
    "deleteSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete request"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Shopping Cart"])},
    "order": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Order"]), _normalize(["Orders"])])},
    "cartEmptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello. Your Shopping Cart is empty. Start by checking the coverage for the address you’re interested in"])},
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New solution"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change request"])},
    "forniture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "forMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly fee"])},
    "titlePriceOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional option fees"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "orderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order being activated"])},
    "expireDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can modify or confirm this Request by"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution details"])},
    "titlePriceSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution fees"])},
    "titlePriceXC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing details"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Activation"])},
    "qtyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty"])},
    "newSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New solution"])},
    "backToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Shopping Cart"])},
    "expireDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at the time"])},
    "cartEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Cart empty!"])},
    "newRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Request"])}
  },
  "removeItemCart": {
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Back to Shopping Cart to return to the order"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Delete Request to permanently delete the Solution. All data will be deleted."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Shopping Cart"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Request"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])}
  },
  "deleteOrderWizardNetwork": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are canceling the transaction: choose “Continue” to continue entering data or click “Cancel Request” to delete all information entered."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Cancel Request to delete all information entered."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])}
  },
  "removeAllItems": {
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Order"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Back to Shopping Cart to return to the order"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Delete Order to permanently cancel the Order. All information will be deleted"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Shopping Cart"])}
  },
  "deleteOrderWizardNetworkTmp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an order waiting to be confirmed. If you confirm the order cancellation, all information of all services in the order will be lost"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel the order, losing all the information you entered?"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Shopping Cart"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the order as a draft<br />and open the one requested"])}
  },
  "confirmOrder": {
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place a new order"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See your order"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order confirmed"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the “Requested solutions” page"])}
  },
  "KitNotFound": {
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to eliminate this and do a new search?"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place a new order"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Shopping Cart"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit no longer available!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The kit is no longer available so you can no longer edit this line"])}
  },
  "shipping": {
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of packages"])},
    "uploadAttachSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You may upload up to a maximum of ", _interpolate(_named("total")), " attachments that do not exceed ", _interpolate(_named("size")), " Mb total"])},
    "deleteTicketPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only alphanumeric values are allowed"])},
    "titleDcDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "uploadPickUpAttachSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach the packing list and waybill*"])},
    "onlyInteger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value entered is not an integer"])},
    "stepsButtonOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "pallets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of pallets"])},
    "moreInfoInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
    "deliveriesTitleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "waybill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach waybill"])},
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "distinct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach detail"])},
    "attachSize": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum limit of ", _interpolate(_named("size")), " Mb was exceeded"])},
    "dateAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected delivery date"])},
    "packagesShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package information"])},
    "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H x W x L (cm)"])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "cardTwoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book the service if you want CDLAN to deliver your goods to the shipper."])},
    "titleLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of arrival"])},
    "minValueValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The minimum value must be ", _interpolate(_named("n"))])},
    "deliveriesTitleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "textFiveShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions for Receiving Goods"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
    "attachFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("format")), " format is accepted"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "deliveriesSubTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form below with the requested information"])},
    "textFivePickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions for Collecting Goods"])},
    "pickUpTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
    "deliveriesTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods Detail"])},
    "pickUpTitleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
    "deliveriesSubTitleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide contact information. You will be contacted if needed"])},
    "waybillTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach waybill"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Deliveries' if you need to send one of your devices to CDLAN’s warehouse. ?Collections? if you need to ask CDLAN to deliver one of your devices to the shipper."])},
    "cardOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOODS RECEIPT"])},
    "pickupSubTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Data Center the shipper will pick up your devices from"])},
    "waybillOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm that you applied the waybill to the package?"])},
    "deliveriesSubTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Data Center to send your goods to"])},
    "dateAccessPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated collection date"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight (kg)"])},
    "descriptionInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "cardTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOODS DELIVERY"])},
    "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Data Center"])},
    "infoShippingPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection information"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods description"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
    "location": {
      "shipping": {
        "sectionThreeTitleC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt of Urgent Goods after hours"])},
        "sectionTwoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions for Receiving Goods"])},
        "sectionTwoLabelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight up to"])},
        "sectionTwoLabelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight greater than"])},
        "sectionTwoLabelTwoValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PALLET REQUIRED"])},
        "sectionFourLabelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight up to 25.0 kg"])},
        "sectionFourLabelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight greater than 25.0 kg"])},
        "sectionFiveTextE100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E100 depot available for storage until installation max 10 days"])},
        "sectionOneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods receiving hours"])},
        "sectionThreeTextC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We accept urgent goods. It is mandatory to open a ticket and submit a request. It is necessary to provide the shipper with the following phone number 351.551.0966"])},
        "sectionTwoLabelOneWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25.0 kg"])},
        "sectionTwoLabelThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods that do not meet the requirements will not be accepted"])},
        "sectionFourValueOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hour"])},
        "sectionFourValueTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 calendar days"])},
        "sectionFiveTextC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C21 depot available for storage until installation max 10 days"])},
        "sectionOneC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.00 am - 6.00 pm Monday through Friday"])},
        "sectionOneE100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.00 am - 12.00 noon, 2.00 - 6.00 pm Monday through Friday"])},
        "sectionTwoLabelOneValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STANDARDS"])},
        "sectionTwoLabelTwoWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25.0 kg"])},
        "sectionFourTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum advance notice"])}
      },
      "pickup": {
        "sectionTwoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For shipments over 25 kg, the goods must be placed on a pallet. The pallet (not provided by CDLAN) must be a euro pallet measuring 80x120 cm"])},
        "sectionFourText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the delivery of goods over 25.0 kg to the shipper, the goods must be placed on a euro pallet (not provided by CDLAN) measuring 80x120 cm"])},
        "sectionFiveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods that do not meet the requirements will not be delivered to the shipping company"])},
        "sectionOneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum advance notice"])},
        "sectionOneValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 calendar days"])},
        "sectionTwoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods delivery time"])},
        "sectionTwoC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.00 am - 6.00 pm Monday through Friday"])},
        "sectionTwoE100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.00 am - 12.00 noon, 2.00 - 6.00 pm Monday through Friday"])}
      }
    },
    "cardOneText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book the service if you want CDLAN to receive your goods at our C21 and E100 data centers. The service is operational for urgencies during non-working hours at C21 only."])},
    "deliveriesTitleStep5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
    "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping code"])},
    "deliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "deliveriesTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Data Center"])},
    "n_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket number"])},
    "uploadAttachPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The waybill is required."])},
    "shippingCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping code"])},
    "openTicketShippingPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "labelTicketShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "labelTicketPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])}
  },
  "deleteShippingTicket": {
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are canceling the transaction: choose “Continue” to continue entering data or click “Cancel Request” to delete all information entered."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All information entered so far will be lost."])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])}
  },
  "confirmShippingDeliveries": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request submitted successfully!"])},
    "sub": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We will arrange to receive your goods at ", _interpolate(_named("destination")), "."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request will soon be available in the Ticket area."])},
    "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Reservation"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Your Requests"])}
  },
  "deleteShippingPickUpTicket": {
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All information entered so far will be lost."])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the form"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to cancel the collection request? If you confirm the cancellation"])}
  },
  "confirmShippingPickUpDeliveries": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request submitted successfully!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will deliver your goods to the shipper."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request will soon be available in the Ticket area."])},
    "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Reservation"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Your Requests"])}
  },
  "users": {
    "surNameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "sectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the role to be assigned"])},
    "saveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save new user"])},
    "titleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
    "nameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "emailUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "revertDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
    "downLoadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "tablePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "subTitleEditUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update information"])},
    "newRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create a new custom role?"])},
    "tabTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROLES"])},
    "subTitleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "subSectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only assign one role per user"])},
    "exPhoneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: 39123456789"])},
    "tabOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USERS"])},
    "emailTwoCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;I want to use this secondary email to receive maintenance notifications"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "associateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate user"])},
    "errorEmailSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already exists"])},
    "tableEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "titleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "titleEditUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit "])},
    "notFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has an invalid format"])},
    "searchUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "abilityUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable user"])},
    "titleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a user to associate"])},
    "subTitleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user to associate with the role you’re creating."])},
    "btnNewRoleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add role"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "disableUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable user"])},
    "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receives maintenance at the address"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can manage users and the roles assigned to them"])},
    "sectionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "searchRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search role"])},
    "subTitleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete role"])},
    "btnNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new role"])},
    "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "detailsRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "phoneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "usersRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associated users:"])},
    "confirmUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "titleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details"])},
    "badEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email format"])},
    "subTitleDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete ", _interpolate(_named("name")), "?"])},
    "tableRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User role"])},
    "emailTwoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary email"])},
    "tableNameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name and First Name"])},
    "areaRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associated areas:"])},
    "textDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All associated information will be deleted"])},
    "deleteGroupTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete this role as long as there are associated users"])},
    "btnDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "maintenanceCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;I want to receive maintenance notifications on the main email"])}
  },
  "companies": {
    "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "btnNewRoleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add role"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "tableVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
    "titleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End-customer management"])},
    "subTitleEditUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update information"])},
    "saveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save new end customer"])},
    "areaRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associated areas:"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "emailTwoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary email"])},
    "subSectionFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information by on-site type"])},
    "disableUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable end customer"])},
    "badEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email format"])},
    "notFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has an invalid format"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can manage end customers"])},
    "subSectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information by technical type"])},
    "subTitleDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete ", _interpolate(_named("name")), "?"])},
    "titleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details"])},
    "btnDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical contact"])},
    "administrative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative contact"])},
    "sectionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "emailUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "usersRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associated users:"])},
    "editCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit "])},
    "emailTwoCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this secondary email to receive maintenance notifications"])},
    "sectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete final customer"])},
    "textDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All associated information will be deleted"])},
    "nameSurnameTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First and last name is a required field."])},
    "searchUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for end customer"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add end customer"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "abilityUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable user"])},
    "subTitleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user to associate with the role you’re creating."])},
    "on-site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-site contact"])},
    "emailTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is a required field."])},
    "mobileTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone is a required field."])},
    "btnNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new role"])},
    "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete role"])},
    "titleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a user to associate"])},
    "surNameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receives maintenance at the address"])},
    "subTitleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see the user’s information"])},
    "titleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete end customer"])},
    "errorEmailSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already exists"])},
    "downLoadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "phoneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "subSectionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information by administrative type"])},
    "textUsersRole": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Associated user"]), _normalize(["Associated users"])])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "revertDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "nameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "newRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create a new custom role?"])},
    "searchRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search role"])},
    "subTitleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add information to allow the new user to log in and operate"])},
    "confirmUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "tableNameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "tableCf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Code"])},
    "associateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate user"])}
  },
  "group": {
    "subTitleEditGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update information"])},
    "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user"])},
    "confirmUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "sectionOneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one area"])},
    "saveNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save new role"])},
    "subTitleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user to associate with the role you’re creating."])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "noAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot assign this area"])},
    "default": {
      "interventi_pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid services"])},
      "ruoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "ordini": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested solutions"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "storico_ingressi_dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC entry log"])},
      "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity"])},
      "ingressi_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry list"])},
      "incident_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident list"])},
      "assistenza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Support"])},
      "report_soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Report"])},
      "report_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "shipping_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping list"])},
      "ingressi_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "shipping_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
      "fatture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
      "storico_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket log"])},
      "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
      "assorbimenti_colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy consumption"])},
      "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
      "servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "gestione_clienti_finali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Customer Management"])},
      "cloud_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "voip_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voip"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "impostazioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "area_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Area"])},
      "first": {
        "accessi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesses"])},
        "cross_connect_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Cross-Connects"])},
        "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
        "change_request_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request list"])},
        "report_soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Report"])},
        "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
        "cross_connect_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Cross-Connects"])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
        "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report VoIP"])},
        "report_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Report"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
        "shipping_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping list"])},
        "utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
        "soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
        "stato_linee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active lines"])},
        "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
        "remote_hands_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Remote Hands"])},
        "shipping_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Shipping"])},
        "ordini": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested solutions"])},
        "ingressi_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry list"])},
        "servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
        "remote_hands_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands list"])},
        "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
        "ingressi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries"])},
        "incident_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident list"])},
        "virtualizzazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualization"])},
        "impostazioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "ruoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
        "help_ticket_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Request List"])},
        "notifiche_manutenzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance Notifications"])},
        "assorbimenti_colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy consumption"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity"])},
        "verifica_copertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage Check"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "ingressi_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New entries"])},
        "storico_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket log"])},
        "interventi_pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid services"])},
        "cross_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connects"])},
        "remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
        "gestione_utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
        "fatture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
        "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
        "assistenza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Support"])},
        "storico_ingressi_dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC entry log"])},
        "cloud_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
        "voip_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voip"])},
        "area_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Area"])}
      },
      "stato_linee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active lines"])},
      "cross_connect_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "ingressi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries"])},
      "help_ticket_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Request List"])},
      "cross_connect_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Cross-Connects"])},
      "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
      "remote_hands_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "virtualizzazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualization"])},
      "notifiche_manutenzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance Notifications"])},
      "accessi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesses"])},
      "remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
      "cross_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connects"])},
      "remote_hands_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands list"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "gestione_utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
      "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
      "change_request_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request list"])},
      "soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
      "verifica_copertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage Check"])},
      "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])}
    },
    "titleNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new role"])},
    "sectionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the areas associated with the role"])},
    "sectionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select users to be associated with the role"])},
    "titleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user to associate"])},
    "nameRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role name"])},
    "sectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a color to associate with the role"])},
    "colorGroupRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The color to be associated with a role"])},
    "titleEditGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the role"])},
    "subTitleNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "subSectionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At this stage you can associate users already in the system who do not have a role associated with them yet. Once you save the new role you can associate other users with it"])}
  },
  "help": {
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can check it here"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "emailRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact email"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To open support requests go to the solutions areas: "])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "secondContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other references"])},
    "confirmedTicketId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ticket ID is ", _interpolate(_named("confirmedRequestId"))])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "duplicatedTicketAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A request for this asset has already been opened"])},
    "incident": {
      "severityLevel1Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total or partial loss of service usability"])},
      "severityLevel3Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event with no impact on service "])},
      "goToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the list"])},
      "severityLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severity level"])},
      "severityLevelDescTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available levels:"])},
      "severityLevel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L1 - Disruption"])},
      "severityLevel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L2 - Deterioration"])},
      "severityLevel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L3 - Notification"])},
      "severityLevel4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S1 - Major fault"])},
      "severityLevel5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S2 - Moderate fault"])},
      "severityLevel6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S3 - Minor fault"])},
      "severityLevel2Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service works but does not perform properly"])},
      "goToListSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can monitor the status of your request by clicking the button below"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])}
    },
    "changeRequest": {
      "confirmedSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "confirmedTicketId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ID of your ticket is ", _interpolate(_named("changeRequestId"))])},
      "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
      "confirmedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request confirmed"])}
    },
    "detailTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request ", _interpolate(_named("ticket_id"))])},
    "secondContactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact email"])},
    "mainInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main information"])},
    "techSupport": {
      "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Support"])}
    },
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
    "confirmedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request confirmed"])},
    "solutionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution concerned"])},
    "generic": {
      "incident": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident - Generic"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Incident - No related solution"])}
      },
      "changeRequest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request - Generic"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Change Request - No related solution"])}
      },
      "techSupport": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Support - Generic"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Technical Support - No related solution"])}
      },
      "btnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cannot find the solution, click on these options to open a generic report"])},
      "solutionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report you are opening is not related to a specific solution"])}
    },
    "submitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "confirmedSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "colocation": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
    "itemCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total solutions: ", _interpolate(_named("total_count"))])},
    "genericInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
    "list": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
      "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])}
    },
    "detail": {
      "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
      "isle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle"])},
      "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared"])},
      "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
    },
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the list"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active service"])},
    "titleDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Detail"])},
    "subTitleDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can find the details of the selected solution"])},
    "solutions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active colocations associated with the company"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
      "data_attivazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation Date"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    }
  },
  "remoteHands": {
    "what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "Rapid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapid"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I authorize the inclusion of the charge for the service (where applicable) in the next billing cycle."])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "typeOperationOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of service available"])},
    "typeActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "detailLabelLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "subTitleDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you’ll find your communications with our operators"])},
    "infoRh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "labelDatacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "labelRack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
    "infoOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can enter the code of the purchase order issued by your company."])},
    "labelConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that I have read and agree to the terms of the documents"])},
    "titleDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket ID ", _interpolate(_named("ticket_id")), " - Thread"])},
    "infoOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "typeOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Service"])},
    "otherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other information:"])},
    "typeOperationTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapid"])},
    "infoOperationRh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location and service information:"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "titleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "subTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
    "subTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carefully read the Remote hand details"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "typeOperationFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performed without minimum guaranteed time in best-effort mode"])},
    "typeOperationFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "Standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "labelRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference"])},
    "typeOperationThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performed within two hours of the request"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of your latest Remote Hands"])},
    "titleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "titleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "subTitleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carefully read the Remote hand details"])},
    "valueDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "tipo_di_richiesta": {
      "riavvio_server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Restart"])},
      "sostituzione_ats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATS Replacement"])},
      "etichettatura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labeling"])},
      "altro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "altro_no_sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other (no SLA)"])}
    },
    "livello_tecnico": {
      "normale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B - Normal"])},
      "avanzato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A - Advanced"])}
    },
    "urgenza": {
      "pianificato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P - Pre-scheduled"])},
      "urgente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U - Urgent"])}
    },
    "finestra_temporale": {
      "bt_orario_ufficio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BT - Business Time"])},
      "etb_fuori_orario_ufficio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT - Extra Business Time"])}
    },
    "request": {
      "typeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Request"])},
      "selectTypeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type of Request"])},
      "typeRequestRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Request is required"])},
      "techLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Level"])},
      "selectTechLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Technical Level"])},
      "techLevelRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Level is required"])},
      "urgency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgency"])},
      "selectUrgency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Urgency"])},
      "urgencyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgency is required"])},
      "timeWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Window"])},
      "selectTimeWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Time Window"])},
      "timeWindowRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Window is required"])},
      "activityStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Start"])},
      "activityStartRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Start is required"])}
    },
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "summaryRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary is required"])}
  },
  "deleteRemoteHandTicket": {
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Cancel Request to delete all information entered."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are canceling the transaction: choose “Continue” to continue entering data or click “Cancel Request” to delete all information entered."])}
  },
  "confirmRemoteHand": {
    "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request submitted successfully!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request will soon be available in the Ticket area."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request will soon be available in the Ticket area."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the Ticket area"])}
  },
  "refTicket": {
    "surnameRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact’s last name"])},
    "phoneRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "emailRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "listUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User list"])},
    "nameRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact name"])}
  },
  "tickets": {
    "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic error"])},
    "noTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ticket found"])},
    "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["There is a new ticket"]), _normalize(["There are ", _interpolate(_named("n")), " new tickets"])])},
    "referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "OnHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "Closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "lastDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last ", _interpolate(_named("days")), " days"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Date"])},
    "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the list"])},
    "btnNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Request"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "noBookeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservation found"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "defaultDetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help request"])},
    "noUserBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not available"])}
  },
  "ticketsList": {
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you created a ticket and don't see it in the list, wait a few minutes and update it."])},
    "Ingresso": {
      "cf_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
      "cf_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket number"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Date"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])}
    },
    "Incident": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "ChangeRequest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "TechSupport": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "default": {
      "severity_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severity"])},
      "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
      "cross-connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening date"])},
      "asset_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Type"])},
      "accesso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesses"])},
      "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
      "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesses"])}
    },
    "RemoteHand": {
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket number"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Date"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "cf_sala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
      "cf_rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
      "cf_tipo_attivita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    },
    "Shipping": {
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "cf_servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket number"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Date"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "cf_data_arrivo_pacco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery/Collection date"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    },
    "ActiveLines": {
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Number"])},
      "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification Date"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
      "closedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure Date"])},
      "contactId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact ID"])},
      "threadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread Count"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "cf_short_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Title"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "cf_email_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referent Email"])},
      "cf_severity_level_picklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severity Level"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referent Name"])},
      "cf_sold_solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
      "cf_asset_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Type"])},
      "cf_servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])}
    },
    "lines": {
      "cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "intestatario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Client"])},
      "serialnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Line"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])}
    },
    "CrossConnect": {
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Number"])},
      "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified Time"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Type"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Time"])},
      "closedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Time"])},
      "contactId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact ID"])},
      "threadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread Count"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "cf_email_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referent"])}
    },
    "Cloud": {
      "ram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ram"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
      "solution_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution type"])},
      "vcpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCPU"])}
    },
    "Voip": {
      "solution_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution type"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])}
    }
  },
  "detailsTicket": {
    "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bcc"])},
    "attachMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments to this email"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply to the ticket"])},
    "uploadAttachSub": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You may upload up to a maximum of 5 attachments that do not exceed ", _interpolate(_named("size")), " Mb total"])},
    "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated on"])},
    "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "answersSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "answersBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply to the ticket"])},
    "modalSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket ID ", _interpolate(_named("ticket_id"))])},
    "IncidentDetailSolutionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cc"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread with operators"])},
    "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ID"])},
    "noReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your mail client to add information or reply to the ticket."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "removeAnswerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "page404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])}
  },
  "invoices": {
    "dateDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue date"])},
    "payDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "noDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoice present."])},
    "downDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "nDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document no."])},
    "typeDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document type"])}
  },
  "orders": {
    "backToOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Requested Solutions"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No solution requested."])},
    "orderRowRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serialnumber"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested solutions"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "nOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution number"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "additionalRefs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order references"])},
    "orderRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
    "requestDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation request date"])}
  },
  "filter": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTER"])}
  },
  "tour": {
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this area, you can view the active solutions and open tickets for incidents and/or change requests."])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this area, you can access services related to colocation solutions. Manage requests for Datacenter access, Remote Hands, and Shipping from here."])},
    "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View invoices and purchase requests."])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find reporting on the status of solutions and assistance in this area."])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users who can access the portal and assign roles to limit the visibility of portal areas."])},
    "partner_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your final customers' data from here."])}
  },
  "buttons": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])}
  },
  "accessDc": {
    "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "sharedRack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared Rack"])},
    "titleAccessDcReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "modalAccessUsersChooseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change entry type"])},
    "doc_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])},
    "doc_expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document expiry"])},
    "titleWhoShouldEnter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who must enter"])},
    "fromTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry hours"])},
    "labelCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the number of spaces you want to reserve"])},
    "modalAccessCarsSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don’t find the car you’re looking for, close the window and add it via the “Add Car” button provided"])},
    "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user found. Close the window and add it via the “New User” button provided"])},
    "titleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
    "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user"])},
    "addNewCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new car"])},
    "modalAccessDcRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are canceling the transaction: choose “Continue” to continue entering data or click “Cancel Request” to delete all information entered."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Cancel Request to delete all information entered."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])}
    },
    "timeConfirmationModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands 24/7"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the current entry request, the Remote Hands “Accompanying visitors/consultants to and from Client spaces with eventual observation during activities” will be opened for the selected shared rack: "])},
      "checkboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming I agree that it will be subject to the existing contractual terms and conditions"])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "btnAddressBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directory"])},
    "titleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry type"])},
    "modalAccessNewUserLabelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "errorCarsExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate number already exists."])},
    "addressBook": {
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
      },
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user in the directory"])},
      "deleteItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click here to delete ", _interpolate(_named("name")), ". Remember that you can re-enter it when opening entry tickets."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directory"])}
    },
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "allRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(entire room)"])},
    "errorNoType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one type of entry"])},
    "licenseCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car license plate"])},
    "modalAccessBioSuccess": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email confirming your request."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the Ticket area"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request submitted successfully!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "carSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected cars"])},
    "titleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who must enter"])},
    "noSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No spaces available at the selected times"])},
    "modalUsersSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the selected"])},
    "listUsersBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User list"])},
    "modalAccessUsersChooseSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have to let in people who do not have free access like you, you must change the reservation type."])},
    "identityCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID card"])},
    "mop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOP"])},
    "detailLabelDc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "errorNoUsersBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of places booked does not correspond to the number of people selected"])},
    "usersWithBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users with biometric entry"])},
    "keysLabelMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "titleWhen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
    "modalAccessUsersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User directory"])},
    "modalAccessNewUserSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add information to allow the new user to access the Data Center and work"])},
    "modalAccessDcInfo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be advised that you selected users who can access the Data Center without opening an entry ticket."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue filling out the form if you need to reserve CoWorking room stations or parking spaces, otherwise you can cancel your request."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add other users"])}
    },
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach the MOP"])},
    "uploadAttachSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry to the Meet Me Rooms is only allowed following specific authorization from CDLAN. To proceed please attach the completed Method of Procedure."])},
    "titleStep6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Entries"])},
    "titleStep8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking space reservation"])},
    "addNewUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new user"])},
    "nDesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of desks"])},
    "searchCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search car"])},
    "removeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to remove"])},
    "errorNoCars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one car"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "InfoAccessCarBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don’t find users in the list, you must open a third-party entry ticket by accessing the section “COWORKING ROOM ENTRY” or “DATA CENTER ROOM ENTRY” continuing to fill out the form"])},
    "accessInDC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC ENTRY"])},
    "nDeskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Desk"])},
    "summary": {
      "noteRoomsKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for the request"])},
      "subTitleRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "apparatino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "apparatifalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center selected"])},
      "coworkingWhoSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of users for whom you have requested entry"])},
      "refPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "apparatisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "referenceSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the reference information you entered carefully"])},
      "refName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "whoSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of users you requested entry for"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry ticket validation"])},
      "dateAndHourSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry date and time, and exit time"])},
      "keysForRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keys to access the room"])},
      "labelRackList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Racks Selected"])},
      "coworkingDeskSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "coworkingDeskBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of desks booked"])},
      "coworkingWhoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who must enter the CoWorking room"])},
      "referenceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the details of the ticket you’re opening carefully"])},
      "typeAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries requested"])},
      "rackList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of selected rooms and racks"])},
      "refEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "dateAndHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry date and time"])},
      "coworkingDeskTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoWorking Room"])},
      "RoomRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about this entry"])},
      "titleRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry summary"])},
      "whatSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you need to do and where"])},
      "warehouseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to the warehouse"])},
      "warehousesi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "warehouseno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    },
    "downloadMop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the MOP Template"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "modalAccessCarsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car directory"])},
    "UsersTitleBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users for whom the car is being reserved"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries"])},
    "dateAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry date"])},
    "roomsAndRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms and Racks"])},
    "selectRoomsKeysRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one room"])},
    "titleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center entry"])},
    "accessWhenTime": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From ", _interpolate(_named("from")), " to ", _interpolate(_named("to"))])},
    "selectAllRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "modalAccessUsersSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don’t find the user you’re looking for, close the window and add them via the “New User” button provided"])},
    "cardOneText7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request entry to C21, reserve your space in the CoWorking Room or reserve a parking space"])},
    "deselectAllRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselect all"])},
    "modalAccessNewCarSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the car ID field to enter a custom label to facilitate searches in future requests."])},
    "cardOneText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request entry to E100"])},
    "licenseDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
    "labelTimeCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "whenDeskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
    "listCarBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car list"])},
    "titleAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you need to book"])},
    "titleWhereAndWhat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center entry"])},
    "accessTypeThreeDescriptionComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve one or more parking spaces"])},
    "infoContentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open an entry ticket only in the following cases:"])},
    "chooseUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the users who need access"])},
    "infoCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can book individual desks for up to 4 hours and the entire CoWorking Room for up to 2 hours."])},
    "errorCars": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You cannot book more than ", _interpolate(_named("n")), " cars"])},
    "subTitleAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "keysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room keys"])},
    "accessTypeThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARKING SPACE RESERVATION"])},
    "UsersParaphBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries in the DC are controlled, if there are other users with you make sure they can access the DC or the CoWorking Room."])},
    "apparati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need to install devices?"])},
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can enter the C21 Data Center in Milan using facial biometric recognition."])},
    "tooHours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can’t book desks for more than ", _interpolate(_named("hours")), " hours."])},
    "newCarBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add car"])},
    "modalAccessNewCarTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new car to the directory"])},
    "goEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the Summary"])},
    "accessInCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COWORKING ENTRY"])},
    "infoContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul class=\"text-list\"><li>If the people who need to enter with you don’t have access via facial recognition.</li><li>To book desks or the entire CoWorking Room.</li><li>To book a parking space</li></ul>"])},
    "labelDateTimeCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
    "titleStep5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["References"])},
    "accessTypeOneDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request entry to data center rooms/isles/racks."])},
    "accessTypeOneDescriptionIsBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request access to data center rooms for third parties"])},
    "accessTypeTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COWORKING ROOM ENTRY"])},
    "accessTypeTwoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book one or more stations or the entire CoWorking Room"])},
    "accessTypeThreeDescriptionIsBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve one or more parking spaces."])},
    "modalAccessDcSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request submitted successfully!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request will soon be available in the Ticket area."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the Ticket area"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])}
    },
    "historicalAccessDc": {
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of entry"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of exit"])},
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
        "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
      },
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entry recorded"])},
      "filterInOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "filter": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRY"])},
        "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXIT"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC entry log"])}
    },
    "toTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit time"])},
    "selectRoomsKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your rooms"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "doc_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of document"])},
    "nameCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car ID"])},
    "titleUsersCoWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoWorking Room Reservation"])},
    "titleStep7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desk and Hours"])},
    "accessTypeThreeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve one or more parking spaces"])},
    "typeAccesslabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry type"])},
    "apparatusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "detailLabelRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested entry details"])},
    "newUserBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user"])},
    "titleDeskAndHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desk and Hours"])},
    "errorDesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of desks selected does not match the number of people entered "])},
    "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car"])},
    "accessDcList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE ENTRY"])},
    "accessTypeOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA CENTER ROOM ENTRY"])},
    "modalAccessNewUserLabelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "errorNoUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one person "])},
    "errorMoreUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of people selected is greater than the number of desks "])},
    "detailLabelRequestCars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car entry detail"])},
    "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need the keys to access the Room?"])},
    "noteRoomsKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justify the request"])},
    "modalAccessNewUserTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user to the directory"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "requestBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request now"])},
    "uploadMop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is mandatory to attach the MOP"])},
    "ticketList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKETS"])},
    "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need to access the warehouse?"])},
    "warehouseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to the warehouse"])},
    "warehouseYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
    "warehouseNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
    "coworkingAccessDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CoWorking Room booking is temporarily disabled due to ongoing work in the room."])},
    "modalWarehouse": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to the warehouse"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to access the warehouse, please fill in the Remote Hands ticket form, to which you will be redirected once you submit your request. If your operation in the warehouse lasts less than 15 minutes, it will be free of charge."])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I read"])}
    }
  },
  "modalUserDisabled": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Customer,\n\nDue to administrative reasons, your portal access is temporarily blocked.\n\nIn order to restore the access, please contact the administrative contact person of your company."])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay, I understand"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User disabled"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been disabled."])}
  },
  "modalSolutions": {
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])}
  },
  "tabs": {
    "ticketListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Requests"])},
    "listLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Solutions"])}
  },
  "reports": {
    "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
    "filter": {
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is a required field"])},
      "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])}
    },
    "historical": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket log"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening date"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of tickets"])}
      },
      "type": {
        "Cross connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect"])},
        "Change request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change request"])},
        "Feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "Access request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC entry"])},
        "Incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
        "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliveries"])},
        "Remote hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])}
      },
      "closeModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "moreTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the full log by <a href='https://portal.cdlan.it/' target='_blank'>clicking here <span class='material-icons'>open_in_new</span></a>"])},
      "modal": {
        "ticket_esteso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of tickets"])},
        "data_apertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening date"])},
        "customer_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact first and last name"])},
        "email_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact email"])},
        "telefono_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact phone"])},
        "messaggio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "access": {
          "traka_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traka keys"])},
          "data_richiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket opening date"])},
          "apparati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
          "messaggio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC entry ticket"])},
          "data_arrivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
          "meeting_room_paxtime_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From ", _interpolate(_named("from")), " to ", _interpolate(_named("to"))])},
          "meeting_room_pax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of desks in the CoWorking Room booked"])},
          "traka_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for keys"])},
          "dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
          "data_uscita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit date"])},
          "meeting_room_pax_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoWorking hours"])}
        },
        "incident": {
          "transito_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Transit"])},
          "id_apparato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
          "tipologia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident ticket"])},
          "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
          "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
          "serialnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serialnumber"])}
        },
        "rh": {
          "po_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference"])},
          "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
          "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands ticket"])},
          "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
          "liv_tecnico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical level"])},
          "liv_urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service time required"])}
        },
        "delivery": {
          "maggiori_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More info"])},
          "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (kg)"])},
          "dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
          "data_arrivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
          "spedita_da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped by"])},
          "bancali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pallets"])},
          "numero_pacchi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of packages"])},
          "descrizione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery ticket"])}
        },
        "xc": {
          "muffola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junction box"])},
          "attivazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation (€)"])},
          "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
          "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
          "panel_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack unit"])},
          "redundancyCta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view"])},
          "wing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wing"])},
          "noLoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive the LOA when the ticket is closed"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connect ticket"])},
          "port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patch panel to MMR"])},
          "po_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference"])},
          "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
          "redundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy"])},
          "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
          "loa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA"])},
          "canone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee (€)"])}
        },
        "cr": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change request ticket"])}
        }
      }
    },
    "voip": {
      "billDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caller"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Called"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry cost"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
        "colSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])}
      },
      "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
    },
    "fax": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page cost (€)"])},
        "colSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (€)"])}
      }
    },
    "paidInterventions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid services"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening date"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of document"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference"])},
        "colEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date"])},
        "colNine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost (€)"])},
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of tickets"])}
      },
      "type": {
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System support"])},
        "dc-remote-hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
        "dc-cross-connect-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connects"])},
        "dc-cross-connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-Connects"])},
        "dc-incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
        "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])}
      },
      "ATTIVAZIONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVATION"])},
      "CANONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEE"])},
      "RATEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYMENT"])}
    },
    "virtualization": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualization"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Name"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VM number"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vCPU"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MB RAM"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB Primary Storage"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB Secondary Storage"])}
      },
      "list": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
        "parentdomainid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Domain ID"])},
        "parentdomainname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Domain Name"])},
        "haschild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Children"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Path"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
        "vmlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VM Limit"])},
        "vmtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total VMs"])},
        "vmavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available VMs"])},
        "iplimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Limit"])},
        "iptotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total IPs"])},
        "ipavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available IPs"])},
        "volumelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume Limit"])},
        "volumetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Volume"])},
        "volumeavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Volume"])},
        "snapshotlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot Limit"])},
        "snapshottotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Snapshots"])},
        "snapshotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Snapshots"])},
        "templatelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Limit"])},
        "templatetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Templates"])},
        "templateavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Templates"])},
        "projectlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Limit"])},
        "projecttotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Projects"])},
        "projectavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Projects"])},
        "networklimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network Limit"])},
        "networktotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Networks"])},
        "networkavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Networks"])},
        "vpclimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VPC Limit"])},
        "vpctotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total VPCs"])},
        "vpcavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available VPCs"])},
        "cpulimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU Limit"])},
        "cputotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total CPUs"])},
        "cpuavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available CPUs"])},
        "memorylimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory Limit"])},
        "memorytotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Memory"])},
        "memoryavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Memory"])},
        "primarystoragelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Storage Limit"])},
        "primarystoragetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Primary Storage"])},
        "primarystorageavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Primary Storage"])},
        "secondarystoragelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Storage Limit"])},
        "secondarystoragetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Secondary Storage"])},
        "secondarystorageavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Secondary Storage"])},
        "hasannotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Annotations"])}
      }
    },
    "maintenanceNotifications": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance notifications"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Date"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance Start Date"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance End Date"])}
      }
    },
    "current": {
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
      "titleLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation current used"])},
      "ConsumiEnergiaDatacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center code"])},
      "ConsumiEnergiaSocketId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socket code"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "titleLegendExel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
      "ConsumiEnergiaColocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy consumption"])},
      "ConsumiEnergiaAmpere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amps billed in the month"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy consumption"])},
      "month": {
        "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
        "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
        "05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
        "07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
        "08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
        "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
        "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
        "06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
        "09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])}
      },
      "textLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following items are added to the monthly fee for the Colocation service for each rack in euros: <ul><li><u>Current Used</u> (UEC) = <strong>No. A</strong> * (<strong>Average monthly PUN</strong> * 0.368 + 29.256) where<ul><li><strong>No. A</strong> is the number of Amps calculated on the 97th percentile of the hourly current usage readings rounded up to the next higher integer;</li><li><strong>the monthly average PUN</strong> (Single National Price) for the relevant month is taken from the Energy Markets Manager (GME) website.</li></ul></li><li><uExcess Amps:</u> if the Current Used (UEC) exceeds the amount related to the contracted Allocated Power, an amount of €50 will be charged for each excess A.</li></ul>"])},
      "ConsumiEnergiaRackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack Code"])},
      "ConsumiEnergiaMagnetotermico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference month"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "typeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type"])},
      "ConsumiEnergiaLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
      "ConsumiEnergiaIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle Code"])},
      "ConsumiEnergiaRackName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack Name"])},
      "ConsumiEnergiaDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement date and time"])}
    },
    "cloud": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours of use"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly cost (€)"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total cost (€)"])},
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
      }
    }
  },
  "actions": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "text_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type at least 3 characters for your search"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])}
  },
  "noSolutionsServices": {
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>This service is linked to a solution that is not active, so you don’t have access to the content on the page.</span>"])},
    "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>This solution is not active and you don’t have access to the content on this page.</span><br /><br /><span style='font-size: 20px'>Our Colocation service is based on a robust infrastructure that is tailored to your needs. We offer flexible options, from Rack solutions to Private Suites, offering a service that is synonymous with quality, scalability and cutting-edge performance.</span>"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>This solution is not active and you don’t have access to the content on this page.</span><br /><br /><span style='font-size: 20px'>We offer state-of-the-art connection technologies designed to elevate productivity and ensure fast, stable connections with reduced latency.</span>"])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>This solution can only be requested if you have active Colocation solutions.</span>"])}
  },
  "cloud": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "TBD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information available soon"])},
    "assetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Type"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "tbyte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage TByte"])},
    "types": {
      "hosting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
      "iaas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Storage Quota"])},
      "iaas_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Backup"])},
      "iaas_cloud_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Cloud account"])},
      "iaas_resource_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Resource pool"])},
      "iaas_vm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - VM"])}
    }
  },
  "voip": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
    "TBD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information available soon"])},
    "assetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Type"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "types": {
      "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voip"])}
    }
  }
}