<template>
  <!-- UTENTI -->
  <div>
    <h2 v-if="!isPark">{{ $t(getTitle()) }}</h2>
    <p class="subTitle" v-if="false">
      {{ $t("accessDc.subTitleWhoShouldEnter") }}
    </p>
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour marginTop20">{{ $t("accessDc.chooseUsers") }}</p>
        <span class="error" v-if="errorNoUsers">
          {{ $t("accessDc.errorNoUsers") }}
        </span>
        <span class="error" v-if="errorMoreUsers">
          {{ $t("accessDc.errorMoreUsers") }}
        </span>
        <span class="error" v-if="errorNoUsersBio">
          {{ $t("accessDc.errorNoUsersBio") }}
        </span>
      </div>
      <div class="mdl-cell mdl-cell--12-col contentUsersSearch">
        <div
          v-for="(userSel, index) in usersSelected"
          :key="index"
          class="singleUser viewRead active"
          v-tooltip:bottom.tooltip="$t('accessDc.removeUser')"
          @click="removeUser(userSel.id, userSel.email)"
          :id="'user-sel-' + index"
        >
          <div v-if="userSel.name">
            {{ userSel.surname }} {{ userSel.name }}
          </div>
          <div v-else-if="userSel.in_lenel">
            {{ userSel.cognome }} {{ userSel.nome }}
          </div>
          <div class="material-icons">done</div>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--3-col">
        <MyLoading :viewLoader="viewLoaderUsers" type="" />
        <button
          class="cdlButton"
          id="btn-add-users"
          @click="openModal()"
          v-if="viewLoaderUsers"
        >
          {{ $t("accessDc.listUsersBtn") }}
        </button>
      </div>
      <div class="mdl-cell mdl-cell--9-col"></div>
    </div>

    <hr />
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour marginTop20">{{ $t("accessDc.addNewUsers") }}</p>
      </div>
      <div class="mdl-cell mdl-cell--3-col">
        <button
          class="cdlButton"
          @click="!isPark ? openModalNewUser() : openModalChoose()"
        >
          {{ $t("accessDc.newUserBtn") }}
        </button>
      </div>
      <div class="mdl-cell mdl-cell--9-col"></div>
    </div>
  </div>

  <!-- MODALE LISTA UTENTI -->
  <div
    class="ModalAccessUsers"
    :class="{ open: viewModal, close: viewModalClose }"
  >
    <div class="modal-background">
      <div class="contentModal modal">
        <span class="material-icons closeOrder" @click="openModal()"
          >close</span
        >
        <h3>{{ $t("accessDc.modalAccessUsersTitle") }}</h3>
        <p class="subTitle">
          {{ $t("accessDc.modalAccessUsersSubTitle") }}
        </p>
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentInputSearch">
              <div><span class="material-icons searchIcon">search</span></div>
              <div class="contentInput">
                <input
                  type="text"
                  :placeholder="$t('accessDc.searchUser')"
                  @keyup="searchByString"
                  v-model="searchString"
                />
              </div>
            </div>
          </div>

          <div class="mdl-cell mdl-cell--12-col" v-if="!viewBtnModal">
            <p class="labelFour">{{ $t("accessDc.noUsers") }}</p>
          </div>

          <div class="mdl-cell mdl-cell--12-col contentUsersSearch">
            <div class="mdl-grid" id="listUsersBio" v-if="viewUsersBio">
              <div
                class="mdl-cell mdl-cell--3-col singleUser"
                v-for="(userBio, index) in usersBio"
                :key="index"
                @click="setUserBio(userBio)"
                :id="['userBio-' + userBio.email]"
                :class="{ view: userBio.in_lenel }"
              >
                <div v-if="isBio">
                  <span class="material-icons">fingerprint</span>
                </div>
                <div>{{ userBio.cognome }} {{ userBio.nome }}</div>
                <div class="material-icons iconDone">done</div>
              </div>
            </div>
            <div class="mdl-grid" id="listUsersNoBio">
              <div
                class="mdl-cell mdl-cell--3-col singleUser view"
                v-for="(userNorm, index) in users"
                :key="index"
                @click="setUser(userNorm.id)"
                :id="['user-' + userNorm.id]"
              >
                <div>{{ userNorm.surname }} {{ userNorm.name }}</div>
                <div class="material-icons iconDone">done</div>
              </div>
            </div>
          </div>

          <div class="mdl-cell mdl-cell--12-col" v-if="viewBtnModal">
            <div class="contentButton">
              <button
                id="submitCustomerEnd"
                class="cdlButtonGreen"
                @click="openModal()"
              >
                {{ $t("accessDc.modalUsersSubmit") }}
              </button>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col" v-else>
            <div class="contentButton">
              <button
                id="submitCustomerEnd"
                class="cdlButton"
                @click="openModal()"
              >
                {{ $t("accessDc.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FINE MODALE LISTA UTENTI -->

  <!-- MODALE NUOVO UTENTE -->
  <div
    class="ModalAccessUsers"
    :class="{ open: viewModalNewUser, close: viewModalNewUserClose }"
  >
    <div class="modal-background">
      <div class="contentModal modal">
        <span class="material-icons closeOrder" @click="openModalNewUser()"
          >close</span
        >
        <h3>{{ $t("accessDc.modalAccessUsersTitle") }}</h3>
        <p class="subTitle" v-if="false">
          {{ $t("accessDc.modalAccessUsersSubTitle") }}
        </p>
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <p class="labelTwo">
              {{ $t("accessDc.modalAccessNewUserLabelOne") }}*
            </p>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="text"
                name="name"
                v-model="user.name"
                @input="label($event)"
                maxlength="50"
              />
              <label>{{ $t("accessDc.name") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.user.name.$invalid &&
                  this.v$.user.name.$dirty
                "
              >
                {{ $t("accessDc.name") }} {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="text"
                name="surname"
                v-model="user.surname"
                @input="label($event)"
                maxlength="50"
              />
              <label>{{ $t("accessDc.surname") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.user.surname.$invalid &&
                  this.v$.user.surname.$dirty
                "
              >
                {{ $t("accessDc.surname") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="text"
                name="company"
                v-model="user.company"
                @input="label($event)"
                maxlength="50"
              />
              <label>{{ $t("accessDc.company") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.user.company.$invalid &&
                  this.v$.user.company.$dirty
                "
              >
                {{ $t("accessDc.company") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="text"
                name="email"
                v-model="user.email"
                @input="label($event)"
                maxlength="100"
              />
              <label>{{ $t("accessDc.email") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.user.email.$invalid &&
                  this.v$.user.email.$dirty
                "
              >
                {{ $t("accessDc.email") }} {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <hr />
          </div>
          <div class="mdl-cell mdl-cell--12-col" style="text-align: left">
            <span class="labelTwo"
              >{{ $t("accessDc.modalAccessNewUserLabelTwo") }}*</span
            >
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input" style="margin-top: 0; text-align: left">
              <span class="labelOne">{{ $t("accessDc.doc_type") }}*</span>
              <select
                name="doc_type"
                v-model="user.doc_type"
                @input="label($event)"
                style="margin-left: 0; width: 104%"
              >
                <option :value="$t('accessDc.identityCard')">
                  {{ $t("accessDc.identityCard") }}
                </option>
                <option :value="$t('accessDc.passport')">
                  {{ $t("accessDc.passport") }}
                </option>
                <option :value="$t('accessDc.licenseDrive')">
                  {{ $t("accessDc.licenseDrive") }}
                </option>
              </select>
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.user.doc_type.$invalid &&
                  this.v$.user.doc_type.$dirty
                "
              >
                {{ $t("accessDc.doc_type") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <br />
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="text"
                name="doc_number"
                v-model="user.doc_number"
                @input="label($event)"
                maxlength="50"
              />
              <label>{{ $t("accessDc.doc_number") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.user.doc_number.$invalid &&
                  this.v$.user.doc_number.$dirty
                "
              >
                {{ $t("accessDc.doc_number") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="date"
                name="doc_expiry"
                v-model="user.doc_expiry"
                @input="label($event)"
                class="not-empty"
                :min="getDate()"
              />
              <label>{{ $t("accessDc.doc_expiry") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.user.doc_expiry.$invalid &&
                  this.v$.user.doc_expiry.$dirty
                "
              >
                {{ $t("accessDc.doc_expiry") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col"></div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentButton">
              <button
                id="submitCustomerEnd"
                class="cdlButtonGreen"
                @click="saveUser()"
              >
                {{ $t("checkCoverage.modalSubmit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FINE MODALE NUOVO UTENTE -->

  <!-- MODALE SCEGLI -->
  <div
    class="ModalAccessUsers"
    :class="{ open: viewModalChoose, close: viewModalChooseClose }"
  >
    <div class="modal-background">
      <div class="contentModal modal">
        <span class="material-icons closeOrder" @click="openModalChoose()"
          >close</span
        >
        <h3>{{ $t("accessDc.modalAccessUsersChooseTitle") }}</h3>
        <p class="subTitle">
          {{ $t("accessDc.modalAccessUsersChooseSubTitle") }}
        </p>
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="chooseAccessDc" @click="changeTypeAccessDc('dc')">
              {{ $t("accessDc.accessInDC") }}
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div
              class="chooseAccessDc"
              @click="changeTypeAccessDc('coworking')"
            >
              {{ $t("accessDc.accessInCoworking") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FINE MODALE SCEGLI -->

  <!-- FINE UTENTI -->
</template>

<script>
import MyLoading from "@/components/MyLoading.vue";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf, email } from "@vuelidate/validators";
import { useAccessDcStore } from "@/stores/AccessDcStore";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "AccessDcUser",
  components: {
    MyLoading,
  },
  setup() {
    const accessDcStore = useAccessDcStore();
    return {
      v$: useVuelidate(),
      accessDcStore,
    };
  },
  validations() {
    return {
      user: {
        name: { requiredIfFoo: requiredIf(this.viewModalNewUser) },
        surname: { requiredIfFoo: requiredIf(this.viewModalNewUser) },
        company: { requiredIfFoo: requiredIf(this.viewModalNewUser) },
        email: { requiredIfFoo: requiredIf(this.viewModalNewUser), email },
        doc_type: { requiredIfFoo: requiredIf(this.viewModalNewUser) },
        doc_number: { requiredIfFoo: requiredIf(this.viewModalNewUser) },
        doc_expiry: { requiredIfFoo: requiredIf(this.viewModalNewUser) },
      },
    };
  },
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
    errorNoUsersDad: {
      type: Boolean,
      required: true,
    },
    errorMoreUsersDad: {
      type: Boolean,
      required: true,
      default: false,
    },
    typeUsers: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      viewModal: false,
      viewModalClose: false,
      viewModalNewUser: false,
      viewModalNewUserClose: false,
      usersSelected: [],
      searchString: "",
      user: {
        name: null,
        surname: null,
        company: null,
        email: null,
        doc_type: null,
        doc_number: null,
        doc_expiry: null,
      },
      // errorNoUsers: false,
      limitUser: 0,
      errorNoUsersBio: false,
      usersBio: [],
      isBio: false,
      viewLoaderUsers: false,
      nPaxBio: 0,
      nPax: 0,
      vieModalInfo: false,
      iWantGo: false,
      goSummary: null,
      viewUsersBio: true,
      viewUsersNoBio: true,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": " multipart/form-data",
      },
      isPark: false,
      viewModalChoose: false,
      viewModalChooseClose: false
    };
  },
  methods: {
    // CHI
    openModal() {
      this.viewModal = !this.viewModal;
      if (this.viewModal) {
        this.viewModalClose = false;
        this.searchString = "";
        this.viewUsersBio = true;
        this.viewUsersNoBio = true;
        this.viewAll();
        if (this.usersSelected.length > 0) {
          setTimeout(() => {
            this.usersSelected.forEach((elm) => {
              if (elm.in_lenel == true) {
                if (
                  elm.email &&
                  document.getElementById("userBio-" + elm.email)
                ) {
                  document
                    .getElementById("userBio-" + elm.email)
                    .classList.add("active");
                }
              } else {
                if (elm.id && document.getElementById("user-" + elm.id)) {
                  document
                    .getElementById("user-" + elm.id)
                    .classList.add("active");
                }
              }
            });
          }, 500);
        }
      } else {
        this.viewModalClose = true;
      }
    },
    openModalNewUser() {
      this.viewModalNewUser = !this.viewModalNewUser;
      if (!this.viewModalNewUser) {
        this.viewModalNewUserClose = true;
      } else {
        this.viewModalNewUserClose = false;
      }
    },
    openModalChoose() {
      this.viewModalChoose = !this.viewModalChoose;
      if (!this.viewModalChoose) {
        this.viewModalChooseClose = true;
      } else {
        this.viewModalChooseClose = false;
      }
    },
    setUser(id) {
      this.errorNoUsers = false;
      this.errorNoUsersBio = false;
      if (document.getElementById("user-" + id).classList.contains("active")) {
        document.getElementById("user-" + id).classList.remove("active");
        if (this.usersSelected.length == 1) {
          this.usersSelected = [];
          this.nPax = 0;
        } else {
          this.usersSelected.forEach((el, i) => {
            if (el.id == id) {
              this.nPax--;
              this.usersSelected.splice(i, 1);
            }
          });
        }
      } else {
        document.getElementById("user-" + id).classList.add("active");
        this.users.forEach((user) => {
          if (user.id == id) {
            this.nPax++;
            this.usersSelected.push(user);
          }
        });
      }
      this.$emit("setUsers", this.usersSelected);
    },
    removeUser(id, email) {
      this.errorNoUsers = false;
      this.errorNoUsersBio = false;
      this.usersSelected.forEach((el, i) => {
        if (id) {
          if (el.id == id) {
            this.usersSelected.splice(i, 1);
            if (el.in_lenel) {
              this.nPaxBio--;
            } else {
              this.nPax--;
            }
          }
        } else {
          if (el.email == email) {
            this.usersSelected.splice(i, 1);
            if (el.in_lenel) {
              this.nPaxBio--;
            } else {
              this.nPax--;
            }
          }
        }
      });
      if (this.usersSelected.length == 0) {
        this.errorNoUsers = true;
      }
      this.$emit("setUsers", this.usersSelected);
    },
    searchByString() {
      if (this.searchString.length == 0) {
        this.viewUsersBio = true;
        this.viewUsersNoBio = true;
        this.viewAll();
        return;
      }

      this.hideAll();

      this.viewUsersBio = false;
      this.viewUsersNoBio = false;

      this.viewBtnModal = false;

      this.users.forEach((elm) => {
        if (
          elm.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          elm.surname.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
          this.viewUsersNoBio = true;
          if (document.getElementById("user-" + elm.id)) {
            document.getElementById("user-" + elm.id).classList.add("view");
            this.viewBtnModal = true;
          }
        }
      });

      this.usersBio.forEach((elm) => {
        if (
          elm.nome.toLowerCase().includes(this.searchString.toLowerCase()) ||
          elm.cognome.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
          this.viewUsersBio = true;
          if (document.getElementById("userBio-" + elm.email)) {
            document
              .getElementById("userBio-" + elm.email)
              .classList.add("view");
            this.viewBtnModal = true;
          }
        }
      });
    },
    viewAll() {
      let allInputs = document.getElementsByClassName("singleUser");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.add("view");
      }
      this.viewBtnModal = true;
    },
    hideAll() {
      let allInputs = document.getElementsByClassName("singleUser");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.remove("view");
      }
    },
    async saveUser() {
      const result = await this.v$.$validate();
      if (result) {
        this.v$.$reset();
        let userStore = useUserStore();
        let data = this.user;
        data.gamma_id = this.wizardData.gammaId;
        data.language = this.wizardData.lang;
        data.mod_by =
          "ASSENZIO - " +
          userStore.user.first_name +
          " " +
          userStore.user.last_name;

        this.axios
          .post(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "front/v1/user_new",
            data,
            { headers: this.he }
          )
          .then((rls) => {
            if (rls.data.status == "ok") {
              this.usersSelected.push(rls.data.data);
              this.$emit("setUsers", this.usersSelected);
              this.getAllUsers();
              this.openModalNewUser();
            }
          });
      }
    },
    getAllUsers() {
      let userStore = useUserStore();
      const self = this;
      let ctrl = false;
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/get-users/" +
            this.wizardData.gammaId,
          { headers: this.he }
        )
        .then((rls) => {
          this.users = !this.isPark ? rls.data.data : [];
          if (this.usersSelected.length == 0) {
            if (this.isBio) {
              this.usersBio.forEach((bio) => {
                if (bio.email == userStore.user.email && !ctrl) {
                  bio.in_lenel = true;
                  this.usersSelected.push(bio);
                  this.$emit("setUsers", this.usersSelected);
                  ctrl = true;
                  this.nPaxBio = 1;
                }
              });
            } else {
              this.users.forEach((elm) => {
                if (elm.email == userStore.user.email && !ctrl) {
                  this.usersSelected.push(elm);
                  this.$emit("setUsers", this.usersSelected);
                  ctrl = true;
                  this.nPax = 1;
                }
              });
            }
          }
          this.viewLoaderUsers = true;
        })
        .catch(() => {
          return self.$router.push({
            name: "AccessRequest" + self.$i18n.locale,
          });
        });
    },
    getDate() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth() + parseInt(1);
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return date.getFullYear() + "-" + mm + "-" + dd;
    },
    setUserBio(user) {
      this.errorNoUsers = false;
      this.errorNoUsersBio = false;
      if (
        document
          .getElementById("userBio-" + user.email)
          .classList.contains("active")
      ) {
        document
          .getElementById("userBio-" + user.email)
          .classList.remove("active");
        if (this.usersSelected.length == 1) {
          this.nPaxBio = 0;
          this.usersSelected = [];
        } else {
          this.usersSelected.forEach((el, i) => {
            if (el.email == user.email) {
              this.nPaxBio--;
              this.usersSelected.splice(i, 1);
            }
          });
        }
      } else {
        document
          .getElementById("userBio-" + user.email)
          .classList.add("active");
        this.nPaxBio++;
        this.usersSelected.push(user);
      }
      this.$emit("setUsers", this.usersSelected);
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    changeTypeAccessDc(type) {
      this.viewModalChoose = false;
      this.viewModalChooseClose = false;
      this.$emit("changeDcType", type);
    },
    getTitle() {
      if (this.typeUsers == "coworking") {
        return "accessDc.titleUsersCoWorking";
      }

      return "accessDc.titleWhoShouldEnter";
    },
  },
  activated() {
    // CHI
    this.errorNoUsersBio = false;
    if (this.wizardData.form.dc.users.length > 0 && this.typeUsers == "dc") {
      this.usersSelected = this.wizardData.form.dc.users;
    }
    if (
      this.wizardData.form.coworking.users.length > 0 &&
      this.typeUsers == "coworking"
    ) {
      this.usersSelected = this.wizardData.form.coworking.users;
    }

    let accessDcStore = useAccessDcStore();

    this.isPark = accessDcStore.form.typeAccess[0] == "parking" ? true : false;

    if (this.isPark) {
      this.usersSelected = this.wizardData.form.parking.users;
    }

    // this.isPark = this.typeUsers != 'parking' ? false : true;

    this.$emit("setUsers", this.usersSelected);
  },
  mounted() {
    if (this.users.length == 0) {
      let accessDcStore = useAccessDcStore();
      this.usersBio = accessDcStore.usersBio;
      this.isBio = accessDcStore.isBio;
      this.getAllUsers();
    }
  },
  watch: {
    errorNoUsersDad: {
      handler(newValue) {
        this.errorNoUsers = newValue;
      },
    },
    errorMoreUsersDad: {
      handler(newValue) {
        this.errorMoreUsers = newValue;
      },
    },
  },
};
</script>
