<template>
  <tr class="dateRow">
    <td colspan="2">
      {{ getDateOrder(order.submission_date) }}
      {{ $t("cart.expireDateTime") }}
      {{ getTimeOrder(order.submission_date) }}
    </td>
  </tr>
  <tr v-for="(item, k) in content" :key="k" :class="{ odd: k % 2 }">
    <td>
      {{ item.kit_description }}
      <small
        v-if="item.category === 'XCONNECT'"
        v-show="
          item.category_specific_fields.cross_connect_details.is_redundant &&
          item.category_specific_fields.cross_connect_details.master_id
        "
        style="
          text-transform: uppercase;
          background-color: bisque;
          padding: 5px;
          border-radius: 5px;
        "
        >{{ $t("crossConnects.ridondanza") }}</small
      >
    </td>
    <td
      style="position: relative"
      class="alignRight"
      v-if="item.category == 'ACCESSO'"
    >
      <div class="actionContainer alignRight" v-if="item.category == 'ACCESSO'">
        <a class="cdlButton" @click="getOrderDetail('OrderDetails', order.id, item.id)">
          {{ $t("orders.details") }}
        </a>
      </div>
    </td>
    <td v-else>
      <div
        class="actionContainer alignRight"
        v-if="item.category == 'XCONNECT'"
      >
        <a
            @click="getOrderDetail('CrossConnectOrderSummary', order.id, item.id)"
            class="cdlButton"
        >
          {{ $t("orders.details") }}
        </a>
      </div>
    </td>
  </tr>
</template>

<style scoped>
.dateRow {
  background-color: #eeeeee;
}
</style>

<script>
import moment from "moment";

export default {
  name: "MobileOrderTableRow",
  props: {
    order: Object,
    content: Object,
  },
  methods: {
    getOrderDetail(componentName, orderId, ItemId) {
      return this.$router.push({
        name: componentName + this.$i18n.locale,
        params: {
          order_id: orderId,
          item_id: ItemId,
        },
      });
    },
    getDateOrder(dateOrder) {
      let nameDay = moment(
        new Date(dateOrder),
        "YYYY-MM-DD",
        this.$i18n.locale,
        true
      ).format("dddd");
      let date = moment(
        new Date(dateOrder),
        "YYYY-MM-DD, hh:mm:ss",
        this.$i18n.locale,
        true
      ).format("LL");
      return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
    },
    getTimeOrder(dateOrder) {
      let time = moment(
        new Date(dateOrder),
        "HH:mm:ss",
        this.$i18n.locale,
        true
      ).format("HH:mm");
      return time;
    },
  },
};
</script>
