import { useBreadCrumbsStore } from "@/stores/BreadCrumbsStore";
import { ctrlPermissionsRoot } from "@/services/Permissions";
import { SetNavigator } from "@/services/SetNavigator";

export function createBreadCrumbs(from) {
  if (document.getElementsByTagName("body")[0].classList.contains("overflow")) {
    document.getElementsByTagName("body")[0].classList.remove("overflow");
  }

  const breadCrumbsStore = useBreadCrumbsStore();
  breadCrumbsStore.setBreadCrumbs(from);
  return true;
}

export async function setNavigator(to, from, next) {
  if (to.meta.permissions && to.meta.permissions.length > 0) {
    let permission = to.meta.permissions;
    let hasPermission = await ctrlPermissionsRoot(permission);
    if (hasPermission) {
      SetNavigator(to.fullPath, to.meta.indexRoute);
      next(); // Proceed to the route
    } else {
      // Redirect to "No Permissions" page
      next({ name: "NoPermission" });
    }
  } else {
    SetNavigator(to.fullPath, to.meta.indexRoute);
    next(); // Proceed to the route
  }
}
