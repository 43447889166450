<template>
  <div class="contentFormTicket">
    <div class="contentForm">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--8-col">
          <h2 class="title">{{ $t("shipping.deliveriesTitleStep4") }}</h2>
          <p class="subTitle">{{ $t("shipping.deliveriesSubTitleStep4") }}</p>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="locationSelected">
            <p class="labelDestination">{{ $t("shipping.destination") }}</p>
            <p class="textDestination">
              {{ this.wizardData.target_datacenter }}
            </p>
            <p class="addressDestination">{{ this.wizardData.dc_address }}</p>
          </div>
        </div>
      </div>

      <div class="space"></div>

      <RefTicket @update="updateRef" @validate="validate" />

      <div v-if="this.btnSubmit">
        <div class="cdlContentButtonDouble">
          <div
            class="cdlButtonSingle green width170"
            id="backStep"
            @click="goBack()"
          >
            {{ $t("checkCoverage.stepsButtonTwo") }}
          </div>
          <div
            class="cdlButtonSingle green width170"
            id="nextStep"
            @click="openTicket()"
          >
            {{ $t("shipping.openTicket") }}
          </div>
        </div>
        <p class="deleteOrder" @click="cancelOrder()">
          {{ $t("shipping.deleteTicket") }}
        </p>
      </div>

      <MyLoading :viewLoader="this.btnSubmit" type="" />
    </div>
  </div>
</template>

<script>
import RefTicket from "@/components/RefTicket.vue";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "MyInfoReference",
  components: {
    RefTicket,
    MyLoading,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
      },
      canGo: false,
      btnSubmit: true,
    };
  },
  methods: {
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
    goBack() {
      this.$emit("goBack");
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    submit() {
      this.$emit("update", this.fields);
    },
    openTicket() {
      // window.scrollTo(0, 0);
      if (this.canGo) {
        this.btnSubmit = false;
        this.$emit("openTicket");
      }
      return;
    },
    updateRef(stepData) {
      Object.assign(this.fields, stepData);
      this.$emit("update", this.fields);
    },
    validate(result) {
      this.canGo = result;
    },
    viwBtnSubmit() {
      this.btnSubmit = !this.btnSubmit;
    },
    cancelOrder() {
      this.$emit("close");
    },
  },
  activated() {
    window.scrollTo(0, 0);
  },
};
</script>
