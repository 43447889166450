<template>
  <div id="OrderNetworkCoverage">
    <div class="contentSteps">
      <div class="steps">
        <div class="step" :class="{ active: getStep() == key + 1, complete: getStep() > key + 1 }"
          v-for="(listStep, key) in getSteps()" :key="key" @click="getStep() > key + 1 ? setStep(key + 1) : null;">
          <div>
            <component :is="listStep.icon"></component>
          </div>
          <div>
            <p class="nameStep"><span v-if="!IsMobile">{{ key + 1 }}.</span> {{ $t(listStep.title) }}</p>
            <p class="statusStep" v-if="getStep() == key + 1 && false">{{ $t('checkCoverage.statusStep1') }}</p>
            <p class="statusStep" v-else-if="getStep() > key + 1 && false">{{ $t('checkCoverage.statusStep2') }}</p>
            <p class="statusStep" v-else-if="getStep() < key + 1 && false">{{ $t('checkCoverage.statusStep3') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="contentForm">
      <div class="contentPrimary contentPrimaryMobile" :class="getClassKit(kit.technology)" v-if="IsMobile">
        <h3 @click="openInfoMobile()">
          <img :src="getIcon(kit.technology)" alt="icon" width="25" />
          {{ $t('checkCoverage.detailLabelOffer') }}
          <span class="material-icons" v-if="!infoMobile">expand_more</span>
          <span class="material-icons" v-if="infoMobile">expand_less</span>
        </h3>
        <div v-if="infoMobile">
          <table class="contentInfoMobile">
            <tr>
              <td><img :src="getIcon(kit.technology)" alt="icon" width="25" /></td>
              <td><span class="labelS">{{ $t('checkCoverage.detailLabelTech') }}</span> <span class="textS">{{ title
              }}</span></td>
            </tr>
            <tr>
              <td></td>
              <td><span class="labelS">Carrier</span> <span class="textS">{{ kit.carrier }}</span></td>
            </tr>
            <tr>
              <td><span class="material-icons locationIcon">location_on</span></td>
              <td><span class="labelS">{{ $t('checkCoverage.detailLabelAddress') }}</span>
                <span class="textS">{{ address.address.toUpperCase() }} {{ address.streetNumber }} {{
                  address.city.toUpperCase() }} {{ address.province.toUpperCase() }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="contentPrimary" :class="getClassKit(kit.technology)" v-if="!IsMobile">
        <img :src="getIcon(kit.technology)" alt="icon" width="45" />
        <p class="label">{{ $t('checkCoverage.detailLabelOffer') }}</p>
        <p class="text">{{ title }}</p>
        <p>
          <span class="labelS">{{ $t('checkCoverage.detailLabelTech') }}</span> <span class="textS">{{ kit.technology
          }}</span>
          <span class="labelS">Carrier</span> <span class="textS">{{ kit.carrier }}</span>
        </p>
        <hr />
        <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
        <!-- <span class="material-icons locationIcon">location_on</span> -->
        <p class="label">{{ $t('checkCoverage.detailLabelAddress') }}</p>
        <p class="text">{{ address.address }} {{ address.streetNumber }} {{ address.city }} {{ address.province }}</p>
      </div>
      <div class="contentSecondary">
        <keep-alive>
          <component :is="steps[step - 1]" @update="processStep" @goBack="goBack" @goNext="goNext" @close="cancelOrder"
            :wizard-data="form" :kit="kit" :step-data="step"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import "@/assets/scss/OrderNetworkCoverage.scss"
import { useNetworkCoverageKitStore } from '@/stores/NetworkCoverageKitStore'

const kit = computed(() => {
  return useNetworkCoverageKitStore().kit;
});

const address = computed(() => {
  return useNetworkCoverageKitStore().address;
});

const title = computed(() => {
  return useNetworkCoverageKitStore().kit.title; //useNetworkCoverageKitStore().lang;
});
</script>

<script>

import MyStep1 from '@/components/OrderNetworkCoverage/svg/Step1.vue'
import MyStep2 from '@/components/OrderNetworkCoverage/svg/Step2.vue'
import MyStep4 from '@/components/OrderNetworkCoverage/svg/Step4.vue'

import MyRelatedServices from '@/components/OrderNetworkCoverage/RelatedServices.vue'
import MyReference from '@/components/OrderNetworkCoverage/References.vue'
import MyAdditionalInformation from '@/components/OrderNetworkCoverage/AdditionalInformation.vue'

export default {
  name: 'OrderNetworkCoverage',
  props: ['openModal'],
  components: {
    MyStep1,
    MyStep2,
    MyStep4,
    MyRelatedServices,
    MyReference,
    MyAdditionalInformation
  },
  data() {
    return {
      step: 1,
      listSteps: [
        {
          icon: "MyStep1",
          title: "checkCoverage.titleStep1"
        },
        {
          icon: "MyStep2",
          title: "checkCoverage.titleStep2"
        },
        {
          icon: "MyStep4",
          title: "checkCoverage.titleStep4"
        }
      ],
      steps: [
        'MyRelatedServices',
        'MyReference',
        'MyAdditionalInformation'
      ],
      form: {
        kit_id: 0,
        relatedServices: [],
        end_customer: {
          company_name: null,
          vat_id: null,
          cf: null
        },
        notes: null,
        contacts: [],
        client_po: null,
        network_access_details: {
          commercial_profile_id: 0,
          delivery_details: {
            building_floor: null,
            time_slot: null,
            notes: null
          },
          radius_account: {
            username: null,
            password: null,
            notes: null
          },
          olo2olo_migration: {
            code: null,
            notes: null
          },
          address: {
            state: "Italia",
            province: null,
            city: null,
            address: null,
            number: null
          }
        },
        isCompleted: false
      },
      IsMobile: this.$IsMobile(),
      infoMobile: false
    }
  },
  methods: {
    processStep(stepData) {
      Object.assign(this.form, stepData);
      if (this.form.isCompleted && this.step != 1) {
        localStorage.setItem("cdlOrderNetworkCoverage", JSON.stringify({ form: this.form, step: 7 }));
      }

    },
    goBack() {
      this.step--;
      window.scrollTo(0, 0);
    },
    goNext(goSummary = null) {
      this.step++;
      window.scrollTo(0, 0);
      localStorage.setItem("cdlOrderNetworkCoverage", JSON.stringify({ form: this.form, step: this.step }));
      if (goSummary.goSummary) {
        setTimeout(() => {
          return this.$router.push({ name: 'OrderNetworkCoverageSummary' + this.$i18n.locale });
        }, 200);
      }
      if (this.step > this.listSteps.length) {
        return this.$router.push({ name: 'OrderNetworkCoverageSummary' + this.$i18n.locale });
      }
    },
    getStep() {
      return this.step;
    },
    getSteps() {
      return this.listSteps;
    },
    getIcon(technology) {
      let rls = this.$KitStyle(technology, 'icon');
      return rls;
    },
    getClassKit(technology) {
      let rls = this.$KitStyle(technology, 'class');
      return rls;
    },
    cancelOrder() {
      window.scrollTo(0, 0);
      let params = { type: 'deleteOrderWizardNetwork' };
      this.$emit('openModal', params);
    },
    openInfoMobile() {
      this.infoMobile = !this.infoMobile;
    },
    getDownUp(download, upload) {
      let down = download + "Mb";
      if (download >= 1000) {
        down = download / 1000 + "Gb";
      }

      let up = upload + "Mb";
      if (upload >= 1000) {
        up = upload / 1000 + "Gb";
      }

      return down + "/" + up;
    },
    setStep(value) {
      this.step = value;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("cdlOrderNetworkCoverage")) {
      let recoveryValue = JSON.parse(localStorage.getItem("cdlOrderNetworkCoverage"));
      this.form = recoveryValue.form;
      this.step = recoveryValue.step;
      if (this.step > this.listSteps.length) {
        return this.$router.push({ name: 'OrderNetworkCoverageSummary' + this.$i18n.locale });
      }
    }

    const self = this;

    let steps = document.getElementsByClassName("step");
    let newElm = "";
    for (let i = 0; i < steps.length; i++) {
      if (i != steps.length - 1) {
        newElm = document.createElement("div");
        newElm.className = "spacing";
        steps[i].after(newElm);
      }
      if (i == steps.length - 1 && !this.form.isCompleted) {
        newElm = document.createElement("span");

        let classMobile = "";
        if (this.IsMobile) {
          classMobile = " mobile";
        }
        newElm.className = "material-icons closeOrder" + classMobile;
        newElm.innerHTML = "close";
        steps[i].after(newElm);
        newElm.addEventListener('click', function handleClick() {
          self.cancelOrder();
        });
      }
    }
  }
}
</script>