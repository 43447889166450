<template>
  <div id="Shipping">
    <MyBanner
      :text="$t('banner.accessDcAddressBookPage')"
      v-if="$t('banner.accessDcAddressBookPage') != ''"
    />
    <MyHeaderPage
      :title="$t('accessDc.addressBook.title')"
      :subtitle="$t('accessDc.addressBook.subTitle')"
    />
    <br /><br />

    <!-- TABLE START -->
    <div id="TicketsList">
    <div class="listTicket">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--4-col mdl-cell mdl-cell--12-col-phone">
          <div class="filterTickets">
            <div class="contentInputFilter">
              <div class="contentIcon">
                <span class="material-icons">search</span>
              </div>
              <div class="contentInput">
                <input
                  type="text"
                  :placeholder="$t('actions.text_search_placeholder')"
                  v-model="searchString"
                  @keyup="searchByString"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!IsMobile">
        <table v-if="columns.length > 0">
          <thead>
            <tr>
              <th
                v-for="(header, keyHead) in this.columns"
                :key="keyHead"
              >
                {{ $t("accessDc.addressBook.col." + header) }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="this.viewLoader">
            <tr
              v-for="(item, keyItem) in this.items"
              :key="keyItem"
              :class="{ odd: keyItem % 2 }"
            >
              <td
                v-for="(index, keyIndex) in this.columns.length"
                :key="keyIndex"
              >
                <span>{{ item[this.columns[keyIndex]] }}</span>
              </td>
              <td style="text-align: center;"
                    v-tooltip:top.tooltip="$t('accessDc.addressBook.deleteItem', 
                    { name: item.surname + ' ' + item.name })">
                <div class="material-icons deleteItem" @click="deleteItem(item.id)">delete</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div v-if="IsMobile && colMobile.length > 0">
        <table>
          <thead>
            <tr>
              <th
                style="text-align: center"
                v-for="(header, keyHead) in colMobile"
                :key="keyHead"
              >
                <span>{{
                  $t("accessDc.addressBook.col." + header)
                }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <TicketsMobileRow
              class="marco"
              v-for="(item, keyItem) in this.items"
              :key="keyItem"
            >
              <template #first-col>{{ item.surname }}</template>
              <template #second-col>{{ item.name }}</template>
              <template #url-label>
                <p style="text-align: center">{{ item.email }}</p>
                <div style="text-align: center;">
                <div class="material-icons deleteItem" @click="deleteItem(item.id)">delete</div>
              </div>
              </template>
            </TicketsMobileRow>
          </tbody>
        </table>
      </div>

      <div class="noTickets" v-if="items.length == 0 && viewLoader">
        <br />{{ $t("accessDc.addressBook.noUsers") }}
      </div>
      <div>
        <br /><br />
        <MyLoading :viewLoader="this.viewLoader" type="" />
        <MyError :viewError="viewError" :message="$t('tickets.error')" />
      </div>
      <br /><br />
      <Paginator
        v-show="total_page > 1"
        :total-page="total_page"
        :current-page="current_page"
        @change-page="changePage"
        @prev-page="beforePage"
        @next-page="nextPage"
      />
      <br /><br />
    </div>
  </div>

  <!-- TABLE END -->

  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/Shipping.scss";
import { useUserStore } from "@/stores/UserStore";

import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import TicketsMobileRow from "@/components/Tables/TicketsMobileRow.vue";
import "@/assets/scss/TicketsList.scss";


export default {
  name: "AccessRequestAddressBook",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    MyError,
    Paginator,
    TicketsMobileRow,
  },
  data() {
    return {
      columns: [
        "surname",
        "name",
        "company",
        "email"
      ],
      items: [],
      searchString: "",
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": "multipart/form-data",
      },
      page_size: 10,
      total_page: 1,
      current_page: 1,
      viewLoader: true,
      viewError: false,
      messageError: "",
      IsMobile: this.$IsMobile(),
      colMobile: [
        "surname",
        "name"
      ],

      allItems: [],
      indexFirst: 0,
      getGammaId: 0
    };
  },
  methods: {
    searchByString() {
      if (this.searchString.length <= 3 || this.searchString.length == 0) {
        this.total_page = Math.ceil(this.allItems.length / this.page_size);
        this.current_page = 1;
        this.indexFirst = 0;
        this.items = this.allItems.slice(this.indexFirst, this.current_page*this.page_size);
      }
      else {
        this.total_page = 0;

        this.items = [];
        this.allItems.forEach((elm) => {
          let surname = elm.surname
            ? elm.surname.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          let name = elm.name
            ? elm.name.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          let company = elm.company
            ? elm.company.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          let email = elm.email
            ? elm.email.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          if (surname || name || company || email) {
            this.items.push(elm);
          }
        });
      }
    },
    getAddressBook() {
      this.viewLoader = false;

      this.total_page = 0;
      this.current_page = 1;

      const self = this;

      this.viewError = false;

      this.items = [];

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "front/v1/get-users/" + this.getGammaId,
          { headers: this.he }
        )
        .then((response) => {

          this.viewLoader = true;

          if( response.data.status == 'ok' && response.data.data.length > 0) {
            this.allItems = response.data.data;
            this.total_page = Math.ceil(response.data.data.length / this.page_size);
            this.items = this.allItems.slice(this.indexFirst, this.current_page*this.page_size);
            this.indexFirst = 11;
          }
        
        })
        .catch(function (err) {
          console.log(err);
          if (err.request.status == 404) {
            self.viewLoader = true;
            return;
          }
          self.viewLoader = true;
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    changePage(page) {
      this.current_page = parseInt(page);
      this.updateElms();
    },
    nextPage() {
      if (this.current_page == this.total_page) {
        return;
      }
      this.current_page = parseInt(this.current_page) + 1;
      this.updateElms();
    },
    beforePage() {
      if (this.current_page == 1) {
        return;
      }
      this.current_page = parseInt(this.current_page) - 1;
      this.updateElms();
    },
    updateElms() {
      let lastTen = this.current_page*this.page_size;
      this.indexFirst = lastTen - (this.page_size - 1);
      this.items = this.allItems.slice(this.indexFirst, this.current_page*this.page_size);
    },
    deleteItem( address_book_id ) {
      this.axios
        .delete(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "front/v1/delete-user/" + address_book_id,
          { headers: this.he }
        )
        .then(() => {
          this.searchString = '';
          this.getAddressBook();
        });
    },
    ctrlGammaId() {
      const userStore = useUserStore();
      if( (userStore.user.account && userStore.user.account.id) || this.getGammaId > 0 ) {
        this.getGammaId = userStore.user.account.id;
        this.getAddressBook();
        return;
      }
      setTimeout(() => {
        this.ctrlGammaId();
      }, 1500);
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    this.ctrlGammaId();

  },
};
</script>