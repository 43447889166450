<template>
  <div id="Users">
    <MyBanner :text="$t('banner.customersEndsPage')"/>
    <MyHeaderPage
        :title="$t('companies.title')"
        :subtitle="$t('')"
    />
    <br/>

    <div class="mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
      <div
          class="mdl-tabs__panel tabOne"
          :class="{ 'is-active': ctrlPerms(['utenti']) }"
      >
        <div class="mdl-grid">
          <div
              class="mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          >
            <div class="contentInputFilter">
              <div class="contentIcon">
                <span class="material-icons">search</span>
              </div>
              <div class="contentInput">
                <input
                    type="text"
                    :placeholder="$t('companies.searchUsers')"
                    v-model="searchString2"
                />
              </div>
            </div>
          </div>
          <div
              class="mdl-cell mdl-cell--3-col mdl-cell--5-offset mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          >
            <div>
              <button class="cdlButtonGreen" id="btnAddUser" @click="addUser()">
                {{ $t("companies.newUser") }}
              </button>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="mdl-grid tableUsers">
              <div
                  class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone title"
              >
                {{ $t("companies.tableNameSurname") }}
              </div>
              <div class="mdl-cell mdl-cell--3-col title" v-if="!IsMobile">
                {{ $t("companies.tableVat") }}
              </div>
              <div class="mdl-cell mdl-cell--2-col title" v-if="!IsMobile">
                {{ $t("companies.tableCf") }}
              </div>
              <div
                  class="mdl-cell mdl-cell--1-col mdl-cell--1-col"
                  v-if="!IsMobile"
              ></div>
              <div class="mdl-cell mdl-cell--1-col" v-if="!IsMobile"></div>
            </div>
            <MyLoading :viewLoader="viewLoader" type=""/>
            <div v-if="viewLoader">
              <div
                  class="mdl-grid tableUsers"
                  v-for="(company, index) in companiesStore.companies"
                  :key="index"
              >
                <div
                    class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone contentTableUsers"
                >
                  {{ company.company_name }}
                </div>
                <div class="mdl-cell mdl-cell--3-col contentTableUsers">
                  {{ company.vat_id }}
                </div>
                <div class="mdl-cell mdl-cell--2-col contentTableUsers">
                  {{ company.cf }}
                </div>
                <div
                    class="mdl-cell mdl-cell--1-col mdl-cell--2-offset mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <span
                      class="editInfo material-icons"
                      @click="editCompanyInfo(index)"
                      v-tooltip.bottom="$t('companies.editCompany')"
                  >edit</span
                  >
                </div>
                <div
                    class="mdl-cell mdl-cell--1-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <div
                      class="menuEditUser"
                      :class="['menuEditUser-' + index]"
                      @click="viewUser(index)"
                      :id="[
                      'user-more-' + company.company_name.replace(' ', '-'),
                    ]"
                  >
                    <i class="material-icons">more_vert</i>
                  </div>
                  <div class="subInfoUser" :class="['subInfoUser-' + index]">
                    <div
                        @click="openModal(index)"
                        :id="['user-delete-' + company.company_name]"
                    >
                      <span class="material-icons">delete</span>
                      {{ $t("companies.deleteUser") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- GROUP -->
    </div>

    <ManagementCompany
        :key="componentKey"
        :company-record="selectedCompany"
        :view="viewPanel"
        @close-panel="openPanel"
    />
    <!-- MODAL -->
    <DeleteModal
        :show-modal="viewModal"
        :title="$t('companies.titleDelete')"
        :subtitle="
        $t('users.subTitleDelete', {
          name: selectedCompany?.company_name,
        })
      "
        :text="$t('users.textDelete')"
        :delete-button-text="$t('users.btnDelete')"
        :revert-delete-text="$t('users.revertDelete')"
        @delete="deleteCompany(selectedCompany.id)"
        @close="closeModal()"
    />
    <!--    <CompaniesListInternal />-->
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {useUserStore} from "@/stores/UserStore";
import {useCompaniesStore} from "@/stores/CompaniesStore";
import DeleteModal from "@/components/modals/DeleteModal.vue";

const companiesStore = useCompaniesStore();

const searchString2 = ref('');

async function fetchAndFilterCompanies(searchTerm) {
  await companiesStore.getCompanies(searchTerm);
}

watch(searchString2, (newValue) => {
  fetchAndFilterCompanies(newValue);
});

const selectedCompany = ref(null);
const viewPanel = ref(false);
const viewModal = ref(false);
const componentKey = ref(0);

onMounted(() => {
  companiesStore.getCompanies('');
});
const openPanel = () => {
  viewPanel.value = !viewPanel.value;
};
const deleteCompany = async (id) => {
  await companiesStore.deleteCompany(id);
  await companiesStore.getCompanies();
  closeModal();
};
const editCompanyInfo = (index) => {
  // console.log(companiesStore.companies[index])
  selectedCompany.value = companiesStore.companies[index];
  componentKey.value++; // Increment the key to force re-render
  openPanel({whichPanel: 0});
  // console.log(selectedCompany.value);
};
const addUser = () => {
  openPanel({whichPanel: 0});
};
const viewUser = (index = -1) => {
  let subInfoUser = document.getElementsByClassName("subInfoUser");

  for (let i = 0; i < subInfoUser.length; i++) {
    if (index != i) {
      subInfoUser[i].classList.remove("active");
    }
  }

  if (
      index > -1 &&
      document.getElementsByClassName("subInfoUser-" + index)[0]
  ) {
    if (
        document
            .getElementsByClassName("subInfoUser-" + index)[0]
            .classList.contains("active")
    ) {
      document
          .getElementsByClassName("subInfoUser-" + index)[0]
          .classList.remove("active");
    } else {
      document
          .getElementsByClassName("subInfoUser-" + index)[0]
          .classList.add("active");
    }
  }
};
const openModal = (ctrl) => {
  window.scrollTo(0, 0);
  selectedCompany.value = companiesStore.companies[ctrl];
  viewModal.value = true;
  if (viewModal.value) {
    document.getElementsByTagName("body")[0].classList.add("overflow");
  }
  viewUser();
};
const closeModal = () => {
  selectedCompany.value = {};
  viewModal.value = false;
  document.getElementsByTagName("body")[0].classList.remove("overflow");
};
</script>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import ManagementCompany from "@/components/CompaniesList/ManagementCompany";
// import CompaniesListInternal from "@/components/CompaniesList/CompaniesListInternal";

// import { useUserStore } from '@/stores/UserStore'

export default {
  name: "MyUsers",
  components: {
    MyBanner,
    MyHeaderPage,
    ManagementCompany,
    MyLoading,
    // CompaniesListInternal
  },
  data() {
    return {
      componentKey: 0,
      viewPanel: false,
      IsMobile: this.$IsMobile(),
      users: [],
      dataFilter: [],
      panel: 0,
      panels: ["ManagementRole", "UsersRole", "DetailsUser", "SearchUser"],
      searchString: "",
      viewModal: false,
      viewLoader: false,
      isAdmin: false,
    };
  },
  methods: {
    openPanel() {
      this.viewPanel = !this.viewPanel;
    },
    addUser() {
      this.singleUser = {};
      this.openPanel({whichPanel: 0});
    },
    searchByString() {
      if (this.searchString.length == 0) {
        this.getUsers();
        return;
      }

      this.dataFilter = [];
      this.users.forEach((elm) => {
        if (
            elm.first_name
                .toLowerCase()
                .includes(this.searchString.toLowerCase()) ||
            elm.last_name
                .toLowerCase()
                .includes(this.searchString.toLowerCase()) ||
            elm.email.toLowerCase().includes(this.searchString.toLowerCase()) ||
            (elm.phone &&
                elm.phone.toLowerCase().includes(this.searchString.toLowerCase()))
        ) {
          this.dataFilter.push(elm);
        }
      });
    },
    getUsers() {
      this.viewLoader = false;
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      this.axios
          .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "users/v1/user", {
            headers: he,
          })
          .then((response) => {
            this.users = response.data;
            this.dataFilter = this.users;
            if (this.searchString.length != 0) {
              this.searchByString();
            }
            this.viewLoader = true;
            this.getIsAdmin();
          })
          .catch(function (err) {
            console.log(err);
          });
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
    getIsAdmin() {
      let userLoggedStore = useUserStore();
      if (
          userLoggedStore.user &&
          userLoggedStore.user.group &&
          userLoggedStore.user.group.name.toLowerCase() == "admin"
      ) {
        this.isAdmin = true;
        return;
      }
      this.isAdmin = false;
      return;
    },
  },
  beforeUnmont() {
    this.getIsAdmin();
  },
  mounted() {
    this.getUsers();
  },
};
</script>
