<template>
<svg id="Step3" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <g id="Ellisse_153" data-name="Ellisse 153" fill="#e4effc" class="setCircle">
    <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
    <circle cx="17.5" cy="17.5" r="17" fill="none"/>
  </g>
  <g id="construction_black_24dp" transform="translate(8 8)">
    <g id="Raggruppa_4123" data-name="Raggruppa 4123">
      <rect id="Rettangolo_2995" data-name="Rettangolo 2995" width="19" height="19" fill="none"/>
      <rect id="Rettangolo_2996" data-name="Rettangolo 2996" width="19" height="19" fill="none"/>
    </g>
    <g id="Raggruppa_4125" data-name="Raggruppa 4125" transform="translate(2.011 2.068)">
      <g id="Raggruppa_4124" data-name="Raggruppa 4124">
        <path class="setColor" id="Tracciato_1619" data-name="Tracciato 1619" d="M19.519,16.961,15.608,13.05,13.93,14.728l3.911,3.911a1.18,1.18,0,0,0,1.678,0A1.2,1.2,0,0,0,19.519,16.961Z" transform="translate(-4.913 -4.787)" fill="#b8c4ce"/>
        <path class="setColor" id="Tracciato_1620" data-name="Tracciato 1620" d="M14.5,8.461A2.774,2.774,0,0,0,17.273,5.69a2.735,2.735,0,0,0-.325-1.267L14.811,6.561l-1.18-1.18,2.138-2.138A2.735,2.735,0,0,0,14.5,2.919,2.774,2.774,0,0,0,11.731,5.69a2.7,2.7,0,0,0,.166.918L10.433,8.073,9.024,6.664h0a.788.788,0,0,0,0-1.116l-.562-.562L10.14,3.307a2.375,2.375,0,0,0-3.357,0L4.551,5.548a.788.788,0,0,0,0,1.116l.562.562H3.1a.532.532,0,0,0-.4.166.554.554,0,0,0,0,.792l2.011,2.011a.554.554,0,0,0,.792,0,.564.564,0,0,0,.166-.4V7.788l.554.554a.788.788,0,0,0,1.116,0L8.755,9.751,3.727,14.778a1.18,1.18,0,0,0,0,1.678h0a1.18,1.18,0,0,0,1.678,0l8.17-8.162A2.781,2.781,0,0,0,14.5,8.461Z" transform="translate(-2.54 -2.613)" fill="#b8c4ce"/>
      </g>
    </g>
  </g>
</svg>
</template>

<script>

export default {
  name: 'MyStep3'
}
</script>