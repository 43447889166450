<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="393.421" height="20.387" viewBox="0 0 393.421 20.387">
    <path id="freccia" d="M306.48,2.126h0a2.122,2.122,0,0,1-2.122,2.122H-69.878A2.122,2.122,0,0,0-72,6.369v7.649a2.122,2.122,0,0,0,2.122,2.122H304.358a2.122,2.122,0,0,1,2.122,2.122,2.121,2.121,0,0,0,3.4,1.693l10.7-8.068a2.122,2.122,0,0,0,0-3.388L309.879.432a2.122,2.122,0,0,0-3.4,1.694" transform="translate(72 -0.001)" fill="#bccada"/>
  </svg>
</template>

<style scoped lang="scss">

</style>
