import Cloud from "@/pages/Cloud.vue";
import GenericIncidentForm from "@/pages/Incident/GenericIncidentForm.vue";
import GenericChangeRequestForm from "@/pages/ChangeRequest/GenericChangeRequestForm.vue";
import IncidentDetails from "@/pages/Incident/IncidentDetails.vue";
import HelpRequestDetails from "@/pages/Help/HelpRequestDetails.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";
import IncidentForm from "@/pages/Incident/IncidentForm.vue";
import ChangeRequestForm from "@/pages/ChangeRequest/ChangeRequestForm.vue";

export function routesCloud() {
  return [
    {
      path: "/cloud",
      name: "Cloudit",
      component: Cloud,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["cloud_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/incident/:asset_id/:asset_type",
      name: "CloudIncidentFormit",
      component: IncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/incident/:asset_id/:asset_type",
      name: "CloudIncidentFormen",
      component: IncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/change-request/:asset_id/:asset_type",
      name: "CloudChangeRequestFormit",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/change-request/:asset_id/:asset_type",
      name: "CloudChangeRequestFormen",
      component: IncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/incident-generico/:asset_type",
      name: "CloudGenericIncidentFormit",
      component: GenericIncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/generic-incident/:asset_type",
      name: "CloudGenericIncidentFormen",
      component: GenericIncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/change-request-generica/:asset_type",
      name: "CloudGenericChangeRequestFormit",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/generic-change-request/:asset_type",
      name: "CloudGenericChangeRequestFormen",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/lista-incident/dettaglio/:id",
      name: "CloudIncidentDetailit",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/incident-list/detail/:id",
      name: "CloudIncidentDetailen",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/lista-change-request/dettaglio/:id",
      name: "CloudChangeRequestDetailit",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud/change-request-list/detail/:id",
      name: "CloudChangeRequestDetailen",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "cloud",
            url: "/cloud",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ];
}
