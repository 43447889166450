<script setup>
import {defineProps, getCurrentInstance, onMounted, ref} from "vue";
// Define props for the component
// Access the global properties directly within setup
const {config} = getCurrentInstance().appContext;
const isMobile = ref(false);
const props = defineProps({
  line2: String,
  line3: String,
  isInfra: Boolean,
});

const type = props.isInfra? 'Infracampus': 'Local'
onMounted(async () => {
  isMobile.value = config.globalProperties.$IsMobile();
});

</script>
<template>
  <div :class="[isMobile ? 'info-rectangle-mobile' : 'info-rectangle']">
    <div class="info-text">
      <div class="line1">{{ $t('crossConnects.activeCrossConnect') }}</div>
      <div class="line2">{{ props.line2 ? props.line2 +' '+ type +' '+ props.line2 : '' }}</div>
      <div class="line3">{{ props.line3 }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";
</style>
