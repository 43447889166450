<template>
  <div id="Users">
    <MyBanner :text="$t('banner.usersPage')" />
    <MyHeaderPage :title="$t('users.title')" subtitle="" />
    <br />

    <div class="mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
      <div class="mdl-tabs__tab-bar">
        <span
          class="mdl-tabs__tab tabOne"
          id="tabOne"
          :class="{ 'is-active': ctrlPerms(['utenti']) }"
          @click="setTab(1)"
          v-if="ctrlPerms(['utenti'])"
          >{{ $t("users.tabOne") }}
          <div class="line"></div>
        </span>
        <span
          class="mdl-tabs__tab tabTwo"
          id="tabTwo"
          @click="setTab(2)"
          :class="{ 'is-active': !ctrlPerms(['utenti']) }"
          v-if="ctrlPerms(['ruoli'])"
          >{{ $t("users.tabTwo") }}
          <div class="line"></div
        ></span>
      </div>

      <div
        class="mdl-tabs__panel tabOne"
        :class="{ 'is-active': ctrlPerms(['utenti']) }"
      >
        <div class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          >
            <div class="contentInputFilter">
              <div class="contentIcon">
                <span class="material-icons">search</span>
              </div>
              <div class="contentInput">
                <input
                  type="text"
                  :placeholder="$t('users.searchUsers')"
                  @keyup="searchByString"
                  v-model="searchString"
                />
              </div>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--4-offset mdl-cell--12-col-tablet mdl-cell--12-col-phone"
            v-if="isAdmin"
          >
            <download-csv
              :data="usersCsv"
              :name="$t('users.tabOne')"
              :delimiter="';'"
              :labels="{
                first_name: $t('users.nameUser'),
                last_name: $t('users.surNameUser'),
                email: $t('users.emailUser'),
                secondary_email: $t('users.emailTwoUser'),
                phone: $t('users.phoneUser'),
                enabled: $t('users.status'),
                group: $t('users.group'),
              }"
            >
              <button class="cdlButton">
                <img src="@/assets/img/download.png" alt="icon" />
                {{ $t("users.downLoadCSV") }}
              </button>
            </download-csv>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          >
            <div>
              <button
                class="cdlButtonGreen"
                id="btnAddUser"
                @click="addUser()"
                v-if="isAdmin && ctrlPerms(['utenti'])"
                style="font-size: 1.2rem"
              >
                {{ $t("users.newUser") }}
              </button>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="mdl-grid tableUsers">
              <div
                class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone title"
              >
                {{ $t("users.tableNameSurname") }}
              </div>
              <div class="mdl-cell mdl-cell--3-col title" v-if="!IsMobile">
                {{ $t("users.tableEmail") }}
              </div>
              <div class="mdl-cell mdl-cell--2-col title" v-if="!IsMobile">
                {{ $t("users.tablePhone") }}
              </div>
              <div
                class="mdl-cell mdl-cell--1-col mdl-cell--2-col-tablet mdl-cell--2-col-phone title"
              >
                {{ $t("users.tableRole") }}
              </div>
              <div class="mdl-cell mdl-cell--1-col" v-if="!IsMobile"></div>
              <div class="mdl-cell mdl-cell--1-col" v-if="!IsMobile"></div>
            </div>
            <MyLoading :viewLoader="viewLoader" type="" />
            <div v-if="viewLoader">
              <div
                class="mdl-grid tableUsers"
                v-for="(user, n) in dataFilter"
                :key="n"
                :class="{ disable: !user.enabled }"
                :id="['user-' + user.first_name.replace(' ', '-')]"
              >
                <div
                  class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone contentTableUsers"
                >
                  {{ user.last_name }} {{ user.first_name }}
                </div>
                <div
                  class="mdl-cell mdl-cell--3-col contentTableUsers"
                  v-if="!IsMobile"
                >
                  {{ user.email }}
                </div>
                <div
                  class="mdl-cell mdl-cell--2-col contentTableUsers"
                  v-if="!IsMobile"
                >
                  {{ user.phone }}
                </div>
                <div
                  class="mdl-cell mdl-cell--2-col mdl-cell--4-col-tablet mdl-cell--2-col-phone contentTableUsers"
                >
                  <div
                    class="cdlChip mdl-grid"
                    :class="['chipColor-' + getColorRole(user.group.color)]"
                  >
                    <div class="mdl-cell mdl-cell--2-col">
                      <div class="circleRole"></div>
                    </div>
                    <div class="mdl-cell mdl-cell--8-col">
                      <span class="nameRole">{{ user.group.name }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="mdl-cell mdl-cell--1-col mdl-cell--2-col-tablet mdl-cell--2-col-phone" 
                  style="text-align: center;"
                >
                  <span
                    @click="editInfo(n)"
                    :id="['user-edit-' + user.first_name.replace(' ', '-')]"
                    v-if="isAdmin"
                    class="editInfo material-icons"
                    v-tooltip:bottom.tooltip="$t('users.editUser')"
                  >
                    edit
                  </span>
                </div>
                <div
                  class="mdl-cell mdl-cell--1-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <div
                    class="menuEditUser"
                    :class="['menuEditUser-' + n]"
                    @click="viewUser(n)"
                    :id="['user-more-' + user.first_name.replace(' ', '-')]"
                  >
                    <i class="material-icons">more_vert</i>
                  </div>
                  <div class="subInfoUser" :class="['subInfoUser-' + n]">
                    <div
                      @click="openDetailsUser(n)"
                      :id="[
                        'user-details-' + user.first_name.replace(' ', '-'),
                      ]"
                    >
                      <span class="material-icons">person</span>
                      {{ $t("users.profile") }}
                    </div>
                    <div
                      @click="openModal(n)"
                      :id="['user-delete-' + user.first_name.replace(' ', '-')]"
                      v-if="user.email != userLogged.email && isAdmin"
                    >
                      <span class="material-icons">delete</span>
                      {{ $t("users.deleteUser") }}
                    </div>
                    <div
                      @click="disableAbilityUser(n)"
                      :id="[
                        'user-disable-' + user.first_name.replace(' ', '-'),
                      ]"
                      v-if="user.email != userLogged.email && isAdmin"
                    >
                      <span class="material-icons">person_off</span>
                      {{
                        user.enabled
                          ? $t("users.disableUser")
                          : $t("users.abilityUser")
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- GROUP -->
      <div
        class="mdl-tabs__panel tabTwo"
        :class="{ 'is-active': !ctrlPerms(['utenti']) }"
      >
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--4-col">
            <div class="contentInputFilter">
              <div class="contentIcon">
                <span class="material-icons">search</span>
              </div>
              <div class="contentInput">
                <input
                  type="text"
                  :placeholder="$t('users.searchRoles')"
                  @keyup="searchGroupByString"
                  v-model="searchGroupString"
                />
              </div>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--4-offset"
            v-if="isAdmin"
          >
            <download-csv
              :data="groupsCsv"
              :name="$t('users.tabTwo')"
              :delimiter="';'"
            >
              <button
                class="cdlButton"
                :labels="{
                  id: 'id',
                  name: $t('users.tabTwo'),
                  permissions: $t('users.permissions'),
                }"
              >
                <img src="@/assets/img/download.png" alt="icon" />
                {{ $t("users.downLoadCSV") }}
              </button>
            </download-csv>
          </div>
          <div class="mdl-cell mdl-cell--2-col">
            <div>
              <button
                class="cdlButtonGreen"
                @click="addGroup()"
                id="addGroup"
                v-if="isAdmin && ctrlPerms(['ruoli'])"
                style="font-size: 1.2rem"
              >
                {{ $t("users.btnNewRoleTwo") }}
              </button>
            </div>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <div class="mdl-grid">
            <!-- CARD -->
            <div
              class="mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
              v-for="(group, n) in dataFilterGroups"
              :key="n"
            >
              <div class="cardRole heightCardRole">
                <div
                  class="mdl-grid"
                  style="align-items: center; padding: 5px 20px 0px 5px"
                >
                  <div class="mdl-cell mdl-cell--2-col">
                    <div
                      class="colorRole"
                      :class="['color-' + getColorRole(group.color)]"
                    ></div>
                  </div>
                  <div class="mdl-cell mdl-cell--7-col">
                    <span class="nameRoleCard firstUpper">
                      {{ group.name }}
                    </span>
                  </div>
                  <div
                    class="mdl-cell mdl-cell--3-col"
                    style="display: inline-flex; justify-content: flex-end"
                  >
                    <div v-if="noEdit(group.name) && isAdmin">
                      <span
                        @click="editGroup(n)"
                        :id="['role-edit-' + group.name.replace(' ', '-')]"
                        class="material-icons editInfo"
                        v-tooltip:bottom.tooltip="$t('users.editUser')"
                      >
                        edit
                      </span>
                    </div>
                    <div v-if="isAdmin" style="margin-left: 10px">
                      <i
                        class="menuEditRole material-icons"
                        :class="['menuEditRole-' + n]"
                        @click="viewGroup(n)"
                        :id="['role-more-' + group.name.replace(' ', '-')]"
                        >more_vert</i
                      >
                    </div>
                    <div class="subInfoRole" :class="['subInfoRole-' + n]">
                      <div
                        @click="addUsersGroup(n)"
                        :id="['role-add-users-' + group.name.replace(' ', '-')]"
                      >
                        <span class="material-icons">person</span>
                        {{ $t("users.associateUser") }}
                      </div>
                      <div
                        @click="!group.users || (group.users && group.users.length == 0) ? deleteGroup(group.id) : null"
                        :id="['role-delete-' + group.name.replace(' ', '-')]"
                        v-if="noEdit(group.name) && isAdmin"
                        v-tooltip:bottom.tooltip="!(!group.users || (group.users && group.users.length == 0)) ? $t('users.deleteGroupTooltip') : ''"
                      >
                      <span style="display: flex; justify-content: center;" :class="{ opacity: !(!group.users || (group.users && group.users.length == 0)) }">
                        <span class="material-icons">delete</span>
                        {{ $t("users.deleteGroup") }}
                      </span>

                      </div>
                    </div>
                  </div>

                  <!-- END EDIT BTN -->
                </div>
                <div class="mdl-grid">
                  <div class="mdl-cell mdl-cell--10-col mdl-cell--2-offset">
                    <span class="textCardRole">{{ $t("users.areaRole") }}</span>
                    
                    <!-- START PERMISSIONS LIST -->
                    <div style="min-height: 220px; max-height: 220px; overflow: hidden; overflow-y: auto;">
                      <div
                      v-for="(permission, keyPermissions) in group.permissions"
                      :key="keyPermissions"
                    >
                      <div :class="['groupIndex-' + keyPermissions]">
                        <div
                          class="itemGroup"
                          @click="
                            openChild('p-' + keyPermissions, '', '', group.id)
                          "
                           v-if="getPermissionForDirectUser(permission.name, 'area_partner')"
                        >
                          <i
                            class="material-icons arrow"
                            :class="[
                              group.id + '-arrow-p-' + keyPermissions,
                              permission.children ? '' : 'opacity',
                            ]"
                            >arrow_right</i
                          >

                          <span class="firstUpper">{{
                            $t("group.default.first." + permission.name)
                          }}</span>
                        </div>
                        <!-- account -->
                        <div
                          v-for="(pages, keyPages) in permission.children"
                          :key="keyPages"
                          class="contentSubElems child"
                          :class="[group.id + '-child-p-' + keyPermissions]"
                        >
                          <div class="block">
                            <div class="border"></div>
                            <div>
                              <div
                                class="itemGroup"
                                @click="
                                  openChild(
                                    'p-' + keyPermissions + '-' + keyPages,
                                    '',
                                    '',
                                    group.id
                                  )
                                "
                              >
                                <span
                                  class="material-icons arrow"
                                  :class="[
                                    group.id +
                                      '-arrow-p-' +
                                      keyPermissions +
                                      '-' +
                                      keyPages,
                                    pages.children ? '' : 'opacity',
                                  ]"
                                  >arrow_right</span
                                >

                                <span class="firstUpper">{{
                                  $t("group.default." + pages.name)
                                }}</span>
                              </div>
                              <div
                                v-for="(type, keytype) in pages.children"
                                :key="keytype"
                                class="childChild"
                                :class="[
                                  group.id +
                                    '-child-p-' +
                                    keyPermissions +
                                    '-' +
                                    keyPages,
                                ]"
                              >
                                <div class="contentSubElems">
                                  <div class="block">
                                    <div class="borderUnion"></div>
                                    <div
                                      class="itemGroup"
                                      @click="
                                        openChild(
                                          'p-' + keyPermissions,
                                          keyPermissions,
                                          keytype,
                                          group.id
                                        )
                                      "
                                    >
                                      <span
                                        class="material-icons arrow"
                                        :class="[
                                          group.id +
                                            '-arrow-p-' +
                                            keyPermissions +
                                            '-' +
                                            keyPermissions +
                                            '-' +
                                            keytype,
                                          type.children ? '' : 'opacity',
                                        ]"
                                        >arrow_right</span
                                      >

                                      <span class="firstUpper">{{
                                        $t("group.default." + type.name)
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    v-for="(
                                      singlePage, keySinglePage
                                    ) in type.children"
                                    :key="keySinglePage"
                                    class="childChildPage"
                                    :class="[
                                      group.id +
                                        '-child-p-' +
                                        keyPermissions +
                                        '-' +
                                        keyPermissions +
                                        '-' +
                                        keytype,
                                    ]"
                                  >
                                    <div class="contentSubElems" v-if="getPermissionForDirectUser(singlePage.name, 'verifica_copertura')">
                                      <div class="block">
                                        <div class="borderUnion"></div>
                                        <div class="itemGroup">
                                          <span class="firstUpper">{{
                                            $t(
                                              "group.default." + singlePage.name
                                            )
                                          }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>

                    <br /><br />
                    <span class="textCardRole">{{
                      $t("users.usersRole")
                    }}</span>
                    <p style="margin: 0">
                      <span class="nUsersRole">{{ group.users.length }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- FINE CARD -->
          </div>
        </div>
      </div>
    </div>

    <keep-alive>
      <component
        :is="panels[panel]"
        :view="viewPanel"
        @close-panel="openPanel"
        :singleUser="singleUser"
        :groups="groups"
        :singleGroup="singleGroup"
        @edit-info-from-details="editInfoFromDetails"
        @disable-ability-user="disableAbilityUser"
        :allUsers="users"
        @delete-user="deleteUser"
        @open-users-group="openUsersGroup"
        @confirm-user-group="confirmUsersGroup"
        :usersGroup="usersGroup"
        @reset-users-group="resetUsersGroup"
        @open-role="openRole"
        :ctrlPanelUsersSearch="ctrlPanelUsersSearch"
        :ctrlPanelUsersSearchFromGroup="ctrlPanelUsersSearchFromGroup"
        :userLogged="userLogged"
        @open-modal="openModal"
      >
      </component>
    </keep-alive>
  </div>
  <DeleteModal
    :show-modal="viewModal"
    :title="$t('users.titleDelete')"
    :subtitle="
      $t('users.subTitleDelete', {
        name: singleUser?.first_name + ' ' + singleUser?.last_name,
      })
    "
    :text="$t('users.textDelete')"
    :delete-button-text="$t('users.btnDelete')"
    :revert-delete-text="$t('users.revertDelete')"
    @delete="deleteUser(singleUser.ctrl)"
    @close="closeModal()"
  />
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";
import DeleteModal from "@/components/modals/DeleteModal.vue";

const userLogged = computed(() => {
  return useUserStore().user;
});
</script>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import ManagementUser from "@/components/Users/ManagementUser.vue";
import ManagementRole from "@/components/Users/ManagementRole.vue";
import UsersRole from "@/components/Users/UsersRole.vue";
import DetailsUser from "@/components/Users/DetailsUser.vue";
import SearchUser from "@/components/Users/SearchUser.vue";
import "@/assets/scss/Users.scss";
import downloadCsv from "vue-json-csv";
import MyLoading from "@/components/MyLoading.vue";

// import { useUserStore } from '@/stores/UserStore'

export default {
  name: "MyUsers",
  components: {
    MyBanner,
    MyHeaderPage,
    ManagementUser,
    ManagementRole,
    UsersRole,
    DetailsUser,
    SearchUser,
    downloadCsv,
    MyLoading,
  },
  data() {
    return {
      viewPanel: false,
      IsMobile: this.$IsMobile(),
      users: [],
      dataFilter: [],
      usersCsv: [],
      groups: [],
      dataFilterGroups: [],
      singleUser: {},
      singleGroup: {},
      usersGroup: [],
      panel: 0,
      panels: [
        "ManagementUser",
        "ManagementRole",
        "UsersRole",
        "DetailsUser",
        "SearchUser",
      ],
      searchString: "",
      searchGroupString: "",
      ctrlPanelUsersSearch: false,
      ctrlPanelUsersSearchFromGroup: false,
      viewModal: false,
      viewLoader: false,
      isAdmin: false,
    };
  },
  methods: {
    setTab(tab) {
      if (tab == 1) {
        document
          .getElementsByClassName("tabTwo")[0]
          .classList.remove("is-active");
        document
          .getElementsByClassName("tabTwo")[1]
          .classList.remove("is-active");
        document.getElementsByClassName("tabOne")[0].classList.add("is-active");
        document.getElementsByClassName("tabOne")[1].classList.add("is-active");
      } else {
        if (Object.keys(this.singleUser).length > 0) {
          this.singleUser = {};
        }

        document.getElementsByClassName("tabTwo")[0].classList.add("is-active");
        document.getElementsByClassName("tabTwo")[1].classList.add("is-active");
        document
          .getElementsByClassName("tabOne")[0]
          .classList.remove("is-active");
        document
          .getElementsByClassName("tabOne")[1]
          .classList.remove("is-active");
      }
    },
    openPanel(value) {
      this.panel = value.whichPanel;

      if (value.whichPanel != 4) {
        this.viewPanel = !this.viewPanel;
      }

      if (!this.viewPanel) {
        setTimeout(() => {
          this.getGroups();
          this.getUsers(true);
        }, 1000);
      }

      this.viewUser();
      this.viewGroup();

      if (
        Object.keys(this.singleUser).length > 0 &&
        value.whoUpdate == 2 &&
        this.panel == 1
      ) {
        this.panel = 0;
        this.viewPanel = !this.viewPanel;
      }
    },
    addUser() {
      this.singleUser = {};
      this.openPanel({ whichPanel: 0 });
    },
    searchByString() {
      if (this.searchString.length == 0) {
        this.getUsers();
        return;
      }

      this.dataFilter = [];
      this.users.forEach((elm) => {
        if (
          elm.first_name
            .toLowerCase()
            .includes(this.searchString.toLowerCase()) ||
          elm.last_name
            .toLowerCase()
            .includes(this.searchString.toLowerCase()) ||
          elm.email.toLowerCase().includes(this.searchString.toLowerCase()) ||
          (elm.phone &&
            elm.phone.toLowerCase().includes(this.searchString.toLowerCase()))
        ) {
          this.dataFilter.push(elm);
        }
      });

      this.buildCsv();
    },
    searchGroupByString() {
      if (this.searchGroupString.length == 0) {
        this.getGroups();
        return;
      }

      this.dataFilterGroups = [];
      this.groups.forEach((elm) => {
        if (
          elm.name.toLowerCase().includes(this.searchGroupString.toLowerCase())
        ) {
          this.dataFilterGroups.push(elm);
        }
      });

      this.buildGroupsCsv();
    },
    editInfo(index) {
      this.singleUser = this.dataFilter[index];
      this.openPanel({ whichPanel: 0 });
    },
    editGroup(index) {
      this.singleGroup = this.dataFilterGroups[index];
      this.openPanel({ whichPanel: 1 });
    },
    editInfoFromDetails() {
      this.openPanel({ whichPanel: 3 });
      this.openPanel({ whichPanel: 0 });
    },
    openRole(ctrl = 0) {
      if (ctrl == 0) {
        this.singleUser = {};
      } else {
        // nuovo ruolo da crea utente
        this.singleGroup = {};
        this.usersGroup = {};
        this.singleUser = ctrl;
        this.viewPanel = !this.viewPanel;
        this.panel = 1;
        this.viewPanel = !this.viewPanel;
        return;
      }
      this.openPanel({ whichPanel: 0 });
      this.addGroup();
    },
    viewUser(index = -1) {
      let subInfoUser = document.getElementsByClassName("subInfoUser");

      for (let i = 0; i < subInfoUser.length; i++) {
        if (index != i) {
          subInfoUser[i].classList.remove("active");
        }
      }

      if (
        index > -1 &&
        document.getElementsByClassName("subInfoUser-" + index)[0]
      ) {
        if (
          document
            .getElementsByClassName("subInfoUser-" + index)[0]
            .classList.contains("active")
        ) {
          document
            .getElementsByClassName("subInfoUser-" + index)[0]
            .classList.remove("active");
        } else {
          document
            .getElementsByClassName("subInfoUser-" + index)[0]
            .classList.add("active");
        }
      }
    },
    viewGroup(index = -1) {
      let subInfoRole = document.getElementsByClassName("subInfoRole");

      for (let i = 0; i < subInfoRole.length; i++) {
        if (index != i) {
          subInfoRole[i].classList.remove("active");
        }
      }

      if (document.getElementsByClassName("subInfoRole-" + index)[0]) {
        if (
          document
            .getElementsByClassName("subInfoRole-" + index)[0]
            .classList.contains("active")
        ) {
          document
            .getElementsByClassName("subInfoRole-" + index)[0]
            .classList.remove("active");
        } else {
          document
            .getElementsByClassName("subInfoRole-" + index)[0]
            .classList.add("active");
        }
      }
    },
    getUsers(noLoader = false) {
      this.viewLoader = noLoader;
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "users/v1/user", {
          headers: he,
        })
        .then((response) => {
          this.users = response.data;
          this.dataFilter = this.users;
          if (this.searchString.length != 0) {
            this.searchByString();
          }
          this.buildCsv();
          this.viewLoader = true;
          this.getIsAdmin();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getGroups() {
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "users/v1/group", {
          headers: he,
        })
        .then((response) => {
          this.groups = response.data;
          this.dataFilterGroups = this.groups;
          if (this.searchGroupString.length != 0) {
            this.searchGroupByString();
          }
          this.buildGroupsCsv();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    openDetailsUser(index) {
      this.viewUser(index);
      this.singleUser = this.dataFilter[index];
      this.singleUser.index = index;
      this.openPanel({ whichPanel: 3 });
    },
    disableAbilityUser(ctrl = -1) {
      let user = this.singleUser;

      if (ctrl >= 0) {
        user = this.dataFilter[ctrl];
      }

      let what = user.enabled ? "disable" : "enable";

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "users/v1/user/" +
            user.id +
            "/" +
            what,
          {},
          { headers: he }
        )
        .then(() => {
          if (ctrl == -1) {
            // Disabilito dal pannello dei dettagli utente
            this.openPanel({ whichPanel: 3, whoUpdate: 1 });
          } else {
            this.viewUser(ctrl);
            this.getUsers(true);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    deleteUser(ctrl = -1) {
      if (ctrl > -1) {
        this.singleUser = this.dataFilter[ctrl];
      }

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      this.axios
        .delete(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "users/v1/user/" +
            this.singleUser.id,
          { headers: he }
        )
        .then(() => {
          if (ctrl == -1) {
            // Elimino dal pannello dei dettagli utente
            this.openPanel({ whichPanel: 3, whoUpdate: 1 });
            return;
          }
          this.viewUser(ctrl);
          this.getUsers(true);
          this.getGroups();
          this.closeModal();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    openModal(ctrl) {
      window.scrollTo(0, 0);
      this.singleUser = this.dataFilter[ctrl];
      this.singleUser.ctrl = ctrl;
      this.viewModal = true;
      if (this.viewModal) {
        document.getElementsByTagName("body")[0].classList.add("overflow");
      }
      this.viewUser();
    },
    closeModal() {
      this.singleUser = {};
      this.viewModal = false;
      document.getElementsByTagName("body")[0].classList.remove("overflow");
    },
    buildCsv() {
      this.usersCsv = this.dataFilter.map((elem) => ({
        first_name: elem.first_name,
        last_name: elem.last_name,
        email: elem.email,
        secondary_email: elem.secondary_email,
        phone: "'" + elem.phone + "'",
        enabled: elem.enabled ? "Attivo" : "Disattivato",
        group: elem.group.name,
      }));
    },
    buildGroupsCsv() {
      this.groupsCsv = [];
      this.dataFilterGroups.forEach((elem) => {
        let perm = [];
        elem.permissions.forEach((elmFirstLevel) => {
          perm.push(elmFirstLevel.name);
          if (elmFirstLevel.children) {
            elmFirstLevel.children.forEach((elmSecondLevel) => {
              perm.push(elmSecondLevel.name);
              if (elmSecondLevel.children) {
                elmSecondLevel.children.forEach((elmThirdLevel) => {
                  perm.push(elmThirdLevel.name);
                  if (elmThirdLevel.children) {
                    elmThirdLevel.children.forEach((elmFourthLevel) => {
                      perm.push(elmFourthLevel.name);
                    });
                  }
                });
              }
            });
          }
        });
        this.groupsCsv.push({
          name: elem.name,
          permissions: JSON.stringify(perm)
            .replaceAll('"', "")
            .replaceAll("[", "")
            .replaceAll("]", ""),
        });
      });
    },
    openChild(index, levelOne = "", levelTwo = "", group_id) {
      if (typeof levelOne != "string") {
        levelOne = "-" + levelOne;
        if (typeof levelTwo != "string") {
          levelOne += "-" + levelTwo;
        }
      }
      if (document.getElementsByClassName(group_id + "-child-" + index)[0]) {
        if (
          document
            .getElementsByClassName(group_id + "-child-" + index + levelOne)[0]
            .classList.contains("active")
        ) {
          let elms = document.getElementsByClassName(
            group_id + "-child-" + index + levelOne
          );
          for (let i = 0; i < elms.length; i++) {
            elms[i].classList.remove("active");
          }
          document
            .getElementsByClassName(group_id + "-arrow-" + index + levelOne)[0]
            .classList.remove("rotate");
        } else {
          let elms = document.getElementsByClassName(
            group_id + "-child-" + index + levelOne
          );
          for (let i = 0; i < elms.length; i++) {
            elms[i].classList.add("active");
          }
          document
            .getElementsByClassName(group_id + "-arrow-" + index + levelOne)[0]
            .classList.add("rotate");
        }
      }
    },
    addGroup() {
      this.singleGroup = {};
      this.usersGroup = [];
      this.openPanel({ whichPanel: 1 });
    },
    openUsersGroup() {
      this.ctrlPanelUsersSearchFromGroup = true;
      this.openPanel({ whichPanel: 4 });
    },
    confirmUsersGroup(value) {
      this.usersGroup = value.users;
      if (this.ctrlPanelUsersSearch) {
        // salva utenti al gruppo
        let allUsers = this.singleGroup.users.concat(this.usersGroup);
        this.ctrlPanelUsersSearch = false;
        let users_id = {
          user_ids: [],
        };

        if (allUsers.length > 0) {
          allUsers.forEach((elm) => {
            users_id.user_ids.push(elm.id);
          });

          let he = {
            Accept: "application/json",
            Authorization: "Bearer " + this.$keycloak.idToken,
          };

          this.axios.post(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "users/v1/group/" +
              this.singleGroup.id +
              "/bulk-add",
            users_id,
            { headers: he }
          );
        }

        this.viewPanel = !this.viewPanel;
        setTimeout(() => {
          this.getGroups();
          this.getUsers(true);
        }, 500);

        this.viewGroup();
        return;
      }

      this.ctrlPanelUsersSearchFromGroup = false;
      this.viewPanel = !this.viewPanel;
      this.openPanel({ whichPanel: 1 });
    },
    deleteGroup(group_id) {
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      this.axios
        .delete(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "users/v1/group/" +
            group_id,
          { headers: he }
        )
        .then(() => {
          this.getGroups();
          this.viewGroup();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    resetUsersGroup() {
      this.usersGroup = [];
    },
    addUsersGroup(index) {
      this.singleGroup = this.dataFilterGroups[index];
      this.panel = 4;
      this.viewPanel = true;
      this.ctrlPanelUsersSearch = true;
    },
    noEdit(group_name) {
      if (group_name.toLowerCase() == "admin") {
        return false;
      }
      return true;
    },
    getIsAdmin() {
      let userLoggedStore = useUserStore();
      if (
        userLoggedStore.user &&
        userLoggedStore.user.group &&
        userLoggedStore.user.group.name.toLowerCase() == "admin"
      ) {
        this.isAdmin = true;
        return;
      }
      this.isAdmin = false;
      return;
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
    getColorRole(color) {
      return color != "" ? color : "6585a7";
    },
    getPermissionForDirectUser( page, permission ) {
      let isPage = page == permission;
      let userInfo = useUserStore();
      if( !userInfo.user.is_partner && isPage) {
        return false;
      }
      return true;
    }
  },
  beforeUnmont() {
    this.getIsAdmin();
  },
  mounted() {
    // this.getIsAdmin();
    this.getUsers();
    this.getGroups();
  },
};
</script>
