import { useUserStore } from "@/stores/UserStore";
import { useCartStore } from "@/stores/CartStore";
import moment from "moment";

export function IsLoggedIn() {
  setTimeout(() => {
    // console.log("isLoggedIn");

    this.$keycloak
      .loadUserInfo()
      .then(() => {
        const userStore = useUserStore();

        if (
          localStorage.getItem("cdlKcToken") &&
          localStorage.getItem("cdlKcToken") == this.$keycloak.idToken
        ) {
          userStore.user.viewLoader = true;
          return;
        }

        // KC
        var date = new Date(this.$keycloak.idTokenParsed.exp * 1000);
        // Hours part from the timestamp
        var hours = date.getHours();
        // Minutes part from the timestamp
        var minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        var seconds = "0" + date.getSeconds();

        // Will display time in 10:30:23 format
        var formattedTime =
          hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

        console.log("La sessione scade alle: " + formattedTime);

        setInterval(() => {
          // NOW
          var dateNow = new Date();
          // Hours part from the timestamp
          var hoursNow = dateNow.getHours();
          // Minutes part from the timestamp
          var minutesNow = "0" + dateNow.getMinutes();
          // Seconds part from the timestamp
          var secondsNow = "0" + dateNow.getSeconds();

          var duration = moment.duration(
            moment("1970-01-01T" + formattedTime).diff(
              "1970-01-01T" +
                hoursNow +
                ":" +
                minutesNow.substr(-2) +
                ":" +
                secondsNow.substr(-2)
            )
          );

          // console.log(
          //   duration._data.hours,
          //   duration._data.minutes,
          //   duration._data.seconds
          // );

          if (
            duration._data.hours == 0 &&
            duration._data.minutes == 0 &&
            duration._data.seconds == 10
          ) {
            // Rinnovare Token
            this.$router.push({ name: "MyLogin" });
          }
        }, 200);

        userStore.token = this.$keycloak.idToken;

        const cartStore = useCartStore();
        userStore.setUser();
        userStore.setIsBiometric();
        userStore.getUsersBiometric();
        cartStore.getItems();

        localStorage.setItem("cdlKcToken", this.$keycloak.idToken);
      })
      .catch(() => {
        this.$router.push({ name: "MyLogin" });
        // this.$Logout();
        // localStorage.clear();
        // this.$router.push({ name: "HomePage" });
      });
  }, 200);
}
