<script>
export default {
  name: "CrossStep3Completato"
}
</script>

<template>
  <svg id="completato" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
    <circle id="bg" cx="17.5" cy="17.5" r="17.5" fill="#e7fffe"/>
    <g id="icon" transform="translate(8 8)">
      <g id="Raggruppa_3078">
        <rect id="Rettangolo_2487" width="19" height="19" fill="none"/>
      </g>
      <g id="Raggruppa_3079" transform="translate(0.791 0.791)">
        <path id="Tracciato_1563" d="M4.167,1.792a.792.792,0,0,0-1.583,0V4.958H1.792A.794.794,0,0,0,1,5.75V9.708H5.75V5.75a.794.794,0,0,0-.792-.792H4.167ZM7.333,12.875a2.383,2.383,0,0,0,1.583,2.233v2.517a.794.794,0,0,0,.792.792h0a.794.794,0,0,0,.792-.792V15.108a2.368,2.368,0,0,0,1.583-2.233V11.292H7.333ZM1,12.875a2.383,2.383,0,0,0,1.583,2.233v2.517a.794.794,0,0,0,.792.792h0a.794.794,0,0,0,.792-.792V15.108A2.383,2.383,0,0,0,5.75,12.875V11.292H1ZM16.833,4.958V1.792a.792.792,0,1,0-1.583,0V4.958h-.792a.794.794,0,0,0-.792.792V9.708h4.75V5.75a.794.794,0,0,0-.792-.792ZM10.5,1.792a.792.792,0,0,0-1.583,0V4.958H8.125a.794.794,0,0,0-.792.792V9.708h4.75V5.75a.794.794,0,0,0-.792-.792H10.5Zm3.167,11.083a2.383,2.383,0,0,0,1.583,2.233v2.517a.794.794,0,0,0,.792.792h0a.794.794,0,0,0,.792-.792V15.108a2.368,2.368,0,0,0,1.583-2.233V11.292h-4.75Z" transform="translate(-1 -1)" fill="#3caea3"/>
      </g>
    </g>
  </svg>

</template>

<style scoped lang="scss">

</style>
