<template>
  <div
    id="loading"
    v-if="!viewLoader"
    :class="{ fixSmallTable: fixSmallTable }"
  >
    <div id="loader-container" :class="{ full: type == 'full' }">
      <span class="loader"></span>
    </div>
  </div>
</template>

<style>
#loader-container {
  padding: 20px 0px;
}
</style>

<script>
export default {
  name: "MyLoading",
  props: ["viewLoader", "type", "fixSmallTable"],
  beforeCreate() {
    setTimeout(() => {
      this.$IsLoggedIn();
    }, 0);
  },
};
</script>
