<template>
  <div id="MyModal" v-if="showModal">
    <div class="contentModal" :class="{ open: showModal }">
      <span class="material-icons closeOrder" @click="emitClose">close</span>
      <h3>{{ title }}</h3>
      <p class="sub">{{ subtitle }}</p>
      <p class="text">{{ text }}</p>
      <div class="contentButton">
        <button class="cdlButton" @click="emitDelete" style="width: 100%;">
          {{ deleteButtonText }}
        </button>
        <br /><br />
        <p class="deleteOrder" @click="emitClose">{{ revertDeleteText }}</p>
      </div>
    </div>
  </div>
</template>
<!--<div id="MyModal" v-if="viewModal">-->
<!--<div class="contentModal" :class="{ open: viewModal }">-->
<!--        <span class="material-icons closeOrder" @click="closeModal()"-->
<!--        >close</span-->
<!--        >-->
<!--  <h3>{{ $t("companies.titleDelete") }}</h3>-->
<!--  <p class="sub">-->
<!--    {{-->
<!--      $t("users.subTitleDelete", {-->
<!--        name: selectedCompany.company_name,-->
<!--      })-->
<!--    }}-->
<!--  </p>-->
<!--  <p class="text">{{ $t("users.textDelete") }}</p>-->
<!--  <div class="contentButton">-->
<!--    <button-->
<!--        id="closeModal"-->
<!--        class="cdlButton"-->
<!--        @click="deleteCompany(selectedCompany.id)"-->
<!--    >-->
<!--      {{ $t("users.btnDelete") }}-->
<!--    </button>-->
<!--    <br/><br/>-->
<!--    <p class="deleteOrder" @click="closeModal()">-->
<!--      {{ $t("users.revertDelete") }}-->
<!--    </p>-->
<!--  </div>-->
<!--</div>-->
<!--</div>-->

<script setup>
import { defineEmits, defineProps } from "vue";

defineProps({
  showModal: Boolean,
  title: String,
  subtitle: String,
  text: String,
  deleteButtonText: String,
  revertDeleteText: String,
});

const emit = defineEmits(["delete", "close"]);

const emitDelete = () => {
  emit("delete");
};

const emitClose = () => {
  emit("close");
};
</script>
