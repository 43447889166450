<template>
  <div id="ManagementPanel" :class="{ active: view, close: !view }">
    <div class="mdl-grid">
      <div
        class="mdl-cell mdl-cell--1-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <span class="material-icons closePanel" @click="closePanel()"
          >close</span
        >
      </div>
      <div
        class="mdl-cell mdl-cell--11-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <h3>{{ $t("users.titleDetailsUser") }}</h3>
        <p class="subTitle">{{ $t("users.subTitleDetailsUser") }}</p>

        <div class="space"></div>
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("users.titleDetailsUser") }}:</span>
          <p class="detailsUser">
            {{ $t("users.tableNameSurname") }}:
            <strong
              >{{ singleUser.last_name }} {{ singleUser.first_name }}</strong
            >
          </p>
          <p class="detailsUser">
            {{ $t("users.emailUser") }}: <strong>{{ singleUser.email }}</strong>
          </p>
          <p class="detailsUser">
            {{ $t("users.emailTwoUser") }}:
            <strong>{{ singleUser.secondary_email }}</strong>
          </p>
          <p class="detailsUser">
            {{ $t("users.phoneUser") }}: <strong>{{ singleUser.phone }}</strong>
          </p>
          <p class="detailsUser">
            {{ $t("users.maintenance") }}:
            <strong>{{
              singleUser.checkEmailTwo
                ? singleUser.secondary_email
                : singleUser.email
            }}</strong>
          </p>
          <br />
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("users.detailsRole") }}
          </p>
          <p class="subSectionTwo">{{ $t("users.subSectionTwo") }}</p>
          <div
            class="groupName color-white"
            :class="['color-' + singleUser.group.color]"
          >
            {{ singleUser.group.name }}
          </div>
        </div>
        <br /><br /><br />
        <div class="contentButton">
          <button class="cdlButtonGreen" @click="editInfo()">
            {{ $t("users.editUser") }}
          </button>
          <br /><br />
          <button
            class="cdlButton" style="width: 100%;"
            @click="disableAbilityUser()"
            v-if="singleUser.email != userLogged.email"
          >
            {{
              singleUser.enabled
                ? $t("users.disableUser")
                : $t("users.abilityUser")
            }}
          </button>
          <br /><br />
          <p
            class="cancel"
            @click="deleteUser()"
            v-if="singleUser.email != userLogged.email"
          >
            {{ $t("users.deleteUser") }}
          </p>
        </div>
        <br /><br />
        <p class="cancel" @click="closePanel()">{{ $t("users.cancel") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/ManagementPanel.scss";

export default {
  name: "MyManagementUser",
  props: {
    view: {
      type: Boolean,
      required: true,
    },
    singleUser: {
      type: Object,
      required: true,
    },
    userLogged: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closePanel() {
      this.$emit("close-panel", { whichPanel: 0, whoUpdate: 0 });
    },
    editInfo() {
      this.$emit("edit-info-from-details");
    },
    disableAbilityUser() {
      this.$emit("disable-ability-user");
    },
    deleteUser() {
      this.closePanel();

      this.$emit("open-modal", this.singleUser.index);
    },
  },
};
</script>
