import ActiveLines from "@/pages/ActiveLines/ActiveLines.vue";
import ActiveLinesDetail from "@/pages/ActiveLines/ActiveLinesDetail.vue";
import GenericIncidentForm from "@/pages/Incident/GenericIncidentForm.vue";
import GenericChangeRequestForm from "@/pages/ChangeRequest/GenericChangeRequestForm.vue";
import IncidentForm from "@/pages/Incident/IncidentForm.vue";
import ChangeRequestForm from "@/pages/ChangeRequest/ChangeRequestForm.vue";
import IncidentDetails from "@/pages/Incident/IncidentDetails.vue";
import HelpRequestDetails from "@/pages/Help/HelpRequestDetails.vue";


import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";

export function routesActiveLines() {
  return [
    {
      path: "/linee-attive",
      name: "Linesit",
      component: ActiveLines,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "",
          },
        ],
        indexRoute: "linee-attive",
        permissions: ["stato_linee"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines",
      name: "Linesen",
      component: ActiveLines,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "",
          },
        ],
        indexRoute: "active-lines",
        permissions: ["stato_linee"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/linee-attive/dettaglio/:id",
      name: "LinesDetailit",
      component: ActiveLinesDetail,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/linee-attive",
          },
          {
            name: "linesDetail",
            url: "",
          }
        ],
        indexRoute: "linee-attive",
        permissions: ["stato_linee"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines/detail/:id",
      name: "LinesDetailen",
      component: ActiveLinesDetail,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/active-lines",
          },
          {
            name: "linesDetail",
            url: "",
          }
        ],
        indexRoute: "active-lines",
        permissions: ["stato_linee"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    //
    {
      path: "/linee-attive/incident-generico/:asset_type",
      name: "ActiveLinesGenericIncidentFormit",
      component: GenericIncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/linee-attive",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "linee-attive",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines/generic-incident/:asset_type",
      name: "ActiveLinesGenericIncidentFormen",
      component: GenericIncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/active-lines",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "active-lines",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/linee-attive/change-request-generica/:asset_type",
      name: "ActiveLinesGenericChangeRequestFormit",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/linee-attive",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "linee-attive",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines/generic-change-request/:asset_type",
      name: "ActiveLinesGenericChangeRequestFormen",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/active-lines",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "active-lines",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/linee-attive/incident/:asset_id/:asset_type",
      name: "ActiveLinesIncidentFormit",
      component: IncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/linee-attive",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "linee-attive",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines/incident/:asset_id/:asset_type",
      name: "ActiveLinesIncidentFormen",
      component: IncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/active-lines",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "active-lines",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/linee-attive/change-request/:asset_id/:asset_type",
      name: "ActiveLinesChangeRequestFormit",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/linee-attive",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "linee-attive",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines/change-request/:asset_id/:asset_type",
      name: "ActiveLinesChangeRequestFormen",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/active-lines",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "active-lines",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/linee-attive/lista-incident/dettaglio/:id",
      name: "IncidentDetailit",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/linee-attive",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: ""
          }
        ],
        indexRoute: "linee-attive",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines/incident-list/detail/:asset_id",
      name: "IncidentDetailen",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/active-lines",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: ""
          }
        ],
        indexRoute: "active-lines",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/linee-attive/lista-change-request/dettaglio/:id",
      name: "ChangeRequestDetailit",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/linee-attive",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: ""
          }
        ],
        indexRoute: "linee-attive",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-lines/change-request-list/detail/:asset_id",
      name: "ChangeRequestDetailen",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "accessLine",
            url: "",
          },
          {
            name: "lines",
            url: "/active-lines",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: ""
          }
        ],
        indexRoute: "active-lines",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}