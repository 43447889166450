import { useUserStore } from "@/stores/UserStore";

export function ctrlPermissions(permission) {
  let permissions = useUserStore().permissions;

  if (
    Object.keys(permissions).length > 0 &&
    permission &&
    permission.length > 0
  ) {
    let youCan = false;
    permission.forEach((section) => {
      let found = permissions.find((element) => element == section);
      if (found) {
        youCan = true;
      }
    });
    return youCan;
  }

  return false;
}

export async function ctrlPermissionsRoot(permission) {
  const permissions = useUserStore().permissions;

  if (permissions.length > 0 && permission && permission.length > 0) {
    for (const perm of permission) {
      if (permissions.includes(perm)) {
        return true;
      }
    }
  }
  return false;
}
