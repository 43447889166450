<template>
  <div id="RemoteHands">
    <div class="thred">
      <MyHeaderPage
        :title="$t('help.detailTitle', { ticket_id: ticket.ticketNumber })"
      />
      <TicketsBackLink />
      <div class="contentFormTwoCol" v-if="this.viewLoader">
        <HelpRequestDetailBox :ticket="ticket" />
        <div class="contentSecondary noPadding minHeightThreads">
          <Threads
            :headers="headerThread"
            :ticket="ticket"
            :threads="threads"
            :ticket-type="ticketType"
            :attachs="attachs"
            @update="getThreads"
          />
        </div>
      </div>

      <MyLoading :viewLoader="viewLoader" type="full" />
    </div>
  </div>
</template>

<script>
import Threads from "@/components/Threads.vue";
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import HelpRequestDetailBox from "@/components/Help/HelpRequestDetailBox";
import TicketsBackLink from "@/components/Tickets/BackLink.vue";
import "@/assets/scss/Solutions.scss";

export default {
  name: "HelpRequestDetail",
  components: {
    Threads,
    MyHeaderPage,
    MyLoading,
    TicketsBackLink,
    HelpRequestDetailBox,
  },
  data() {
    return {
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      ticket: {},
      threads: {},
      attachs: {},
      ticketType: "",
      headerThread: {
        createdTime: "###",
        modifiedTime: "###",
      },
      viewLoader: true,
      viewError: false,
    };
  },
  methods: {
    getEntity(entityId) {
      this.viewLoader = false;
      const URL = `tickets/v1/ticket/?ticket_id=${this.$route.params.id}&ticket_type=Incident`;
      if (entityId) {
        this.axios
          .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + URL, {
            headers: this.he,
          })
          .then((response) => {
            this.ticket = response.data.res;
            for (const [key, value] of Object.entries(this.ticket)) {
              if (
                Object.prototype.hasOwnProperty.call(this.headerThread, key)
              ) {
                this.headerThread[key] = value;
              }
            }
            if (response.data) {
              this.ticket = response.data.res;
            }
          })
          .then(() => {
            this.viewLoader = true;
            this.getThreads();
          })
          .catch(function (err) {
            self.viewSubmit = true;
            self.viewError = true;
            self.messageError = err.response.data.message;
          });
      }
    },
    getThreads() {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/ticket-threads-by-ticket-number/?ticket_number=" +
            this.$route.params.id,
          { headers: this.he }
        )
        .then((response) => {
          this.threads = response.data;
          if (response.data.threadAttachments) {
            this.attachs = response.data.threadAttachments;
          }
          this.viewLoader = true;
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.ticketType = this.$route.query.crossconnect ? "crossconnect" : "";
    this.getEntity(this.$route.params.id);
  },
  beforeUpdate() {
    this.$IsLoggedIn();
  },
};
</script>
