<template>
  <div id="contentThreads">
    <div class="padding4010">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <h2>{{ $t("detailsTicket.title") }}</h2>
        </div>
        <div
          class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone"
          v-for="(headerOne, name, index) in headers"
          :key="index"
        >
          <div class="label">{{ $t("detailsTicket." + name) }}</div>
          <div
            class="text"
            v-if="name == 'createdTime' || name == 'modifiedTime'"
          >
            {{ cleanDate(headerOne) }}
          </div>
          <div class="text" v-else>{{ headerOne }}</div>
        </div>

        <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
          <div class="label">{{ $t("detailsTicket.statusType") }}</div>
          <div
            class="statusTickets"
            :class="['status' + getClassStatus(ticket.statusType)]"
          >
            {{ $t("tickets." + getClassStatus(ticket.statusType)) }}
          </div>
        </div>

        <div
          class="mdl-cell mdl-cell--3-col"
          v-if="
            ticket.attachment_ids &&
            Object.keys(ticket.attachment_ids).length > 0
          "
        >
          <div class="label">{{ $t("detailsTicket.attach") }}</div>
          <div class="contentAttach">
            <div
              v-for="(attach, name, indexAttach) in ticket.attachment_ids"
              :key="indexAttach"
              @click="downloadFile(ticket.thread0_id, name, attach)"
              v-tooltip:bottom.tooltip="attach"
            >
              <Attach />
            </div>
          </div>
        </div>
        <div
          class="mdl-cell mdl-cell--12-col"
          v-if="ticketType && ticketType === 'crossconnect'"
        >
          <div class="labelThread">{{ $t("detailsTicket.description") }}</div>
          <div class="xconnOrderTable" v-html="ticket.description"></div>
        </div>
        <div
          class="mdl-cell mdl-cell--12-col"
          v-else-if="!ticketType || ticketType != 'Ingressi'"
        >
          <div class="labelThread">{{ $t("detailsTicket.description") }}</div>
          <div class="textThread" v-html="ticket.description"></div>
        </div>
      </div>
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--8-col">
          <div class="labelThread">{{ $t("detailsTicket.operator") }}</div>
          <div class="textThread">{{ ticket.assignee_name }}</div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div
            class="cdlButtonGreen"
            style="width: 80%"
            @click="ctrlModal()"
            v-if="false"
          >
            {{ $t("detailsTicket.answersBtn") }}
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <hr />
        </div>
      </div>
    </div>
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col" v-if="false">
        <p class="text noReply">{{ $t("detailsTicket.noReply") }}</p>
      </div>
    </div>
    <div
      class="mdl-grid padding1040 borderBottom"
      :class="{ fromOperator: thread.direction == 'out' }"
      v-for="(thread, index) in threads"
      :key="index"
    >
      <div class="mdl-cell mdl-cell--8-col mdl-cell mdl-cell--12-col-phone">
        <div style="word-break: break-all">
          <span class="fromMessageLabel"
            >{{ $t("detailsTicket.from") }}
            <span class="fromMessageValue">{{
              thread.fromEmailAddress
            }}</span></span
          >
        </div>
        <div>
          <span class="fromMessageLabel"
            >{{ $t("detailsTicket.to") }}
            <span class="fromMessageValue">{{ thread.to }}</span></span
          >
        </div>
        <div>
          <span class="fromMessageLabel"
            >{{ $t("detailsTicket.cc") }}
            <span class="fromMessageValue">{{ thread.cc }}</span></span
          >
        </div>
        <div>
          <span class="fromMessageLabel"
            >{{ $t("detailsTicket.bcc") }}
            <span class="fromMessageValue">###</span></span
          >
        </div>
      </div>
      <div class="mdl-cell--4-col mdl-cell--12-col-phone">
        <span class="labelDate fromMessageLabel" style="padding: 10px">{{
          cleanDate(thread.createdTime)
        }}</span>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <div class="textThread" v-html="thread.content"></div>
      </div>
      <div
        class="mdl-cell mdl-cell--12-col"
        v-if="
          thread.attachment_ids && Object.keys(thread.attachment_ids).length > 0
        "
      >
        <div class="label">{{ $t("detailsTicket.attachMail") }}</div>
        <div class="contentAttach">
          <div
            v-for="(attach, indexAttach) in thread.attachment_ids"
            :key="indexAttach"
            @click="downloadFile(thread.id, indexAttach, attach)"
            v-tooltip:bottom.tooltip="attach"
          >
            <Attach />
          </div>
        </div>
      </div>
    </div>

    <div class="mdl-cell mdl-cell--12-col" v-if="false">
      <div class="cdlContentButtonSingle">
        <div class="cdlButtonGreen" style="width: 80%" @click="ctrlModal()">
          {{ $t("detailsTicket.answersBtn") }}
        </div>
      </div>
      <br /><br />
    </div>
    <div class="mdl-cell mdl-cell--12-col">
      <p class="text" style="text-align: center;">{{ $t("detailsTicket.noReply") }}</p>
    </div>

    <!-- MODALE -->
    <div
      class="modalTicket ModalVer2"
      :class="{ open: viewModal, close: viewModalClose }"
    >
      <div class="modal-background">
        <div class="contentModal modal contentModalTicket">
          <span class="material-icons closeOrder" @click="ctrlModal()"
            >close</span
          >
          <h3>{{ $t("detailsTicket.modalTitle") }}</h3>
          <p class="subTitle">
            {{
              $t("detailsTicket.modalSubTitle", {
                ticket_id: ticket.ticketNumber,
              })
            }}
          </p>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
              <div class="label-container">
                <span class="labelTwo"> {{ $t("detailsTicket.answer") }}</span>
              </div>
              <div class="cdl-textarea">
                <textarea
                  maxlength="255"
                  name="note"
                  id="textarea"
                  v-model="note"
                ></textarea>
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12-col">
              <div class="label-container">
                <span class="labelTwo">
                  {{ $t("detailsTicket.uploadAttach") }}</span
                >
              </div>
              <div class="subLabelAttach" style="text-align: left">
                {{
                  $t("detailsTicket.uploadAttachSub", {
                    size: getLimitSizeAttach(),
                  })
                }}
              </div>
              <div class="error" v-if="errorAttach" style="text-align: left">
                {{
                  $t("shipping.attachFormat", { format: getFormatNameAttach() })
                }}
              </div>
              <div
                class="error"
                v-if="errorAttachSize"
                style="text-align: left"
              >
                {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12-col listAttach">
              <div
                :class="{ hover: this.attach.length == 0 || this.attach[0] }"
                v-if="this.nAttach >= 1"
              >
                <img
                  src="@/assets/img/shipping/free_attach.png"
                  alt="attach"
                  v-if="this.attach.length == 0"
                />
                <img
                  src="@/assets/img/shipping/complete_attach.png"
                  alt="attach"
                  v-if="this.attach[0]"
                />
                <span
                  class="material-icons deleteAttach"
                  v-if="this.attach[0]"
                  @click="removeAttach(0)"
                  >cancel</span
                >
                <p v-if="this.attach[0]" class="fileName">
                  {{ this.attach[0].file ?? this.attach[0].name }}
                </p>
                <input
                  type="file"
                  @change="onFileChanged($event, 0)"
                  :accept="getFormatAttach()"
                  capture
                  class="fileInput"
                />
              </div>
              <div
                :class="{ hover: this.attach[0] || this.attach[1] }"
                v-if="this.nAttach >= 2"
              >
                <img
                  src="@/assets/img/shipping/free_attach.png"
                  alt="attach"
                  v-if="this.attach[0] && !this.attach[1]"
                />
                <img
                  src="@/assets/img/shipping/no_attach.png"
                  alt="attach"
                  v-if="!this.attach[0] && !this.attach[1]"
                />
                <img
                  src="@/assets/img/shipping/complete_attach.png"
                  alt="attach"
                  v-if="this.attach[1]"
                />
                <span
                  class="material-icons deleteAttach"
                  v-if="this.attach[1]"
                  @click="removeAttach(1)"
                  >cancel</span
                >
                <p v-if="this.attach[1]" class="fileName">
                  {{ this.attach[1].file ?? this.attach[0].name }}
                </p>
                <input
                  type="file"
                  @change="onFileChanged($event, 1)"
                  :accept="getFormatAttach()"
                  capture
                  class="fileInput"
                />
              </div>
              <div
                :class="{ hover: this.attach[1] || this.attach[2] }"
                v-if="this.nAttach >= 3"
              >
                <img
                  src="@/assets/img/shipping/free_attach.png"
                  alt="attach"
                  v-if="this.attach[1] && !this.attach[2]"
                />
                <img
                  src="@/assets/img/shipping/no_attach.png"
                  alt="attach"
                  v-if="!this.attach[1] && !this.attach[2]"
                />
                <img
                  src="@/assets/img/shipping/complete_attach.png"
                  alt="attach"
                  v-if="this.attach[2]"
                />
                <span
                  class="material-icons deleteAttach"
                  v-if="this.attach[2]"
                  @click="removeAttach(2)"
                  >cancel</span
                >
                <p v-if="this.attach[2]" class="fileName">
                  {{ this.attach[2].file ?? this.attach[0].name }}
                </p>
                <input
                  type="file"
                  @change="onFileChanged($event, 2)"
                  :accept="getFormatAttach()"
                  capture
                  class="fileInput"
                />
              </div>
              <div
                :class="{ hover: this.attach[2] || this.attach[3] }"
                v-if="this.nAttach >= 4"
              >
                <img
                  src="@/assets/img/shipping/free_attach.png"
                  alt="attach"
                  v-if="this.attach[2] && !this.attach[3]"
                />
                <img
                  src="@/assets/img/shipping/no_attach.png"
                  alt="attach"
                  v-if="!this.attach[2] && !this.attach[3]"
                />
                <img
                  src="@/assets/img/shipping/complete_attach.png"
                  alt="attach"
                  v-if="this.attach[3]"
                />
                <span
                  class="material-icons deleteAttach"
                  v-if="this.attach[3]"
                  @click="removeAttach(3)"
                  >cancel</span
                >
                <p v-if="this.attach[3]" class="fileName">
                  {{ this.attach[3].file ?? this.attach[0].name }}
                </p>
                <input
                  type="file"
                  @change="onFileChanged($event, 3)"
                  :accept="getFormatAttach()"
                  capture
                  class="fileInput"
                />
              </div>
              <div
                :class="{ hover: this.attach[3] || this.attach[4] }"
                v-if="this.nAttach >= 5"
              >
                <img
                  src="@/assets/img/shipping/free_attach.png"
                  alt="attach"
                  v-if="this.attach[3] && !this.attach[4]"
                />
                <img
                  src="@/assets/img/shipping/no_attach.png"
                  alt="attach"
                  v-if="!this.attach[3] && !this.attach[4]"
                />
                <img
                  src="@/assets/img/shipping/complete_attach.png"
                  alt="attach"
                  v-if="this.attach[4]"
                />
                <span
                  class="material-icons deleteAttach"
                  v-if="this.attach[4]"
                  @click="removeAttach(4)"
                  >cancel</span
                >
                <p v-if="this.attach[4]" class="fileName">
                  {{ this.attach[4].file ?? this.attach[0].name }}
                </p>
                <input
                  type="file"
                  @change="onFileChanged($event, 4)"
                  :accept="getFormatAttach()"
                  capture
                  class="fileInput"
                />
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12-col" v-if="btnSubmit">
              <div class="cdlContentButtonSingle">
                <div
                  class="cdlButtonGreen"
                  style="width: 80%"
                  @click="sendAnswer()"
                >
                  {{ $t("detailsTicket.answersSend") }}
                </div>
              </div>
              <br /><br />
              <p class="deleteOrder" @click="ctrlModal()">
                {{ $t("detailsTicket.removeAnswerTicket") }}
              </p>
            </div>

            <div style="margin: 0 auto">
              <MyLoading :viewLoader="btnSubmit" type="" />
            </div>
            <p
              class="error"
              style="text-align: center"
              v-if="this.errorAnswer != ''"
            >
              {{ this.errorAnswer }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- FINE MODALE -->

    <div class="modalTicket" v-if="false">
      <div class="contentModalTicket"></div>
    </div>
  </div>
</template>

<style>
.label-container {
  margin-bottom: 6px;
  text-align: left;
}
</style>

<script>
import Attach from "@/components/svg/Attach.vue";
import "@/assets/scss/Threads.scss";
import moment from "moment";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "MyThreads",
  components: {
    Attach,
    MyLoading,
  },
  props: {
    headers: {
      type: Object,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    threads: {
      type: Object,
      required: true,
    },
    attachs: {
      type: Object,
    },
    ticketType: {
      type: String,
    },
  },
  data() {
    return {
      viewModal: false,
      viewModalClose: false,
      attach: [],
      nAttach: 5,
      errorAttach: false,
      sizeAttach: 0,
      errorAttachSize: false,
      note: "",
      btnSubmit: true,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": " multipart/form-data",
      },
      errorAnswer: "",
      attachments_list: "",
      attachTmp: [],
    };
  },
  methods: {
    ctrlModal() {
      this.viewModal = !this.viewModal;
      if (this.viewModal) {
        this.viewModalClose = false;
      } else {
        this.viewModalClose = true;
      }
    },
    onFileChanged($event, index) {
      const target = $event.target;
      if (target && target.files) {
        if (
          this.getFormatAttach().search(target.files[0].type) < 0 ||
          target.files[0].type == ""
        ) {
          this.errorAttach = true;
          return;
        }
        this.errorAttach = false;
        this.errorAttachSize = false;

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize = true;
          return;
        }

        this.attachTmp.push(target.files[0]);

        this.attach[index] = target.files[0];
      }
    },
    removeAttach(index) {
      this.attach.splice(index, 1);
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getClassStatus(string) {
      if (string) {
        return string.replace(" ", "");
      }
    },
    cleanDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    downloadFile(thread_id, file_id, filename) {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/upload?ticket_id=" +
            this.ticket.id +
            "&thread_id=" +
            thread_id +
            "&file_id=" +
            file_id +
            "&file_name=" +
            filename,
          { headers: this.he }
        )
        .then((response) => {
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + response.data.content; //Image Base64 Goes here
          a.download = response.data.file_name; //File name Here
          a.click(); //Downloaded file
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },
    getFormatAttach() {
      return window.VUE_APP_TYPE_ATTACH;
    },
    getFormatNameAttach() {
      return window.VUE_APP_TYPE_ATTACH_NAME.replaceAll("&nbsp;", " ");
    },
    sendAnswer() {
      if (this.note != "") {
        let params = {
          ticket_id: this.ticket.ticketNumber,
          body: this.note,
          send_by:
            this.ticket.cf_list.cf_nome_referente +
            "(" +
            this.ticket.cf_list.cf_email_referente +
            ")",
          // attachments_list: this.attachments_list,
          attachments: this.attachTmp,
        };

        this.btnSubmit = false;

        const self = this;

        this.axios
          .post(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "tickets/v1/thread",
            params,
            { headers: this.he }
          )
          .then(() => {
            this.ctrlModal();
            this.$emit("update");
            this.btnSubmit = true;
          })
          .catch(function (err) {
            console.log(err);
            self.btnSubmit = true;
            self.btnSubmit = true;
            self.errorAnswer = err.message;
          });
      }
    },
  },
  mounted() {},
};
</script>
