import RemoteHands from "@/pages/RemoteHands/RemoteHands.vue";
import RemoteHandsList from "@/pages/RemoteHands/RemoteHandsList.vue";
import RemoteHandsDetails from "@/pages/RemoteHands/RemoteHandsDetails.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesRemoteHands() {
  return [
    {
      path: "/remote-hands",
      name: "RemoteHands",
      component: RemoteHands,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "remote_hands",
            url: "",
          },
        ],
        indexRoute: "remote-hands",
        permissions: ["remote_hands_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/lista-remote-hands",
      name: "RemoteHandsListit",
      component: RemoteHandsList,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "RemoteHandsList",
            url: "",
          },
        ],
        indexRoute: "lista-remote-hands",
        permissions: ["remote_hands_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/remote-hands-list",
      name: "RemoteHandsListen",
      component: RemoteHandsList,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "RemoteHandsList",
            url: "",
          },
        ],
        indexRoute: "remote-hands-list",
        permissions: ["remote_hands_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/lista-remote-hands/dettaglio/:id",
      name: "RemoteHandsDetailsit",
      component: RemoteHandsDetails,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "RemoteHandsList",
            url: "/lista-remote-hands/",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "lista-remote-hands",
        permissions: ["remote_hands_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/remote-hands-list/detail/:id",
      name: "RemoteHandsDetailsen",
      component: RemoteHandsDetails,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "RemoteHandsList",
            url: "/remote-hands-list/",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "remote-hands-list",
        permissions: ["remote_hands_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}