<template>
  <div class="mdl-cell mdl-cell--2-col">
    <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
  </div>
  <div class="mdl-cell mdl-cell--10-col">
    <div>
      <span class="label"
        >{{ $t("remoteHands.detailLabelLocation") }}
        <span class="text">{{ data?.cf_datacenter }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.labelRoom") }}
        <span class="text">{{ data?.cf_sala }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.labelRack") }}
        <span class="text">{{ data?.cf_rack }}</span></span
      >
    </div>
  </div>
  <div class="mdl-cell mdl-cell--12-col">
    <hr />
  </div>
  <div class="mdl-cell mdl-cell--2-col">
    <img src="@/assets/img/icon_operation.png" alt="operation" width="45" />
  </div>
  <div class="mdl-cell mdl-cell--10-col">
    <div>
      <span class="label"
        >{{ $t("remoteHands.request.techLevel") }}
        <span class="text">{{ data?.cf_activity_group }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.request.activityStart") }}
        <span class="text">{{
          this.data?.cf_inizio_attivita
            ? dayjs(this.data?.cf_inizio_attivita)?.format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "n.a."
        }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.summary") }}
        <span class="text">{{ data?.cf_sommario || "n.a." }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.request.timeWindow") }}
        <span class="text">{{ data?.cf_timing || "n.a." }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.activity") }}
        <span class="text">{{ data?.cf_tipo_attivita }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.typeOperation") }}
        <span class="text">{{ data?.cf_tipo_di_intervento }}</span></span
      >
    </div>
    <div>
      <span class="label"
        >{{ $t("remoteHands.order") }}
        <span class="text">{{
          data?.cf_ordine ? data.cf_ordine : "n.a."
        }}</span></span
      >
    </div>
  </div>
  <div class="mdl-cell mdl-cell--12-col">
    <hr />
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "RemoteHandsInfo",
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  props: {
    data: Object,
  },
};
</script>
