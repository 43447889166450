<template>
  <div>
    <h2>{{ $t('accessDc.titleStep8') }}</h2>
    <p class="subTitle" v-if="false">{{ $t('accessDc.subTitleWhen') }}</p>

    <div v-if="viewUsers">
      <!-- UTENTI -->
      <AccessDcUser :wizard-data="wizardData" @setUsers="setUsers" :errorNoUsersDad="errorNoUsersDad"
        :typeUsers="'parking'" @changeDcType="changeDcType" />
      <hr />
    </div>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour marginTop20">{{ $t('accessDc.car') }}*</p>
        <span class="error" v-if="carsRequired">
          {{ $t('accessDc.errorNoCars') }}
        </span>
      </div>
      <div class="mdl-cell mdl-cell--12-col contentUsersSearch">
        <div v-for="(car, index) in carsSelected" :key="index" class="singleCar viewRead active"
          v-tooltip:bottom.tooltip="$t('accessDc.removeUser')" @click="removeCar(car.id)">
          <div>{{ car.name }} {{ car.license }}</div>
          <div class="material-icons">done</div>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--3-col">
        <MyLoading :viewLoader="viewLoaderCars" type="" />
        <button class="cdlButton" @click="openModalCar()" v-if="viewLoaderCars">
          {{ $t('accessDc.listCarBtn') }}
        </button>
      </div>
      <div class="mdl-cell mdl-cell--9-col"></div>
    </div>

    <hr />
    <div class="mdl-grid">

      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour marginTop20">{{ $t('accessDc.addNewCar') }}</p>
      </div>
      <div class="mdl-cell mdl-cell--3-col">
        <button class="cdlButton" @click="openModalNewCar()">
          {{ $t('accessDc.newCarBtn') }}
        </button>
      </div>
      <div class="mdl-cell mdl-cell--9-col"></div>
    </div>

    <div class="cdlContentButtonDouble">
      <div class="cdlButtonSingle width170" @click="goBack()">
        {{ $t('checkCoverage.stepsButtonTwo') }}
      </div>
      <div id="goNext" class="cdlButtonSingle width170" @click="goNext(false)">
        {{ $t('checkCoverage.stepsButtonOne') }}
      </div>
      <div class="cdlButtonSingle" @click="goNext(true)" v-if="wizardData.isCompleted">
        {{ $t('accessDc.goEnd') }}
      </div>
    </div>
    <p class="deleteOrder" v-if="!wizardData.isCompleted" @click="ctrlModalAccessDcRemove()">{{
      $t('checkCoverage.stepsButtonThree') }}</p>
  </div>

  <!-- MODALE LISTA AUTO -->
  <div class="ModalAccessUsers" :class="{ open: viewModalCars, close: viewModalCarsClose }">
    <div class="modal-background">
      <div class="contentModal modal">
        <span class="material-icons closeOrder" @click="openModalCar()">close</span>
        <h3>{{ $t('accessDc.modalAccessCarsTitle') }}</h3>
        <p class="subTitle">{{ $t('accessDc.modalAccessCarsSubTitle') }}</p>
        <span class="error" v-if="errorCars">
          {{ $t('accessDc.errorCars', { n: limitCars }) }}
        </span>
        <span class="error" v-if="errorCarsMuch">
          {{ $t('accessDc.errorCars', { n: 3 }) }}
        </span>

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentInputSearch">
              <div><span class="material-icons searchIcon">search</span></div>
              <div class="contentInput">
                <input type="text" :placeholder="$t('accessDc.searchCar')" @keyup="searchByString"
                  v-model="searchString" />
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col contentUsersSearch">
            <div>
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--3-col singleCar view" v-for="(car, index) in cars" :key="index"
                  @click="setCar(car.id)" :id="['car-' + car.id]">
                  <div>{{ car.name }} - {{ car.license }}</div>
                  <div class="material-icons iconDone">done</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentButton">
              <button id="submitCustomerEnd" class="cdlButtonGreen" @click="openModalCar()">
                {{ $t('accessDc.modalUsersSubmit') }}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- FINE MODALE LISTA AUTO -->

  <!-- MODALE NUOVA AUTO -->
  <div class="ModalAccessUsers" :class="{ open: viewModalNewCar, close: viewModalNewCarClose }">
    <div class="modal-background">
      <div class="contentModal modal">
        <span class="material-icons closeOrder" @click="openModalNewCar()">close</span>
        <h3>{{ $t('accessDc.modalAccessNewCarTitle') }}</h3>
        <p class="subTitle" v-html="$t('accessDc.modalAccessNewCarSubTitle')"></p>
        <span class="error" v-if="errorExists">
          {{ $t('accessDc.errorCarsExists') }}
        </span>
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input" style="margin-top: 0;">
              <input type="text" name="name" v-model="car.name" @input="label($event);" maxlength="50" />
              <label>{{ $t('accessDc.nameCar') }}*</label>
              <span class="error" v-if="(this.v$.car.name.$invalid && this.v$.car.name.$dirty)">
                {{ $t('accessDc.nameCar') }} {{ $t('checkCoverage.isRequired') }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input" style="margin-top: 0;">
              <input type="text" name="license" v-model="car.license" @input="label($event);" maxlength="50" />
              <label>{{ $t('accessDc.licenseCar') }}*</label>
              <span class="error" v-if="(this.v$.car.license.$invalid && this.v$.car.license.$dirty)">
                {{ $t('accessDc.licenseCar') }} {{ $t('checkCoverage.isRequired') }}
              </span>
            </div>
          </div>

          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentButton">
              <button id="submitCustomerEnd" class="cdlButtonGreen" @click="saveCar()">
                {{ $t('checkCoverage.modalSubmit') }}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- FINE MODALE NUOVA AUTO -->
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import { useUserStore } from '@/stores/UserStore'
import { useAccessDcStore } from "@/stores/AccessDcStore";
import AccessDcUser from '@/components/AccessDc/AccessDcUser.vue'
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: 'BookParking',
  components: {
    AccessDcUser,
    MyLoading
  },
  props: {
    wizardData: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      car: {
        name: { requiredIfFoo: requiredIf(this.viewModalNewCar) },
        license: { requiredIfFoo: requiredIf(this.viewModalNewCar) }
      },
    }
  },
  data() {
    return {
      viewModalCars: false,
      viewModalCarsClose: false,
      viewModalNewCar: false,
      viewModalNewCarClose: false,
      carsSelected: [],
      searchString: '',
      form: {
        parking: {
          fromTime: null,
          toTime: null,
          users: [],
          cars: []
        }
      },
      car: {
        name: null,
        license: null
      },
      he: this.getHeaders(),
      limitCars: 0,
      errorCars: false,
      errorExists: false,
      carsRequired: false,
      errorCarsMuch: false,
      errorNoUsersDad: false,
      viewUsers: true,
      viewLoaderCars: false,
    }
  },
  methods: {
    getHeaders() {
      return {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.$keycloak.idToken
      }
    },
    openModalCar() {
      this.carsRequired = false;
      this.viewModalCars = !this.viewModalCars;
      if (this.viewModalCars) {
        this.viewModalCarsClose = false;
        this.searchString = '';
        this.viewAll();
        if (this.carsSelected.length > 0) {
          setTimeout(() => {
            this.carsSelected.forEach(elm => {
              document.getElementById("car-" + elm.id).classList.add('active');
            });
          }, 500);

        }
      }
      else {
        this.viewModalCarsClose = true;
      }
    },
    openModalNewCar() {
      this.errorCars = false,
        this.carsRequired = false;

      this.viewModalNewCar = !this.viewModalNewCar;
      if (this.viewModalNewCar) {
        this.viewModalNewCarClose = false;
      }
      else {
        this.viewModalNewCarClose = true;
      }
    },
    goBack() {
      this.$emit('goBack');
    },
    async goNext(goSummary = null) {

      this.carsRequired = false;
      if (this.carsSelected.length == 0) {
        this.carsRequired = true;
        return;
      }

      const result = await this.v$.$validate()
      if (result) {
        this.v$.$reset();
        let accessDcStore = useAccessDcStore();
        accessDcStore.form.parking.cars = this.carsSelected;
        this.$emit('update');
        this.$emit('goNext', { goSummary });
      }

    },
    label(ev) {
      this.$LabelInput(ev);
    },
    ctrlModalAccessDcRemove() {
      this.$emit('close');
    },
    setCar(id) {
      this.errorCars = false;
      this.errorCarsMuch = false;
      if (document.getElementById("car-" + id).classList.contains('active')) {
        document.getElementById("car-" + id).classList.remove('active');
        if (this.carsSelected.length == 1) {
          this.carsSelected = [];
        }
        else {
          this.carsSelected.forEach((el, i) => {
            if (el.id == id) {
              this.carsSelected.splice(i, 1);
            }
          });
        }
      }
      else {
        if (this.limitCars == this.carsSelected.length) {
          this.errorCars = true;
          return;
        }

        if (this.carsSelected.length == 3) {
          this.errorCarsMuch = true;
          return;
        }

        document.getElementById("car-" + id).classList.add('active');
        this.cars.forEach(car => {
          if (car.id == id) {
            this.carsSelected.push(car);
          }
        })
      }
    },
    removeCar(id) {
      this.errorCars = false;
      this.errorCarsMuch = false;
      this.carsSelected.forEach((el, i) => {
        if (el.id == id) {
          this.carsSelected.splice(i, 1);
        }
      });
    },
    searchByString() {
      if (this.searchString.length == 0) {
        this.viewAll();
        return;
      }

      this.hideAll();
      this.cars.forEach(elm => {
        if (
          elm.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          elm.license.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
          document.getElementById("car-" + elm.id).classList.add('view');
        }
      });
    },
    viewAll() {
      let allInputs = document.getElementsByClassName("singleCar");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.add('view');
      }
    },
    hideAll() {
      let allInputs = document.getElementsByClassName("singleCar");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.remove('view');
      }
    },
    async saveCar() {
      this.errorExists = false;
      this.cars.forEach(elm => {
        if (elm.license == this.car.license) {
          this.errorExists = true;
          return;
        }
      });

      if (!this.errorExists) {
        const result = await this.v$.$validate()
        if (result) {
          this.v$.$reset();
          let userStore = useUserStore();
          let data = this.car;
          data.gamma_id = this.wizardData.gammaId;
          data.mod_by = "ASSENZIO - " + userStore.user.first_name + " " + userStore.user.last_name;

          this.axios.post(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'front/v1/car_new', data, { headers: this.he }).then((rls) => {
            if (rls.data.status == 'ok') {
              this.carsSelected.push(rls.data.data);
              this.getAllCars();
              this.openModalNewCar();
            }
          });
        }
      }


    },
    getAllCars() {
      const self = this;
      this.axios.get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'front/v1/get-cars/' + this.wizardData.gammaId, { headers: this.he }).then((rls) => {
        this.cars = rls.data.data;
        this.viewLoaderCars = true;
      }).catch(() => {
        return self.$router.push({ name: 'AccessRequest' + self.$i18n.locale });
      });
    },
    //UTENTI
    setUsers(result) {
      this.form.parking.users = result;
      this.limitCars = this.form.parking.users.length >= 3 ? 3 : this.form.parking.users.length;
      let accessDcStore = useAccessDcStore();
      accessDcStore.form.parking.users = this.form.parking.users;
    },
    changeDcType(type) {
      this.$emit('goNext', { type });
    }
  },
  activated() {
    // Se è DC orario DC, Se è CoWorking orario CoWorking
    if (this.wizardData.form.typeAccess.indexOf('dc') > -1) {
      this.limitCars = this.wizardData.form.dc.users.length >= 3 ? 3 : this.wizardData.form.dc.users.length;
    }
    else if (this.wizardData.form.typeAccess.indexOf('coworking') > -1) {
      this.limitCars = this.wizardData.form.coworking.users.length >= 3 ? 3 : this.wizardData.form.coworking.users.length;
    }
    else {
      this.limitCars = this.wizardData.form.parking.users.length >= 3 ? 3 : this.wizardData.form.parking.users.length;
    }

    if (this.wizardData.form.parking.cars.length > 0) {
      this.carsSelected = this.wizardData.form.parking.cars;
    }

    if (this.wizardData.form.typeAccess.indexOf('dc') > -1 || this.wizardData.form.typeAccess.indexOf('coworking') > -1) {
      this.viewUsers = false;
    }

  },
  mounted() {
    if (this.form.parking.cars.length == 0) {
      this.getAllCars();
    }

    if (this.wizardData.form.typeAccess.indexOf('dc') > -1 || this.wizardData.form.typeAccess.indexOf('coworking') > -1) {
      this.viewUsers = false;
    }

  }
}
</script>