<template>
  <div class="contentPrimary boxRhDetails">
    <div class="mdl-grid alignItems">
      <div class="mdl-cell mdl-cell--2-col">
        <img src="@/assets/img/icon_info.png" alt="location" width="45" />
      </div>
      <div
        class="mdl-cell mdl-cell--10-col"
        v-if="ticket.cf_list?.cf_sold_solution"
      >
        <p class="label">
          {{ $t("detailsTicket.IncidentDetailSolutionLabel") }}
        </p>
        <span class="text">{{ ticket.cf_list.cf_sold_solution }}</span>
      </div>
      <div class="mdl-cell mdl-cell--10-col" v-else>
        <span class="label">{{ $t("tickets.defaultDetailTitle") }}</span>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <hr />
      </div>

      <div
        v-if="ticket.cf_list?.cf_severity_level_picklist"
        class="mdl-cell mdl-cell--2-col"
      >
        <img src="@/assets/img/icon_operation.png" alt="operation" width="45" />
      </div>
      <div
        class="mdl-cell mdl-cell--10-col"
        v-if="ticket.cf_list?.cf_severity_level_picklist"
      >
        <div>
          <span class="label">{{ $t("help.incident.severityLevel") }}</span>
          <span class="text" style="margin-left: 3px">{{
            ticket.cf_list.cf_severity_level_picklist
          }}</span>
        </div>
      </div>
      <div
        v-if="ticket.cf_list?.cf_severity_level_picklist"
        class="mdl-cell mdl-cell--12-col"
      >
        <hr />
      </div>

      <slot name="colocation-category"></slot>

      <div class="mdl-cell mdl-cell--2-col">
        <img src="@/assets/img/icon_reference.png" alt="reference" width="45" />
      </div>
      <div class="mdl-cell mdl-cell--10-col">
        <div>
          <span class="label">{{ $t("help.emailRef") }}</span>
          <span
            v-if="ticket.cf_list?.cf_email_referente"
            class="text"
            style="margin-left: 3px"
            >{{ ticket.cf_list.cf_email_referente }}</span
          >
          <span v-else class="text" style="margin-left: 3px">{{
            userStore.user?.email
          }}</span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "HelpRequestDetailBox",
  data() {
    return {
      userStore: {},
    };
  },
  props: {
    ticket: Object,
  },
  mounted() {
    this.userStore = useUserStore();
  },
};
</script>
