<template>
  <div class="card mdl-grid">
    <div class="label-container mdl-cell mdl-cell--12-col">
      <label for="C21" class="cardTitle mt-3">B - END</label>
    </div>
    <div class="divider mdl-cell mdl-cell--12-col"></div>
    <RedundancyComponent
      :header-text="$t('crossConnects.rackOfYourOwnership')"
      :sub-text="''"
      :togled="localFormContent.is_my_rack"
      @toggle-changed="handleToggleChangedInsideCard"
      class="mdl-cell mdl-cell--12-col"
    />

    <div class="mdl-grid" v-if="localFormContent.is_my_rack">
      <div class="field mdl-cell mdl-cell--12-col">
        <div class="field-title second">{{ $t("crossConnects.place") }}*</div>
        <select
          name="room-option-b"
          id="room-option-b"
          v-model="localFormContent.cage"
          class="form-element select-grey"
          @change="updateRoom"
        >
          <option value="Seleziona" selected>
            {{ $t("crossConnects.select") }}
          </option>
          <option
            v-for="room in selectedDatacenterRoomsRB"
            :key="room.id"
            :value="room.id"
          >
            {{ room.name }}
          </option>
        </select>
        <span
          v-if="validationInitiated && !localFormContent.cage"
          class="error-message"
        >
          {{ $t("crossConnects.requiredRoom") }}
        </span>
      </div>
      <div class="field mdl-cell mdl-cell--12-col">
        <div class="field-title second">{{ $t("crossConnects.isle") }}</div>
        <select
          name="island-option-b"
          id="island-option-b"
          v-model="localFormContent.island"
          class="form-element select-grey"
          @change="updateIsland"
        >
          <option value="Seleziona">{{ $t("crossConnects.select") }}</option>
          <option v-for="island in islands" :key="island.id" :value="island.id">
            {{ island.name }}
          </option>
        </select>
      </div>
      <div class="field mdl-cell mdl-cell--12-col">
        <div class="field-title second">Rack*</div>
        <select
          name="rack-option-b"
          id="rack-option-b"
          v-model="localFormContent.rack"
          class="form-element select-grey"
          @change="updateRack"
        >
          <option value="Seleziona">{{ $t("crossConnects.select") }}</option>
          <option
            v-for="rack in racks"
            :key="rack.id_rack ? rack.id_rack : rack.id"
            :value="rack.id_rack ? rack.id_rack : rack.id"
          >
            {{ rack.name }}
          </option>
        </select>
        <span
          v-if="validationInitiated && !localFormContent.rack"
          class="error-message"
        >
          {{ $t("crossConnects.requiredRack") }}
        </span>
      </div>
    </div>
    <div v-else class="mdl-grid" style="align-items: flex-start">
      <div
        class="mdl-cell--12-col"
        style="margin-bottom: 30px; text-align: left"
      >
        <span class="field-title">{{ $t("crossConnects.uploadLOA") }}*</span>
      </div>
      <div class="mdl-cell--4-col" @click="clickUpload">
        <div v-if="!attachment?.name" class="upoload-btn-container cloud-icon">
          <img src="@/assets/img/shipping/free_attach.png" alt="attach" />
        </div>
        <div v-else class="upoload-btn-container">
          <img src="@/assets/img/shipping/complete_attach.png" alt="attach" />
        </div>
      </div>
      <div class="mdl-cell--2-col" style="text-align: left">
        <span
          class="material-icons deleteAttach"
          v-if="attachment?.name"
          @click="removeAttach"
        >
          cancel
        </span>
      </div>
      <div class="mdl-cell--6-col" @click="clickUpload">
        <div class="text-info">
          <span>{{ $t("crossConnects.infoLOAReq") }}</span>
        </div>

        <input
          type="file"
          ref="fileInput"
          @change="updateFile"
          style="display: none"
        />
      </div>
      <div class="mdl cell--12-col">
        <p v-if="attachment?.name" class="fileName" style="float: left">
          {{ attachment?.name }}
        </p>
      </div>
      <div
        v-if="validationInitiated && Object.keys(attachment).length === 0"
        class="error-message"
      >
        <span>{{ $t("crossConnects.requiredLOA") }}</span>
      </div>
      <div v-if="localFormContent.wrongFileFormatR" class="error-message">
        <span>{{ $t("crossConnects.wrongLoaFormat") }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from "vue";
import RedundancyComponent from "@/components/CrossConnect/ToggleSwitch.vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";

const datacenterStore = useDatacenterStore();

const props = defineProps({
  formContent: Object,
  selectedDatacenterRoomsRB: Array,
  validationInitiated: Boolean,
  roomBNameR: String,
  rackBNameR: String,
  redundant: Boolean,
  attachment: Object,
});

onMounted(() => {
  if (props.formContent.rack) {
    datacenterStore.fetchRacksA(props.formContent.cage).then((res) => {
      racks.value = res.data;
    });
    datacenterStore.fetchIslands(props.formContent.cage).then((res) => {
      islands.value = res.data;
    });
  }
});

const emits = defineEmits([
  "update-room",
  "update-island",
  "update-rack",
  "remove-attach",
  "toggle-changed",
  "update-file",
]);
let racks = ref([]);
let islands = ref([]);
const localFormContent = ref(props.formContent);
const fileInput = ref(null);
const updateRoom = (event) => {
  datacenterStore.fetchRacksB(event.target.value).then((res) => {
    racks.value = res.data;
  });
  datacenterStore.fetchIslands(event.target.value).then((res) => {
    islands.value = res.data;
  });
  localFormContent.value.rack = "";
  localFormContent.value.island = "";
  if (!props.redundant) {
    datacenterStore.roomBName = event.target.selectedOptions[0].label;
  } else {
    datacenterStore.roomBNameR = event.target.selectedOptions[0].label;
  }
  emits(
    "update-room",
    event.target.value,
    event.target.selectedOptions[0].label,
    props.redundant
  );
};

const updateIsland = (event) => {
  datacenterStore.fetchRacksBByIsland(event.target.value).then((res) => {
    racks.value = res.data;
  });
  localFormContent.value.rack = "";
  if (!props.redundant) {
    datacenterStore.islandName = event.target.selectedOptions[0].label;
  } else {
    datacenterStore.islandNameR = event.target.selectedOptions[0].label;
  }
  emits(
    "update-island",
    event.target.value,
    event.target.selectedOptions[0].label,
    props.redundant
  );
};

const updateFile = (event) => {
  if (props.redundant) {
    datacenterStore.handleFileChange(event, "attachment_rbend", true);
  } else {
    datacenterStore.handleFileChange(event, "attachment_b_end", false);
  }
  emits("update-file", event, props.redundant);
};

const updateRack = (event) => {
  if (!props.redundant) {
    datacenterStore.rackBName = event.target.selectedOptions[0].label;
  } else {
    datacenterStore.rackBNameR = event.target.selectedOptions[0].label;
  }
  emits(
    "update-rack",
    event.target.value,
    event.target.selectedOptions[0].label,
    props.redundant
  );
};

const handleToggleChangedInsideCard = (event) => {
  localFormContent.value.is_my_rack = event;
};

const clickUpload = () => {
  fileInput.value.click();
};

const removeAttach = () => {
  if (props.redundant) {
    datacenterStore.attachment_rbend = {};
    datacenterStore.redunandLoaName = null;
  } else {
    datacenterStore.attachment_b_end = {};
    datacenterStore.loaName = null;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/Global.scss";
@import "@/assets/scss/variables.scss";
</style>
