<template>
    <svg id="Step2" data-name="icon step 02 in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle id="Ellisse_153" class="setCircle" data-name="Ellisse 153" cx="17.5" cy="17.5" r="17" fill="#e4effc"/>
        <g id="lightbulb_black_24dp" transform="translate(8 8)">
            <g id="Raggruppa_1630" data-name="Raggruppa 1630">
            <path id="Tracciato_1348" data-name="Tracciato 1348" d="M0,0H19V19H0Z" fill="none"/>
            </g>
            <g id="Raggruppa_1635" data-name="Raggruppa 1635" transform="translate(3.563 1.583)">
            <g id="Raggruppa_1634" data-name="Raggruppa 1634">
                <g id="Raggruppa_1631" data-name="Raggruppa 1631" transform="translate(4.354 14.25)">
                <path id="Tracciato_1349" class="setColor" data-name="Tracciato 1349" d="M11.583,21.583A1.588,1.588,0,0,0,13.167,20H10A1.588,1.588,0,0,0,11.583,21.583Z" transform="translate(-10 -20)" fill="#134c7a"/>
                </g>
                <g id="Raggruppa_1632" data-name="Raggruppa 1632" transform="translate(2.771 11.875)">
                <path id="Tracciato_1350" class="setColor" data-name="Tracciato 1350" d="M8.792,18.583h4.75a.794.794,0,0,0,.792-.792h0A.794.794,0,0,0,13.542,17H8.792A.794.794,0,0,0,8,17.792H8A.794.794,0,0,0,8.792,18.583Z" transform="translate(-8 -17)" fill="#134c7a"/>
                </g>
                <g id="Raggruppa_1633" data-name="Raggruppa 1633">
                <path id="Tracciato_1351" class="setColor" data-name="Tracciato 1351" d="M10.438,2A5.934,5.934,0,0,0,7.485,13.083H13.39A5.934,5.934,0,0,0,10.438,2Z" transform="translate(-4.5 -2)" fill="#134c7a"/>
                </g>
            </g>
            </g>
        </g>
    </svg>
</template>

<script>

export default {
  name: 'MyStep2'
}
</script>