<template>
  <div id="NetworkCoverage" @click="closeFilter($event)">
    <MyBanner :text="$t('banner.checkCoverage', {
    name: user.first_name + ' ' + user.last_name,
  })
    " />
    <MyHeaderPage :title="$t('checkCoverage.title')" :subtitle="$t('checkCoverage.subTitle')" :calendar="false" />

    <div class="singleCard" style="padding: 20px; width: 90%; margin: 0 auto;" v-if="viewCoverage">
      <div class="mdl-grid">
        <div :class="[
                        IsMobile
                          ? 'mdl-cell mdl-cell--12-col-phone content-input'
                          : 'mdl-cell mdl-cell--6-col content-input',
                      ]" id="content-province">
          <label class="labelOne labelSearch" for="province">{{ $t('checkCoverage.searchKit.province') }}</label>
          <model-list-select :list="listProvinces" option-value="abbreviation" option-text="name" v-model="province"
              id="province">
          </model-list-select>    
        </div>
        <div :class="[
                        IsMobile
                          ? 'mdl-cell mdl-cell--12-col-phone content-input'
                          : 'mdl-cell mdl-cell--6-col content-input',
                      ]" id="content-city">
          <label class="labelOne labelSearch" for="city">{{ $t('checkCoverage.searchKit.city') }}</label>
          <model-list-select :list="listCities" option-value="name" option-text="name" v-model="city" id="city"
            :isDisabled="!province ? true : false">
          </model-list-select>  
        </div>
        <div :class="[
                        IsMobile
                          ? 'mdl-cell mdl-cell--12-col-phone content-input'
                          : 'mdl-cell mdl-cell--6-col content-input',
                      ]" id="content-address">
          <label class="labelOne labelSearch" for="address">{{ $t('checkCoverage.searchKit.address') }}</label>
          <model-list-select :list="listAddress" option-value="name" option-text="name" v-model="address"
            id="address" :isDisabled="!city ? true : false">
          </model-list-select> 
        </div>
        <div :class="[
                        IsMobile
                          ? 'mdl-cell mdl-cell--12-col-phone content-input'
                          : 'mdl-cell mdl-cell--6-col content-input',
                      ]" id="content-streetNumber">
          <label class="labelOne labelSearch" for="streetNumber">{{ $t('checkCoverage.searchKit.civic') }}</label>
          <model-list-select :list="listCivic" option-value="name" option-text="name" v-model="streetNumber"
            id="streetNumber" :isDisabled="!address ? true : false">
          </model-list-select> 
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <br /><br />
          <div class="contentCenter" v-if="viewButton">
            <div class="contentButton" @click="getCoverage()" style="width: 100%; margin-top: 0;">
              <button id="checkCoverageId" class="cdlButton checkCoverage" :class="{ active: viewButton }">
                {{ $t("checkCoverage.buttonOne") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contentResult" v-if="viewDetails">
      <p class="resultLabel">{{ $t("checkCoverage.resultLabel") }}</p>
      <div class="contentAddress">
        <p class="resultAddress">{{ getAddress() }}</p>
        <p class="editAddress" @click="editAddress()">
          <span class="material-icons"> edit </span>
        </p>
      </div>
      <NetWorkCoverageFilter ref="MyNetWorkCoverageFilter" @search="filterSearch"
        @searchMultiselect="filterSearchMultiselect" :technology="technology" :carrier="carrier" />

      <p class="helperSearchGoogle noCoverage" v-if="viewError" @click="editAddress()">
        <span class="material-icons">undo</span>
        {{ $t("checkCoverage.errorNoNetworkCoverage") }}
      </p>

      <MyLoading :viewLoader="dataFilter.length > 0 || getCtrlFilter() ? true : false" type="" />
      <div class="contentKits">
        <div v-for="(myKit, key) in dataFilter" :key="key" class="myKit">
          <div class="contentTitleKit" :class="getClassKit(myKit.technology)">
            <div class="contentKitIcon">
              <img :src="getIcon(myKit.technology)" alt="icon" />
            </div>
            <p class="titleKit">{{ myKit.title ?? "" }}</p>
            <div class="contentDetailsTitleKit">
              <div class="detail">
                <p>
                  <span class="detailLabel">{{ $t("checkCoverage.detailLabelTech") }}
                    <span class="detailValue">{{
    myKit.technology
  }}</span></span>
                </p>
              </div>
              <div class="detail">
                <p>
                  <span class="detailLabel">Carrier </span>
                  <span class="detailValue">{{ myKit.carrier }}</span>
                </p>
              </div>
            </div>
            <div>
              <p class="description" v-if="false">###</p>
            </div>
          </div>
          <div class="detailsKit">
            <div class="contentDetail">
              <p class="detailTitle">{{ $t("checkCoverage.detailTitle") }}</p>
              <div class="listDetails" v-if="false">
                <div>
                  <img src="@/assets/img/icon_kit_one.png" alt="icon" />
                </div>
                <div class="detailOne">{{ $t("checkCoverage.speed") }}</div>
                <div>
                  <small class="detailTwo">{{
    $t("checkCoverage.until")
  }}</small><br />
                  <span class="detailThree">##</span>
                </div>
              </div>
              <div class="listDetails" v-if="false">
                <div>
                  <img src="@/assets/img/icon_kit_two.png" alt="icon" />
                </div>
                <div class="detailOne">
                  {{ $t("checkCoverage.guaranteedMinimum") }}
                </div>
                <div>
                  <small class="detailTwo">{{
    $t("checkCoverage.until")
  }}</small><br />
                  <span class="detailThree">##</span>
                </div>
              </div>
              <div class="listDetails" v-if="myKit.cabinet_distance">
                <div>
                  <img src="@/assets/img/icon_kit_three.png" alt="icon" />
                </div>
                <div class="detailOne">
                  {{ $t("checkCoverage.cabinDistance") }}
                </div>
                <div class="listDetailsValueKit">
                  <small class="detailTwo">{{
    $t("checkCoverage.meters")
  }}</small><br />
                  <span class="detailThree">{{ myKit.cabinet_distance }}</span>
                </div>
              </div>
              <div class="listDetails">
                <div>
                  <img src="@/assets/img/icon_kit_five.png" alt="icon" />
                </div>
                <div class="detailOne">
                  {{ $t("checkCoverage.speedDownload") }}
                </div>
                <div class="listDetailsValueKit">
                  <small class="detailTwo">Mbps</small><br />
                  <span class="detailThree">{{
    myKit.commercial_profile.downstream_bandwidth
  }}</span>
                </div>
              </div>
              <div class="listDetails">
                <div>
                  <img src="@/assets/img/icon_kit_four.png" alt="icon" />
                </div>
                <div class="detailOne">
                  {{ $t("checkCoverage.speedUpload") }}
                </div>
                <div class="listDetailsValueKit">
                  <small class="detailTwo">Mbps</small><br />
                  <span class="detailThree">{{
    myKit.commercial_profile.upstream_bandwidth
  }}</span>
                </div>
              </div>
            </div>
            <hr />
            <div class="contentPrice" v-if="myKit.base_price.mrc != -1">
              <p class="titlePrice">{{ $t("checkCoverage.pricesKit") }}</p>
              <div class="contentDetailsPrice">
                <div>
                  <span class="typePrice">{{
    $t("checkCoverage.priceTantum")
  }}</span><br />
                  <span class="price">€{{
      myKit.base_price.nrc.toFixed(2).replace(".", ",")
    }}</span><br />
                  <span class="renewal" v-if="false">{{
    $t("checkCoverage.tantum")
  }}</span>
                </div>
                <div>
                  <span class="typePrice">{{
      $t("checkCoverage.priceMonth")
                    }}</span><br />
                  <span class="price">€{{
                    myKit.base_price.mrc.toFixed(2).replace(".", ",")
                    }}</span><br />
                  <span class="renewal" v-if="false">{{
                    $t("checkCoverage.forMonth")
                    }}</span>
                </div>
              </div>
            </div>
            <div class="contentButton" @click="startOrderNetworkCoverage(myKit, key)">
              <button :id="['buttonSetKitId-' + key]" class="cdlButton buttonSetKit" style="width: 100%">
                <span class="btnKitForHover">{{
                  $t("checkCoverage.buttonTwo")
                  }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const user = computed(() => {
  return useUserStore().user;
});
</script>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/NetworkCoverage.scss";
import { useNetworkCoverageKitStore } from "@/stores/NetworkCoverageKitStore";
import NetWorkCoverageFilter from "@/components/OrderNetworkCoverage/MyNetWorkCoverageFilter.vue";
import MyLoading from "@/components/MyLoading.vue";
import { ModelListSelect } from "vue-search-select"
import "vue-search-select/dist/VueSearchSelect.css"

export default {
  name: "NetworkCoverage",
  props: ["openModalUserDisabled"],
  components: {
    MyBanner,
    MyHeaderPage,
    NetWorkCoverageFilter,
    MyLoading,
  },
  data() {
    return {
      address: "",
      streetNumber: "",
      city: "",
      province: "",
      dataMistra: [],
      dataFilter: [],
      viewError: false,
      messageError: "",
      viewCoverage: true,
      viewButton: false,
      viewDetails: false,
      technology: [],
      carrier: [],
      speed: [],
      ctrlFilter: false,
      IsMobile: this.$IsMobile(),
      helperG: false,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      listProvinces: [],
      listCities: [],
      listAddress: [],
      listCivic: []
    };
  },
  methods: {
    getCoverage() {
      this.viewButton = false;
      this.viewCoverage = false;
      this.viewDetails = true;
      this.ctrlFilter = false;
      this.messageError = "";
      this.viewError = false;
      this.step = 1;

      let checkAddress = {
        province: this.province.abbreviation.toUpperCase(),
        city: typeof this.city === 'string' ? this.city.toUpperCase() : this.city.name.toUpperCase(),
        streetNumber: this.streetNumber.toUpperCase(),
        address: typeof this.city === 'string' ? this.address.toUpperCase() : this.address.name.toUpperCase().replaceAll(".", "%"),
      };

      let ctrlError = 0;

      if (checkAddress.province.name == "") {
        this.messageError = this.$t("checkCoverage.errorMessageProvince");
        ctrlError++;
      }

      if (checkAddress.city.name == "") {
        this.messageError = this.$t("checkCoverage.errorMessageCity");
        ctrlError++;
      }

      if (checkAddress.streetNumber == "") {
        this.messageError = this.$t("checkCoverage.errorMessageStreetNumber");
      }

      if (checkAddress.address.name == "") {
        this.messageError = this.$t("checkCoverage.errorMessageAddress");
        ctrlError++;
      }

      if (ctrlError > 0) {
        this.ctrlFilter = true;
        return;
      }

      let myUrl =
        "province=" +
        checkAddress.province +
        "&city=" +
        checkAddress.city +
        "&house_number=" +
        checkAddress.streetNumber +
        "&address=" +
        checkAddress.address;

      this.setAddress(checkAddress);

      let url =
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
        "net-coverages/v1/coverage-kit?" +
        myUrl.replaceAll(" ", "%20");

      const self = this;

      this.axios
        .get(url, { headers: this.he })
        .then((response) => {
          this.helperG = false;
          if (response.data.length > 0) {
            this.dataMistra = response.data;
            this.dataFilter = response.data;
            this.setFilter();
          } else {
            this.viewError = true;
            this.ctrlFilter = true;
          }
        })
        .catch(function (err) {
          console.log(err);
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.ctrlFilter = true;
        });
    },
    setAddress(address) {
      let networkCoverageKitStore = useNetworkCoverageKitStore();
      networkCoverageKitStore.setAddress(address);
      localStorage.setItem("cdlOrderKitAddress", JSON.stringify(address));
    },
    editAddress() {
      // location.reload();
      this.dataMistra = [];
      this.dataFilter = [];
      this.viewDetails = false;
      this.viewCoverage = true;
      this.province = null;
      this.city = null;
      this.streetNumber = null;
      this.address = null;
      this.technology = [];
      this.carrier = [];
      this.messageError = "";
      this.viewError = false;
      this.step = 1;
      this.listCities = [];
      this.listAddress = [];
      this.listCivic = [];
    },
    startOrderNetworkCoverage(kit) {
      let networkCoverageKitStore = useNetworkCoverageKitStore();
      networkCoverageKitStore.setKit(kit, this.$i18n.locale);
      localStorage.setItem(
        "cdlOrderKit",
        JSON.stringify({ kit: kit, lang: this.$i18n.locale })
      );
      // setTimeout(() => {
      return this.$router.push({
        name: "OrderNetworkCoverage" + this.$i18n.locale,
      });
      // }, 500);
    },
    getIcon(technology) {
      let rls = this.$KitStyle(technology, "icon");
      return rls;
    },
    getClassKit(technology) {
      let rls = this.$KitStyle(technology, "class");
      return rls;
    },
    filterSearch(search) {
      if (search.search == "") {
        this.dataFilter = this.dataMistra;
        return;
      }
      this.dataFilter = [];
      this.dataMistra.forEach((elm) => {
        let titleIt = elm.title
          ? elm.title.toLowerCase().includes(search.search.toLowerCase())
          : false;
        let descriptionIt = elm.description
          ? elm.description.toLowerCase().includes(search.search.toLowerCase())
          : false;
        let technology = elm.technology
          ? elm.technology.toLowerCase().includes(search.search.toLowerCase())
          : false;
        let carrier = elm.carrier
          ? elm.carrier.toLowerCase().includes(search.search.toLowerCase())
          : false;
        if (titleIt || descriptionIt || technology || carrier) {
          this.dataFilter.push(elm);
        }
        this.ctrlFilter = true;
      });

      if (this.dataFilter.length == 0) {
        this.ctrlFilter = true;
      }
    },
    filterSearchMultiselect(search) {
      if (search.total == 0) {
        this.dataFilter = this.dataMistra;
        return;
      }
      this.dataFilter = [];
      if (search.technology.length > 0) {
        this.dataMistra.forEach((elm) => {
          search.technology.forEach((tech) => {
            if (elm.technology.toLowerCase().includes(tech.toLowerCase())) {
              this.dataFilter.push(elm);
            }
          });
        });
      }

      if (search.carrier.length > 0) {
        let ctrlObject =
          this.dataFilter.length > 0 ? this.dataFilter : this.dataMistra;
        this.dataFilter = [];
        ctrlObject.forEach((elm) => {
          search.carrier.forEach((carr) => {
            if (elm.carrier.toLowerCase().includes(carr.toLowerCase())) {
              this.dataFilter.push(elm);
            }
          });
        });
      }

      this.dataFilter = Array.from(new Set(this.dataFilter));

      if (this.dataFilter.length == 0) {
        this.ctrlFilter = true;
      }
    },
    setFilter() {
      this.dataMistra.forEach((elm) => {
        this.technology.push(elm.technology);
        this.carrier.push(elm.carrier);
        this.carrier = Array.from(new Set(this.carrier));
        this.technology = Array.from(new Set(this.technology));
      });
    },
    closeFilter(ev) {
      if (
        this.viewDetails &&
        ev.srcElement.classList.value.search("buttonSetKit") < 0 &&
        ev.srcElement.classList.value.search("closeMultiselectFilter") < 0 &&
        ev.srcElement.classList.value.search("technology") < 0 &&
        ev.srcElement.classList.value.search("carrier") < 0
      ) {
        if (this.$refs.MyNetWorkCoverageFilter) {
          this.$refs.MyNetWorkCoverageFilter.closeAllFilter();
        }
      }
    },
    getPlaceHolderSearchAddress() {
      return this.$t("checkCoverage.placeholder");
    },
    getAddress() {
      let addr = typeof this.address === 'string' ? this.address : this.address.name;
      let city = typeof this.city === 'string' ? this.city : this.city.name;
      return (
        addr +
        " " +
        this.streetNumber +
        " " +
        city +
        " " +
        this.province.abbreviation
      );
    },
    isMobile() {
      return this.IsMobile;
    },
    IsLoggedIn() {
      return this.$IsLoggedIn();
    },
    getCtrlFilter() {
      return this.ctrlFilter;
    },
    ctrlInput(ev) {
      this.helperG = false;
      if (ev.srcElement.value != "") {
        setTimeout(() => {
          this.helperG = true;
        }, 500);
      }
    },
    getStates() {
      let url =
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
        "net-coverages/v1/states";

      this.axios
        .get(url, { headers: this.he })
        .then((response) => {
          this.listProvinces = response.data.data;
        });
    },
    viewCities() {

      let province = this.listProvinces.find((item) => item.abbreviation === ( typeof this.province === 'string' ? this.province : this.province.name )); 
      
      if( province && Object.keys(province).length > 0 ) {
        this.province = province;
        let url =
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "net-coverages/v1/cities?state_id=" + this.province.id;

        this.axios
          .get(url, { headers: this.he })
          .then((response) => {
            this.listCities = response.data.data;
          });
      }

    },
    viewAddress() {
      let city = this.listCities.find((item) => item.name === ( typeof this.city === 'string' ? this.city : this.city.name ));

      if( city && Object.keys(city).length > 0 ) {
        this.city = city;
        let url =
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "net-coverages/v1/address?city_id=" + this.city.id;

        this.axios
          .get(url, { headers: this.he })
          .then((response) => {
            this.listAddress = response.data.data;
          });
      }

    },
    viewCivic() {
      let address = this.listAddress.find((item) => item.name === ( typeof this.address === 'string' ? this.address : this.address.name ));
      if( address && Object.keys(address).length > 0 ) {
        this.address = address;
        let url =
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "net-coverages/v1/civic?address_id=" + this.address.id;

        this.axios
          .get(url, { headers: this.he })
          .then((response) => {
            this.listCivic = response.data.data;
          });
      }

    }
  },
  mounted() {
    if (localStorage.getItem("cdlOrderNetworkCoverage")) {
      let form = JSON.parse(localStorage.getItem("cdlOrderNetworkCoverage"));
      if (form.form.cart_id) {
        localStorage.removeItem("cdlOrderKit");
        localStorage.removeItem("cdlOrderKitAddress");
        localStorage.removeItem("cdlOrderNetworkCoverage");
        localStorage.removeItem("cdlOrderNetworkCoverageConditions");
      } else {
        let kit = JSON.parse(localStorage.getItem("cdlOrderKit"));
        this.setAddress(JSON.parse(localStorage.getItem("cdlOrderKitAddress")));
        if (kit) {
          return this.startOrderNetworkCoverage(kit.kit);
        }
        localStorage.removeItem("cdlOrderKit");
        localStorage.removeItem("cdlOrderKitAddress");
        localStorage.removeItem("cdlOrderNetworkCoverage");
        localStorage.removeItem("cdlOrderNetworkCoverageConditions");
      }
    }

    if (localStorage.getItem("cdlOrderNewKit")) {
      let address = JSON.parse(localStorage.getItem("cdlOrderNewKit"));
      localStorage.removeItem("cdlOrderNewKit");
      this.streetNumber = address.number;
      this.province = address.province;
      this.city = address.city;
      this.address = address.address;
      this.getCoverage();
      return;
    }

    this.getStates();

  },
  watch: {
    province: {
      handler(newValue, oldValue) {
        let myValue = newValue ? typeof newValue === 'string' ? newValue : newValue.abbreviation : '';
        let myOldValue = oldValue ? oldValue.abbreviation : '';
        if (myValue && ( myValue!= myOldValue )) {
          this.province = myValue;
          this.city = '';
          this.address = '';
          this.streetNumber = '';
          this.listCities = [];
          this.listAddress = [];
          this.listCivic = [];
          this.viewButton = false;
          this.viewCities();
          if( document.getElementById("city") ) {
            document.getElementById("city").value = "";
            document.getElementById("city").focus();
            document.querySelector('#content-city').querySelector('.icon').click();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    city: {
      handler(newValue, oldValue) {
        let myValue = newValue ? typeof newValue === 'string' ? newValue : newValue.name : '';
        let myOldValue = oldValue ? oldValue.name : '';
        this.city = myValue;
        this.address = '';
        this.streetNumber = '';
        this.listAddress = [];
        this.listCivic = [];
        this.viewButton = false;
        if (myValue && ( myValue!= myOldValue )) {
          this.viewAddress();
          if( document.getElementById("address") ) {
            document.getElementById("address").value = "";
            document.getElementById("address").focus();
            document.querySelector('#content-address').querySelector('.icon').click();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    address: {
      handler(newValue, oldValue) {
        let myValue = newValue ? typeof newValue === 'string' ? newValue : newValue.name : '';
        let myOldValue = oldValue ? oldValue.name : '';
        this.address = myValue;
        this.streetNumber = '';
        this.listCivic = [];
        this.viewButton = false;
        if (myValue && ( myValue!= myOldValue )) {
          this.address = newValue;
          this.viewCivic();
          if( document.getElementById("streetNumber") ) {
            document.getElementById("streetNumber").value = "";
            document.getElementById("streetNumber").focus();
            document.querySelector('#content-streetNumber').querySelector('.icon').click();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    streetNumber: {
      handler(newValue) {
        if (newValue && newValue != '') {
          this.viewButton = true;
        }
      },
      deep: true,
      immediate: true,
    }
  },
};
</script>
