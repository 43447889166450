<script setup>
import { defineEmits, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import moment from "moment";

const props = defineProps(["cart", "cartKey"]),
  emit = defineEmits(["openRemoveItemModal"]);
const I18n = useI18n({});
const router = useRouter();

function getExpired(dateCart) {
  let nameDay = moment(
    new Date(dateCart),
    "YYYY-MM-DD",
    I18n.locale.value,
    true
  ).format("dddd");
  let date = moment(
    new Date(dateCart),
    "YYYY-MM-DD, hh:mm:ss",
    I18n.locale.value,
    true
  ).format("LL");
  return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
}

function getExpiredTime(dateCart) {
  let time = moment(
    new Date(dateCart),
    "HH:mm:ss",
    I18n.locale.value,
    true
  ).format("HH:mm");
  return time;
}

function modalRemoveItem(cart_id) {
  emit("openRemoveItemModal", cart_id);
}

function goToDetail(cart_id) {
  window.scroll(0, 0);
  return router.push({
    name: "CartItemSummary" + I18n.locale.value,
    params: { cart_id: cart_id },
  });
}

function viewDetails(cart_id) {
  let allClasses = document.getElementsByClassName("viewDetails-" + cart_id);
  if (
    document
      .getElementsByClassName("viewDetails-" + cart_id)[0]
      .classList.contains("active")
  ) {
    for (let i = 0; i < allClasses.length; i++) {
      if (allClasses[i].value != "") {
        allClasses[i].classList.remove("active");
      }
    }
    document
      .getElementsByClassName("iconDetails-" + cart_id)[0]
      .classList.remove("rotate");
  } else {
    for (let i = 0; i < allClasses.length; i++) {
      if (allClasses[i].value != "") {
        allClasses[i].classList.add("active");
      }
    }
    document
      .getElementsByClassName("iconDetails-" + cart_id)[0]
      .classList.add("rotate");
  }
}

function getQuantity(product) {
  let prod = product.products[0] ?? product.products;
  return prod.quantity == 0 ? 1 : prod.quantity;
}

// function getPrice(price) {
//   return this.$PriceClean(price ?? 0);
// }
// function getPriceProduct(product, type) {
//   let prod = product.products[0] ?? product.products;
//   if (type == 'nrc') {
//     return this.getPrice(prod.price.nrc);
//   }
//   return this.getPrice(prod.price.mrc);
// }
</script>

<template>
  <div class="mdl-grid">
    <div class="mdl-cell mdl-cell--12-col expireDate">
      <span
        >{{ $t("cart.expireDate") }} {{ getExpired(cart.expire_date) }}
        {{ $t("cart.expireDateTime") }}
        {{ getExpiredTime(cart.expire_date) }}</span
      >
    </div>
    <div class="mdl-cell mdl-cell--10-col">
      <h3>
        {{ props.cart.title }}
        <small
          v-show="
            cart.cross_connect_details?.is_redundant &&
            cart.cross_connect_details?.master_id
          "
          style="
            font-weight: bolder;
            font-size: 12px;
            text-transform: uppercase;
            background-color: bisque;
            padding: 5px;
            border-radius: 5px;
            opacity: 1;
            position: relative;
            top: -5px;
          "
        >
          {{ $t("crossConnects.ridondanza") }}
        </small>
      </h3>
    </div>

    <div
      class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
    >
      <div class="contents-ctrl" style="text-align: center">
        <span
          :id="['item-cart-' + cartKey]"
          class="material-icons CdlIcon CdlIconReverse"
          @click="goToDetail(cart.id)"
          v-tooltip:bottom.tooltip="$t('cart.edit')"
          >edit</span
        >
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span
          class="material-icons CdlIcon CdlIconReverse"
          @click="modalRemoveItem(cart.id)"
          v-tooltip:bottom.tooltip="$t('cart.deleteSolution')"
          >delete</span
        >
      </div>
    </div>

    <div class="mdl-cell mdl-cell--12 content-elms">
      <div class="contents-icons">
        <img src="@/assets/img/icon_kit_one.png" alt="technology" width="30" />
      </div>
      <div class="contents-info">
        <span class="value positionLabel"
          ><span class="label">{{
            $t("checkCoverageSummary.supplyActivation")
          }}</span>
          {{ cart.related_products[0].products[0].title }}
        </span>
      </div>
      <div class="contents-more-info dsk" v-if="!$parent.IsMobile">
        <span class="moreInfo" @click="viewDetails(cart.id)"
          >{{ $t("cart.details") }}
          <span
            class="material-icons"
            :id="'iconDetails-' + cartKey"
            :class="'iconDetails-' + cart.id"
            >expand_more</span
          >
        </span>
      </div>
    </div>
    <div class="mdl-cell mdl-cell--12" v-if="$parent.IsMobile">
      <div class="contents-more-info mobile">
        <span class="moreInfo" @click="viewDetails(cart.id)">
          <span>{{ $t("cart.details") }}</span>
          <span
            class="material-icons"
            :id="'iconDetails-' + cartKey"
            :class="'iconDetails-' + cart.id"
            >expand_more</span
          >
        </span>
      </div>
    </div>

    <div
      class="mdl-cell mdl-cell--12-col viewDetails"
      :class="'viewDetails-' + cart.id"
    >
      <div class="mdl-grid" style="width: 100%">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="boxPrices">
            <div v-if="cart.is_main_prd_sellable">
              <p class="detailPrice">{{ $t("cart.titlePriceSelected") }}</p>

              <div class="content-prices">
                <p class="priceLabel">{{ $t("cart.priceActivation") }}</p>
                <p class="price">€{{ cart.base_price.nrc }}</p>
              </div>
              <div class="content-prices">
                <p class="priceLabel">{{ $t("cart.forMonth") }}</p>
                <p class="price">€{{ cart.base_price.mrc }}</p>
              </div>
            </div>
            <div class="viewDetails" :class="'viewDetails-' + cart.id">
              <hr />
              <p class="detailPrice">{{ $t("cart.titlePriceXC") }}</p>

              <div v-if="typeof cart.related_products.products == 'object'">
                <div
                  v-for="(products, key) in cart.related_products"
                  :key="key"
                >
                  <div v-for="(product, jey) in products.products" :key="jey">
                    <div class="content-prices">
                      <p class="priceLabel">
                        {{ $t("cart.priceActivation") }}
                        {{ product.title }} (x{{ product.quantity }})
                      </p>
                      <p class="price">€{{ product.price.nrc }}</p>
                    </div>
                    <div class="content-prices">
                      <p class="priceLabel">
                        {{ $t("cart.forMonth") }} {{ product.title }} (x{{
                          product.quantity
                        }})
                      </p>
                      <p class="price">€{{ product.price.mrc }}</p>
                    </div>
                    <br /><br />
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(products, key) in cart.related_products"
                  :key="key"
                >
                  <div class="content-prices">
                    <p class="priceLabel">
                      {{ $t("cart.priceActivation") }} {{ cart.title }} ({{
                        $t("cart.qtyLabel")
                      }}
                      {{ getQuantity(products) }})
                    </p>
                    <p class="price">€{{ products.products[0].price.nrc }}</p>
                  </div>
                  <div class="content-prices">
                    <p class="priceLabel">
                      {{ $t("cart.forMonth") }} {{ products.title }} ({{
                        $t("cart.qtyLabel")
                      }}
                      {{ getQuantity(products) }})
                    </p>
                    <p class="price">€{{ products.products[0].price.mrc }}</p>
                  </div>
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--2-col"></div>
      </div>
    </div>

    <div class="mdl-cell mdl-cell--2-col"></div>
  </div>
</template>

<style scoped lang="scss"></style>
