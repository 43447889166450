<template>
<svg id="Step8" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <g id="Ellisse_153" data-name="Ellisse 153" fill="#e4effc" class="setCircle">
    <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
    <circle cx="17.5" cy="17.5" r="17" fill="none"/>
  </g>
  <g id="drive_eta_black_24dp" transform="translate(8 8)">
    <path id="Tracciato_1630" data-name="Tracciato 1630" d="M0,0H19V19H0Z" fill="none"/>
    <path class="setColor" id="Tracciato_1631" data-name="Tracciato 1631" d="M15.6,4.8A1.183,1.183,0,0,0,14.479,4H5.771a1.19,1.19,0,0,0-1.124.8L3.087,9.288A1.644,1.644,0,0,0,3,9.811v5.668a1.188,1.188,0,1,0,2.375,0v-.4h9.5v.4a1.188,1.188,0,1,0,2.375,0V9.811a1.682,1.682,0,0,0-.087-.522ZM5.771,12.708a1.188,1.188,0,1,1,1.188-1.187A1.186,1.186,0,0,1,5.771,12.708Zm8.708,0a1.188,1.188,0,1,1,1.188-1.187A1.186,1.186,0,0,1,14.479,12.708ZM4.583,8.75,5.589,5.726a.8.8,0,0,1,.752-.538h7.568a.8.8,0,0,1,.752.538L15.667,8.75Z" transform="translate(-0.625 -0.833)" fill="#b8c4ce"/>
  </g>
</svg>

</template>

<script>

export default {
  name: 'MyStep8'
}
</script>