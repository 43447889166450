<template>
  <div class="no-permission-page">
    <h1>{{ $t("noPermission.title") }}</h1>
    <p>{{ $t("noPermission.message") }}</p>
    <router-link to="/">{{ $t("noPermission.returnHome") }}</router-link>
  </div>
</template>

<script>
export default {
  name: "NoPermissionPage",
};
</script>

<style scoped>
.no-permission-page {
  text-align: center;
  margin-top: 50px;
}
</style>
