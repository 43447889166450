<template>
  <tr :class="this.$.vnode.key % 2 === 0 ? 'odd' : ''">
    <td style="text-align: center">
      <span> <slot name="first-col"></slot></span>
    </td>
    <td style="text-align: center">
      <span> <slot name="second-col"></slot></span>
    </td>
  </tr>

  <tr :class="this.$.vnode.key % 2 === 0 ? 'odd' : ''">
    <td class="mobile-cta-container" colspan="2">
      <slot name="url-label"></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TicketsMobileRow",
};
</script>
