<template>
  <svg id="step_01_bloccato" data-name="step 01 bloccato" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
    <g id="bg" fill="none" stroke="#e1e7ec" stroke-width="1">
      <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
      <circle cx="17.5" cy="17.5" r="17" fill="none"/>
    </g>
    <g id="icon" transform="translate(8 8)">
      <path id="Tracciato_1557"  d="M0,0H19V19H0Z" fill="none"/>
      <path id="Tracciato_1558"  d="M15.667,10.917H4.583A1.588,1.588,0,0,0,3,12.5v3.167A1.588,1.588,0,0,0,4.583,17.25H15.667a1.588,1.588,0,0,0,1.583-1.583V12.5A1.588,1.588,0,0,0,15.667,10.917Zm-9.5,4.75A1.583,1.583,0,1,1,7.75,14.083,1.588,1.588,0,0,1,6.167,15.667ZM15.667,3H4.583A1.588,1.588,0,0,0,3,4.583V7.75A1.588,1.588,0,0,0,4.583,9.333H15.667A1.588,1.588,0,0,0,17.25,7.75V4.583A1.588,1.588,0,0,0,15.667,3Zm-9.5,4.75A1.583,1.583,0,1,1,7.75,6.167,1.588,1.588,0,0,1,6.167,7.75Z" transform="translate(-0.625 -0.625)" fill="#b8c4ce"/>
    </g>
  </svg>

</template>
<script>
export default {
  name: 'MyCrossStep1'
}
</script>
