<template>
  <div id="MyModal" v-if="viewModal" :style="{marginTop: posModalRackShared + 'px'}">
    <div class="contentModal open success modalCrm"><span class="material-icons closeOrder" id="closeModalX"
        @click="closeModal()">close</span>
      <h3 class="titleModal">{{ $t("accessDc.timeConfirmationModal.title") }}</h3>
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col sub" style="font-size: 1.4rem; line-height: normal;">
          {{ $t("accessDc.timeConfirmationModal.sub") }}:
          {{ sharedRackNames.join(", ") }}
          <!-- Checkbox -->
          <br /><br />
          <div class="checkboxContainer">
            <input type="checkbox" id="timeAgreement" v-model="isChecked" />&nbsp;&nbsp;
            <label for="timeAgreement">{{
              $t("accessDc.timeConfirmationModal.checkboxLabel")
            }}</label>
          </div>
        </div>
      </div>
      <div class="contentButton" >
        <button id="btnModal" class="cdlButtonGreen" @click="confirmAndProceed()" v-if="isChecked">
          {{ $t("accessDc.modalWarehouse.btn") }}
        </button><br><br>
      </div>
    </div>
  </div>
</template>

<script>

import "@/assets/scss/MyModal.scss"

export default {
  name: 'ModalWarehouse',
  props: ['viewModal', 'posModalRackShared', 'sharedRackNames'],
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    closeModal() {
      this.isChecked = false;
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      this.$emit('cancel');
    },
    confirmAndProceed() {
      this.isChecked = false;
      this.$emit("confirm");
    },
  }
}
</script>