export function filterDataByColumns(items, columns, search_string) {
  // iterate through items
  return items.filter((row) => {
    // get values for specified columns
    return (
      columns
        .map((r) => {
          return row[r] != null ? row[r].toLowerCase() : "";
        })
        // check if results match search string
        .some((r) => r.toLowerCase().includes(search_string))
    );
  });
}
