<template>
  <div class="mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
    <div class="mdl-tabs__tab-bar">
      <slot name="tabs"></slot>
    </div>

    <div class="container">
      <div class="toolbar">
        <slot name="toolbar"></slot>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped>
.mdl-tabs {
  background-color: #f8f9fa;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.mdl-tabs .container {
  border: 1px solid rgb(207, 214, 222);
}

.mdl-tabs .toolbar {
  margin: 20px auto;
}

.mdl-tabs__tab-bar {
  justify-content: flex-start;
  border-bottom: 0;
  min-height: 53px;
  margin: 10px auto;
}

.mdl-tabs__tab-bar {
  min-height: 50px;
  font-family: MontserratBold;
  color: #5f6b7a;
  cursor: pointer;
}

.mdl-tabs__tab-bar:hover {
  color: #001831;
}

.mdl-tabs__panel {
  margin-top: -13px;
  display: none;
  padding: 20px;
}
.mdl-tabs__panel.is-active {
  display: block;
}
</style>
