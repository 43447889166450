<template>
    <svg id="Step2" data-name="step 02 in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35"
        viewBox="0 0 35 35">
        <circle id="bg" cx="17.5" cy="17.5" r="17.5" fill="#e4effc" class="setCircle" />
        <g id="icona" transform="translate(8 8)">
            <path id="Tracciato_1554" data-name="Tracciato 1554" d="M0,0H19V19H0Z" fill="none" opacity="0.87" />
            <path class="setColor" id="Tracciato_1555" data-name="Tracciato 1555"
                d="M10.6,1.968,6.209,6.353A1.582,1.582,0,0,0,5.75,7.47v7.909a1.588,1.588,0,0,0,1.583,1.583h7.125A1.6,1.6,0,0,0,15.915,16L18.5,9.979A2.379,2.379,0,0,0,16.311,6.67H11.838l.752-3.626a1.194,1.194,0,0,0-.325-1.085,1.178,1.178,0,0,0-1.67.008ZM2.583,16.962a1.588,1.588,0,0,0,1.583-1.583V9.045A1.583,1.583,0,0,0,1,9.045v6.333A1.588,1.588,0,0,0,2.583,16.962Z"
                transform="translate(-0.208 -0.337)" fill="#134c7a" />
        </g>
    </svg>
</template>

<script>

export default {
    name: 'MyStep2'
}
</script>