import { defineStore } from 'pinia'
import axios from "axios"
import { MyCrypto } from '@/services/MyCrypto';
import i18n from '@/i18n'
import { useAccessDcStore } from '@/stores/AccessDcStore'

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      user: {},
      token: "",
      permissions: [],
      errorMistra: false
    }
  },
  getters: {},
  actions: {
    getHeader() {
      return {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token
      };
    },
    async setPrefered() {
      // Preferences user
      let url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'base/v1/assenzio/preferences';

      const self = this;

      axios
        .get(url, { headers: this.getHeader() })
        .then((rls) => {
          self.user.preferences = rls.data;
          i18n.global.locale.value = self.user.preferences.language;
          i18n.global.fallbackLocale.value = self.user.preferences.language;
          document.querySelector("html").setAttribute('lang', self.user.preferences.language);
          return true;
        })
        .catch(err => { console.log(err) });
    },
    async allAccountsActive() {
      let url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'base/v1/available-account-list';

      const self = this;

      axios
        .get(url, { headers: this.getHeader() })
        .then((rls) => {
          self.user.accounts = rls.data.length > 0 ? rls.data : [];
          if (!self.user.account && rls.data.length > 0) {
            self.user.account = rls.data[0];
          }
          return true;
        })
        .catch(err => { console.log(err) });
    },
    async getAccount() {
      let url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'base/v1/active-account';

      const self = this;

      axios
        .get(url, { headers: this.getHeader() })
        .then((rls) => {
          self.user.account = rls.data;
          return true;
        })
        .catch(err => { console.log(err) });
    },
    async setUser() {
      // User
      let url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'base/v1/user-info';
      const self = this;
      axios
        .get(url, { headers: this.getHeader() })
        .then(response => {
          this.user = response.data;
          this.setPermissions();
          this.setIfColocation();
          this.setIfAccess();
          if (this.setPrefered() && this.allAccountsActive() && this.getAccount()) {
            this.user.viewLoader = true;
            return true;
          }

        })
        .catch(err => { console.log(err); self.user.viewLoader = true; self.errorMistra = true; });
    },
    async setPermissions() {
      if( this.user.permissions.length == 0 ) {
        localStorage.setItem('cdlDisabled', 1);
        return;
      }
      else {
        localStorage.removeItem('cdlDisabled');
      }
      this.user.permissions.forEach((elm) => {
        this.permissions.push(elm.name);
        if (elm.children && elm.children.length > 0) {
          elm.children.forEach(childOne => {
            this.permissions.push(childOne.name);
            if (childOne.children && childOne.children.length > 0) {
              childOne.children.forEach(childTwo => {
                this.permissions.push(childTwo.name);
                if (childTwo.children && childTwo.children.length > 0) {
                  childTwo.children.forEach(childThree => {
                    this.permissions.push(childThree.name);
                  })
                }
              })
            }
          })
        }
      });
    },
    async setIsBiometric() {
      let accessDcStore = useAccessDcStore();
      let url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'front/v1/is-biometric';
      axios
        .get(url, { headers: this.getHeader() })
        .then(response => {
          accessDcStore.isBio = response.data == "true" ? true : false;
          return;
        })
        .catch(err => { console.log(err) });
    },
    async getUsersBiometric() {
      let accessDcStore = useAccessDcStore();
      let url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'front/v1/users-with-cardholder';
      axios
        .get(url, { headers: this.getHeader() })
        .then(response => {
          accessDcStore.usersBio = response.data;
          return;
        })
        .catch(err => { console.log(err) });
    },
    async setIfColocation() {
      this.user.isColocation = false;
      const self = this;
      axios
      .get(
        MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "tickets/v1/service-dc-details?service=Shipping",
        { headers: this.getHeader() }
      )
      .then((response) => {
        if( response.data.length > 0 ) {
          this.user.isColocation = true;
        }
      })
      .catch(function (err) {
        console.log(err);
        self.user.isColocation = false;
      });
    },
    async setIfAccess() {
      this.user.isAccess = false;
      axios
      .get(MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "assets/v1/lines", {
        headers: this.getHeader(),
      })
      .then((response) => {
        if( response.data.length > 0 ) {
          this.user.isAccess = true;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    }
  }
})
