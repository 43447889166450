<template>
  <div
    id="ManagementPanel"
    class="cardRole"
    :class="{ active: view, close: !view }"
  >
    <div class="mdl-grid">
      <div
        class="mdl-cell mdl-cell--1-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <span class="material-icons closePanel" @click="closePanel()"
          >close</span
        >
      </div>
      <div
        class="mdl-cell mdl-cell--11-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <h3 v-if="form.id">{{ $t("group.titleEditGroup") }} {{ form.name }}</h3>
        <h3 v-else>{{ $t("group.titleNewRole") }}</h3>

        <p class="subTitle">{{ $t("group.subTitleNewRole") }}</p>

        <div class="mdl-grid noMargin">
          <div class="mdl-cell mdl-cell--12-col">
            <div class="cdl-input" style="margin: 0; margin-top: 20px">
              <input
                type="text"
                name="name"
                v-model="form.name"
                @input="label($event)"
              />
              <label>{{ $t("group.nameRole") }}*</label>
              <span
                class="error"
                v-if="this.v$.form.name.$invalid && this.v$.form.name.$dirty"
              >
                {{ $t("group.nameRole") }} {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
            <div class="space"></div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <hr />
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("group.sectionOne") }}*</span><br />
            <span
              class="error"
              v-if="
                this.v$.form.permissions.$invalid &&
                this.v$.form.permissions.$dirty
              "
            >
              {{ $t("group.sectionOneError") }}
            </span>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div
              v-for="(permission, keyPermissions) in permissions"
              :key="keyPermissions"
            >
              <div :class="['groupIndex-' + keyPermissions]">
                <div
                  class="itemGroup"
                  @click="openChild($event, 'p-' + keyPermissions)"
                  v-if="getPermissionForDirectUser(permission.name, 'area_partner')"
                >
                  <span
                    class="material-icons arrow"
                    :class="[
                      'arrowPanel-p-' + keyPermissions,
                      permission.children ? '' : 'opacity',
                      form.id ? 'rotate' : '',
                    ]"
                    >arrow_right</span
                  >
                  <span
                    v-if="permission.selectable"
                    class="boxCheck"
                    @click="setPermission(permission.name, keyPermissions)"
                    ><span
                      class="material-icons iconCheck"
                      :value="permission.name"
                      :class="[
                        permission.name,
                        'iconCheck-' + keyPermissions,
                        checkBox(permission.name) ? 'active' : '',
                      ]"
                      >check</span
                    ></span
                  >
                  <span v-else class="boxCheck" style="cursor: no-drop;" v-tooltip="$t('group.noAssign')"></span>
                  <span class="firstUpper"
                  >{{ $t("group.default." + permission.name ) }}</span>
                </div>
                <!-- account -->
                <div
                  v-for="(pages, keyPages) in permission.children"
                  :key="keyPages"
                  class="contentSubElems child"
                  :class="[
                    'child-p-' + keyPermissions,
                    form.id ? 'active' : '',
                  ]"
                >
                  <div class="block">
                    <div class="border"></div>
                    <div>
                      <div
                        class="itemGroup"
                        @click="
                          openChild(
                            $event,
                            'p-' + keyPermissions + '-' + keyPages
                          )
                        "
                      >
                        <span
                          class="material-icons arrow"
                          :class="[
                            'arrowPanel-p-' + keyPermissions + '-' + keyPages,
                            pages.children ? '' : 'opacity',
                            form.id ? 'rotate' : '',
                          ]"
                          >arrow_right</span
                        >

                        <span
                          v-if="pages.selectable"
                          class="boxCheck"
                          @click="
                            setPermission(
                              pages.name,
                              keyPermissions + '-' + keyPages
                            )
                          "
                          ><span
                            class="material-icons iconCheck"
                            :value="pages.name"
                            :class="[
                              pages.name,
                              'iconCheck-' + keyPermissions,
                              'iconCheck-' + keyPermissions + '-' + keyPages,
                              checkBox(pages.name) ? 'active' : '',
                            ]"
                            >check</span
                          >
                          </span>
                          <span v-else class="boxCheck" style="cursor: no-drop;" v-tooltip="'Non puoi assegnare quest&rsquo;area'"></span>
                        <span class="firstUpper">{{
                          $t("group.default." + pages.name)
                        }}</span>
                      </div>
                      <div
                        v-for="(type, keytype) in pages.children"
                        :key="keytype"
                        class="childChild"
                        :class="[
                          'child-p-' + keyPermissions + '-' + keyPages,
                          form.id ? 'active' : '',
                        ]"
                      >
                        <div class="contentSubElems">
                          <div class="block">
                            <div class="borderUnion"></div>
                            <div
                              class="itemGroup"
                              @click="
                                openChild(
                                  $event,
                                  'p-' + keyPermissions,
                                  keyPermissions,
                                  keytype
                                )
                              "
                            >
                              <span
                                class="material-icons arrow"
                                :class="[
                                  'arrowPanel-p-' +
                                    keyPermissions +
                                    '-' +
                                    keyPermissions +
                                    '-' +
                                    keytype,
                                  type.children ? '' : 'opacity',
                                  form.id ? 'rotate' : '',
                                ]"
                                >arrow_right</span
                              >

                              <span
                                v-if="type.selectable"
                                class="boxCheck"
                                @click="
                                  setPermission(
                                    type.name,
                                    keyPermissions +
                                      '-' +
                                      keyPages +
                                      '-' +
                                      keytype
                                  )
                                "
                                ><span
                                  class="material-icons iconCheck"
                                  :value="type.name"
                                  :class="[
                                    type.name,
                                    'iconCheck-' + keyPermissions,
                                    'iconCheck-' +
                                      keyPermissions +
                                      '-' +
                                      keyPages,
                                    'iconCheck-' +
                                      keyPermissions +
                                      '-' +
                                      keyPages +
                                      '-' +
                                      keytype,
                                    checkBox(type.name) ? 'active' : '',
                                  ]"
                                  >check</span
                                >
                              </span>
                              <span v-else class="boxCheck" style="cursor: no-drop;" v-tooltip="'Non puoi assegnare quest&rsquo;area'"></span>

                              <span class="firstUpper">{{
                                $t("group.default." + type.name)
                              }}</span>
                            </div>
                          </div>

                          <div
                            v-for="(singlePage, keySinglePage) in type.children"
                            :key="keySinglePage"
                            class="childChildPage"
                            :class="[
                              'child-p-' +
                                keyPermissions +
                                '-' +
                                keyPermissions +
                                '-' +
                                keytype,
                              form.id ? 'active' : '',
                            ]"
                          >
                            <div class="contentSubElems">
                              <div class="block">
                                <div class="borderUnion"></div>
                                <div class="itemGroup" v-if="getPermissionForDirectUser(singlePage.name, 'verifica_copertura')">
                                  <span
                                    v-if="singlePage.selectable"
                                    class="boxCheck"
                                    @click="
                                      setPermission(
                                        singlePage.name,
                                        keyPermissions +
                                          '-' +
                                          keyPages +
                                          '-' +
                                          keytype +
                                          '-' +
                                          keySinglePage
                                      )
                                    "
                                    ><span
                                      class="material-icons iconCheck"
                                      :value="singlePage.name"
                                      :class="[
                                        'iconCheck-' + keyPermissions,
                                        'iconCheck-' +
                                          keyPermissions +
                                          '-' +
                                          keyPages,
                                        'iconCheck-' +
                                          keyPermissions +
                                          '-' +
                                          keyPages +
                                          '-' +
                                          keytype,
                                        'iconCheck-' +
                                          keyPermissions +
                                          '-' +
                                          keyPages +
                                          '-' +
                                          keytype +
                                          '-' +
                                          keySinglePage,
                                        checkBox(singlePage.name)
                                          ? 'active'
                                          : '',
                                      ]"
                                      >check</span
                                    >
                                  </span>
                                  <span v-else class="boxCheck" style="cursor: no-drop;" v-tooltip="'Non puoi assegnare quest&rsquo;area'"></span>

                                  <span class="firstUpper">{{
                                    $t("group.default." + singlePage.name)
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="space"></div>
            <hr />
            <div class="space"></div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("group.sectionTwo") }}*</span><br />
            <span
              class="error"
              v-if="this.v$.form.color.$invalid && this.v$.form.color.$dirty"
            >
              {{ $t("group.colorGroupRequired") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentColorRole">
              <div
                v-for="(color, keyC) in colors"
                class="colorRole"
                :class="['color-' + color]"
                @click="setColor($event, color)"
                :key="keyC"
              ></div>
            </div>
            <input type="hidden" v-model="form.color" />
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="space"></div>
            <hr />
            <div class="space"></div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("group.sectionThree") }}</span>
            <p class="subSectionTwo" v-if="false">{{ $t("group.subSectionThree") }}</p>
            <br /><br />
          </div>
          <div
            class="mdl-cell mdl-cell--12-col contentUsersSearch"
            v-if="form.users && form.users.length > 0"
          >
            <div
              v-for="(user, index) in form.users"
              :key="index"
              class="singleUser view active"
              :class="['user-' + index]"
            >
              <div>{{ user.first_name }} {{ user.last_name }}</div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentButton">
              <button class="cdlButton" @click="searchUsers()" style="width: 100%;">
                {{ $t("group.searchUser") }}
              </button>
            </div>
            <div class="space"></div>
            <hr />
            <div class="space"></div>
            <div class="contentButton">
              <button class="cdlButtonGreen" @click="submit" id="submitRole">
                {{
                  form.id
                    ? $t("group.subTitleEditGroup")
                    : $t("group.saveNewRole")
                }}
              </button>
            </div>
            <p class="cancel" @click="closePanel()">{{ $t("users.cancel") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import "@/assets/scss/ManagementPanel.scss";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "MyManagementRole",
  props: {
    view: {
      type: Boolean,
      required: true,
    },
    singleGroup: {
      type: Object,
      required: true,
    },
    groups: {
      type: Object,
      required: true,
    },
    usersGroup: {
      type: Object,
      required: true,
    },
    userLogged: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: { required },
        color: { required },
        permissions: { required },
      },
    };
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
        color: "",
        permissions: [],
        users: [],
      },
      permissions: null,
      colors: [
        "a8eec1",
        "fb8873",
        "fae29f",
        "6ed7d3",
        "cfd6de",
        "6585a7",
        "5f6b7a",
        "1b655e",
        "8c6d1f",
      ],
      ctrl: false,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
    };
  },
  methods: {
    closePanel(whoUpdate = 0) {
      this.ctrl = false;
      this.$emit("close-panel", { whichPanel: 1, whoUpdate: whoUpdate });
    },
    async submit() {
      const result = await this.v$.$validate();
      if (result) {
        this.v$.$reset();
        this.viewLoader = false;

        let saveRole = new Promise((resolve) => {
          if (this.form.id) {
            this.axios
              .put(
                this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                  "users/v1/group/" +
                  this.form.id,
                this.form,
                { headers: this.he }
              )
              .then(() => {
                resolve(true);
              });
          } else {
            this.axios
              .post(
                this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                  "users/v1/group/",
                this.form,
                { headers: this.he }
              )
              .then((rls) => {
                this.form.id = rls.data;
                resolve(true);
              });
          }
        });

        Promise.all([saveRole]).then((values) => {
          if (values[0]) {
            let reload = false;

            let users_id = {
              user_ids: [],
            };
            if (this.form.users && this.form.users.length > 0) {
              this.form.users.forEach((elm) => {
                users_id.user_ids.push(elm.id);
                if (this.userLogged.email == elm.email) {
                  reload = true;
                }
              });

              this.axios.post(
                this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                  "users/v1/group/" +
                  this.form.id +
                  "/bulk-add",
                users_id,
                { headers: this.he }
              );
            }

            if (reload) {
              location.reload();
            }

            this.$emit("reset-users-group");

            this.viewLoader = true;
            this.closePanel(2);
          }
        });
      }
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    searchUsers() {
      this.$emit("open-users-group");
    },
    setColor(ev, color) {
      let colorsDiv = document.getElementsByClassName("colorRole");
      for (let i = 0; i < colorsDiv.length; i++) {
        if (i != 0) {
          colorsDiv[i].classList.remove("active");
        }
      }
      ev.srcElement.classList.add("active");

      this.form.color = color;
    },
    getPermissions() {
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "users/v1/permission/",
          { headers: he }
        )
        .then((response) => {
          this.permissions = response.data;
        });
    },
    openChild(ev, index, levelOne = "", levelTwo = "") {
      if (
        !ev.srcElement.classList.contains("boxCheck") &&
        !ev.srcElement.classList.contains("iconCheck")
      ) {
        if (typeof levelOne != "string") {
          levelOne = "-" + levelOne;
          if (typeof levelTwo != "string") {
            levelOne += "-" + levelTwo;
          }
        }
        if (document.getElementsByClassName("child-" + index)[0]) {
          if (
            document
              .getElementsByClassName("child-" + index + levelOne)[0]
              .classList.contains("active")
          ) {
            let elms = document.getElementsByClassName(
              "child-" + index + levelOne
            );
            for (let i = 0; i < elms.length; i++) {
              elms[i].classList.remove("active");
            }
            document
              .getElementsByClassName("arrowPanel-" + index + levelOne)[0]
              .classList.remove("rotate");
          } else {
            let elms = document.getElementsByClassName(
              "child-" + index + levelOne
            );
            for (let i = 0; i < elms.length; i++) {
              elms[i].classList.add("active");
            }
            document
              .getElementsByClassName("arrowPanel-" + index + levelOne)[0]
              .classList.add("rotate");
          }
        }
      }
    },
    setPermission(permission, key, ctrl = 0) {
      let elm = document.getElementsByClassName("iconCheck-" + key)[0];

      // Se quello che ho cliccato è attivo, rimuovo e tolgo tutto quello che c'è dopo
      if (elm.classList.contains("active")) {
        elm.classList.remove("active");
        let index = this.form.permissions.indexOf(permission);
        if (index > -1) {
          this.form.permissions.splice(index, 1);
        }
        let elms = document.getElementsByClassName("iconCheck-" + key);
        if (elms.length > 1) {
          for (let i = 1; i < elms.length; i++) {
            let namePerm = document
              .getElementsByClassName("iconCheck-" + key)
              [i].getAttribute("value");
            let index = this.form.permissions.indexOf(namePerm);
            if (index > -1) {
              this.form.permissions.splice(index, 1);
              document
                .getElementsByClassName("iconCheck-" + key)
                [i].classList.remove("active");
            }
          }
        }
      } else {
        elm.classList.add("active");
        this.form.permissions.push(permission);
        let elms = document.getElementsByClassName("iconCheck-" + key);
        if (elms.length > 1) {
          for (let i = 1; i < elms.length; i++) {
            let namePerm = document
              .getElementsByClassName("iconCheck-" + key)
              [i].getAttribute("value");
            this.form.permissions.push(namePerm);
            document
              .getElementsByClassName("iconCheck-" + key)
              [i].classList.add("active");
          }
        }
      }

      // rimuovo il livello precende a quello selezionato
      if (key.length > 1 && ctrl == 1) {
        let levelUp = key.substring(0, key.length - 2);
        let namePerm = document
          .getElementsByClassName("iconCheck-" + levelUp)[0]
          .getAttribute("value");
        if (
          document
            .getElementsByClassName("iconCheck-" + levelUp)[0]
            .classList.contains("active")
        ) {
          this.setPermission(namePerm, levelUp, 1);
        }
      } else {
        if (key.length > 1) {
          let levelUp = key.substring(0, key.length - 2);
          let namePerm = document
            .getElementsByClassName("iconCheck-" + levelUp)[0]
            .getAttribute("value");
          if (
            document
              .getElementsByClassName(namePerm)[0]
              .classList.contains("active")
          ) {
            let index = this.form.permissions.indexOf(namePerm);
            if (index > -1) {
              this.form.permissions.splice(index, 1);
              document
                .getElementsByClassName(namePerm)[0]
                .classList.remove("active");
            }
          }
        }
      }
      this.removeDuplicates();
    },
    build() {
      if (this.view && !this.ctrl) {
        // Sto aprendo
        this.ctrl = true;
        if (Object.keys(this.singleGroup).length > 0) {
          Object.assign(this.form, this.singleGroup);
        } else {
          for (var key in this.form) {
            this.form[key] = null;
          }
          this.form.permissions = [];
        }

        let allInputs = document.getElementsByTagName("input");
        setTimeout(() => {
          for (let i = 0; i < allInputs.length; i++) {
            if (allInputs[i].value != "") {
              allInputs[i].classList.add("not-empty");
            }
          }
        }, 500);

        let colorsDiv = document.getElementsByClassName("colorRole");
        for (let i = 0; i < colorsDiv.length; i++) {
          colorsDiv[i].classList.remove("active");
          if (
            this.form.id != null &&
            colorsDiv[i].classList.contains("color-" + this.form.color)
          ) {
            colorsDiv[i].classList.add("active");
          }
        }
        this.getPermissions();
        this.cleanPermissionGroup();
      }
    },
    cleanPermissionGroup() {
      let perm = [];
      this.form.permissions.forEach((elmFirstLevel) => {
        perm.push(elmFirstLevel.name);
        if (elmFirstLevel.children) {
          elmFirstLevel.children.forEach((elmSecondLevel) => {
            perm.push(elmSecondLevel.name);
            if (elmSecondLevel.children) {
              elmSecondLevel.children.forEach((elmThirdLevel) => {
                perm.push(elmThirdLevel.name);
                if (elmThirdLevel.children) {
                  elmThirdLevel.children.forEach((elmFourthLevel) => {
                    perm.push(elmFourthLevel.name);
                  });
                }
              });
            }
          });
        }
      });
      this.form.permissions = perm;
      this.tmpPermissions = this.form.permissions;
    },
    checkBox(check) {
      return this.form.permissions.find((element) => element == check);
    },
    removeDuplicates() {
      return this.form.permissions.filter(
        (item, index) => this.form.permissions.indexOf(item) === index
      );
    },
    getPermissionForDirectUser( page, permission ) {
      let isPage = page == permission;
      let userInfo = useUserStore();
      if( !userInfo.user.is_partner && isPage) {
        return false;
      }
      return true;
    }
  },
  watch: {
    usersGroup: {
      handler() {
        if (this.singleGroup.users) {
          this.form.users = this.singleGroup.users.concat(this.usersGroup);
        } else {
          this.form.users = this.usersGroup;
        }
      },
      deep: true,
    },
  },
  activated() {
    window.scrollTo(0, 0);
    this.build();
  },
  updated() {
    // window.scrollTo(0, 0);
    this.build();
  },
};
</script>
