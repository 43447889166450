<template>
  <span class="mdl-tabs__tab"
    >{{ label }}
    <div class="line"></div>
  </span>
</template>

<style scoped>
.mdl-tabs__tab {
  margin: 0;
  border: none;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  overflow: hidden;
  min-height: 50px;
  font-family: MontserratBold;
  color: #5f6b7a;
  cursor: pointer;
  padding: 10px 15px 0px 15px;
  background-color: #f8f9fa;
  line-break: normal;
}
.mdl-tabs__tab.is-active {
  border-top: 1px solid #cfd6de;
  border-left: 1px solid #cfd6de;
  border-right: 1px solid #cfd6de;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #001831;
}

.line {
  min-width: 80%;
  min-height: 2px;
  margin: 0 auto;
}

.mdl-tabs__tab.is-active .line {
  background-color: #001831;
}
</style>

<script>
export default {
  name: "TabElement",
  props: {
    label: String,
  },
};
</script>
