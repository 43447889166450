<template>
    <svg id="Step5" data-name="icon step 05 in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle id="Ellisse_153" class="setCircle" data-name="Ellisse 153" cx="17.5" cy="17.5" r="17" fill="#e4effc"/>
        <g id="account_tree_black_24dp" transform="translate(8 8)">
            <rect id="Rettangolo_2407" data-name="Rettangolo 2407" width="19" height="19" fill="none"/>
            <path id="Tracciato_1356" class="setColor" data-name="Tracciato 1356" d="M13.875,9.333H16.25A1.583,1.583,0,0,0,17.833,7.75V4.583A1.583,1.583,0,0,0,16.25,3H13.875a1.583,1.583,0,0,0-1.583,1.583v.792H7.55V4.583A1.583,1.583,0,0,0,5.966,3H3.583A1.588,1.588,0,0,0,2,4.583V7.75A1.583,1.583,0,0,0,3.583,9.333H5.958A1.583,1.583,0,0,0,7.542,7.75V6.958H9.125v5.55a2.368,2.368,0,0,0,2.367,2.367h.8v.792a1.583,1.583,0,0,0,1.583,1.583H16.25a1.583,1.583,0,0,0,1.583-1.583V12.5a1.583,1.583,0,0,0-1.583-1.583H13.875A1.583,1.583,0,0,0,12.292,12.5v.792h-.8a.791.791,0,0,1-.784-.784V6.958h1.583V7.75A1.588,1.588,0,0,0,13.875,9.333Z" transform="translate(-0.417 -0.625)" fill="#134c7a"/>
        </g>
    </svg>
</template>

<script>

export default {
  name: 'MyStep5'
}
</script>