import { useI18n } from "vue-i18n";
import axios from "axios";
import { MyCrypto } from "./MyCrypto";

export function buildSolutionLabel(solutionData, assetType) {
  console.log(assetType);
  const datacenter = solutionData.building ? `${solutionData.building} - ` : "";
  return `${datacenter}${solutionData.solution}`;
}

export function getBackUrlFromAssetType(assetType) {
  const i18n = useI18n();
  if (assetType === "cross-connects") {
    return "CrossConnectList" + i18n.locale.value;
  }
  return (
    assetType.charAt(0).toUpperCase() +
    assetType.slice(1, assetType.length) +
    i18n.locale.value
  );
}

export function checkDuplicateTicketForAsset(assetType, assetId, headers) {
  const GET_DUPLICATES_URL = `tickets/v1/duplication-check?asset_type=${assetType}&asset=${assetId}`;
  return axios.get(
    MyCrypto(window.VUE_APP_MISTRA_URL, 1) + GET_DUPLICATES_URL,
    { headers: headers }
  );
}

export function getIncidentSeverityLevels(headers) {
  const GET_SEVERITY_LEVELS_URL = `front/v1/incident-levels`;
  return axios.get(
    MyCrypto(window.VUE_APP_MISTRA_URL, 1) + GET_SEVERITY_LEVELS_URL,
    { headers: headers }
  );
}
