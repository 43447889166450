<template>
  <div id="app-content">
    <div>
      <div ref="el" id="header" class="demo-layout-transparent mdl-layout">
        <header class="mdl-layout__header">
          <div class="mdl-layout__header-row">
            <div class="infoAccounts">
              <div class="avatarAccount"></div>

              <!-- MOBILE MENU -->
              <div class="menuMobile" v-if="IsMobile">
                <span class="material-icons" @click="OnOpenMenuMobile()"
                  >menu</span
                >
                <div
                  class="menuMobileOpen"
                  :class="{ active: openMenuMobile, close: closeMenuMobile }"
                  v-if="true"
                >
                  <MenuMobile
                    @closeMenu="OnOpenMenuMobile"
                    :lang="useUserStore().user?.preferences?.language"
                    :user="user"
                  />
                </div>
              </div>
              <!-- FINE MOBILE MENU -->

              <div>
                <div class="smallLabel">{{ $t("nav.labelAccount") }}</div>
                <div id="currentAccount" class="currentAccount">
                  {{ user.account ? user.account.business_name : "" }}
                </div>
              </div>
              <div
                class="contentArrowAccount"
                :class="{ active: getPositionInfoAccountShow() }"
              >
                <span
                  v-if="
                    user.accounts && user.accounts.length > 1 ? true : false
                  "
                  class="material-icons arrowAccounts"
                >
                  <span @click="viewAccount()"> keyboard_arrow_down </span>
                </span>
              </div>
            </div>
            <div
              class="subInfoAccounts"
              :style="getPositionInfoAccounts()"
              v-if="positionInfoAccountShow"
              :class="{ active: positionInfoAccountShow }"
            >
              <div
                v-for="account in user.accounts"
                :key="account.id"
                class="contentSingleAccount"
              >
                <div
                  v-if="user.account.business_name != account.business_name"
                  @click="changeAccount(account)"
                >
                  {{ account.business_name }}
                </div>
              </div>
            </div>

            <CdLanLogo v-if="!IsMobile" />

            <!-- Add spacer, to align navigation to the right -->
            <div class="mdl_layout_spacer"></div>
            <div class="orderDraft" @click="getDraftOrder()" v-if="orderDraft">
              Hai una soluzione non completata. Clicca qui per riprenderla
            </div>
            <div
              id="infoUser"
              class="infoUser"
              @click="viewUser()"
              :style="{
                backgroundImage: getImageAvatar(
                  user.preferences ? user.preferences.avatar_link : null
                ),
              }"
            >
              <!-- Dropdown Content (Nested Inside InfoUser) -->
              <div
                class="subInfoUser"
                v-if="getPositionInfoUserShow()"
                :class="{ active: getPositionInfoUserShow() }"
              >
                <div
                  class="noHover"
                  v-if="
                    user.preferences &&
                    user.preferences.language &&
                    getEnvSwichLang()
                  "
                >
                  <label class="switch">
                    <input
                      type="checkbox"
                      class="changeLang"
                      :checked="langChange == 'en' ? true : false"
                      @click="handleLanguageToggle"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div v-if="ctrlPerms(['utenti']) && false" @click="editUser()">
                  {{ $t("nav.settingUser") }}
                </div>
                <div v-if="false">Password</div>
                <div class="groupMenu">
                  <span @click="logout()">{{
                    $t("keycloak.buttonLogout")
                  }}</span>
                </div>
              </div>
            </div>

            <div class="cdl-spaces"></div>
            <div
              class="contentCart items"
              @click="ctrlCart()"
              v-if="
                ctrlPerms([
                  'verifica_copertura',
                  'cross_connect',
                  'cross_connect_nuovo',
                  'cross_connect_lista',
                ])
              "
            >
              <span class="material-icons mdl-badge" :data-badge="cartItems"
                >shopping_cart</span
              >
            </div>
          </div>
          <hr />
        </header>

        <!-- SIDEMENU -->
        <MenuDesktop
          ref="menuDesktopRef"
          :ctrlMenuDesktop="ctrlMenuDesktop"
          :lang="useUserStore().user?.preferences?.language"
          :user="user"
          v-if="!IsMobile"
        />

        <!-- FINE SIDEMENU -->
      </div>
      <div
        class="content"
        :class="{ mobile: IsMobile }"
        @click="
          OnOpenMenuMobile(false);
          viewUser(false);
        "
      >
        <div class="marginTop"></div>
        <MyLoading :viewLoader="viewLoader" type="full" />
        <MyCart
          :view="viewCart"
          @close-cart="ctrlCart"
          :getCart="viewCart"
          @count-cart="countCart"
          @openModal="openModal"
        />
        <MyModal
          v-if="isTokenAvailable"
          :viewModal="getViewMyModal()"
          @close="closeModal"
          :valueModal="modal.valueModal"
          :typeModal="getTypeMyModal()"
          :tokenKc="getToken()"
        />
        <MyBreadcrumbs />
        <div @click="closeMenuDesktop()">
          <router-view @openModal="openModal" />
        </div>

        <ModalUserDisabled
          :viewModal="modal.userDisabled"
          @close="ctrlModalUserDisabled"
        />
      </div>
    </div>
    <div class="contentBubble">
      <img
        src="@/assets/img/webp/blob_bottom_sx.webp"
        width="800"
        alt="bubble"
      />
      <img
        src="@/assets/img/webp/blob_bottom_dx_800.webp"
        width="800"
        alt="bubble"
        class="dx"
      />
    </div>

    <div v-if="errorMistra" id="outService">
      <p>
        <span class="material-icons icon">back_hand</span><br />
        Servizio al momento non disponibile.
      </p>
    </div>
  </div>
</template>

<!-- Uses a transparent header that draws on top of the layout's background -->
<style>
.demo-layout-transparent .mdl-layout__header,
.demo-layout-transparent .mdl-layout__drawer-button {
  /* This background is dark, so we set text to white. Use 87% black instead if
      your background is light. */
  color: white;
}
</style>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { useCartStore } from "@/stores/CartStore";
import { useOrderDraftStore } from "@/stores/OrderDraft";
import "shepherd.js/dist/css/shepherd.css";

const user = computed(() => useUserStore().user);
const isTokenAvailable = computed(() => !!useUserStore().token);

const cartItems = computed(() => useCartStore().cart.length);

const viewLoader = computed(() => useUserStore().user.viewLoader);

const orderDraft = computed(() => useOrderDraftStore().draft);

const errorMistra = computed(() => {
  let rls = useUserStore().errorMistra;
  if (rls) {
    document.getElementsByTagName("body")[0].classList.add("overflow");
  }
  return rls;
});

const el = ref(null);
const menuDesktopRef = ref(null);

onMounted(async () => {
  await nextTick(); // Wait for DOM updates
  if (menuDesktopRef.value) {
    await menuDesktopRef.value.$nextTick(); // Ensure MenuDesktop is rendered
    menuDesktopRef.value.startTour();
  }
});
</script>

<script>
import "@/assets/scss/App.scss";
import CdLanLogo from "@/components/CdLanLogo.vue";
import MyLoading from "@/components/MyLoading.vue";
import MyCart from "@/components/MyCart.vue";
import MyBreadcrumbs from "@/components/MyBreadcrumbs.vue";
import MyModal from "@/components/MyModal.vue";
import ModalUserDisabled from "@/components/ModalUserDisabled.vue";
import { useUserStore } from "@/stores/UserStore";
import MenuMobile from "@/components/MenuMobile.vue";
import MenuDesktop from "@/components/MenuDesktop.vue";
import i18n from "@/i18n";

export default {
  name: "App",
  components: {
    CdLanLogo,
    MyLoading,
    MyCart,
    MyBreadcrumbs,
    MyModal,
    ModalUserDisabled,
    MenuMobile,
    MenuDesktop,
  },
  data() {
    return {
      viewNavbar: false,
      openMenu: false,
      closeMenu: true,
      mylastUrl: "/",
      positionInfoAccountShow: false,
      positionInfoAccountLeft: 0,
      positionInfoAccountWidth: 0,
      positionInfoUserShow: false,
      positionInfoUserLeft: 0,
      positionInfoUserWidth: 0,
      imageDefault: "",
      viewCart: false,
      groupMenuOneShow: false,
      groupMenuTwoShow: false,
      IsMobile: this.$IsMobile(),
      openMenuMobile: false,
      closeMenuMobile: true,
      modal: {
        viewModal: false,
        valueModal: 0,
        typeModal: null,
        userDisabled: false,
      },
      backgroundImage: `${require("@/assets/img/webp/pic_profile.webp")}`,
      contItemMenuOpen: 0,
      switchLang: false,
      langChange: null,
      ctrlMenuDesktop: false,
    };
  },
  methods: {
    logout() {
      this.$Logout();
    },
    ctrlCart() {
      this.viewCart = !this.viewCart;
    },
    countCart(n) {
      this.cartItems = n.n;
    },
    OnOpenMenuMobile(ctrl = true) {
      if (!ctrl) {
        this.openMenuMobile = false;
        this.closeMenuMobile = true;
        return;
      }
      this.openMenuMobile = !this.openMenuMobile;
      this.closeMenuMobile = !this.closeMenuMobile;
    },
    viewAccount() {
      this.positionInfoAccountShow = !this.positionInfoAccountShow;
    },
    getPositionInfoAccounts() {
      return {
        left: this.positionInfoAccountLeft + "px",
        width: this.positionInfoAccountWidth + "px",
      };
    },
    getPositionInfoAccountShow() {
      return this.positionInfoAccountShow;
    },
    viewUser(ctrl = true) {
      // Toggle the visibility of the user info popup if it's explicitly called to open or close
      if (ctrl) {
        this.positionInfoUserShow = !this.positionInfoUserShow;
        this.langChange = useUserStore().user.preferences.language;
      } else {
        this.positionInfoUserShow = false;
      }
    },
    getPositionUser() {
      return {
        left: this.positionInfoUserLeft + "px",
        width: this.positionInfoUserWidth + "px",
      };
    },
    getPositionInfoUserShow() {
      return this.positionInfoUserShow;
    },
    setGroupMenuOneShow() {
      this.groupMenuOneShow = !this.groupMenuOneShow;
    },
    getGroupMenuOneShow() {
      return this.groupMenuOneShow;
    },
    setGroupMenuTwoShow() {
      this.groupMenuTwoShow = !this.groupMenuTwoShow;
    },
    getGroupMenuTwoShow() {
      return this.groupMenuTwoShow;
    },
    openModal(value) {
      window.scrollTo(0, 0);
      document.getElementsByTagName("body")[0].classList.add("overflow");

      switch (value.type) {
        case "removeItemCart":
          this.modal.valueModal = value.cart_id;
          break;
        case "removeAllItems":
          this.modal.valueModal = value.carts;
          break;
        case "KitNotFound":
        case "confirmShippingDeliveries":
        case "confirmShippingPickUpDeliveries":
          this.modal.valueModal = value;
          break;
        default:
          break;
      }

      this.modal.typeModal = value.type;
      this.modal.viewModal = true;
    },
    closeModal() {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      this.modal.viewModal = false;
    },
    getViewMyModal() {
      return this.modal.viewModal;
    },
    getTypeMyModal() {
      return this.modal.typeModal;
    },
    getDraftOrder() {
      localStorage.setItem(
        "cdlOrderKit",
        localStorage.getItem("cdlOrderKitBcK")
      );
      localStorage.setItem(
        "cdlOrderKitAddress",
        localStorage.getItem("cdlOrderKitAddressBcK")
      );
      localStorage.setItem(
        "cdlOrderNetworkCoverage",
        localStorage.getItem("cdlOrderNetworkCoverageBcK")
      );

      localStorage.removeItem("cdlOrderKitBcK");
      localStorage.removeItem("cdlOrderKitAddressBcK");
      localStorage.removeItem("cdlOrderNetworkCoverageBcK");

      localStorage.removeItem("cdlOrderNetworkCoverageConditions");

      this.$OrderDraft();

      return this.$router.push({ name: "NetworkCoverage" + this.$i18n.locale });
    },
    getImageAvatar(image) {
      return image ? "url(" + image + ")" : "url(" + this.backgroundImage + ")";
    },
    changeAccount(account) {
      localStorage.removeItem("cdlSetups");
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      this.axios
        .put(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "base/v1/active-account",
          { account_id: account.id },
          { headers: he }
        )
        .then(() => {
          location.reload();
        });
    },
    getVersion() {
      return window.VUE_APP_VERSION;
    },
    ctrlVersions() {
      return true; //window.VUE_APP_VERSION.search("v0.9.") > -1 ? false : true;
    },
    editUser() {
      this.viewUser();
      return this.$router.push({ name: "Users" + this.$i18n.locale });
    },
    getToken() {
      if (localStorage.getItem("cdlKcToken") != null) {
        return localStorage.getItem("cdlKcToken");
      } else return useUserStore().token;
    },
    ctrlModalUserDisabled() {
      if (this.modal.userDisabled || !localStorage.getItem("cdlUserDisabled")) {
        this.modal.userDisabled = !this.modal.userDisabled;
        localStorage.setItem("cdlUserDisabled", 1);
      }

      if (this.modal.userDisabled) {
        window.scrollTo(0, 0);
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
    handleLanguageToggle() {
      // Toggle language state instantly
      const newLanguage = this.langChange === "en" ? "it" : "en";
      this.changLang(newLanguage);
    },
    changLang(value) {
      // Update language state
      this.langChange = value;

      i18n.global.locale.value = value;
      i18n.global.fallbackLocale.value = value;

      document.querySelector("html").setAttribute("lang", value);

      let url =
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
        "base/v1/assenzio/preferences";

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      this.axios
        .put(url, { language: value }, { headers: he })
        .then(() => {
          useUserStore().user.preferences.language = value;
        })
        .catch((error) => {
          console.error("Error updating language:", error);
        });
    },
    closeMenuDesktop() {
      this.ctrlMenuDesktop = !this.ctrlMenuDesktop;
    },
    getEnvSwichLang() {
      return window.VUE_APP_SHOW_LANG_SWITCH
        ? this.$MyCrypto(window.VUE_APP_SHOW_LANG_SWITCH, 1)
        : false;
    },
  },
  beforeCreate() {
    setTimeout(() => {
      if (!this.$keycloak.authenticated) {
        this.$router.push({ name: "MyLogin" });
      }
      if (localStorage.getItem("cdlSetups")) {
        let list = JSON.parse(localStorage.getItem("cdlSetups"));
        this.mylastUrl = list.mylastUrl;
        if (
          list.mylastUrl != "" &&
          document.getElementsByClassName("cdlMenuHome")[0]
        ) {
          document
            .getElementsByClassName("cdlMenuHome")[0]
            .classList.add("router-link-active");
          this.$router.push("/" + list.mylastUrl);
        }
      }
    }, 1500);
  },
  mounted() {
    this.$OrderDraft();
    window.addEventListener("resize", () => {
      this.IsMobile = this.$IsMobile(1);
    });
    if (document.getElementsByClassName("infoAccounts")[0]) {
      let leftAccount = document
        .getElementsByClassName("infoAccounts")[0]
        .getBoundingClientRect().left;
      if (this.IsMobile) {
        leftAccount = 10;
      }
      this.positionInfoAccountLeft = leftAccount;
      this.positionInfoAccountWidth = document
        .getElementsByClassName("infoAccounts")[0]
        .getBoundingClientRect().width;
    }

    if (document.getElementsByClassName("infoUser")[0]) {
      let leftUser = 50;
      if (this.IsMobile) {
        leftUser = 10;
      }
      this.positionInfoUserLeft =
        document.getElementsByClassName("infoUser")[0].getBoundingClientRect()
          .left - parseInt(leftUser);
      this.positionInfoUserWidth =
        document.getElementsByClassName("infoUser")[0].getBoundingClientRect()
          .width + parseInt(50);
    }

    setTimeout(() => {
      if (localStorage.getItem("cdlDisabled")) {
        this.ctrlModalUserDisabled();
      }
    }, 4000);
  },
  beforeUpdate() {
    // this.$IsLoggedIn(1);
  },
  updated() {
    setTimeout(() => {
      if (localStorage.getItem("cdlSetups")) {
        let list = JSON.parse(localStorage.getItem("cdlSetups"));
        this.mylastUrl = list.mylastUrl;
        if (this.mylastUrl == "soluzioni-richieste") {
          if (document.getElementById("mangementMenu")) {
            document.getElementById("mangementMenu").classList.add("active");
          }
          if (document.getElementById("solutionMenu")) {
            document
              .getElementById("solutionMenu")
              .classList.remove("router-link-active");
            document.getElementById("solutionMenu").classList.remove("active");
          }
        }
      }
    }, 2000);
  },
  beforeUnmont() {
    // console.log(`5 the component beforeUnmont.`)
  },
  unount() {
    // console.log(`6 the component unount.`)
  },
};
</script>
