<template>
  <div id="MyError" v-if="viewError">
    <p v-html="message"></p>
  </div>
</template>

<script>

import "@/assets/scss/MyError.scss"

export default {
  name: 'MyError',
  props: ['viewError', 'message']
}
</script>