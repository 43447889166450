<script setup>
import AbDevice from "@/components/CrossConnect/ABDevice.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import SummaryBox from "@/components/CrossConnect/SummaryBox.vue";
import SummaryInfo from "@/components/CrossConnect/SummaryInfo.vue";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import LogoIrveos from "@/components/OrderNetworkCoverage/svg/cross-svg/LogoIrveos.vue";
import CrossCardInfra from "@/components/CrossConnect/CrossCardInfra.vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";
import { useRouter } from "vue-router";
import { UpdateCart } from "@/services/UpdateCart";
import WarningModal from "@/components/CrossConnect/WarningModal.vue";

// Access the global properties directly within setup
const { config } = getCurrentInstance().appContext;
const isMobile = ref(false);
const dataCenterStore = useDatacenterStore();
const xcArticle = computed(() => dataCenterStore.xc_article);
const dcAddress = computed(() => dataCenterStore.dc_address);
const showWaringModal = ref(false);
const router = useRouter();

onMounted(async () => {
  isMobile.value = config.globalProperties.$IsMobile();
  window.scrollTo(0, 0); // Scroll to the top of the page
});
const toggleWarningModal = () => {
  try {
    showWaringModal.value = !showWaringModal.value;
    errorMessage.value = ""; // Reset the error message when the modal is toggled
  } catch (e) {
    console.log(e);
  }
};

const showModal = ref(false);
const errorMessage = ref("");

const editInfo = async (pageNr) => {
  dataCenterStore.pageNumber = await pageNr;
  dataCenterStore.isFromvalidationIntra = true;
  await router.push({ name: "OrderCrossConnectIntra" });
};
const addToCart = async () => {
  errorMessage.value = ""; // Reset error message
  try {
    await dataCenterStore.postCartItem();
    if (dataCenterStore.redundancy) {
      await dataCenterStore.postCartItemR(dataCenterStore.master_id);
    }
    UpdateCart?.();
    // toggleModal(); // Open modal only if no error occurs
    // Clear the data in the store
    await dataCenterStore.clearData();
    await router.push({ name: "CartPageit" });
  } catch (e) {
    console.log(e);
    errorMessage.value = "An error occurred: " + e.message; // Set the error message
  }
};
const updateThisCartItem = async (itemId, redundantId) => {
  errorMessage.value = ""; // Reset error message
  try {
    await dataCenterStore.updateCartItem(itemId);
    if (dataCenterStore.redundancy) {
      if (dataCenterStore.master_id && dataCenterStore.master_id !== 0) {
        await dataCenterStore.updateCartItemR(
          redundantId,
          dataCenterStore.master_id
        );
      } else {
        await dataCenterStore.postCartItemR(itemId);
      }
    }
    UpdateCart?.();
    // toggleModal(); // Open modal only if no error occurs
    // Clear the data in the store
    await dataCenterStore.clearData();
    // Programmatically navigate to the CartPage route
    await router.push({ name: "CartPageit" });
  } catch (e) {
    console.log(e);
    errorMessage.value = "An error occurred: " + e.message; // Set the error message
  }
};

// const toggleModal = () => {
//   try {
//     showModal.value = !showModal.value;
//     errorMessage.value = ""; // Reset the error message when the modal is toggled
//   } catch (e) {
//     console.log(e);
//   }
// };
</script>
<template>
  <div id="OrderCrossCoverage" v-if="!isMobile">
    <div class="mainWrapper">
      <div
        class="contentsForm"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          class="form-container"
          style="max-width: 100%; margin: 0px 0px; width: 90%"
        >
          <MyHeaderPage
            style="margin-top: 3rem"
            :calendar="true"
            :title="$t('crossConnects.validateCrossConnectInfra')"
            :subtitle="$t('checkCoverageSummary.subtitle')"
          />
          <SummaryBox style="margin-top: 3rem">
            <template #itemDescription>
              {{ xcArticle }}
            </template>
            <template #address>
              {{ dataCenterStore.boxData.address }}
            </template>
            <template #nrc
              >{{ dataCenterStore.nrc + dataCenterStore.rnrc }}
            </template>
            <template #mrc
              >{{ dataCenterStore.mrc + dataCenterStore.rmrc }}
            </template>
            <template #min_duration>{{
              dataCenterStore.kitRelatedProduct.starter_subscription_time
            }}</template>
            <template #renewal>{{
              dataCenterStore.kitRelatedProduct.regular_subscription_time
            }}</template>
            <template #release_time>{{
              dataCenterStore.kitRelatedProduct.activation_time
            }}</template>
          </SummaryBox>
          <div class="mdl-grid section">
            <div
              class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            >
              <div class="this-title title-headings">
                {{ $t("crossConnects.tipologia") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.carefullyRead") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col"
              style="align-self: center; margin-top: 0"
            >
              <p
                class="editInfo"
                @click="editInfo(1)"
                v-if="!dataCenterStore.isCartItem"
              >
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>
            <div class="mdl-cell mdl-cell--12-col">
              <CrossCardInfra
                id="C21"
                name="data_center"
                value="C21"
                :label="dataCenterStore.kitRelatedProduct.title"
                subtitle="Data Center C21 Milano"
                address="Via Caldera, 21, 20153, Milano"
                style="margin: 0 0rem"
              >
                <template v-slot:description>
                  <LogoIrveos style="text-align: center" />
                </template>
              </CrossCardInfra>
            </div>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid section">
            <div
              class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
            >
              <div class="this-title title-headings">
                {{ $t("crossConnects.step2Heading") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.carefullyReadBothData") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--8-col mdl-cell--12-col-phone"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(2)">
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div class="mdl-grid">
            <AbDevice
              style="margin-top: 1rem"
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomAName"
              :rack="dataCenterStore.rackAName"
              :device-port="dataCenterStore.aend.device_port"
              :patch-panel="dataCenterStore.aend.patch_panel_to_mmr"
            ></AbDevice>

            <AbDevice
              style="margin-top: 1rem"
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone"
              :class="[
                isMobile ? 'descriptive-card card-mobile-validation' : '',
              ]"
              :title="'B - END'"
              :wing="dataCenterStore.wingName"
              :building="dataCenterStore.buildingName"
              :attachment="dataCenterStore.attachment_b_end?.id"
              :attachmentName="dataCenterStore.attachment_b_end?.name"
              :is-loa-provided="dataCenterStore.kindOfkit === ''"
            ></AbDevice>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid section">
            <div
              class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            >
              <div class="this-title-redundancy title-headings">
                {{ $t("crossConnects.ridondanza") }}
              </div>
              <div class="subtitle"></div>
            </div>
            <div
              class="mdl-cell mdl-cell--8-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(3)" style="margin-top: 10px">
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>

            <div class="mdl-cell mdl-cell--4-col">
              <span style="font-weight: bolder"></span
              ><span style="font-family: MontserratBold">{{
                dataCenterStore.redundancy === false ? "NO" : ""
              }}</span>
            </div>
          </div>
          <div
            class="mdl-grid"
            style="align-content: flex-start"
            v-if="dataCenterStore.redundancy"
          >
            <AbDevice
              style="margin-top: 1rem"
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomANameR"
              :rack="dataCenterStore.rackANameR"
              :device-port="dataCenterStore.raend.device_port"
              :patch-panel="dataCenterStore.raend.patch_panel_to_mmr"
            ></AbDevice>
            <AbDevice
              style="margin-top: 1rem"
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone"
              :class="[
                isMobile ? 'descriptive-card card-mobile-validation' : '',
              ]"
              :title="'B - END'"
              :wing="dataCenterStore.rWingName"
              :building="dataCenterStore.rBuildingName"
              :attachment="dataCenterStore.attachment_rbend?.id"
              :attachmentName="dataCenterStore.attachment_rbend?.name"
              :is-loa-provided="dataCenterStore.kindOfkit === ''"
            ></AbDevice>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid section">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet">
              <div class="this-title title-headings">
                {{ $t("crossConnects.additionalInfo") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.controlWithAttention") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--8-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(4)">
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div style="margin-left: 8px">
            <SummaryInfo />
          </div>

          <div class="separator"></div>
          <div class="button-container">
            <div
              class="cdlButtonGreen"
              @click="
                dataCenterStore.cartItem?.id
                  ? updateThisCartItem(
                      dataCenterStore.cartItem.id,
                      dataCenterStore.redundantData?.id
                    )
                  : addToCart()
              "
            >
              {{
                dataCenterStore.isCartItem
                  ? $t("crossConnects.updateItem")
                  : $t("crossConnects.validate")
              }}
            </div>
            <br />
            <div class="deleteOrder" @click="toggleWarningModal()">
              {{ $t("crossConnects.cancel") }}
            </div>
            <WarningModal
              v-if="showWaringModal"
              :isOpen="showWaringModal"
              @update:isOpen="toggleWarningModal"
            />
          </div>
          <ConfirmationModal
            v-if="showModal"
            :isOpen="showModal"
            @update:isOpen="toggleModal"
          />
          <div style="margin: 20px 0px; text-align: center">
            <span class="error-message" v-if="errorMessage">{{
              errorMessage
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="OrderCrossCoverage" v-if="isMobile">
    <div class="mainWrapper">
      <div
        class="contentsForm"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          class="form-container-mobile"
          style="max-width: 100%; margin: 0px 0px; width: 90%"
        >
          <MyHeaderPage
            style="margin-top: 3rem"
            :calendar="true"
            :title="$t('crossConnects.validateCrossConnectInfra')"
            :subtitle="$t('checkCoverageSummary.subtitle')"
          />
          <SummaryBox style="margin-top: 3rem" :is-mobile="isMobile">
            <template #itemDescription>
              {{ xcArticle }}
            </template>
            <template #address>
              {{ dcAddress }}
            </template>
            <template #nrc
              >{{ dataCenterStore.nrc + dataCenterStore.rnrc }}
            </template>
            <template #mrc
              >{{ dataCenterStore.mrc + dataCenterStore.rmrc }}
            </template>
            <template #min_duration>{{
              dataCenterStore.kitRelatedProduct.starter_subscription_time
            }}</template>
            <template #renewal>{{
              dataCenterStore.kitRelatedProduct.regular_subscription_time
            }}</template>
            <template #release_time>{{
              dataCenterStore.kitRelatedProduct.activation_time
            }}</template>
          </SummaryBox>
          <div class="mdl-grid section" style="align-items: baseline">
            <div
              class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            >
              <div class="this-title title-headings">
                {{ $t("crossConnects.tipologia") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.carefullyRead") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(1)">
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div class="mdl-grid section marcomarco">
            <CrossCardInfra
              id="C21"
              name="data_center"
              value="C21"
              :label="dataCenterStore.kitRelatedProduct.title"
              subtitle="Data Center C21 Milano"
              address="Via Caldera, 21, 20153, Milano"
              style="margin: 0 0rem"
            >
              <template v-slot:description>
                <LogoIrveos style="text-align: center" />
              </template>
            </CrossCardInfra>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid" style="margin-left: -15px">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet">
              <div class="this-title title-headings">A - End / B - End</div>
              <div class="subtitle">
                {{ $t("crossConnects.carefullyReadBothData") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(2)">
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div class="mdl-grid" style="align-content: flex-start">
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomAName"
              :rack="dataCenterStore.rackAName"
              :device-port="dataCenterStore.aend.device_port"
              :patch-panel="dataCenterStore.aend.patch_panel_to_mmr"
            ></AbDevice>
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone"
              :class="[
                isMobile ? 'descriptive-card card-mobile-validation' : '',
              ]"
              :title="'B - END'"
              :wing="dataCenterStore.wingName"
              :building="dataCenterStore.buildingName"
              :attachment="dataCenterStore.attachment_b_end?.id"
              :attachmentName="dataCenterStore.attachment_b_end?.name"
              :is-loa-provided="dataCenterStore.kindOfkit === ''"
            ></AbDevice>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid" style="margin-left: -15px">
            <div class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet">
              <div class="this-title-redundancy title-headings">
                {{ $t("crossConnects.ridondanza") }}
              </div>
              <div class="subtitle"></div>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(3)" style="margin-top: 10px">
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <br />
          <div style="font-family: MontserratMedium; margin-bottom: -2rem">
            <span style="font-weight: bolder"></span
            ><span style="font-family: MontserratBold">{{
              dataCenterStore.redundancy === false ? "NO" : ""
            }}</span>
          </div>
          <div
            class="mdl-grid"
            style="align-content: flex-start"
            v-if="dataCenterStore.redundancy"
          >
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomANameR"
              :rack="dataCenterStore.rackANameR"
              :device-port="dataCenterStore.raend.device_port"
              :patch-panel="dataCenterStore.raend.patch_panel_to_mmr"
            ></AbDevice>
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone"
              :class="[
                isMobile ? 'descriptive-card card-mobile-validation' : '',
              ]"
              :title="'B - END'"
              :wing="dataCenterStore.rWingName"
              :building="dataCenterStore.rBuildingName"
              :attachment="dataCenterStore.attachment_rbend?.id"
              :attachmentName="dataCenterStore.attachment_rbend?.name"
              :is-loa-provided="dataCenterStore.kindOfkit === ''"
            ></AbDevice>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid" style="margin-left: -15px">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet">
              <div class="this-title title-headings-mobile">
                {{ $t("crossConnects.additionalInfo") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.controlWithAttention") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--1-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(4)">
                <span id="go-step-1" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <br />

          <SummaryInfo />
          <div class="separator"></div>
          <div class="button-container">
            <div class="cdlButtonGreen" @click="addToCart">
              {{
                dataCenterStore.isCartItem
                  ? $t("crossConnects.updateItem")
                  : $t("crossConnects.validate")
              }}
            </div>
            <br />
            <div
              style="align-content: center; color: #002e5f; margin-bottom: 3rem"
              @click="toggleWarningModal()"
            >
              {{ $t("crossConnects.cancel") }}
            </div>
            <WarningModal
              v-if="showWaringModal"
              :isOpen="showWaringModal"
              @update:isOpen="toggleWarningModal"
            />
          </div>
          <ConfirmationModal
            v-if="showModal"
            :isOpen="showModal"
            @update:isOpen="toggleModal"
          />
          <div style="margin: 20px 0px; text-align: center">
            <span class="error-message" v-if="errorMessage">{{
              errorMessage
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";

.section {
  margin-top: 4rem;
  align-items: center;
}

.editInfo {
  background-color: $grayFive;
  color: $blu;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 30px;

  &:hover {
    background-color: white;
  }

  &.active {
    .material-icons {
      color: $green;
    }
  }
}

.clause-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; // Adjust as needed to provide spacing above the button
}

.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.clause-container input[type="checkbox"] {
  margin-right: 10px;
}

.clause-container label {
  font-size: 12px; // Adjust based on your design preference
  color: #002e5f; // This is a muted gray; adjust as desired
  font-family: OpenSansSemiBold;
}

.card-validation-grid {
  display: grid;
  grid-template-columns: auto auto; /* Adjust the column sizes as needed */
  row-gap: 20px; /* Space between rows and columns */
  justify-items: flex-start;

  .grid-title {
    grid-column: 1 / -1; /* Span across all columns */
    text-align: center;
    font-family: MontserratMedium;

    /* Additional styles for the title */
  }

  .grid-label {
    /* Styles for labels */
    text-align: right;
    color: #002e5f;
    font-family: MontserratMedium;
    font-size: 16px;
    font-weight: normal;
  }

  .grid-value {
    /* Styles for values */
    text-align: left;
  }
}

.info-header {
  padding: 10px 0;
  font-weight: bold;
  font-family: Monte;
}

.info-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.info-item {
  display: flex;
  align-items: baseline;
  margin-right: 3.75rem;
}

.label {
  margin-right: 10px;
  font-weight: bold;
}

.value {
  font-family: OpenSansBold;
  font-size: 18px;
}

.descriptive-text-container {
  display: flex;
  max-width: 24rem;
}

.descriptive-card {
  width: 32rem !important;
  text-align: left !important;
  margin-left: 0 !important;
}

.descriptive-text {
  float: right;
  flex-grow: 2;
}

.contentsForm {
  .title-headings {
    font-family: "MontserratMedium" !important;
    font-size: 36px !important;
  }
  .title-headings-mobile {
    font-family: "MontserratMedium" !important;
    font-size: 28px !important;
  }
  margin-bottom: 3rem;
  margin-top: 0rem !important;
}

.sectionMargin {
  margin-bottom: 0;
}

.flex-row.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sectionTitle {
    display: flex;
    align-items: center; // to ensure the icon and text are vertically aligned
  }

  .value {
    margin-left: 1rem; // this pushes the value to the rightmost side of its container
    align-self: flex-end; // this ensures the value aligns to the end of the separator line
  }

  .card-value {
    float: right;
  }

  .align-right {
    float: right;
  }

  div {
    overflow: auto;
  }
}

.label {
  color: #002e5f;
  font-family: MontserratMedium;
  font-size: 18px;
  font-weight: normal;
}
</style>
