import CompaniesList from "@/pages/CompaniesList.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesPartners() {
  return [
    {
      path: "/gestione-clienti-finali",
      name: "Companiesit",
      component: CompaniesList,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "companies",
            url: "",
          },
        ],
        indexRoute: "gestione-clienti-finali",
        permissions: ["gestione_clienti_finali"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/end-customer-management",
      name: "Companieseng",
      component: CompaniesList,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "companies",
            url: "",
          },
        ],
        indexRoute: "end-customer-management",
        permissions: ["gestione_clienti_finali"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}