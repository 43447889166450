<template>
  <div class="contentPrimary" :class="theme">
    <h3 @click="openInfoMobile()" v-if="IsMobile">
      {{ $t('tickets.details') }}
      <span class="material-icons" v-if="!infoMobile">expand_more</span>
      <span class="material-icons" v-if="infoMobile">expand_less</span>
    </h3>
    <div class="mdl-grid alignItems" v-if="(IsMobile && infoMobile) || !IsMobile">
      <RemoteHandsInfo v-if="type === 'remotehands'" :data="ticket?.cf_list" />
      <ShippingInfo v-else :shippingData="ticket.cf_list" />
      <div class="mdl-cell mdl-cell--2-col">
        <img src="@/assets/img/icon_reference.png" alt="reference" width="45" />
      </div>
      <div class="mdl-cell mdl-cell--10-col">
        <div>
          <span class="label"
            >{{ $t("refTicket.nameRef") }}
            <span class="text">{{
              ticket.cf_list ? ticket.cf_list.cf_nome_referente : "-"
            }}</span></span
          >
        </div>
        <div>
          <span class="label"
            >{{ $t("refTicket.phoneRef") }}
            <span class="text">{{
              ticket.cf_list ? ticket.cf_list.cf_telefono_referente : "-"
            }}</span></span
          >
        </div>
        <div>
          <span class="label"
            >{{ $t("refTicket.emailRef") }}
            <span class="text">{{
              ticket.cf_list ? ticket.cf_list.cf_email_referente : "-"
            }}</span></span
          >
        </div>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <hr />
      </div>
      <slot name="info"></slot>
    </div>
  </div>
</template>

<script>
import ShippingInfo from "@/components/Tickets/Detail/ShippingInfo.vue";
import RemoteHandsInfo from "@/components/Tickets/Detail/RhInfo.vue";
export default {
  name: "SupportRequestDetailBox",
  components: {
    ShippingInfo,
    RemoteHandsInfo,
  },
  props: {
    theme: String,
    type: String,
    ticket: Object,
  },
  data() {
    return {
      IsMobile: this.$IsMobile(),
      infoMobile: false
    }
  },
  methods: {
    openInfoMobile() {
      this.infoMobile = !this.infoMobile;
    }
  }
};
</script>
