<template>
  <div>
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.virtualization.title')"
      :subtitle="$t('reports.virtualization.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead v-if="!this.$IsMobile()">
                <tr>
                  <th>{{ $t("reports.virtualization.col.colOne") }}</th>
                  <th>{{ $t("reports.virtualization.col.colTwo") }}</th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.virtualization.col.colThree") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.virtualization.col.colFour") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.virtualization.col.colFive") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.virtualization.col.colSix") }}
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="!this.$IsMobile() && this.viewLoader && items.length > 0"
              >
                <tr
                  v-for="(item, keyItem) in this.items"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.volumetotal }}</td>
                  <td>{{ item.cputotal }}</td>
                  <td>{{ item.memorytotal }}</td>
                  <td>{{ item.primarystoragetotal }}</td>
                  <td>{{ item.secondarystoragetotal }}</td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  this.$IsMobile() && this.viewLoader && items.length > 0
                "
              >
                <MobileCard
                  v-for="(item, keyItem) in this.items"
                  :key="keyItem"
                  :titles="['name', 'volumetotal']"
                  :item="item"
                  translation-path="reports.virtualization.list"
                ></MobileCard>
              </tbody>
              <tbody v-else-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="6">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import "@/assets/scss/TicketsList.scss";
import MobileCard from "@/components/Cards/MobileCard.vue";

export default {
  name: "CVirtualization",
  components: {
    MobileCard,
    MyBanner,
    MyHeaderPage,
    MyLoading,
  },
  data() {
    return {
      viewLoader: false,
      items: [],
    };
  },
  methods: {},
  mounted() {
    let he = {
      Accept: "application/json",
      Authorization: "Bearer " + this.$keycloak.idToken,
    };
    const self = this;
    this.axios
      .get(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "front/v1/report-virtualization",
        { headers: he }
      )
      .then((rls) => {
        this.items = rls.data;
        this.viewLoader = true;
      })
      .catch(function (err) {
        self.viewLoader = true;
        console.log(err);
      });
  },
};
</script>
