import NetworkCoverage from "@/pages/NetworkCoverage/NetworkCoverage.vue";
import OrderNetworkCoverage from "@/pages/NetworkCoverage/OrderNetworkCoverage.vue";
import OrderNetworkCoverageSummary from "@/pages/NetworkCoverage/OrderNetworkCoverageSummary.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesNetworkCoverage() {
  return [
    {
      path: "/verifica-copertura",
      name: "NetworkCoverageit",
      component: NetworkCoverage,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "checkCoverage",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["verifica_copertura"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/coverage-check",
      name: "NetworkCoverageen",
      component: NetworkCoverage,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "checkCoverage",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["verifica_copertura"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/crea-ordine-linea",
      name: "OrderNetworkCoverageit",
      component: OrderNetworkCoverage,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "checkCoverage",
            url: "verifica-copertura",
          },
          {
            name: "newOrderLine",
            url: "",
          },
        ],
        indexRoute: "verifica-copertura",
        permissions: ["verifica_copertura"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/create-line-order",
      name: "OrderNetworkCoverageen",
      component: OrderNetworkCoverage,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "checkCoverage",
            url: "network-coverage",
          },
          {
            name: "newOrderLine",
            url: "",
          },
        ],
        indexRoute: "network-coverage",
        permissions: ["verifica_copertura"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/riepilogo-ordine-linea",
      name: "OrderNetworkCoverageSummaryit",
      component: OrderNetworkCoverageSummary,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "checkCoverage",
            url: "/verifica-copertura",
          },
          {
            name: "summaryOrderLine",
            url: "",
          },
        ],
        indexRoute: "verifica-copertura",
        permissions: ["verifica_copertura"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/line-order-summary",
      name: "OrderNetworkCoverageSummaryen",
      component: OrderNetworkCoverageSummary,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "networkConnectivity",
            url: "",
          },
          {
            name: "checkCoverage",
            url: "/verifica-copertura",
          },
          {
            name: "summaryOrderLine",
            url: "",
          },
        ],
        indexRoute: "network-coverage",
        permissions: ["verifica_copertura"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}