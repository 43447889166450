<template>
    <svg id="Step3" data-name="icona contatto in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35"
        viewBox="0 0 35 35">
        <circle id="Ellisse_153" data-name="Ellisse 153" class="setCircle" cx="17.5" cy="17.5" r="17.5" fill="#e4effc" />
        <g id="face_black_24dp" transform="translate(5 6)">
            <g id="Raggruppa_2981" data-name="Raggruppa 2981" transform="translate(1)">
                <path id="Tracciato_1532" data-name="Tracciato 1532" class="setColor"
                    d="M9.838,12.45A1.188,1.188,0,1,1,8.65,11.262,1.188,1.188,0,0,1,9.838,12.45Zm4.512-1.187a1.188,1.188,0,1,0,1.188,1.188A1.188,1.188,0,0,0,14.35,11.262ZM21,11.5A9.5,9.5,0,1,1,11.5,2,9.5,9.5,0,0,1,21,11.5Zm-1.9,0a7.486,7.486,0,0,0-.313-2.128,9.79,9.79,0,0,1-2.062.228A9.5,9.5,0,0,1,9.353,6.094,9.515,9.515,0,0,1,3.9,11.367a.621.621,0,0,1,0,.133,7.6,7.6,0,1,0,15.2,0Z"
                    fill="#134c7a" />
            </g>
        </g>
    </svg>
</template>

<script>

export default {
    name: 'MyStep3'
}
</script>