<template>
  <div>

    <span class="required draftSaved" v-if="!this.wizardData.isCompleted">
      <span class="material-icons">done</span>
      <span>{{ $t('checkCoverage.draftSaved') }}</span>
    </span>

    <h2>{{ $t('checkCoverage.titleAdditionalInformation') }}</h2>

    <!-- CONSEGNA -->
    <h3>{{ $t('checkCoverage.delivery') }}</h3>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input" style="margin-top: 20px;">
          <input type="text" name="building_floor" v-model="building_floor" @change="submit" @input="label($event);" maxlength="255" />
          <label>{{ $t('checkCoverage.buildingFloor') }}*</label>
        </div>
        <p class="error" style="margin-left: 0;" v-if="this.v$.building_floor.$invalid && this.v$.building_floor.$dirty && !this.v$.building_floor.integer.$invalid">{{ $t('checkCoverage.buildingFloor') }} {{ $t('checkCoverage.isRequired') }}</p>
        <p class="error" style="margin-left: 0;" v-else-if="this.v$.building_floor.$invalid && this.v$.building_floor.$dirty && this.v$.building_floor.integer.$invalid">{{ $t('checkCoverage.buildingFloor') }} {{ $t('checkCoverage.isNotNumeric') }}</p>
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <span class="labelOne marginLeft8">{{ $t('checkCoverage.timeSlot') }}</span>
        <select name="time_slot" v-model="time_slot" @change="submit" @input="label($event);">
          <option :value="$t('checkCoverage.timeSlotMorning')">{{
            $t('checkCoverage.timeSlotMorning') }}</option>
          <option :value="$t('checkCoverage.timeSlotAfternoon')">{{
            $t('checkCoverage.timeSlotAfternoon') }}</option>
          <option :value="$t('checkCoverage.timeSlotAll')">{{
            $t('checkCoverage.timeSlotAll') }}</option>
        </select>
      </div>
      <div class="mdl-cell mdl-cell--12-col contentMiddle">
        <div class="cdl-textarea textAreaWidth">
          <textarea maxlength="255" name="note" id="textarea" v-model="notes" @input="label($event)"
            @change="submit"></textarea>
          <label>{{ $t('checkCoverage.noteInfo') }}</label>
        </div>
      </div>
    </div>
    <!-- FINE CONSEGNA -->

    <hr />

    <!-- RADIUS -->
    <h3>Account radius</h3>
    <p class="subTitle" v-html="$t('checkCoverage.subTitleRadiusAccount')" v-if="false"></p>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input type="text" name="radiusUsername" v-model="radius.username" @change="submit" @input="label($event)" maxlength="255" />
          <label>Username</label>
          <p class="error" v-if="(this.v$.radius.username.$invalid && this.v$.radius.username.$dirty)">
            {{ $t('checkCoverage.radiusAccountUsernameRequired') }}
          </p>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input type="text" name="radiusPassword" v-model="radius.password" @change="submit" @input="label($event)" maxlength="255" />
          <label>Password</label>
          <p class="error" v-if="(this.v$.radius.password.$invalid && this.v$.radius.password.$dirty)">
            {{ $t('checkCoverage.radiusAccountPasswordRequired') }}
          </p>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--12-col contentMiddle">
        <div class="cdl-textarea">
          <textarea maxlength="255" name="notes" id="textarea" v-model="radius.notes" @input="label($event)"
            @change="submit"></textarea>
          <label>{{ $t('checkCoverage.noteRadius') }}</label>
        </div>
      </div>
    </div>
    <!-- FINE RADIUS-->

    <hr />

    <!-- MIGRAZIONI -->
    <h3>{{ $t('checkCoverage.titleMigration') }}</h3>
    <p class="subTitle" v-if="false">{{ $t('checkCoverage.subTitleMigration') }}</p>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <div class="cdl-input width95">
          <input type="text" name="code" v-model="olo2olo_migration.code" @change="submit" @input="label($event);"
            maxlength="18" />
          <label>{{ $t('checkCoverage.migrationCode') }}</label>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--12-col contentMiddle">
        <div class="cdl-textarea">
          <textarea maxlength="255" name="note" id="textarea" v-model="olo2olo_migration.notes" @input="label($event)"
            @change="submit"></textarea>
          <label>{{ $t('checkCoverage.migrationNote') }}</label>
        </div>
      </div>
    </div>
    <!-- FINE MIGRAZIONI -->

    <hr />

    <!-- NOTE AGGIUNTIVE -->
    <h3>{{ $t('checkCoverage.technicalDetails') }}</h3>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col contentMiddle">
        <div class="cdl-textarea">
          <textarea maxlength="255" name="note" id="textarea" v-model="note" @input="label($event)"
            @change="submitNote"></textarea>
        </div>
      </div>
    </div>
    <!-- FINE NOTE AGGIUNTIVE -->

    <div class="cdlContentButtonDouble">
      <div class="cdlButtonSingle width170" @click="goBack()">
        {{ $t('checkCoverage.stepsButtonTwo') }}
      </div>
      <div id="goNext" class="cdlButtonSingle width170" @click="goNext(false)">
        {{ $t('checkCoverage.stepsButtonOne') }}
      </div>
      <div id="buttonCompleteRelatedServices" class="cdlButtonSingle" @click="goNext(true)"
        v-if="this.wizardData.isCompleted">
        {{ $t('checkCoverage.goEnd') }}
      </div>
    </div>
    <p class="deleteOrder" v-if="!this.wizardData.isCompleted" @click="cancelOrder()">{{
      $t('checkCoverage.stepsButtonThree') }}</p>

  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, integer, requiredIf } from "@vuelidate/validators";

export default {
  name: 'MyAdditionalInformation',
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      building_floor: { required, integer },
      radius: {
        username: { requiredIfFoo: requiredIf(this.radius.password != null && this.radius.password != '') },
        password: { requiredIfFoo: requiredIf(this.radius.username != null && this.radius.username != '') }
      }
    };
  },
  props: {
    wizardData: {
      type: Object,
      required: true
    },
    stepData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      building_floor: this.wizardData.network_access_details.delivery_details.building_floor,
      time_slot: this.wizardData.network_access_details.delivery_details.time_slot,
      notes: this.wizardData.network_access_details.delivery_details.notes,
      radius: {
        username: this.wizardData.network_access_details.radius_account.username,
        password: this.wizardData.network_access_details.radius_account.password,
        notes: this.wizardData.network_access_details.radius_account.notes
      },
      olo2olo_migration: {
        code: this.wizardData.network_access_details.olo2olo_migration.code,
        notes: this.wizardData.network_access_details.olo2olo_migration.notes
      },
      note: this.wizardData.notes,
      myProps: this.wizardData
    }
  },
  methods: {
    submit() {
      this.myProps.network_access_details.delivery_details.building_floor = this.building_floor;
      this.myProps.network_access_details.delivery_details.time_slot = this.time_slot;
      this.myProps.network_access_details.delivery_details.notes = this.notes;
      this.myProps.network_access_details.radius_account = this.radius;
      this.myProps.network_access_details.olo2olo_migration = this.olo2olo_migration;

      this.$emit('update', {
        network_access_details: this.myProps.network_access_details
      });
    },
    goBack() {
      this.$emit('goBack');
    },
    async goNext(goSummary = null) {
      const result = await this.v$.$validate();
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (result) {
        this.v$.$reset();
        this.$emit('goNext', { goSummary });
      }
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    cancelOrder() {
      this.$emit('close');
    },
    getStep(type) {
      let step = this.stepData;
      if (type == 'before') {
        return step - 1;
      }
      return step + 1;
    },
    submitNote() {
      this.$emit("update", {
        notes: this.note,
      });
    },
  },
  activated() {
    if (localStorage.getItem("cdlOrderNetworkCoverage")) {
      let allInputs = document.getElementsByTagName("input");
      let allTextarea = document.getElementsByTagName("textarea");
      setTimeout(() => {
        for (let i = 0; i < allInputs.length; i++) {
          if (allInputs[i].value != "") {
            allInputs[i].classList.add('not-empty');
          }
        }
        for (let k = 0; k < allTextarea.length; k++) {
          if (allTextarea[k].value != "") {
            allTextarea[k].classList.add('not-empty');
          }
        }
      }, 500);

      let recoveryValue = JSON.parse(
        localStorage.getItem("cdlOrderNetworkCoverage")
      );
      
      if (recoveryValue.form.notes != null) {
        this.note = recoveryValue.form.notes;
      }
    }
  }
}
</script>