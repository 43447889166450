<template>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" id="Step0">
  <g id="location_on_black_24dp" transform="translate(23.086 23.086)">
    <rect id="Rettangolo_2231" data-name="Rettangolo 2231" width="30" height="30" rx="15" transform="translate(-23.086 -23.086)" fill="#e4effc" class="setCircle" />
    <g id="Raggruppa_1613" data-name="Raggruppa 1613" transform="translate(-14.461 -14.461)">
      <rect id="Rettangolo_2398" data-name="Rettangolo 2398" width="12.75" height="12.75" fill="none"/>
    </g>
    <g id="Raggruppa_1614" data-name="Raggruppa 1614" transform="translate(-12.314 -13.372)">
      <path class="setColor" id="Tracciato_1338" data-name="Tracciato 1338" d="M8.229,2A4.2,4.2,0,0,0,4,6.334Q4,8.855,7.88,12.27a.538.538,0,0,0,.7,0q3.869-3.417,3.874-5.936A4.2,4.2,0,0,0,8.229,2Zm0,5.286A1.057,1.057,0,1,1,9.286,6.229,1.06,1.06,0,0,1,8.229,7.286Z" transform="translate(-4 -2)" fill="#6585a7"/>
    </g>
  </g>
</svg>

</template>

<script>

export default {
  name: 'MyStep0'
}
</script>