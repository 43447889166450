<template>
  <div class="selectable-fields">
    <div class="field">
      <div class="field-title">LOA*:</div>
      <div class="description-text">
        {{ $t("crossConnects.OpticCdlan") }}
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style>
/* Add your styles here */
</style>
