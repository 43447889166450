<template>
  <div class="contentPrimary">
    <div style="display: flex; align-items: baseline">
      <div class="img-container">
        <img src="@/assets/img/icon_info.png" alt="icon" width="45" />
      </div>
      <div class="text-container" style="flex-grow: 1; padding-left: 15px">
        <p class="text">{{ $t(`help.${helpRequestType}.title`) }}</p>
      </div>
    </div>

    <hr />
    <div class="text-container">
      <p class="label">{{ $t(`help.generic.solutionDescription`) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    helpRequestType: String,
  },
};
</script>
