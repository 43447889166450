<template>
  <div id="RemoteHands">
    <div class="thred">
      <MyHeaderPage
        :title="'Ticket ' + ticket.ticketNumber"
        :subtitle="$t('remoteHands.subTitleDetails')"
      />
      <TicketsBackLink />
      <div class="contentFormTwoCol">
        <div class="contentPrimary boxAccessDcDetails" :class="{ contentPrimaryMobile: IsMobile }">
          <h3 @click="openInfoMobile()" v-if="IsMobile">
            {{ $t('tickets.details') }}
            <span class="material-icons" v-if="!infoMobile">expand_more</span>
            <span class="material-icons" v-if="infoMobile">expand_less</span>
          </h3>

          <div class="mdl-grid alignItems" v-if="(IsMobile && infoMobile) || !IsMobile">
            <div class="mdl-cell mdl-cell--2-col">
              <div
                class="steps"
                style="background-color: transparent; box-shadow: unset"
              >
                <div class="step active">
                  <component :is="'MyStep2'"></component>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--10-col">
              <div>
                <span class="label">{{ $t("accessDc.titleStep2") }}</span
                >&nbsp;
                <span class="text" style="word-break: break-word">
                  {{ ticket.cf_list ? getDate(ticket.cf_list.cf_from) : "-" }}
                  {{
                    ticket.cf_list
                      ? $t("accessDc.accessWhenTime", {
                          from: getTime(ticket.cf_list.cf_from),
                          to: getTime(ticket.cf_list.cf_to),
                        }).toLowerCase()
                      : "-"
                  }}
                </span>
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12-col">
              <hr />
            </div>

            <div class="mdl-cell mdl-cell--2-col" v-if="getRoomsLength() > 0">
              <div
                class="steps"
                style="background-color: transparent; box-shadow: unset"
              >
                <div class="step active">
                  <component :is="'MyStep3'"></component>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--10-col" v-if="getRoomsLength() > 0">
              <div>
                <span class="label">{{
                  $t("remoteHands.detailLabelLocation")
                }}</span
                >&nbsp;
                <span class="text">{{
                  ticket.cf_list ? ticket.cf_list.cf_datacenter : "-"
                }}</span>
              </div>
              <br />
              <div v-for="(room, key) in getRooms()" :key="key">
                <span class="label">{{ $t("remoteHands.labelRoom") }}</span
                >&nbsp; <span class="text">{{ room.room }}</span
                ><br />
                <span class="label">{{ $t("remoteHands.labelRack") }}</span
                >&nbsp; <span class="text">{{ room.racks }}</span
                ><br />
              </div>
              <br />
              <span class="label">{{ $t("accessDc.apparatusLabel") }}</span
              >&nbsp;
              <span class="text">{{
                ticket.cf_list ? ticket.cf_list.apparati : "no"
              }}</span
              ><br />
              <span class="label" v-if="viewWarehouse">{{ $t("accessDc.warehouseLabel") }}</span
              >&nbsp;
              <span class="text" v-if="viewWarehouse">{{
                ticket.cf_list ? ticket.cf_list.warehouse : "no"
              }}</span
              ><br />
              <span v-if="ticket.cf_list && ticket.cf_list.keys.traka_rooms">
                <span class="label">{{ $t("accessDc.keysLabel") }}</span
                >&nbsp;
                <span class="text">{{
                  ticket.cf_list ? ticket.cf_list.keys.traka_rooms : ""
                }}</span
                ><br />
                <span class="label">{{ $t("accessDc.keysLabelMessage") }}</span
                >&nbsp;
                <span class="text">{{
                  ticket.cf_list ? ticket.cf_list.keys.traka_message : ""
                }}</span>
              </span>

              <span
                v-if="ticket.cf_list && ticket.cf_list.manutenzione == 'si'"
              >
                <span class="label">Manutenzione</span>&nbsp;
                <span class="text">{{
                  ticket.cf_list ? ticket.cf_list.manutenzione : "no"
                }}</span>
              </span>
            </div>

            <div class="mdl-cell mdl-cell--12-col" v-if="getRoomsLength() > 0">
              <hr />
            </div>

            <div class="mdl-cell mdl-cell--2-col" v-if="getCoworking()">
              <div
                class="steps"
                style="background-color: transparent; box-shadow: unset"
              >
                <div class="step active">
                  <component :is="'MyStep7'"></component>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--10-col" v-if="getCoworking()">
              <div>
                <span class="label">{{ $t("accessDc.nDeskLabel") }}&nbsp;</span>
                <span class="text"
                  >{{ ticket.cf_list ? ticket.cf_list.coworking.desks : 0 }}
                  {{
                    ticket.cf_list
                      ? $t("accessDc.accessWhenTime", {
                          from: getTime(
                            "1970-01-01T" + ticket.cf_list.coworking.from
                          ),
                          to: getTime(
                            "1970-01-01T" + ticket.cf_list.coworking.to
                          ),
                        }).toLowerCase()
                      : "-"
                  }}
                </span>
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12-col" v-if="getCoworking()">
              <hr />
            </div>

            <div class="mdl-cell mdl-cell--2-col" v-if="getUsersLength() > 0">
              <div
                class="steps"
                style="background-color: transparent; box-shadow: unset"
              >
                <div class="step active">
                  <component :is="'MyStep4'"></component>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--10-col" v-if="getUsersLength() > 0">
              <div>
                <span class="label">{{
                  $t("accessDc.titleWhoShouldEnter")
                }}</span>
              </div>
              <div v-for="(user, key) in getUsers()" :key="key">
                <span class="text">{{ user.name }}</span
                ><br />
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12-col" v-if="getUsersLength() > 0">
              <hr />
            </div>

            <div class="mdl-cell mdl-cell--2-col" v-if="getCarsLength() > 0">
              <div
                class="steps"
                style="background-color: transparent; box-shadow: unset"
              >
                <div class="step active">
                  <component :is="'MyStep8'"></component>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--10-col" v-if="getCarsLength() > 0">
              <div>
                <span class="label">{{ $t("accessDc.car") }}</span>
              </div>
              <div v-for="(car, key) in getCars()" :key="key">
                <span class="text">
                  {{ car.name }} <span v-if="car.plate">- {{ car.plate }}</span>&nbsp;
                  <span v-if="car.park != null" class="park"
                    >(<a
                      :href="
                        'https://www.google.it/maps/place/' +
                        car.park.latitude +
                        'N+' +
                        car.park.longitude +
                        'E'
                      "
                      target="_blank"
                      >{{ car.park.name }}
                      <span class="material-icons" style="font-size: 1.3rem"
                        >open_in_new</span
                      ></a
                    >)</span
                  > </span
                ><br />
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12-col" v-if="getCarsLength() > 0">
              <hr />
            </div>

            <div class="mdl-cell mdl-cell--2-col">
              <img
                src="@/assets/img/icon_reference.png"
                alt="reference"
                width="45"
              />
            </div>
            <div class="mdl-cell mdl-cell--10-col">
              <div>
                <span class="label">{{ $t("refTicket.nameRef") }}</span
                >&nbsp;
                <span class="text">{{
                  ticket.cf_list ? ticket.cf_list.cf_nome_referente : "-"
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("refTicket.phoneRef") }}</span
                >&nbsp;
                <span class="text">{{
                  ticket.cf_list ? ticket.cf_list.cf_telefono_referente : "-"
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("refTicket.emailRef") }}</span
                >&nbsp;
                <span class="text">{{
                  ticket.cf_list ? ticket.cf_list.cf_email_referente : "-"
                }}</span>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--12-col">
              <hr />
            </div>
          </div>
        </div>
        <div class="contentSecondary noPadding minHeightThreads">
          <Threads
            :headers="headerThread"
            :ticket="ticket"
            :threads="threads"
            :attachs="attachs"
            @update="getThreads"
            :ticketType="'Ingressi'"
          />
        </div>
      </div>
    </div>

    <br /><br />
    <MyLoading :viewLoader="viewLoader" type="full" />
  </div>
</template>

<style setup>
.park a {
  color: unset;
}
</style>

<script>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import Threads from "@/components/Threads.vue";
import "@/assets/scss/RemoteHands.scss";
import MyLoading from "@/components/MyLoading.vue";
import moment from "moment";
import TicketsBackLink from "@/components/Tickets/BackLink.vue";

import MyStep2 from "@/components/AccessDc/svg/Step2.vue";
import MyStep3 from "@/components/AccessDc/svg/Step3.vue";
import MyStep4 from "@/components/AccessDc/svg/Step4.vue";
import MyStep7 from "@/components/AccessDc/svg/Step7.vue"; // coworking
import MyStep8 from "@/components/AccessDc/svg/Step8.vue";

export default {
  name: "AccessRequestDetails",
  components: {
    MyHeaderPage,
    MyLoading,
    TicketsBackLink,
    Threads,
    MyStep2,
    MyStep3,
    MyStep4,
    MyStep7,
    MyStep8,
  },
  data() {
    return {
      ticket: {},
      threads: {},
      attachs: {},
      headerThread: {
        // ticketNumber: '###',
        createdTime: "###",
        modifiedTime: "###",
      },
      viewLoader: false,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      IsMobile: this.$IsMobile(),
      infoMobile: false,
      viewWarehouse: true
    };
  },
  methods: {
    openInfoMobile() {
      this.infoMobile = !this.infoMobile;
    },
    getRooms() {
      if (this.ticket.cf_list) {
        return this.ticket.cf_list.rooms;
      }
      return [];
    },
    getRoomsLength() {
      if (this.ticket.cf_list && this.ticket.cf_list.rooms) {
        return Object.keys(this.ticket.cf_list.rooms).length;
      }
      return 0;
    },
    getUsers() {
      if (this.ticket.cf_list) {
        return this.ticket.cf_list.people;
      }
      return [];
    },
    getUsersLength() {
      if (this.ticket.cf_list && this.ticket.cf_list.people) {
        return Object.keys(this.ticket.cf_list.people).length;
      }
      return 0;
    },
    getCars() {
      if (this.ticket.cf_list) {
        return this.ticket.cf_list.cars;
      }
      return [];
    },
    getCarsLength() {
      if (this.ticket.cf_list && this.ticket.cf_list.cars) {
        return Object.keys(this.ticket.cf_list.cars).length;
      }
      return 0;
    },
    getCoworking() {
      if (this.ticket.cf_list && this.ticket.cf_list.coworking) {
        return true;
      }
      return false;
    },
    getDate(myTime) {
      return moment(myTime).format("DD/MM/YYYY", this.$i18n.locale, true);
    },
    getTime(myTime) {
      return moment(myTime).format("HH:mm", this.$i18n.locale, true);
    },
    getThreads() {
      this.viewLoader = false;
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/ticket-threads-by-ticket-number/?ticket_number=" +
            this.$route.params.id,
          { headers: this.he }
        )
        .then((response) => {
          this.threads = response.data;
          if (response.data.threadAttachments) {
            this.attachs = response.data.threadAttachments;
          }
          this.viewLoader = true;
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.ticket.ticket_id = this.$route.params.id;

    let getTicket = new Promise((resolve) => {
      let timestamp = new Date().valueOf();
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/ticket/?ticket_id=" +
            this.$route.params.id +
            "&ticket_type=Ingresso" +
            "&timestamp=" +
            timestamp,
          { headers: this.he }
        )
        .then((response) => {
          this.ticket = response.data.res;
          for (const [key, value] of Object.entries(this.ticket)) {
            if (Object.prototype.hasOwnProperty.call(this.headerThread, key)) {
              this.headerThread[key] = value;
            }
          }
          if( this.ticket.cf_list.cf_datacenter != "C21 - Milano" ) {
            // Se è Roma nascondi
            this.viewWarehouse = false;
          }
          resolve(true);
        });
    });

    Promise.all([getTicket]).then((values) => {
      if (values[0]) {
        this.getThreads();
      }
    });
  },
};
</script>
