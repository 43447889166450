import { defineStore } from 'pinia'
import axios from "axios"
import { MyCrypto } from '@/services/MyCrypto';
import { useUserStore } from '@/stores/UserStore'

export const useCartStore = defineStore('CartStore', {
  state: () => {
    return {
      cart: [],
      total: 0,
      totalMonths: 0
    }
  },
  getters: {
    // length: () => {
    //   return cart.length;
    // }
  },
  actions: {
    async getItems() {

      let he = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + useUserStore().token
      };

      axios
        .get(MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'sales/v1/cart', { headers: he })
        .then(response => {
          this.cart = response.data.items;
          if (response.data.items.length > 0) {
            this.total = response.data.total_amount.nrc;
            this.totalMonths = response.data.total_amount.mrc;
          }
        }).catch(function (err) {
          console.log(err);
        });
    },
    async getItem(cart) {

      let rP = [];
      let obj;
      cart.related_products.forEach(el => {
        obj = el;
        if( el.products[0] ) {
          obj.products = el.products[0];
        }
        if( obj.products && obj.products.quantity && obj.products.quantity != 0 ) {
          obj.quantity = obj.products.quantity;
        }
        rP.push(obj);
      });

      let form = {
        kit_id: cart.kit_id,
        relatedServices: rP,
        end_customer: {
          company_name: cart.end_customer,
          vat_id: cart.end_customer.vat_id,
          cf: cart.end_customer.cf
        },
        end_customer_id: cart.end_customer ?? 0,
        notes: cart.notes ?? null,
        contacts: cart.contacts,
        client_po: cart.client_po ?? null,
        network_access_details: {
          carrier: cart.network_access_details.carrier ?? null,
          delivery_details: {
            building_floor: cart.network_access_details.delivery_details ? cart.network_access_details.delivery_details.building_floor : null,
            time_slot: cart.network_access_details.delivery_details ? cart.network_access_details.delivery_details.time_slot : null,
            notes: cart.network_access_details.delivery_details ? cart.network_access_details.delivery_details.notes : null
          },
          radius_account: {
            username: cart.network_access_details.radius_account ? cart.network_access_details.radius_account.username ?? null : null,
            password: cart.network_access_details.radius_account ? cart.network_access_details.radius_account.password ?? null : null,
            notes: cart.network_access_details.radius_account ? cart.network_access_details.radius_account.notes ?? null : null
          },
          olo2olo_migration: {
            code: cart.network_access_details.olo2olo_migration ? cart.network_access_details.olo2olo_migration.code ?? null : null,
            notes: cart.network_access_details.olo2olo_migration ? cart.network_access_details.olo2olo_migration.notes ?? null : null
          },
          address: {
            state: "Italia",
            province: cart.network_access_details.address.province ?? null,
            city: cart.network_access_details.address.city ?? null,
            address: cart.network_access_details.address.address ?? null,
            number: cart.network_access_details.address.number ?? null
          }
        },
        cart_id: cart.id,
        total: 0,
        totalMonths: 0
      };

      localStorage.setItem('cdlOrderKitAddress', JSON.stringify(form.network_access_details.address));
      localStorage.setItem('cdlOrderNetworkCoverage', JSON.stringify({ form: form, step: 6 }));

    }
  }
})