import { createApp } from 'vue'
import App from './App.vue'
import "@/assets/scss/Global.scss"
import router from './router'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { vueKeycloak } from '@baloise/vue-keycloak'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createPinia } from 'pinia'

import { IsLoggedIn } from '@/services/IsLoggedIn';
import { PriceClean } from '@/services/PriceClean';
import { LabelInput } from '@/services/LabelInput';
import { KitStyle } from '@/services/KitStyle';
import { IsMobile } from '@/services/IsMobile';
import { UpdateCart } from '@/services/UpdateCart';
import { OrderDraft } from '@/services/OrderDraft';
import { MyCrypto } from '@/services/MyCrypto';
import { Logout } from '@/services/Logout';
import { ctrlPermissions } from '@/services/Permissions';

import directives from "@/directives/";

// let test = MyCrypto('https://mistra-coverage-edit.i.cub-otto.it/', 0);
// console.log(test);

const pinia = createPinia();

const app = createApp(App).use(i18n).use(vueKeycloak, {
    initOptions: {
        flow: 'implicit', // default
        checkLoginIframe: false, // default
        onLoad: 'check-sso', //'check-sso', // 'login-required'
        // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        redirectUri: window.location.origin + '/',
        "always-refresh-token": true
    },
    config: {
        url: MyCrypto(window.VUE_APP_KEYCLOAK_URL, 1),
        realm: MyCrypto(window.VUE_APP_KEYCLOAK_REALM, 1),
        clientId: MyCrypto(window.VUE_APP_KEYCLOAK_CLIENT_ID, 1)
    }
}).use(VueGoogleMaps, {
    load: {
        key: MyCrypto(window.VUE_APP_GOOGLE_KEY, 1),
        libraries: "places"
        // language: 'it',
    },
}).use(router).use(VueAxios, axios).use(pinia);

app.config.globalProperties.$IsLoggedIn = IsLoggedIn;
app.config.globalProperties.$PriceClean = PriceClean;
// app.config.globalProperties.$SetNavigator = SetNavigator;
app.config.globalProperties.$LabelInput = LabelInput;
app.config.globalProperties.$KitStyle = KitStyle;
app.config.globalProperties.$IsMobile = IsMobile;
app.config.globalProperties.$UpdateCart = UpdateCart;
app.config.globalProperties.$OrderDraft = OrderDraft;
app.config.globalProperties.$MyCrypto = MyCrypto;
app.config.globalProperties.$Logout = Logout;
app.config.globalProperties.$ctrlPermissions = ctrlPermissions;

// app.loadScript("https://code.getmdl.io/1.3.0/material.min.js");

directives(app);

app.mount('#app');


