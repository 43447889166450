<template>
  <div id="text-search">
    <div class="contentIcon">
      <span class="material-icons">search</span>
    </div>
    <div class="contentInput">
      <input
        id="text-search-input"
        name="text-search-input"
        type="search"
        spellcheck="false"
        :placeholder="$t('actions.text_search_placeholder')"
        @input="this.debouncedHandler"
      />
    </div>
  </div>
</template>

<style scoped>
.contentInput {
  width: 100%;
}

#text-search {
  width: 98%;
  background: #e1e7ec 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #0000001a;
  border-radius: 41px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

#text-search input {
  box-sizing: content-box;
  outline: 0;
  width: 100%;
  height: 50px;
  border: 0;
  padding: 5px;
  font-size: 1.125rem;
  color: #032f5e;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

#text-search .contentIcon {
  width: 55px;
  text-align: center;
  color: #6585a7;
}
</style>

<script>
import debounce from "lodash.debounce";

export default {
  emits: ["search", "reset"],
  methods: {
    /**
     * the main search method
     */
    searchByString(search) {
      let keyword = search.toLowerCase();

      // search input reset
      if (keyword.length === 0) {
        this.$emit("reset");
      }

      // if keyword is not enough to perform search
      if (keyword.length < 3) {
        return;
      }

      console.log("Searching for", keyword);

      this.$emit("search", keyword);
    },
  },
  created() {
    /**
     * debounces the input to increase performance
     */
    this.debouncedHandler = debounce((event) => {
      this.searchByString(event.target.value);
    }, 300);
  },
  beforeUnmount() {
    this.debouncedHandler.cancel();
  },
};
</script>
