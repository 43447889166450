<template>
<svg id="Step1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <g id="Ellisse_153" data-name="Ellisse 153" fill="#e4effc" class="setCircle">
    <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
    <circle cx="17.5" cy="17.5" r="17" fill="none"/>
  </g>
  <g id="key_black_24dp" transform="translate(8 8)">
    <g id="Raggruppa_4440" data-name="Raggruppa 4440">
      <rect id="Rettangolo_3003" data-name="Rettangolo 3003" width="19" height="19" fill="none" />
      <rect id="Rettangolo_3004" data-name="Rettangolo 3004" width="19" height="19" fill="none" />
    </g>
    <g id="Raggruppa_4441" data-name="Raggruppa 4441" transform="translate(0.793 4.748)">
      <path class="setColor" id="Tracciato_1627" data-name="Tracciato 1627" d="M16.509,9.166H10.223a4.75,4.75,0,1,0,0,3.167H10.5l1.021,1.021a.788.788,0,0,0,1.116,0l1.029-1.021,1.021,1.021a.8.8,0,0,0,1.124,0l2.05-2.066a.791.791,0,0,0-.008-1.124L17.071,9.4A.811.811,0,0,0,16.509,9.166ZM5.75,13.125A2.375,2.375,0,1,1,8.125,10.75,2.382,2.382,0,0,1,5.75,13.125Z" transform="translate(-1.002 -5.998)" fill="#b8c4ce"/>
    </g>
  </g>
</svg>

</template>

<script>

export default {
  name: 'MyStep1'
}
</script>