<template>
  <div id="Shipping" class="accessRequest" v-if="isColocation">
    <MyBanner :text="$t('banner.accessDcInitTicket')" />
    <MyHeaderPage
      :title="$t('accessDc.title')"
      :subtitle="$t('accessDc.subTitle')"
    />

    <p class="intro-text" v-if="isBio" v-html="$t('accessDc.infoTitle')"></p>
    <div class="list-container" v-if="isBio">
      <p
        class="infoContentTitle marginBottom0"
        v-html="$t('accessDc.infoContentTitle')"
      ></p>
      <div class="lists-content" v-html="$t('accessDc.infoContent')"></div>
    </div>
    <MyLoading :viewLoader="viewLoader" type="" />
    <br /><br />
    <div class="typeShipping">
      <div
        class="card"
        v-for="(dc, indexDc) in dc_list"
        :key="indexDc"
        :class="{ opacity: !dc.available }"
      >
        <div class="cardContent">
          <h3>{{ dc.dc_name }}</h3>
          <p class="text">{{ $t("accessDc.cardOneText" + dc.dc_id) }}</p>
          <div class="contentButton">
            <div
              class="cdlButton"
              @click="dc.available ? setAccess(dc) : null"
              :id="'dc-' + indexDc"
              style="width: 87%"
            >
              {{ $t("accessDc.requestBtn") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br /><br />
  </div>
  <div v-else>
    <MyHeaderPage
      :title="$t('accessDc.title')"
      :subtitle="$t('accessDc.subTitle')"
    />
    <CNoSolutionsService :viewBtn="false" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const isColocation = computed(() => {
  let colo = false;
  let user = useUserStore().user;
  if (user.isColocation) {
    colo = user.isColocation;
  }
  return colo;
});
</script>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import "@/assets/scss/Shipping.scss";
import "@/assets/scss/AccessRequest.scss";
import { useAccessDcStore } from "@/stores/AccessDcStore";
import CNoSolutionsService from "@/components/NoSolutionsService.vue";

export default {
  name: "MyAccessRequest",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    CNoSolutionsService,
  },
  data() {
    return {
      dc_list: [],
      viewLoader: false,
      isBio: false,
    };
  },
  methods: {
    setAccess(dc) {
      let accessDc = useAccessDcStore();
      accessDc.form.dc.dc_id = dc.dc_id;
      accessDc.form.dc.dc_name = dc.dc_name;
      accessDc.form.dc.address = dc.dc_address;
      return this.$router.push({
        name: "AccessRequestForm" + this.$i18n.locale,
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    let accessDc = useAccessDcStore();
    accessDc.resetAccess();

    let he = {
      Accept: "application/json",
      Authorization: "Bearer " + this.$keycloak.idToken,
    };

    this.axios
      .get(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "tickets/v1/service-dc-details?service=Shipping",
        { headers: he }
      )
      .then((response) => {
        this.dc_list = response.data;
        this.viewLoader = true;
      })
      .catch(function (err) {
        console.log(err);
      });

    if (localStorage.getItem("cdlAccessDc")) {
      accessDc.form = JSON.parse(localStorage.getItem("cdlAccessDc")).accessDc;
      accessDc.isCompleted = true;
      return this.$router.push({
        name: "AccessRequestSummary" + this.$i18n.locale,
      });
    } else {
      localStorage.removeItem("cdlAccessDc");
      localStorage.removeItem("cdlAccessDcSteps");
      localStorage.removeItem("cdlAccessDcStepsGoTo");
      localStorage.removeItem("accessDc");
      localStorage.removeItem("accessCoWo");
    }

    let accessStore = useAccessDcStore();
    this.isBio = accessStore.isBio;
  },
};
</script>
