// stores/CompaniesStore.js
import { defineStore } from "pinia";
import axios from "axios";
import { MyCrypto } from "@/services/MyCrypto";
import { useUserStore } from "@/stores/UserStore";
import { cleanObject } from "@/stores/DataCenterStore";

export const url = MyCrypto(window.VUE_APP_MISTRA_URL, 1);
export const useCompaniesStore = defineStore("companies", {
  state: () => ({
    companies: [],
    company: {},
  }),
  actions: {
    getHeader() {
      return {
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      };
    },
    async getCompanies(searchTerm = "") {
      const authStore = useUserStore();
      let curl = url + "sales/v1/end-customer";
      try {
        const response = await axios.get(curl, {
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        // Apply filtering based on searchTerm
        this.companies = response.data.filter(
          (company) =>
            company.company_name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            company.vat_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            company.cf?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async editCompany(companyId, companyData) {
      try {
        const authStore = useUserStore();
        const cleanCompanyData = cleanObject(companyData);
        const response = await axios.put(
          url + `sales/v1/end-customer/${companyId}`,
          cleanCompanyData,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );

        // Find the company in the local state and update it
        const index = this.companies.findIndex(
          (company) => company.id === companyId
        );
        if (index !== -1) {
          this.companies[index] = { ...this.companies[index], ...companyData };
        }

        return response.data;
      } catch (error) {
        console.error("Error editing company:", error);
        throw error;
      }
    },
    async deleteCompany(companyId) {
      try {
        const authStore = useUserStore();
        const response = await axios.delete(
          url + `sales/v1/end-customer/${companyId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error editing company:", error);
        throw error;
      }
    },

    async addCompany(companyData) {
      try {
        const cleanCompanyData = cleanObject(companyData);
        const authStore = useUserStore();
        const response = await axios.post(
          url + `sales/v1/end-customer/`,
          cleanCompanyData,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );

        return response.data;
      } catch (error) {
        console.error("Error editing company:", error);
        throw error;
      }
    },
  },
});
