<template>
  <svg id="Step1" data-name="step 01 in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35"
    viewBox="0 0 35 35">
    <circle id="bg" cx="17.5" cy="17.5" r="17.5" fill="#e4effc" class="setCircle" />
    <g id="icon" transform="translate(6.887 8)">
      <g id="Raggruppa_3070" data-name="Raggruppa 3070" transform="translate(4.678)">
        <path class="setColor" id="Tracciato_1551" data-name="Tracciato 1551"
          d="M13.783,9H8.826A.829.829,0,0,0,8,9.826v9.913a.829.829,0,0,0,.826.826h4.957a.829.829,0,0,0,.826-.826V9.826A.829.829,0,0,0,13.783,9ZM11.3,13.337A1.033,1.033,0,1,1,12.337,12.3,1.033,1.033,0,0,1,11.3,13.337Z"
          transform="translate(-5.256 -1.565)" fill="#134c7a" />
        <path class="setColor" id="Tracciato_1552" data-name="Tracciato 1552"
          d="M7.768,6.33h0a.839.839,0,0,0,1.074.083,4.082,4.082,0,0,1,2.379-.76A4.135,4.135,0,0,1,13.6,6.4a.838.838,0,0,0,1.074-.074h0A.822.822,0,0,0,14.6,5.09a5.78,5.78,0,0,0-6.757,0A.816.816,0,0,0,7.768,6.33Z"
          transform="translate(-5.172 -0.696)" fill="#134c7a" />
        <path class="setColor" id="Tracciato_1553" data-name="Tracciato 1553"
          d="M10.727,0A9.061,9.061,0,0,0,4.985,2.04a.832.832,0,0,0-.066,1.231h0a.812.812,0,0,0,1.09.058,7.463,7.463,0,0,1,4.717-1.677,7.383,7.383,0,0,1,4.709,1.685.812.812,0,0,0,1.09-.058h0a.832.832,0,0,0-.066-1.231A8.981,8.981,0,0,0,10.727,0Z"
          transform="translate(-4.678)" fill="#134c7a" />
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'MyStep1'
}
</script>