<template>
  <div>
    <!-- UTENTI -->
    <AccessDcUser
      :wizard-data="wizardData"
      @setUsers="setUsers"
      :errorNoUsersDad="errorNoUsersDad"
      :errorMoreUsersDad="errorMoreUsersDad"
      :typeUsers="'coworking'"
    />

    <hr />

    <h2>{{ $t("accessDc.titleDeskAndHours") }}</h2>
    <p class="subTitle">{{ $t("accessDc.infoCoworking") }}</p>
    <div class="mdl-grid" style="margin: 0; padding: 0">
      <div class="mdl-cell mdl-cell--12-col" style="padding: 0; margin: 10px 0">
        <p class="labelFour">{{ $t("accessDc.labelCoworking") }}*</p>
        <small class="labelFour" v-if="false">{{
          $t("accessDc.infoCoworking")
        }}</small>
        <span
          class="error"
          v-if="
            this.v$.form.coworking.desks.$invalid &&
            this.v$.form.coworking.desks.$dirty
          "
        >
          <br />
          {{ $t("accessDc.nDesk") }} {{ $t("checkCoverage.isRequired") }}
        </span>
        <span class="error" v-if="this.errorDesk">
          <br />
          {{ $t("accessDc.errorDesk") }}
        </span>
      </div>
    </div>
    <div class="contentDesks">
      <div v-for="i in maxUsers" :key="i" class="singleDesk">
        <div class="buttonRadio" @click="setDesk(i)" :id="['desk-' + i]">
          <div></div>
        </div>
        <div>
          <p class="labelFour">
            {{ i }}
            {{ i == maxSeatsDeskCoworking ? $t("accessDc.allRoom") : "" }}
          </p>
        </div>
      </div>
      <div class="singleDesk" v-if="maxUsers < maxSeatsDeskCoworking">
        <div
          class="buttonRadio"
          @click="setDesk(maxSeatsDeskCoworking)"
          :id="['desk-' + maxSeatsDeskCoworking]"
        >
          <div></div>
        </div>
        <div>
          <p class="labelFour">
            {{ maxSeatsDeskCoworking }} {{ $t("accessDc.allRoom") }}
          </p>
        </div>
      </div>
    </div>

    <hr />

    <div class="mdl-grid" v-if="false">
      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour marginTop20"></p>
        <p class="labelFour marginTop20">
          {{ $t("accessDc.labelDateTimeCoworking") }}*
        </p>
      </div>
    </div>

    <div class="mdl-grid" style="margin: 0; padding: 0">
      <div class="mdl-cell mdl-cell--4-col" v-if="false">
        <span class="labelOne">{{ $t("accessDc.dateAccess") }}</span>
        <div class="cdl-input" style="margin-top: 0">
          <input
            type="date"
            name="dateAccess"
            v-model="dateAccess"
            class="not-empty"
            :min="minDateAccess"
          />
          <span
            class="error"
            v-if="this.v$.dateAccess.$invalid && this.v$.dateAccess.$dirty"
          >
            {{ $t("accessDc.dateAccess") }} {{ $t("checkCoverage.isRequired") }}
          </span>
        </div>
      </div>
      <div
        class="mdl-cell mdl-cell--4-col"
        style="margin: 0; padding: 0; margin-top: 10px"
      >
        <span class="labelFour">{{ $t("accessDc.fromTime") }}</span>
        <div class="cdl-input" style="margin-top: 0">
          <input
            type="time"
            name="fromTime"
            class="not-empty"
            v-model="form.coworking.fromTime"
            :min="form.coworking.fromTime"
            @change="getDiffTime()"
          />
          <span
            class="error"
            v-if="
              this.v$.form.coworking.fromTime.$invalid &&
              this.v$.form.coworking.fromTime.$dirty
            "
          >
            {{ $t("accessDc.fromTime") }} {{ $t("checkCoverage.isRequired") }}
          </span>
        </div>
      </div>
      <div
        class="mdl-cell mdl-cell--4-col"
        style="margin: 0; padding: 0; margin-top: 10px"
      >
        <span class="labelFour">{{ $t("accessDc.toTime") }}</span>
        <div class="cdl-input" style="margin-top: 0">
          <input
            type="time"
            name="toTime"
            class="not-empty"
            v-model="form.coworking.toTime"
            :min="mintoTimeValue"
            :max="maxTime"
            @change="getDiffTime()"
            :disabled="!form.coworking.desks"
          />
          <span class="error" v-if="errorTime">
            {{
              $t("accessDc.tooHours", {
                hours: form.coworking.desks == 6 ? 2 : 4,
              })
            }}
          </span>
          <span class="error" v-else-if="errorNoAvaible">
            {{ $t("accessDc.noSeats") }}
          </span>
          <span
            class="error"
            v-else-if="
              this.v$.form.coworking.toTime.$invalid &&
              this.v$.form.coworking.toTime.$dirty &&
              !errorTime
            "
          >
            {{ $t("accessDc.toTime") }} {{ $t("checkCoverage.isRequired") }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="viewLoaderCoWo">
      <div class="cdlContentButtonDouble">
        <div class="cdlButtonSingle width170" @click="goBack()">
          {{ $t("checkCoverage.stepsButtonTwo") }}
        </div>
        <div
          id="goNext"
          class="cdlButtonSingle width170"
          @click="goNext(false)"
        >
          {{ $t("checkCoverage.stepsButtonOne") }}
        </div>
        <div
          class="cdlButtonSingle"
          @click="goNext(true)"
          v-if="wizardData.isCompleted"
        >
          {{ $t("accessDc.goEnd") }}
        </div>
      </div>
      <p
        class="deleteOrder"
        v-if="!wizardData.isCompleted"
        @click="ctrlModalAccessDcRemove()"
      >
        {{ $t("checkCoverage.stepsButtonThree") }}
      </p>
    </div>
    <div>
      <MyLoading :viewLoader="viewLoaderCoWo" type="" />
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, integer, minValue } from "@vuelidate/validators";
import moment from "moment";
import MyLoading from "@/components/MyLoading.vue";
import AccessDcUser from "@/components/AccessDc/AccessDcUser.vue";
import { useAccessDcStore } from "@/stores/AccessDcStore";

export default {
  name: "DeskAndHours",
  components: {
    MyLoading,
    AccessDcUser,
  },
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    let myValidate = {
      form: {
        coworking: {
          desks: { required, integer, minValueValue: minValue(1) },
          fromTime: { required },
          toTime: { required },
        },
      },
    };
    // if (this.typeAccess == 2 || this.typeAccess == 0 || this.typeAccess == 3) {
    //   myValidate.dateAccess = { required };
    // }
    return myValidate;
  },
  data() {
    return {
      form: {
        coworking: {
          desks: null,
          fromTime: "00:00",
          toTime: "01:00",
          users: [],
        },
      },
      maxTime: null,
      mintoTimeValue: null,
      errorTime: false,
      errorNoAvaible: false,
      maxSeatsDeskCoworking: 6,
      maxUsers: 0,
      dateAccess: null,
      minDateAccess: null,
      typeAccess: 0,
      viewLoaderCoWo: true,
      errorNoUsersDad: false,
      errorMoreUsersDad: false,
      errorDesk: false,
    };
  },
  methods: {
    setDesk(n) {
      let elms = document.getElementsByClassName("buttonRadio");
      for (let i = 0; i < elms.length; i++) {
        elms[i].classList.remove("active");
      }
      this.form.coworking.desks = n;
      if (document.getElementById("desk-" + n)) {
        document.getElementById("desk-" + n).classList.add("active");
      }
      this.maxtoTime();
    },
    goBack() {
      this.$emit("goBack");
    },
    async goNext(goSummary = null) {
      this.errorNoAvaible = false;
      this.errorDesk = false;

      let errors = 0;
      if (this.form.coworking.users.length == 0) {
        this.errorNoUsersDad = true;
        errors++;
      }

      if( this.form.coworking.desks >= this.maxSeatsDeskCoworking && this.form.coworking.users.length > this.maxSeatsDeskCoworking ) {
        // va bene i 6 desk però non più dei posti!
        this.errorMoreUsersDad = true;
        errors++;
      }

      if( 
        this.form.coworking.desks < this.maxSeatsDeskCoworking && 
        (this.form.coworking.users.length > this.form.coworking.desks || 
        this.form.coworking.users.length < this.form.coworking.desks) ) {
        // Se gli utenti sono maggiori del numero di desk
        this.errorMoreUsersDad = true;
        errors++;
      }

      if (errors > 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      const result = await this.v$.$validate();
      if (
        result &&
        !this.errorTime &&
        this.form.coworking.users.length > 0 &&
        errors == 0
      ) {
        this.v$.$reset();
        let date = this.wizardData.form.dateAccess;
        // if (this.typeAccess == 2 || this.typeAccess == 0 || this.typeAccess == 3 ) {
        //   date = this.dateAccess;
        //   let form = {
        //     dateAccess: this.dateAccess,
        //     coworking: this.form.coworking
        //   }
        //   this.$emit('update', form);
        // }

        this.viewLoaderCoWo = false;

        const self = this;

        let he = {
          Accept: "application/json",
          Authorization: "Bearer " + this.$keycloak.idToken,
        };
        let input = {
          start_date: date + " " + this.form.coworking.fromTime,
          end_date: date + " " + this.form.coworking.toTime,
          n_positions: this.form.coworking.desks,
        };
        this.axios
          .post(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/check-coworking",
            input,
            { headers: he }
          )
          .then((response) => {
            if (response.data == true) {
              if (this.typeAccess.indexOf("dc") < 0) {
                let accessDcStore = useAccessDcStore();
                accessDcStore.form.dc.fromTime = this.form.coworking.fromTime;
                accessDcStore.form.dc.toTime = this.form.coworking.toTime;
              }

              this.$emit("update");
              this.$emit("goNext", { goSummary });
            } else {
              this.errorNoAvaible = true;
            }
            this.viewLoaderCoWo = true;
          })
          .catch(function (err) {
            console.log(err);
            self.viewLoaderCoWo = true;
            return false;
          });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    ctrlModalAccessDcRemove() {
      this.$emit("close");
    },
    mintoTime() {
      let hour = parseInt(this.form.coworking.fromTime.split(":")[0]) + 1;
      if (hour < 10) {
        hour = "0" + hour;
      }

      if (!this.form.coworking.desks) {
        this.form.coworking.toTime = hour + ":00";
      }
      this.mintoTimeValue = hour + ":00";
    },
    maxtoTime() {
      let time = 4;
      if (this.form.coworking.desks == 6) {
        time = 2;
      }
      let hour = parseInt(this.form.coworking.fromTime.split(":")[0]) + time;
      this.maxTime = hour + ":00";
    },
    getDiffTime() {
      this.msgErrorTime = "";
      this.errorTime = false;
      if (!this.form.coworking.desks) {
        // devi selezionare prima il numero di desk
        this.errorTime = true;
        this.form.coworking.toTime = null;
        return;
      }
      var duration = moment.duration(
        moment("1970-01-01T" + this.form.coworking.toTime).diff(
          "1970-01-01T" + this.form.coworking.fromTime
        )
      );
      if (duration._milliseconds < 0) {
        this.form.coworking.toTime = null;
        return;
      }
      let time = 14400000; // 4 ore
      if (this.form.coworking.desks == 6) {
        time = 7200000; // 2 ore
      }
      let rls = duration._milliseconds / time;
      if (rls > 1) {
        this.errorTime = true;
        this.form.coworking.toTime = null;
        return;
      }
    },
    getDate() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth() + parseInt(1);
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      this.dateAccess = date.getFullYear() + "-" + mm + "-" + dd;
      this.minDateAccess = date.getFullYear() + "-" + mm + "-" + dd;
      return date.getFullYear() + "-" + mm + "-" + dd;
    },
    //UTENTI
    setUsers(result) {
      this.form.coworking.users = result;

      let accessDcStore = useAccessDcStore();

      accessDcStore.form.coworking.users = result;

      this.maxUsers = this.form.coworking.users.length;

      if (this.maxUsers > 6) {
        this.maxUsers = 6;
      }

      localStorage.setItem("accessCoWo", JSON.stringify(result));

      if (localStorage.getItem("accessDc")) {
        accessDcStore.form.dc.users = JSON.parse(
          localStorage.getItem("accessDc")
        );
      }
    },
  },
  activated() {
    if (localStorage.getItem("cdlAccessDc")) {
      let ticket = JSON.parse(localStorage.getItem("cdlAccessDc"));
      if (ticket.accessDc.coworking) {
        this.form.coworking = ticket.accessDc.coworking;
      }
    }

    this.maxSeatsDeskCoworking = this.wizardData.maxSeatsDeskCoworking;

    this.maxUsers =
      this.wizardData.form.coworking.users.length == 0
        ? this.wizardData.maxSeatsDeskCoworking
        : this.wizardData.form.coworking.users.length > 6 ? 6 : this.wizardData.form.coworking.users.length;

    this.dateAccess = this.wizardData.form.dateAccess ?? this.getDate();
    this.typeAccess = this.wizardData.form.typeAccess;

    this.form.coworking = this.wizardData.form.coworking;
    if (this.wizardData.form.coworking.desks > 0) {
      setTimeout(() => {
        this.setDesk(this.wizardData.form.coworking.desks);
      }, 500);
    }
    this.mintoTime();
  },
};
</script>
