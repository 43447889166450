<template>
  <div v-if="!isMobile">
    <div class="selectable-fields2 row">
      <div class="field">
        <select
            name="datacenter"
            id="selectedUser"
            class="form-element select-grey"
            style="background-color: #eef1f4; margin-left: 0"
            @change="submit($event)"
            @input="label($event)"
        >
          <option value="-1" selected>
            {{ $t("remoteHands.valueDefault") }}
          </option>
          <option :value="key" v-for="(user, key) in users" :key="key">
            {{ user.first_name }} {{ user.last_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="selectable-fields2 row">
      <div class="field-title">
        {{ $t("crossConnects.crossConnectReferrerName") }}
      </div>
      <div class="field-title" style="margin-right: 410px">
        {{ $t("crossConnects.crossConnectReferrerSurname") }}
      </div>
    </div>
    <div class="selectable-fields2 row">
      <div class="field">
        <input
            type="text"
            name="nameRef"
            v-model="fields.ref_name"
            @change="submit"
            @input="label($event)"
            class="input-element"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_name.$invalid && this.v$.fields.ref_name.$dirty
        "
        >
        {{ $t("refTicket.nameRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>
      <div class="field">
        <input
            type="text"
            name="nameRef"
            v-model="fields.ref_surname"
            @change="submit"
            @input="label($event)"
            class="input-element"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_surname.$invalid &&
          this.v$.fields.ref_surname.$dirty
        "
        >
        {{ $t("refTicket.nameRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>
    </div>
    <div class="selectable-fields2 row">
      <div class="field-title">
        {{ $t("crossConnects.crossConnectReferrerPhone") }}
      </div>
      <div class="field-title" style="margin-right: 441px">
        {{ $t("crossConnects.crossConnectReferrerMail") }}
      </div>
    </div>
    <div class="selectable-fields2 row">
      <div class="field">
        <input
            type="text"
            name="ref_phone"
            v-model="fields.ref_phone"
            @change="submit"
            @input="label($event)"
            class="input-element"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_phone.$invalid && this.v$.fields.ref_phone.$dirty
        "
        >
        {{ $t("refTicket.phoneRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>
      <div class="field">
        <input
            type="text"
            name="ref_email"
            v-model="fields.ref_email"
            @change="submit"
            @input="label($event)"
            class="input-element"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_email.$invalid && this.v$.fields.ref_email.$dirty
        "
        >
        {{ $t("refTicket.emailRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="selectable-fields2 column">
      <div class="field">
        <select
            name="datacenter"
            id="selectedUser"
            class="form-element select-grey"
            style="background-color: #eef1f4; margin-left: 0"
            @change="submit($event)"
            @input="label($event)"
        >
          <option value="-1" selected>
            {{ $t("remoteHands.valueDefault") }}
          </option>
          <option :value="key" v-for="(user, key) in users" :key="key">
            {{ user.first_name }} {{ user.last_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="selectable-fields2 column">
      <div class="field-title">
        {{ $t("crossConnects.crossConnectReferrerName") }}
      </div>
      <div class="field">
        <input
            type="text"
            name="nameRef"
            v-model="fields.ref_name"
            @change="submit"
            @input="label($event)"
            class="input-element-mobile"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_name.$invalid && this.v$.fields.ref_name.$dirty
        "
        >
        {{ $t("refTicket.nameRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>
    </div>
    <div class="selectable-fields2 column">
      <div class="field-title" style="margin-right: 410px">
        {{ $t("crossConnects.crossConnectReferrerSurname") }}
      </div>
      <div class="field">
        <input
            type="text"
            name="nameRef"
            v-model="fields.ref_surname"
            @change="submit"
            @input="label($event)"
            class="input-element-mobile"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_surname.$invalid &&
          this.v$.fields.ref_surname.$dirty
        "
        >
        {{ $t("refTicket.nameRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>
    </div>
    <div class="selectable-fields2 column">
      <div class="field-title">
        {{ $t("crossConnects.crossConnectReferrerPhone") }}
      </div>
      <div class="field">
        <input
            type="text"
            name="ref_phone"
            v-model="fields.ref_phone"
            @change="submit"
            @input="label($event)"
            class="input-element-mobile"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_phone.$invalid && this.v$.fields.ref_phone.$dirty
        "
        >
        {{ $t("refTicket.phoneRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>

    </div>
    <div class="selectable-fields2 column">
      <div class="field-title" style="margin-right: 441px">
        {{ $t("crossConnects.crossConnectReferrerMail") }}
      </div>
      <div class="field">
        <input
            type="text"
            name="ref_email"
            v-model="fields.ref_email"
            @change="submit"
            @input="label($event)"
            class="input-element-mobile"
        /><br/>
        <span
            class="error"
            v-if="
          this.v$.fields.ref_email.$invalid && this.v$.fields.ref_email.$dirty
        "
        >
        {{ $t("refTicket.emailRef") }} {{ $t("checkCoverage.isRequired") }}
      </span>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {useUserStore} from "@/stores/UserStore";

import "@/assets/scss/RefTicket.scss";
import {useDatacenterStore} from "@/stores/DataCenterStore";

export default {
  name: "MyCrossRefTicket",
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      fields: {
        ref_name: {required},
        ref_surname: {required},
        ref_phone: {required},
        ref_email: {required},
      },
    };
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: {
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
      },
      users: [],
      userSelected: 0,
    };
  },
  methods: {
    label(ev) {
      this.$LabelInput(ev);
    },
    async submit(ev) {
      if (ev && ev.target.id == "selectedUser") {
        if (ev.target.value == "") {
          this.fields.ref_name = null;
          this.fields.ref_surname = null;
          this.fields.ref_phone = null;
          this.fields.ref_email = null;
        } else {
          this.buildFieldsRef(ev.target.value);
        }
      } else {
        // Directly update the datacenterStore with input values
        const datacenterStore = useDatacenterStore();
        datacenterStore.ref_name = this.fields.ref_name;
        datacenterStore.ref_surname = this.fields.ref_surname;
        datacenterStore.ref_email = this.fields.ref_email;
        datacenterStore.ref_phone = this.fields.ref_phone;
      }

      this.$emit("update", this.fields);

      const result = await this.v$.$validate();
      this.$emit("validate", result);
    },
    async changeUser(index) {
      this.userSelected = index;
      this.buildFieldsRef(index);

      this.$emit("update", this.fields);

      const result = await this.v$.$validate();
      this.$emit("validate", result);
    },
    // Helper method to clear fields
    clearFields() {
      this.fields.ref_name = null;
      this.fields.ref_surname = null;
      this.fields.ref_phone = null;
      this.fields.ref_email = null;
    },
    buildFieldsRef(index) {
      const datacenterStore = useDatacenterStore();

      if (index == -1) {
        this.clearFields();
        // Also clear the fields in datacenterStore
        datacenterStore.ref_name = null;
        datacenterStore.ref_surname = null;
        datacenterStore.ref_phone = null;
        datacenterStore.ref_email = null;
        return;
      }
      this.fields.ref_name = this.users[index].first_name;
      this.fields.ref_surname = this.users[index].last_name;
      this.fields.ref_email = this.users[index].email;
      this.fields.ref_phone = this.users[index].phone;

      // Also update the datacenterStore
      datacenterStore.ref_name = this.users[index].first_name;
      datacenterStore.ref_surname = this.users[index].last_name;
      datacenterStore.ref_email = this.users[index].email;
      datacenterStore.ref_phone = this.users[index].phone;
    },
  },
  mounted() {
    let userInfo = useUserStore();
    this.fields.ref_name = userInfo.user.first_name;
    this.fields.ref_surname = userInfo.user.last_name;
    this.fields.ref_email = userInfo.user.email;
    this.fields.ref_phone = userInfo.user.phone;

    let he = {
      Accept: "application/json",
      Authorization: "Bearer " + this.$keycloak.idToken,
    };
    this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "users/v1/user", {
          headers: he,
        })
        .then((response) => {
          this.users = response.data;
          let getIndex = this.users.findIndex(
              (element) =>
                  element.first_name == this.fields.ref_name &&
                  element.last_name == this.fields.ref_surname &&
                  element.email == this.fields.ref_email
          );
          this.changeUser(getIndex);
          this.submit();
        })
        .catch(function (err) {
          console.log(err);
        });
  },
};
</script>
<style scoped lang="scss">
.input-element-mobile {
  padding-left: 1rem;
  width: 100%;
  height: 60px;
  background: #eef1f4 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b8c4ce;
}
</style>
