<template>
  <div id="RemoteHands">
    <MyBanner :text="$t('banner.RemoteHandsPage')" />
    <MyHeaderPage :title="$t('remoteHands.title')" button="rh" />

    <br /><br />
    <TicketsList ticket-type="RemoteHand" details-url="listRemoteHandsUrl" />
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import TicketsList from "@/components/TicketsList.vue";
import "@/assets/scss/RemoteHands.scss";

export default {
  name: "RemoteHandsList",
  props: ["openModal"],
  components: {
    MyBanner,
    MyHeaderPage,
    TicketsList,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
    this.$IsLoggedIn();
    // return this.$router.push('/lista-remote-hands/dettaglio/1234')
  },
};
</script>
