<script setup>

</script>

<template>
  <svg id="icon_fattura" data-name="icon fattura" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
    <rect id="bg" width="35" height="35" rx="17.5" fill="#6585a7"/>
    <g id="icon" transform="translate(7 7)">
      <path id="Tracciato_1579" data-name="Tracciato 1579" d="M0,0H21V21H0Z" fill="none"/>
      <path id="Tracciato_1580" data-name="Tracciato 1580" d="M13.266,2.516A1.755,1.755,0,0,0,12.024,2H5.75A1.755,1.755,0,0,0,4,3.75v14A1.748,1.748,0,0,0,5.741,19.5H16.25A1.755,1.755,0,0,0,18,17.75V7.976a1.72,1.72,0,0,0-.516-1.234L13.266,2.516ZM13.625,16H8.375a.875.875,0,0,1,0-1.75h5.25a.875.875,0,0,1,0,1.75Zm0-3.5H8.375a.875.875,0,0,1,0-1.75h5.25a.875.875,0,0,1,0,1.75Zm-1.75-5.25V3.313l4.813,4.813H12.75A.878.878,0,0,1,11.875,7.25Z" transform="translate(-0.5 -0.25)" fill="#e4effc" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </g>
  </svg>

</template>

<style scoped lang="scss">

</style>
