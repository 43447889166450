<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="48"
    viewBox="0 0 48 48" class="iconAttach">
    <defs>
      <filter id="Ellisse_153" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse">
        <feOffset dy="1" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.5" result="blur" />
        <feFlood flood-opacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="icona_allegati" data-name="icona allegati" transform="translate(-279.5 -366.5)">
      <g transform="matrix(1, 0, 0, 1, 279.5, 366.5)" filter="url(#Ellisse_153)">
        <circle id="Ellisse_153-2" data-name="Ellisse 153" cx="22.5" cy="22.5" r="22.5" transform="translate(1.5 0.5)"
          fill="#e1e7eb" opacity="0.9" />
      </g>
      <g id="attach_file_black_24dp" transform="translate(285.5 371.5)">
        <path id="Tracciato_1621" data-name="Tracciato 1621" d="M0,0H36V36H0Z" fill="none" />
        <path id="Tracciato_1622" class="attach" data-name="Tracciato 1622"
          d="M21.25,9.625v15.87a6.174,6.174,0,0,1-5.415,6.225A5.99,5.99,0,0,1,9.25,25.75V7.21a3.894,3.894,0,0,1,3.36-3.945A3.75,3.75,0,0,1,16.75,7V22.75a1.5,1.5,0,0,1-3,0V9.625a1.125,1.125,0,0,0-2.25,0V22.54a3.894,3.894,0,0,0,3.36,3.945A3.75,3.75,0,0,0,19,22.75V7.255A6.174,6.174,0,0,0,13.585,1.03,6,6,0,0,0,7,7V25.4a8.488,8.488,0,0,0,7.44,8.565,8.258,8.258,0,0,0,9.06-8.22V9.625a1.125,1.125,0,0,0-2.25,0Z"
          transform="translate(3.5 0.5)" fill="#6585a7" />
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'MyAttach'
}
</script>
