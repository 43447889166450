<template>
<svg id="Step4" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <g id="Ellisse_153" data-name="Ellisse 153" fill="#e4effc" class="setCircle">
    <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
    <circle cx="17.5" cy="17.5" r="17" fill="none"/>
  </g>
  <g id="people_black_24dp" transform="translate(8 8)">
    <path id="Tracciato_1621" data-name="Tracciato 1621" d="M0,0H19V19H0Z" fill="none"/>
    <path class="setColor" id="Tracciato_1622" data-name="Tracciato 1622" d="M12.875,9.75A2.375,2.375,0,1,0,10.5,7.375,2.365,2.365,0,0,0,12.875,9.75Zm-6.333,0A2.375,2.375,0,1,0,4.167,7.375,2.365,2.365,0,0,0,6.542,9.75Zm0,1.583C4.7,11.333,1,12.26,1,14.1v1.188a.794.794,0,0,0,.792.792h9.5a.794.794,0,0,0,.792-.792V14.1C12.083,12.26,8.386,11.333,6.542,11.333Zm6.333,0c-.23,0-.491.016-.768.04.016.008.024.024.032.032a3.293,3.293,0,0,1,1.528,2.7v1.188a2.379,2.379,0,0,1-.143.792h4.1a.794.794,0,0,0,.792-.792V14.1C18.417,12.26,14.72,11.333,12.875,11.333Z" transform="translate(-0.208 -1.042)" fill="#b8c4ce"/>
  </g>
</svg>
</template>

<script>

export default {
  name: 'MyStep4'
}
</script>