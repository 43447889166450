<template>
<svg id="Step2" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <g id="Ellisse_153" data-name="Ellisse 153" fill="#e4effc" class="setCircle">
    <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
    <circle cx="17.5" cy="17.5" r="17" fill="none"/>
  </g>
  <g id="event_black_24dp" transform="translate(8 8)">
    <path id="Tracciato_1617" data-name="Tracciato 1617" d="M0,0H19V19H0Z" fill="none"/>
    <path class="setColor" id="Tracciato_1618" data-name="Tracciato 1618" d="M13.292,10.708H10.917a.794.794,0,0,0-.792.792v2.375a.794.794,0,0,0,.792.792h2.375a.794.794,0,0,0,.792-.792V11.5A.794.794,0,0,0,13.292,10.708Zm0-7.917v.792H6.958V2.792a.792.792,0,1,0-1.583,0v.792H4.583A1.576,1.576,0,0,0,3.008,5.167L3,16.25a1.583,1.583,0,0,0,1.583,1.583H15.667A1.588,1.588,0,0,0,17.25,16.25V5.167a1.588,1.588,0,0,0-1.583-1.583h-.792V2.792a.792.792,0,1,0-1.583,0ZM14.875,16.25h-9.5a.794.794,0,0,1-.792-.792V7.542H15.667v7.917A.794.794,0,0,1,14.875,16.25Z" transform="translate(-0.625 -0.417)" fill="#b8c4ce"/>
  </g>
</svg>
</template>

<script>

export default {
  name: 'MyStep2'
}
</script>