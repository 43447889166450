<template>
  <div id="LinesList">
    <table v-if="this.viewLoader && this.items && this.items.length">
      <thead v-if="!IsMobile">
        <tr>
          <th v-show="!IsMobile">{{ $t("lines.list.id") }}</th>
          <th>{{ $t("lines.list.owner") }}</th>
          <th>{{ $t("lines.list.address") }}</th>
          <th v-if="!IsMobile"></th>
          <th v-if="!IsMobile"></th>
          <th v-if="!IsMobile"></th>
          <th v-if="!IsMobile"></th>
        </tr>
      </thead>
      <tbody v-if="IsMobile">
        <MobileCard
          v-for="(item, keyItem) in this.items"
          :key="keyItem"
          :titles="['serialnumber', 'intestatario', 'solution']"
          :item="item"
          translationPath="ticketsList.lines"
        >
          <template #url-label>
            <router-link
              :to="`${$t('nav.' + detailsUrl)}/${$t('nav.detail')}/${item.id}`"
            >
              <div class="cdlButton">
                <span>
                  {{ $t("lines.details") }}
                </span>
              </div>
            </router-link></template
          >
        </MobileCard>
      </tbody>
      <tbody v-else-if="!IsMobile">
        <tr
          v-for="(item, keyItem) in this.items"
          :key="keyItem"
          :class="{ odd: keyItem % 2 }"
        >
          <td>
            <span>
              {{ item.serialnumber }}
            </span>
          </td>
          <td>
            <span>
              {{ item.intestatario }}
            </span>
          </td>
          <td colspan="2">
            <span>
              {{ item.solution }}
            </span>
          </td>
          <td>
            <router-link
              :to="
                '/' +
                $t('nav.' + detailsUrl) +
                '/' +
                $t('nav.detail') +
                '/' +
                item.id
              "
            >
              <div class="cdlButton">{{ $t("lines.details") }}</div>
            </router-link>
          </td>
          <td colspan="3">
            <div style="display: inline-flex; justify-content: space-around">
              <HelpRequestBtn
                v-if="ctrlPerms(['incident_lista'])"
                :component-name="'ActiveLinesIncidentForm'"
                :asset-type="'lines'"
                :item-id="item.id"
                :icon="'warning'"
                :label="$t('help.incident.formTitle')"
              />

              <HelpRequestBtn
                v-if="ctrlPerms(['incident_lista'])"
                :component-name="'ActiveLinesChangeRequestForm'"
                :asset-type="'lines'"
                :item-id="item.id"
                :icon="'person'"
                :label="$t('help.changeRequest.formTitle')"
              />

              <HelpRequestBtn
                v-if="ctrlPerms(['change_request_list']) && false"
                :url="`/solutions/${$t('nav.techSupportUrl', {
                  item_id: item.id,
                  asset_type: 'lines',
                })}`"
                :icon="'settings'"
                :label="$t('help.techSupport.formTitle')"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="this.viewLoader">
        <tr>
          <td colspan="7" style="text-align: center">
            <span>{{ $t("lines.noData") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <br /><br />
      <MyError :viewError="viewError" :message="$t('tickets.error')" />
    </div>
    <paginator
      v-if="totalPage > 1"
      :total-page="totalPage"
      :current-page="currentPage"
      @change-page="this.changePage"
      @prev-page="this.prevPage"
      @next-page="this.nextPage"
    ></paginator>
  </div>
</template>

<script>
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import HelpRequestBtn from "@/components/Tables/HelpRequestBtn";
import MobileCard from "@/components/Cards/MobileCard.vue";

export default {
  name: "LinesList",
  components: {
    MobileCard,
    MyError,
    Paginator,
    HelpRequestBtn,
  },
  props: {
    detailsUrl: String,
    list: Array,
    total: Number,
    viewLoader: Boolean,
    viewError: Boolean,
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      searchString: "",
      messageError: "",
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage == this.totalPage) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) + 1;
    },
    prevPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) - 1;
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.list.length / this.pageSize) > 1
        ? Math.ceil(this.list.length / this.pageSize)
        : 1;
    },
    items() {
      return this.list.slice(
        (this.currentPage - 1) * this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
};
</script>
