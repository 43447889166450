<template>
  <div class="contentFormTicket">
    <div class="contentForm">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <h2 class="title">{{ $t("shipping.deliveriesTitleStep4") }}</h2>
          <p class="subTitle">{{ $t("shipping.deliveriesSubTitleStep4") }}</p>
        </div>
      </div>

      <div class="space"></div>

      <RefTicket @update="updateRef" @validate="validate" />

      <div v-if="this.btnSubmit">
        <div class="cdlContentButtonDouble">
          <div class="cdlButtonSingle green width170" @click="goBack()">
            {{ $t("checkCoverage.stepsButtonTwo") }}
          </div>
          <div class="cdlButtonSingle green width170" @click="openTicket()">
            {{ $t("shipping.openTicketShippingPickUp") }}
          </div>
        </div>

        <p class="deleteOrder" @click="cancelOrder()">
          {{ $t("shipping.deleteTicketPickUp") }}
        </p>
      </div>

      <MyLoading :viewLoader="this.btnSubmit" type="" />
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";

import RefTicket from "@/components/RefTicket.vue";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "MyInfoReference",
  components: {
    RefTicket,
    MyLoading,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
      },
      canGo: false,
      btnSubmit: true,
    };
  },
  methods: {
    goBack() {
      this.$emit("goBack");
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    submit() {
      this.$emit("update", this.fields);
    },
    openTicket() {
      // window.scrollTo(0, 0);
      if (this.canGo) {
        this.btnSubmit = false;
        this.$emit("openTicket");
      }
      return;
    },
    updateRef(stepData) {
      Object.assign(this.fields, stepData);
      this.$emit("update", this.fields);
    },
    validate(result) {
      this.canGo = result;
    },
    viwBtnSubmit() {
      this.btnSubmit = !this.btnSubmit;
    },
    cancelOrder() {
      this.$emit("close");
    },
  },
  activated() {
    window.scrollTo(0, 0);
    const userStore = useUserStore().user;
    this.nameRef = userStore.name;
    this.surnameRef = userStore.surname;
    this.emailRef = userStore.email;
    this.phoneRef = "+391234567890"; //userStore.telephone;
    this.submit();
  },
};
</script>
