<template>
<svg id="Step6" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <g id="Ellisse_153" data-name="Ellisse 153" fill="#e4effc" class="setCircle">
    <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
    <circle cx="17.5" cy="17.5" r="17" fill="none"/>
  </g>
  <g id="other_houses_black_24dp" transform="translate(8 8)">
    <rect id="Rettangolo_3005" data-name="Rettangolo 3005" width="19" height="19" fill="none"/>
    <path class="setColor" id="Tracciato_1628" data-name="Tracciato 1628" d="M1.567,10.329a.794.794,0,0,0,1.108.15l.784-.6v6.634a.794.794,0,0,0,.792.792H15.334a.794.794,0,0,0,.792-.792V9.878l.784.6a.791.791,0,1,0,.958-1.259l-7.6-5.8a.8.8,0,0,0-.958,0l-7.6,5.8A.793.793,0,0,0,1.567,10.329Zm5.059,2.225a.792.792,0,1,1,.792-.792A.794.794,0,0,1,6.626,12.554Zm3.167,0a.792.792,0,1,1,.792-.792A.794.794,0,0,1,9.792,12.554Zm3.167,0a.792.792,0,1,1,.792-.792A.794.794,0,0,1,12.959,12.554Z" transform="translate(-0.292 -0.679)" fill="#b8c4ce" stroke="rgba(0,0,0,0)" stroke-width="1"/>
  </g>
</svg>

</template>

<script>

export default {
  name: 'MyStep6'
}
</script>