import ReportVoip from "@/pages/Reports/Voip.vue";
import Fax from "@/pages/Reports/Fax.vue";
import Current from "@/pages/Reports/Current.vue";
import PaidInterventions from "@/pages/Reports/PaidInterventions.vue";
import ReportCloud from "@/pages/Reports/ReportCloud.vue";
import Historical from "@/pages/Reports/Historical.vue";
import Virtualization from "@/pages/Reports/Virtualization.vue";
import MaintenanceNotifications from "@/pages/Reports/MaintenanceNotifications.vue";
import AccessRequestHistorical from "@/pages/AccessDc/AccessRequestHistorical.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesSolutionsReport() {
  return [
    {
      path: "/virtualizzazione",
      name: "Virtualizationit",
      component: Virtualization,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "virtualization",
            url: "",
          },
        ],
        indexRoute: "virtualizzazione",
        permissions: ["virtualizzazione"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/virtualization",
      name: "Virtualizationen",
      component: Virtualization,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "virtualization",
            url: "",
          },
        ],
        indexRoute: "virtualization",
        permissions: ["virtualizzazione"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/notifiche-manutenzioni",
      name: "MaintenanceNotificationst",
      component: MaintenanceNotifications,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "maintenanceNotifications",
            url: "",
          },
        ],
        indexRoute: "notifiche-manutenzioni",
        permissions: ["notifiche_manutenzioni"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/maintenance-notifications",
      name: "MaintenanceNotificationsen",
      component: MaintenanceNotifications,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "maintenanceNotifications",
            url: "",
          },
        ],
        indexRoute: "maintenance-notifications",
        permissions: ["notifiche_manutenzioni"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/storico",
      name: "Historicalit",
      component: Historical,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "historical",
            url: "",
          },
        ],
        indexRoute: "storico",
        permissions: ["storico_ticket"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/log",
      name: "Historicalen",
      component: Historical,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "historical",
            url: "",
          },
        ],
        indexRoute: "log",
        permissions: ["storico_ticket"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip-report",
      name: "Voip",
      component: ReportVoip,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "voip",
            url: "",
          },
        ],
        indexRoute: "voip-report",
        permissions: ["voip"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/fax",
      name: "Fax",
      component: Fax,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "fax",
            url: "",
          },
        ],
        indexRoute: "fax",
        permissions: ["fax"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/corrente",
      name: "Currentit",
      component: Current,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "current",
            url: "",
          },
        ],
        indexRoute: "corrente",
        permissions: ["assorbimenti_colocation"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/current",
      name: "Currenten",
      component: Current,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "current",
            url: "",
          },
        ],
        indexRoute: "current",
        permissions: ["assorbimenti_colocation"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/interventi-a-pagamento",
      name: "PaidInterventionsit",
      component: PaidInterventions,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "paidInterventions",
            url: "",
          },
        ],
        indexRoute: "interventi-a-pagamento",
        permissions: ["interventi_pagamento"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/paid-services",
      name: "PaidInterventionsen",
      component: PaidInterventions,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "paidInterventions",
            url: "",
          },
        ],
        indexRoute: "paid-services",
        permissions: ["interventi_pagamento"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cloud-report",
      name: "ReportCloud",
      component: ReportCloud,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "cloud",
            url: "",
          },
        ],
        indexRoute: "cloud-report",
        permissions: ["report_cloud"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/storico-ingressi-dc",
      name: "AccessRequestHistoricalit",
      component: AccessRequestHistorical,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "entry_request_historical",
            url: "",
          },
        ],
        indexRoute: "storico-ingressi-dc",
        permissions: ["storico_ingressi_dc"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/dc-entry-log",
      name: "AccessHistoryRequestListen",
      component: AccessRequestHistorical,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "entry_request_historical",
            url: "",
          },
        ],
        indexRoute: "dc-entry-log",
        permissions: ["storico_ingressi_dc"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}