<template>
  <div id="OrderNetworkCoverageSummary" class="accessRequest">
    <div class="contentSummary">
      <div class="padding60">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <MyHeaderPage
              :title="
                !onlyRead
                  ? $t('accessDc.summary.title')
                  : $t('accessDc.summary.titleRead')
              "
              :subtitle="
                !onlyRead
                  ? $t('accessDc.summary.subTitle')
                  : $t('accessDc.summary.subTitleRead')
              "
            />
          </div>
        </div>
        <div class="infoKit pink">
          <div class="mdl-grid">
            <div
              class="mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
            >
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--12-col">
                  <p class="labelSummaryOne">
                    {{ $t("accessDc.summary.datacenter") }}
                  </p>
                </div>
                <div class="mdl-cell mdl-cell--12-col">
                  <div class="contentFlexSummary">
                    <div>
                      <component :is="'MyStep0'"></component>
                    </div>
                    <div class="contentElmPrimary">
                      <div>
                        <span class="textOne">{{
                          form.dc ? form.dc.dc_name : null
                        }}</span
                        ><br />
                        <span class="textTwo">{{
                          form.dc ? form.dc.address : null
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--8-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
            >
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--12-col">
                  <p class="labelSummaryOne">
                    {{ $t("accessDc.summary.typeAccess") }}
                  </p>
                </div>

                <div class="mdl-cell mdl-cell--12-col" v-if="getAccessDc()">
                  <div class="contentFlexSummary">
                    <div>
                      <component :is="'MyStep1'"></component>
                    </div>
                    <div class="contentElmPrimary">
                      <div>
                        <span class="textOne">{{
                          $t("accessDc.accessTypeOne")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mdl-cell mdl-cell--12-col" v-if="getIfCoworking()">
                  <div class="contentFlexSummary">
                    <div>
                      <component :is="'MyStep7'"></component>
                    </div>
                    <div class="contentElmPrimary">
                      <div>
                        <span class="textOne">{{
                          $t("accessDc.accessTypeTwo")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mdl-cell mdl-cell--12-col" v-if="getIfCar()">
                  <div class="contentFlexSummary">
                    <div>
                      <component :is="'MyStep7'"></component>
                    </div>
                    <div class="contentElmPrimary">
                      <div>
                        <span class="textOne">{{
                          $t("accessDc.accessTypeThree")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-for="(type, key) in form.typeAccess" :key="key">
          <!-- ACCESSO DC -->
          <div v-if="type == 'dc'" class="contentSummaryAccessDc">
            <div>
              <!-- Quando -->
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
                >
                  <h2>{{ $t("accessDc.titleStep3") }}</h2>
                  <p class="subTitle" v-if="false">
                    {{ $t("accessDc.summary.whatSubTitle") }}
                  </p>
                </div>
                <div class="mdl-cell mdl-cell--2-col" v-if="!onlyRead">
                  <p class="editInfo" @click="editInfo('WhereAndWhat')">
                    <span id="go-step-1" class="material-icons"> edit </span>
                  </p>
                </div>
              </div>

              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
                >
                  <h3>{{ $t("accessDc.summary.dateAndHour") }}</h3>
                  <p class="subTitle" v-if="false">
                    {{ $t("accessDc.summary.dateAndHourSubTitle") }}
                  </p>
                </div>
                <div class="mdl-cell mdl-cell--12-col">
                  <span class="valueSummaryOne"
                    >{{ getAccessWhen(form.dateAccess) }}.
                    {{
                      $t("accessDc.accessWhenTime", {
                        from: form.dc.fromTime,
                        to: form.dc.toTime,
                      })
                    }}</span
                  >
                </div>
              </div>

              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--12-col">
                  <hr />
                </div>
              </div>

              <!-- Sale e Rack -->
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
                >
                  <h3>{{ $t("accessDc.summary.RoomRacks") }}</h3>
                  <p class="subTitle" v-if="false">
                    Anche qui forse va messo qualcosa
                  </p>
                </div>
              </div>
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--3-col">
                  <span class="labelSummaryOne">{{
                    $t("accessDc.apparati")
                  }}</span
                  >&nbsp;
                  <span class="valueSummaryOne">{{
                    $t("accessDc.summary.apparati" + form.dc.apparati)
                  }}</span>
                </div>
                <div class="mdl-cell mdl-cell--3-col" v-if="viewWarehouse">
                  <span class="labelSummaryOne">{{
                    $t("accessDc.warehouse")
                  }}</span
                  >&nbsp;
                  <span class="valueSummaryOne">{{
                    $t("accessDc.summary.warehouse" + form.dc.warehouse)
                  }}</span>
                </div>
                <div
                  class="mdl-cell mdl-cell--4-col"
                  v-if="form.dc.dc_id == 7 && false"
                >
                  <span class="labelSummaryOne">{{ $t("accessDc.keys") }}</span
                  >&nbsp;
                  <span class="valueSummaryOne">{{
                    form.dc.keys.length > 0
                      ? $t("accessDc.summary.apparatisi")
                      : $t("accessDc.summary.apparatino")
                  }}</span>
                </div>
                <div
                  class="mdl-cell mdl-cell--3-col"
                  v-if="form.dc.keys.length > 0"
                >
                  <span class="labelSummaryOne">{{
                    $t("accessDc.summary.keysForRooms")
                  }}</span
                  >&nbsp;
                  <span
                    class="valueSummaryOne"
                    v-for="(roomKey, i) in form.dc.keys"
                    :key="i"
                    >{{ roomKey.name }};&nbsp;</span
                  >
                </div>
                <div
                  class="mdl-cell mdl-cell--2-col"
                  v-if="form.dc.keys.length > 0"
                >
                  <span class="labelSummaryOne">{{
                    $t("accessDc.summary.noteRoomsKeys")
                  }}</span
                  >&nbsp;
                  <span class="valueSummaryOne"
                    >{{ form.dc.noteKey }};&nbsp;</span
                  >
                </div>
                <div class="mdl-cell mdl-cell--12-col blockContentRacksSummary">
                  <div class="labelTwo" style="margin-bottom: 10px">
                    {{ $t("accessDc.summary.rackList") }}
                  </div>
                  <div class="contentRacksSummary" v-if="false">
                    <div
                      class="racksSummary"
                      v-for="(racksKey, k) in form.dc.racks"
                      :key="k"
                    >
                      <div class="labelSummaryOne">
                        {{ racksKey.room.name }}
                      </div>
                      <div class="contentFlexSummaryRacks">
                        <span class="labelSummaryTwo">{{
                          $t("accessDc.summary.labelRackList")
                        }}</span>
                        <span class="valueSummaryOne">{{
                          racksKey.racks.length
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="racksSummary"
                      v-for="(racksKey, k) in form.dc.racks"
                      :key="k"
                    >
                      <div class="labelSummaryOne">
                        {{ racksKey.room.name }}
                      </div>
                      <span
                        class="valueSummaryOne"
                        v-for="(rack, j) in racksKey.racks"
                        :key="j"
                        >{{ rack.name }};
                      </span>
                      <br /><br />
                    </div>
                  </div>
                </div>
                <div class="mdl-cell mdl-cell--12-col" v-if="mopTmp.length > 0">
                  <p class="labelTwo" style="margin-bottom: 0">
                    {{ $t("accessDc.mop") }}
                  </p>
                </div>
                <div
                  v-for="(attach, keyattach) in mopTmp"
                  :key="keyattach"
                  class="mdl-cell mdl-cell--12-col"
                >
                  <a
                    href=""
                    :download="attach.filename"
                    @click="downloadFile(attach, keyattach)"
                    :id="['file-' + keyattach]"
                    class="linkA"
                    >{{ attach.filename }}</a
                  ><br />
                </div>
              </div>

              <!-- Utenti -->
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
                >
                  <h3>{{ $t("accessDc.titleStep4") }}</h3>
                  <p class="subTitle" v-if="false">
                    {{ $t("accessDc.summary.whoSubTitle") }}
                  </p>
                </div>
              </div>
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--12-col">
                  <span
                    class="valueSummaryOne"
                    v-for="(user, k) in form.dc.users"
                    :key="k"
                  >
                    <span v-if="user.name"
                      >{{ user.name }} {{ user.surname }};</span
                    >
                    <span v-else-if="user.in_lenel"
                      >{{ user.nome }} {{ user.cognome }};</span
                    >
                    <span v-if="!IsMobile">&nbsp;</span>
                    <span v-else><br /></span>
                  </span>
                </div>
              </div>
              <hr />

              <!-- Referenti -->
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
                >
                  <h3>{{ $t("accessDc.summary.referenceTitle") }}</h3>
                  <p class="subTitle">
                    {{ $t("accessDc.summary.referenceSubTitle") }}
                  </p>
                </div>
              </div>
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--4-col">
                  <span class="labelSummaryOne">{{
                    $t("accessDc.summary.refName")
                  }}</span
                  >&nbsp;
                  <span class="valueSummaryOne">{{ getRefName() }}</span>
                </div>
                <div class="mdl-cell mdl-cell--4-col">
                  <span class="labelSummaryOne">{{
                    $t("accessDc.summary.refPhone")
                  }}</span
                  >&nbsp;
                  <span class="valueSummaryOne">{{ getRefPhone() }}</span>
                </div>
                <div class="mdl-cell mdl-cell--4-col">
                  <span class="labelSummaryOne">{{
                    $t("accessDc.summary.refEmail")
                  }}</span
                  >&nbsp;
                  <span class="valueSummaryOne">{{ getRefEmail() }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- CoWorking -->
          <div v-if="type == 'coworking'" class="contentSummaryAccessDc">
            <div class="mdl-grid">
              <div
                class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
              >
                <h2>{{ $t("accessDc.summary.coworkingDeskTitle") }}</h2>
                <p class="subTitle" v-if="false">
                  {{ $t("accessDc.summary.coworkingDeskSubTitle") }}
                </p>
              </div>
              <div class="mdl-cell mdl-cell--2-col" v-if="!onlyRead">
                <p class="editInfo" @click="editInfo('DeskAndHours')">
                  <span id="go-step-1" class="material-icons"> edit </span>
                </p>
              </div>
            </div>

            <div class="mdl-grid">
              <div
                class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
                v-if="false"
              >
                <h3>{{ $t("accessDc.summary.dateAndHour") }}</h3>
                <p class="subTitle">
                  {{ $t("accessDc.summary.dateAndHourSubTitle") }}
                </p>
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <span class="valueSummaryOne"
                  >{{ getAccessWhen(form.dateAccess) }}.
                  {{
                    $t("accessDc.accessWhenTime", {
                      from: form.coworking.fromTime,
                      to: form.coworking.toTime,
                    })
                  }}</span
                >
              </div>
            </div>
            <div class="mdl-grid">
              <div class="mdl-cell mdl-cell--12-col">
                <span class="labelSummaryOne">{{
                  $t("accessDc.summary.coworkingDeskBooked")
                }}</span
                >&nbsp;
                <span class="valueSummaryOne">{{ form.coworking.desks }}</span>
              </div>
            </div>
            <hr />
            <!-- Utenti -->
            <div class="mdl-grid">
              <div
                class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
              >
                <h3>{{ $t("accessDc.titleStep4") }}</h3>
                <p class="subTitle" v-if="false">
                  {{ $t("accessDc.summary.whoSubTitle") }}
                </p>
              </div>
            </div>
            <div class="mdl-grid">
              <div class="mdl-cell mdl-cell--12-col" v-if="form.coworking.users[0]">
                <span
                  class="valueSummaryOne"
                  v-for="(user, k) in form.coworking.users"
                  :key="k"
                >
                  <span v-if="user.name"
                    >{{ user.name }} {{ user.surname }};</span
                  >
                  <span v-else-if="user.in_lenel"
                    >{{ user.nome }} {{ user.cognome }};</span
                  >
                  <span v-if="!IsMobile">&nbsp;</span>
                  <span v-else><br /></span>
                </span>
              </div>
              <div class="mdl-cell mdl-cell--12-col" v-else>
                <span class="valueSummaryOne">{{ $t("tickets.noUserBio") }}</span>
              </div>
            </div>
          </div>

          <!-- AUTO -->
          <div v-if="type == 'parking'" class="contentSummaryAccessDc">
            <div class="mdl-grid">
              <div
                class="mdl-cell mdl-cell--1-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
              >
                <h2>{{ $t("accessDc.car") }}</h2>
              </div>
              <div class="mdl-cell mdl-cell--2-col" v-if="!onlyRead">
                <p class="editInfo" @click="editInfo('BookParking')">
                  <span id="go-step-1" class="material-icons"> edit </span>
                </p>
              </div>
            </div>
            <!-- QUANDO -->
            <div class="mdl-grid" v-if="
                form.typeAccess.indexOf('dc') < 0 &&
                form.typeAccess.indexOf('coworking') < 0
              ">
              <div
                class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone" 
                v-if="false"
              >
                <h3>{{ $t("accessDc.summary.dateAndHour") }}</h3>
                <p class="subTitle">
                  {{ $t("accessDc.summary.dateAndHourSubTitle") }}
                </p>
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <span class="valueSummaryOne"
                  >{{ getAccessWhen(form.dateAccess) }}.
                  {{
                    $t("accessDc.accessWhenTime", {
                      from: form.coworking.fromTime,
                      to: form.coworking.toTime,
                    })
                  }}</span
                >
              </div>
            </div>
            <hr
              v-if="
                form.typeAccess.indexOf('dc') < 0 &&
                form.typeAccess.indexOf('coworking') < 0
              "
            />
            <!-- Utenti -->
            <div
              class="mdl-grid"
              v-if="
                form.typeAccess.indexOf('dc') < 0 &&
                form.typeAccess.indexOf('coworking') < 0
              "
            >
              <div
                class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
              >
                <h3>{{ $t("accessDc.titleStep4") }}</h3>
                <p class="subTitle" v-if="false">
                  {{ $t("accessDc.summary.whoSubTitle") }}
                </p>
              </div>
            </div>
            <div
              class="mdl-grid"
              v-if="
                form.typeAccess.indexOf('dc') < 0 &&
                form.typeAccess.indexOf('coworking') < 0
              "
            >
              <div class="mdl-cell mdl-cell--12-col">
                <span
                  class="valueSummaryOne"
                  v-for="(user, k) in form.parking.users"
                  :key="k"
                >
                  <span v-if="user.name"
                    >{{ user.name }} {{ user.surname }};</span
                  >
                  <span v-else-if="user.in_lenel"
                    >{{ user.nome }} {{ user.cognome }};</span
                  >
                  <span v-if="!IsMobile">&nbsp;</span>
                  <span v-else><br /></span>
                </span>
              </div>
            </div>
            <hr
              v-if="
                form.typeAccess.indexOf('dc') < 0 &&
                form.typeAccess.indexOf('coworking') < 0
              "
            />
            <div
              class="mdl-grid"
              v-if="
                form.typeAccess.indexOf('dc') < 0 &&
                form.typeAccess.indexOf('coworking') < 0
              "
            >
              <div
                class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--12-col-phone"
              >
                <h3>{{ $t("accessDc.listCarBtn") }}</h3>
              </div>
            </div>
            <div class="mdl-grid">
              <div class="mdl-cell mdl-cell--12-col">
                <span
                  class="valueSummaryOne"
                  v-for="(car, k) in form.parking.cars"
                  :key="k"
                  >{{ car.name }} ({{ car.license }});&nbsp;</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="btnSubmit && !onlyRead">
        <div class="cdlContentButtonSingle">
          <div
            id="submitCoverageSummary"
            class="cdlButtonSingle"
            @click="submit()"
          >
            {{ $t("accessDc.summary.submit") }}
          </div>
        </div>

        <div v-if="generalError != ''">
          <p class="error" style="text-align: center" v-html="generalError"></p>
        </div>

        <div>
          <p class="deleteOrder" @click="ctrlModalAccessDcRemove()">
            {{ $t("checkCoverage.stepsButtonThree") }}
          </p>
        </div>
      </div>
      <div v-if="onlyRead" style="width: 200px; margin: 0 auto">
        <div class="cdlButton" @click="$router.back()">
          {{ $t("tickets.backToList") }}
        </div>
      </div>
      <MyLoading :viewLoader="btnSubmit" type="" />
    </div>
  </div>

  <ModalAccessDcRemove
    :viewModal="modalAccessDcRemoveView"
    @close="ctrlModalAccessDcRemove"
    @cancel="cancelAccessRequest"
  />

  <ModalAccessDcSuccess
    :viewModal="modalAccessDcSuccess"
    @cancel="cancelAccessRequest"
    :title="'accessDc.modalAccessDcSuccess.title'"
    :sub="'accessDc.modalAccessDcSuccess.sub'"
    :text="'accessDc.modalAccessDcSuccess.text'"
    :btn="'accessDc.modalAccessDcSuccess.btnContinue'"
    :link="'accessDc.modalAccessDcSuccess.btnRemove'"
  />

  <ModalAccessDcSuccess
    :viewModal="modalAccessBioSuccess"
    @cancel="cancelAccessRequest"
    :title="'accessDc.modalAccessBioSuccess.title'"
    :sub="'accessDc.modalAccessBioSuccess.sub'"
    :text="'accessDc.modalAccessBioSuccess.text'"
    :btn="'accessDc.modalAccessBioSuccess.btnContinue'"
    :link="'accessDc.modalAccessBioSuccess.btnRemove'"
  />
</template>

<script>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyStep0 from "@/components/AccessDc/svg/Step0.vue";
import MyStep1 from "@/components/AccessDc/svg/Step1.vue";
import MyStep7 from "@/components/AccessDc/svg/Step7.vue";
import moment from "moment";
import { useUserStore } from "@/stores/UserStore";
import { useAccessDcStore } from "@/stores/AccessDcStore";
import ModalAccessDcRemove from "@/components/AccessDc/ModalAccessDcRemove.vue";
import ModalAccessDcSuccess from "@/components/AccessDc/ModalAccessDcSuccess.vue";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "MyAccessRequestSummary",
  components: {
    MyHeaderPage,
    MyStep0,
    MyStep1,
    MyStep7,
    ModalAccessDcRemove,
    ModalAccessDcSuccess,
    MyLoading,
  },
  data() {
    return {
      form: {},
      modalAccessDcRemoveView: false,
      he: this.getHeaders(),
      modalAccessDcSuccess: false,
      modalAccessBioSuccess: false,
      btnSubmit: true,
      mopTmp: [],
      generalError: "",
      isBio: false,
      users: [],
      usersBio: [],
      steps: [],
      gammaId: 0,
      onlyRead: false,
      usersGCalendar: [],
      IsMobile: this.$IsMobile(),
      allUsers: [],
      allUsersIds: [],
      allUsersNoBio: [],
      allUsersIdsCtrl: [],
      viewWarehouse: true
    };
  },
  methods: {
    getIfCoworking() {
      if (this.form.coworking && this.form.coworking.desks > 0) {
        return true;
      }
      return false;
    },
    getAccessDc() {
      return this.form.dc && this.form.dc.racks.length > 0 ? true : false;
    },
    getIfCar() {
      if (this.form.parking && this.form.parking.cars.length > 0) {
        return true;
      }
      return false;
    },
    editInfo(goTo) {
      localStorage.setItem("cdlAccessDcStepsGoTo", goTo);
      return this.$router.push({
        name: "AccessRequestForm" + this.$i18n.locale,
      });
    },
    getAccessWhen() {
      if (!this.form.dateAccess) {
        return "";
      }
      let nameDay = moment(
        new Date(this.form.dateAccess),
        "YYYY-MM-DD",
        this.getLang(),
        true
      ).format("dddd");
      let date = moment(
        new Date(this.form.dateAccess),
        "YYYY-MM-DD",
        this.getLang(),
        true
      ).format("LL");
      return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
    },
    getLang() {
      let userStore = useUserStore();
      if (userStore.user && userStore.user.preferences) {
        return userStore.user.preferences.language;
      }
      return "it";
    },
    getRefName() {
      if (this.form.dc.ref) {
        return this.form.dc.ref.ref_name + " " + this.form.dc.ref.ref_surname;
      }
      return "";
    },
    getRefPhone() {
      if (this.form.dc.ref) {
        return this.form.dc.ref.ref_phone;
      }
      return "";
    },
    getRefEmail() {
      if (this.form.dc.ref) {
        return this.form.dc.ref.ref_email;
      }
      return "";
    },
    ctrlModalAccessDcRemove() {
      window.scrollTo(0, 0);
      this.modalAccessDcRemoveView = !this.modalAccessDcRemoveView;
      if (this.modalAccessDcRemoveView) {
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
    },
    cancelAccessRequest() {
      this.modalAccessDcSuccess = false;
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      localStorage.removeItem("cdlAccessDc");
      localStorage.removeItem("cdlAccessDcSteps");
      localStorage.removeItem("cdlAccessDcStepsGoTo");
      localStorage.removeItem("accessDc");
      localStorage.removeItem("accessCoWo");
      setTimeout(() => {
        return this.$router.push({
          name: "AccessRequestList" + this.$i18n.locale,
        });
      }, 500);
    },
    submit() {
      this.btnSubmit = false;

      let dc = this.form.typeAccess.indexOf("dc") > -1 ? true : false;
      let coworking =
        this.form.typeAccess.indexOf("coworking") > -1 ? true : false;
      let parking = this.form.typeAccess.indexOf("parking") > -1 ? true : false;

      let allNoBio =
        this.allUsersNoBio.length > 0 && this.usersBio.length == 0
          ? true
          : false;
      let allBio =
        this.allUsersNoBio.length == 0 && this.usersBio.length > 0
          ? true
          : false;
      let mixed =
        this.allUsersNoBio.length > 0 && this.usersBio.length > 0
          ? true
          : false;

      // console.log("NO BIO", this.allUsersNoBio);
      // console.log("BIO", this.usersBio);

      let ticketWithCowo = false;

      let exitSuccess = true;

      let ticket = {};

      // console.log(dc, allNoBio, mixed, this.form.dc.dc_id);

      if (dc && (allNoBio || mixed || this.form.dc.dc_id == 4)) {
        ticket = this.form.dc;
        ticket.dateAccess = this.form.dateAccess;
        ticket.dc = {
          dc_id: ticket.dc_id,
          dc_name: ticket.dc_name,
          dc_address: ticket.address,
        };

        // ticket.apparati = ticket.apparati ? "si" : "no";

        if (parking) {
          ticket.cars = this.form.parking.cars;
        }
        else {
          delete ticket.cars;
        }

        if (ticket.coworking) {
          delete ticket.coworking;
        }

        ticket.users = this.allUsersNoBio;

        // console.log(ticket);

        if (this.form.dc.dc_id == 4) {
          // ROMA
          let userStore = useUserStore();
          ticket.users = [];
          let tmpUser = {};
          let users = this.allUsersNoBio.concat(this.usersBio);
          users.forEach((elm) => {
            tmpUser = {
              name: elm.nome ?? elm.name,
              surname: elm.cognome ?? elm.surname,
              id: elm.address_book_id ?? elm.id,
              email: elm.email,
              is_bio: elm.in_lenel ?? 0,
              company: elm.company ?? "",
              profile_id: userStore.user.account.id,
            };
            ticket.users.push(tmpUser);
          });
        }

        if (coworking) {
          ticketWithCowo = true;
          this.usersGCalendar = this.form.coworking.users;
          ticket.coworking = {
            desks: this.form.coworking.desks,
            fromTime: this.form.coworking.fromTime,
            toTime: this.form.coworking.toTime,
          };
        }

        if (this.mopTmp && this.mopTmp.length > 0) {
          ticket.mop = this.mopTmp[0].zoo_id;
        } else if (
          ticket.mop &&
          Array.isArray(ticket.mop) &&
          this.mopTmp.length == 0
        ) {
          ticket.mop = ticket.mop[0].zoo_id;
        }

        if (!ticket.ref.ref_name) {
          let userStore = useUserStore();
          ticket.ref.ref_name = userStore.user.first_name;
          ticket.ref.ref_surname = userStore.user.last_name;
          ticket.ref.ref_phone = userStore.user.phone;
          ticket.ref.ref_email = userStore.user.email;
        }
        this.exitSuccess = false;
        this.openTicket(ticket, ticketWithCowo, mixed);
        return;
      } else {
        dc = false;
      }

      if (!dc && coworking && allBio) {
        this.exitSuccess = false;
        this.accessOnlyCoworking(coworking);
      }

      if (!dc && coworking && (allNoBio || mixed)) {
        ticket = this.form.dc;
        ticket.dateAccess = this.form.dateAccess;
        ticket.dc = {
          dc_id: ticket.dc_id,
          dc_name: ticket.dc_name,
          dc_address: ticket.address,
        };
        ticket.apparati = "no";
        ticket.warehouse = "no";

        ticket.users = this.allUsersNoBio;

        if (parking) {
          ticket.cars = this.form.parking.cars;
        }

        ticketWithCowo = true;
        ticket.coworking = {
          desks: this.form.coworking.desks,
          fromTime: this.form.coworking.fromTime,
          toTime: this.form.coworking.toTime,
        };

        if (!ticket.ref.ref_name) {
          let userStore = useUserStore();
          ticket.ref.ref_name = userStore.user.first_name;
          ticket.ref.ref_surname = userStore.user.last_name;
          ticket.ref.ref_phone = userStore.user.phone;
          ticket.ref.ref_email = userStore.user.email;
        }

        this.usersGCalendar = this.allUsersNoBio.concat(this.usersBio);
        this.exitSuccess = false;
        this.openTicket(ticket, ticketWithCowo, mixed);
      }

      if (!dc && !coworking && parking && allBio) {
        this.exitSuccess = false;
        this.accessOnlyCoworking();
      }

      if (exitSuccess) {
        window.scrollTo(0, 0);
        this.modalAccessBioSuccess = true;
        this.btnSubmit = true;
        document.getElementsByTagName("body")[0].classList.add("overflow");
        localStorage.removeItem("cdlAccessDc");
        localStorage.removeItem("cdlAccessDcSteps");
        localStorage.removeItem("cdlAccessDcStepsGoTo");
        localStorage.removeItem("accessDc");
        localStorage.removeItem("accessCoWo");
      }
    },
    openTicket(ticket, coworking = false, mixed = false) {
      let timing = 0;
      if (mixed) {
        ticket.all_users = JSON.stringify(this.allUsersIds);
      }
      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "tickets/v1/access",
          ticket,
          { headers: this.he }
        )
        .then(() => {
          if (mixed && coworking) {
            this.form.coworking.users = this.usersBio;
            this.accessOnlyCoworking(coworking, true, ticket );
            timing = 1500;
            return;
          } 
          else if (coworking) {
            this.saveToGCalendar();
          }
          setTimeout(() => {
            window.scrollTo(0, 0);
            localStorage.removeItem("cdlAccessDc");
            localStorage.removeItem("cdlAccessDcSteps");
            localStorage.removeItem("cdlAccessDcStepsGoTo");
            localStorage.removeItem("accessDc");
            localStorage.removeItem("accessCoWo");
            if( ticket.warehouse == 'si' ) {
              localStorage.setItem("DcAccessRemoteHands", 1);
              return this.$router.push({ name: "RemoteHands" });
            }
            this.modalAccessDcSuccess = true;
            document.getElementsByTagName("body")[0].classList.add("overflow");
            this.btnSubmit = true;
          }, timing);
        })
        .catch(function (err) {
          self.generalError = err.response
            ? err.response.data.message
            : err.message;
          self.btnSubmit = true;
        });
    },
    accessOnlyCoworking(coworking = false, fromTicket = false, ticket = {} ) {
      let users = fromTicket
        ? this.usersBio
        : coworking
        ? this.form.coworking.users
        : this.form.parking.users;
      let cars = fromTicket ? [] : this.form.parking.cars;

      let meeting_room_id = 13;
      let typeTemplate = "parking";
      let n_desk = 1;
      if (coworking) {
        typeTemplate = "coworking";
        meeting_room_id = 10;
        n_desk = this.form.coworking.desks;
      }

      let descr = "Questo è un accesso biometrico";

      users.forEach((user, i) => {
        let carJson = {
          id: 0,
          name: "",
          license: "",
        };

        if (cars[i]) {
          carJson = {
            id: cars[i].id,
            name: cars[i].name,
            license: cars[i].license,
          };
        }

        let surname = user.surname ?? user.cognome;
        let name = user.name ?? user.nome;
        let lang = user.language ?? this.getLang();

        let fromTime =
          this.form.coworking && !this.form.coworking.fromTime
            ? this.form.fromTime
            : this.form.coworking.fromTime;
        let toTime =
          this.form.coworking && !this.form.coworking.toTime
            ? this.form.toTime
            : this.form.coworking.toTime;

        let params = {
          id: 0,
          cargo: 0,
          date_start: this.form.dateAccess + " " + fromTime,
          date_end: this.form.dateAccess + " " + toTime,
          user_id: 0,
          meeting_room_id: meeting_room_id,
          request_from: "USR",
          title: "Accesso per " + surname + " " + name,
          description: descr,
          state: true,
          profile_id: this.gammaId,
          address_book_id: user.id ?? 0,
          address_book_cars_id: carJson.id ?? 0,
          prospect: 0,
          username: "assenzio",
          useradmin: "security",
          new_user: JSON.stringify({
            id: user.id ?? 0,
            name: name.replaceAll("'", " "),
            surname: surname.replaceAll("'", " "),
            company: user.company ? user.company.replaceAll("'", " ") : null,
            doc_type: user.doc_type ?? "Carta di identità",
            doc_number: user.doc_number
              ? user.doc_number.replaceAll("'", " ")
              : null,
            doc_expiry: null,
            email: user.email,
            language: lang,
            status: 1,
            type_badge: "customer",
          }),
          new_car: JSON.stringify(carJson),
          isBio: 1,
        };

        this.axios
          .post(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/accesses-booked",
            params,
            { headers: this.he }
          )
          .then((rls) => {
            params = {
              booked_id: rls.data.data.booked_id,
              lang: lang,
              type: typeTemplate,
              n_desk: n_desk,
            };

            this.axios.post(
              this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                "front/v1/send-email",
              params,
              { headers: this.he }
            );

            setTimeout(() => {
              window.scrollTo(0, 0);
              document.getElementsByTagName("body")[0].classList.add("overflow");
              localStorage.removeItem("cdlAccessDc");
              localStorage.removeItem("cdlAccessDcSteps");
              localStorage.removeItem("cdlAccessDcStepsGoTo");
              localStorage.removeItem("accessDc");
              localStorage.removeItem("accessCoWo");
              if( ticket.warehouse && ticket.warehouse == 'si' ) {
                localStorage.setItem("DcAccessRemoteHands", 1);
                return this.$router.push({ name: "RemoteHands" });
              }
              this.modalAccessBioSuccess = true;
              this.btnSubmit = true;
            }, 1000);
          });
      });

      if (meeting_room_id == 10) {
        this.usersGCalendar = users;
        if (fromTicket) {
          this.usersGCalendar = this.allUsersNoBio.concat(this.usersBio);
        }
        this.saveToGCalendar();
      }
    },
    saveToGCalendar() {
      let accessStore = new useAccessDcStore();

      let gCalendar = {
        start_date:
          this.form.dateAccess + " " + this.form.coworking.fromTime ??
          this.form.dc.fromTime,
        end_date:
          this.form.dateAccess + " " + this.form.coworking.toTime ??
          this.form.dc.toTime,
        users: JSON.stringify(this.usersGCalendar),
        room:
          accessStore.maxSeatsDeskCoworking == this.form.coworking.desks
            ? "full"
            : "users",
      };

      this.axios.post(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "front/v1/book-coworking",
        gCalendar,
        { headers: this.he }
      );
    },
    getHeaders() {
      return {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
    },
    downloadFile(fileInput) {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/generic-upload?file_id=" +
            fileInput.zoo_id,
          { headers: this.he }
        )
        .then((response) => {
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + response.data.content; //Image Base64 Goes here
          a.download = response.data.file_name; //File name Here
          a.click(); //Downloaded file
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    initPage() {
      let accessDcStore = useAccessDcStore();

      if (accessDcStore.onlyRead) {
        this.onlyRead = accessDcStore.onlyRead;
      }

      this.form = accessDcStore.form;

      this.isBio = accessDcStore.isBio;

      if( this.form.dc.dc_id == 4 ) {
        // Se è Roma nascondi
        this.viewWarehouse = false;
      }

      // console.log(this.form);

      this.mopTmp = accessDcStore.form.dc.mop ?? [];

      let myMenu = JSON.parse(localStorage.getItem("cdlAccessDcSteps"));

      let ctrlDoubleUsers = myMenu.steps.filter(
        (x) => x == "WhoShouldEnter"
      ).length;

      if (ctrlDoubleUsers > 1) {
        let k = myMenu.steps.indexOf("WhoShouldEnter");
        myMenu.steps.splice(k, 1);
      }

      this.steps = myMenu.steps;

      if (
        this.form.typeAccess.indexOf("dc") > -1 ||
        this.form.typeAccess.indexOf("coworking") > -1
      ) {
        this.form.parking.users = [];
      }

      this.allUsers = this.form.dc.users
        .concat(this.form.coworking.users)
        .concat(this.form.parking.users);

      this.buildUsers();

      // setTimeout(() => {
      //     console.log(this.usersBio);
      //     console.log(this.allUsersNoBio);
      //     console.log(this.form.parking.users);
      // }, 5000);

      if (this.form.coworking.desks > 0 && !this.form.fromTime) {
        this.form.fromTime = this.form.coworking.fromTime;
        this.form.toTime = this.form.coworking.toTime;
      }

    },
    checkIfExist(email) {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/usermail/" +
            email,
          { headers: this.he }
        )
        .then((rls) => {
          return rls;
        })
        .catch(function () {
          return false;
        });
    },
    buildUsers() {
      if( this.onlyRead && !this.allUsers[0] ) {
        return;
      }
      this.allUsers.forEach((elm, i) => {
        if( !elm ) {
          return;
        }
        if (elm.in_lenel) {
          elm.id = elm.address_book_id;

          if (elm.id == 0) {
            //aggiungo in rubrica
            let data = {
              name: elm.name ?? elm.nome,
              surname: elm.surname ?? elm.cognome,
              email: elm.email,
              company: "n.d.",
              doc_type: "Carta di identità",
              doc_number: "AAXXX",
              doc_expiry: null,
              gamma_id: this.gammaId,
              language: this.getLang(),
              is_bio: 1,
            };

            this.axios
              .post(
                this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                  "front/v1/user_new",
                data,
                { headers: this.he }
              )
              .then((rls) => {
                this.usersBio.push(rls.data.data);
                this.allUsers[i].id = rls.data.data.id;
                this.allUsersIds.push({ id: rls.data.data.id });
                this.allUsersIdsCtrl.push(rls.data.data.id);
              });
          } else {
            if (this.allUsersIdsCtrl.indexOf(elm.id) < 0) {
              this.usersBio.push(elm);
              this.allUsersIds.push({ id: elm.id });
              this.allUsersIdsCtrl.push(elm.id);
            }
          }
        } else {
          if (!elm.id) {
            //aggiungo in rubrica
            let check = this.checkIfExist(elm.email);
            if (!check) {
              let data = {
                name: elm.name ?? elm.nome,
                surname: elm.surname ?? elm.cognome,
                email: elm.email,
                company: "n.d.",
                doc_type: "Carta di identità",
                doc_number: "AAXXX",
                doc_expiry: null,
                gamma_id: this.gammaId,
                language: this.getLang(),
              };

              this.axios
                .post(
                  this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                    "front/v1/user_new",
                  data,
                  { headers: this.he }
                )
                .then((rls) => {
                  this.allUsersNoBio.push(rls.data.data);
                  this.allUsersIdsCtrl.push(rls.data.id);
                  this.allUsersIds.push({ id: rls.data.id });
                });
            } else {
              if (this.allUsersIdsCtrl.indexOf(elm.id) < 0) {
                this.allUsersNoBio.push(elm);
                this.allUsersIds.push({ id: elm.id });
                this.allUsersIdsCtrl.push(elm.id);
              }
            }
          } else {
            if (this.allUsersIdsCtrl.indexOf(elm.id) < 0) {
              this.allUsersNoBio.push(elm);
              this.allUsersIds.push({ id: elm.id });
              this.allUsersIdsCtrl.push(elm.id);
            }
          }
        }
      });
    },
  },
  activated() {
    this.initPage();
  },
  mounted() {
    window.scrollTo(0, 0);
    let accessDcStore = useAccessDcStore();
    this.gammaId = accessDcStore.gammaId;
    if (Object.keys(accessDcStore.form.dc).length == 0) {
      return this.$router.push({
        name: "AccessRequestList" + this.$i18n.locale,
      });
    }

    this.initPage();
  },
};
</script>
