<template>
  <div id="MyBanner" v-if="getViewBanner() && text != ''" :class="{ mobile: this.IsMobile }">
    <div class="closeBanner" @click="setViewBanner()"><span class="material-icons">close</span></div>
    <p class="contentText" v-html="text"></p>
  </div>
</template>

<script>
import "@/assets/scss/MyBanner.scss"

export default {
  name: 'MyBanner',
  props: ['text'],
  data() {
    return {
      viewBanner: true,
      IsMobile: this.$IsMobile()
    }
  },
  methods: {
    setViewBanner() {
      this.viewBanner = !this.viewBanner;
    },
    getViewBanner() {
      return this.viewBanner;
    }
  }
}
</script>