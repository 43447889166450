<template>
  <div>
    <!-- Include OptionalFields component -->
    <OptionalFields
      ref="optionalFieldsRef"
      :initialData="fields"
      :dcOptions="dc"
      :roomsOptions="rooms"
      :racksOptions="racks"
      @updateField="handleFieldUpdate"
      @fetchRooms="fetchRooms"
      @fetchRacks="fetchRacks"
      @goNext="goNext"
      @goBack="goBack"
      @cancelOrder="cancelOrder"
    />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import OptionalFields from "@/components/RemoteHands/OptionalFields";

export default {
  name: "InfoGeneral",
  components: {
    OptionalFields,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      fields: {
        // All fields from both components
        timing: "",
        cf_inizio_attivita: "",
        cf_sommario: "",
        datacenter: "",
        cage: "",
        rack: "",
        intervention_type: "",
        attachments: [],
        purchase_order: null,
        description: null,
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
        fromAccessDc: false,
      },
      dc: [],
      rooms: [],
      racks: [],
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
    };
  },
  watch: {
    wizardData: {
      handler(newData) {
        console.log("wizardData changed:", newData);
        this.fields = {
          ...this.fields,
          ...newData,
          ref_name: this.fields.ref_name,
          ref_surname: this.fields.ref_surname,
          ref_phone: this.fields.ref_phone,
          ref_email: this.fields.ref_email,
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleFieldUpdate(updatedFields) {
      Object.assign(this.fields, updatedFields);
      this.submit();
    },
    fetchRooms(datacenter) {
      // Fetch rooms based on selected datacenter
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "assets/v1/dc-rooms/" +
            datacenter.id,
          { headers: this.he }
        )
        .then((response) => {
          this.rooms = response.data;
          this.$refs.optionalFieldsRef.rooms = this.rooms;
        });
    },
    fetchRacks(cage) {
      // Fetch racks based on selected cage (room)
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "assets/v1/racks/" +
            cage.id,
          { headers: this.he }
        )
        .then((response) => {
          this.racks = response.data;
          this.$refs.optionalFieldsRef.racks = this.racks;
        });
    },
    goNext() {
      // Proceed to next step
      this.$emit("goNext");
    },
    goBack() {
      if (this.fields.fromAccessDc) {
        return;
      }
      this.$emit("goBack");
    },
    cancelOrder() {
      this.$emit("close");
    },
    submit() {
      this.$emit("update", this.fields);
    },
    fetchDatacenters() {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "assets/v1/datacenter",
          { headers: this.he }
        )
        .then((response) => {
          this.dc = response.data;
          if (
            this.wizardData.activity_type.name ===
              "Accesso al deposito merce accompagnato da CDLAN" ||
            this.wizardData.activity_type.name ===
              "Access to the warehouse accompanied by CDLAN"
          ) {
            this.dc = this.dc.filter((elm) => elm.id !== 4);
          }
          this.$refs.optionalFieldsRef.dc = this.dc;
        });
    },
  },
  created() {
    // Initialize fields with wizard data
    Object.assign(this.fields, {
      datacenter: this.wizardData.datacenter,
      cage: this.wizardData.cage,
      rack: this.wizardData.rack,
      intervention_type: this.wizardData.intervention_type,
      attachments: this.wizardData.attachments,
      purchase_order: this.wizardData.purchase_order,
      description: this.wizardData.description,
      fromAccessDc: this.wizardData.fromAccessDc,
    });

    this.fetchDatacenters();
  },
};
</script>

<style scoped>
/* Include any necessary styles here */
</style>
