import Voip from "@/pages/Voip.vue";
import IncidentForm from "@/pages/Incident/IncidentForm.vue";
import ChangeRequestForm from "@/pages/ChangeRequest/ChangeRequestForm.vue";
import GenericIncidentForm from "@/pages/Incident/GenericIncidentForm.vue";
import GenericChangeRequestForm from "@/pages/ChangeRequest/GenericChangeRequestForm.vue";
import IncidentDetails from "@/pages/Incident/IncidentDetails.vue";
import HelpRequestDetails from "@/pages/Help/HelpRequestDetails.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";

export function routesVoip() {
  return [
    {
      path: "/voip",
      name: "Voipit",
      component: Voip,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["voip_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/incident/:asset_id/:asset_type",
      name: "VoipIncidentFormit",
      component: IncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/incident/:asset_id/:asset_type",
      name: "VoipIncidentFormen",
      component: IncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/change-request/:asset_id/:asset_type",
      name: "VoipChangeRequestFormit",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["change_request_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/incident/:asset_id/:asset_type",
      name: "VoipChangeRequestFormen",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/incident-generico/:asset_type",
      name: "VoipGenericIncidentFormit",
      component: GenericIncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/generic-incident/:asset_type",
      name: "VoipGenericIncidentFormen",
      component: GenericIncidentForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/change-request-generica/:asset_type",
      name: "VoipGenericChangeRequestFormit",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/generic-change-request/:asset_type",
      name: "VoipGenericChangeRequestFormen",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/lista-incident/dettaglio/:id",
      name: "VoipIncidentDetailit",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/incident-list/detail/:id",
      name: "VoipIncidentDetailen",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/lista-change-request/dettaglio/:id",
      name: "VoipChangeRequestDetailit",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/voip/change-request-list/detail/:id",
      name: "VoipChangeRequestDetailen",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "voip",
            url: "/voip",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ];
}
