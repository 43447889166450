<template>
<svg id="Step7" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <g id="Ellisse_153" data-name="Ellisse 153" fill="#e4effc" class="setCircle">
    <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
    <circle cx="17.5" cy="17.5" r="17" fill="none"/>
  </g>
  <g id="other_houses_black_24dp" transform="translate(8 8)">
    <rect id="Rettangolo_3005" data-name="Rettangolo 3005" width="19" height="19" fill="none"/>
    <path class="setColor" id="desktop_windows_black_24dp" d="M16.455,2H2.545A1.55,1.55,0,0,0,1,3.545v9.273a1.55,1.55,0,0,0,1.545,1.545H7.955v1.545H7.182a.773.773,0,0,0,0,1.545h4.636a.773.773,0,0,0,0-1.545h-.773V14.364h5.409A1.55,1.55,0,0,0,18,12.818V3.545A1.55,1.55,0,0,0,16.455,2Zm-.773,10.818H3.318a.775.775,0,0,1-.773-.773V4.318a.775.775,0,0,1,.773-.773H15.682a.775.775,0,0,1,.773.773v7.727A.775.775,0,0,1,15.682,12.818Z" fill="#b8c4ce" stroke="rgba(0,0,0,0)" stroke-width="1"/>
  </g>
</svg>

</template>

<script>

export default {
  name: 'MyStep7'
}
</script>