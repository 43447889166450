import ShippingList from "@/pages/Shipping/ShippingList.vue";
import ShippingType from "@/pages/Shipping/ShippingType.vue";
import ShippingDeliveries from "@/pages/Shipping/ShippingDeliveries.vue";
import ShippingPickUp from "@/pages/Shipping/ShippingPickUp.vue";
import ShippingDetails from "@/pages/Shipping/ShippingDetails.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesShipping() {
  return [
    {
      path: "/shipping",
      name: "ShippingType",
      component: ShippingType,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shipping",
            url: "",
          },
        ],
        indexRoute: "shipping",
        permissions: ["shipping_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/lista-shipping",
      name: "ShippingListit",
      component: ShippingList,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shippingList",
            url: "",
          },
        ],
        indexRoute: "lista-shipping",
        permissions: ["shipping_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/shipping-list",
      name: "ShippingListen",
      component: ShippingList,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shippingList",
            url: "",
          },
        ],
        indexRoute: "shipping-list",
        permissions: ["shipping_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/lista-shipping/dettaglio/:id",
      name: "ShippingDetailsit",
      component: ShippingDetails,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shippingList",
            url: "/lista-shipping/",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "lista-shipping",
        permissions: ["shipping_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/shipping-list/detail/:id",
      name: "ShippingDetailsen",
      component: ShippingDetails,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shippingList",
            url: "/shipping-list/",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "shipping-list",
        permissions: ["shipping_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/shipping-consegne",
      name: "ShippingDeliveriesit",
      component: ShippingDeliveries,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shipping",
            url: "/shipping",
          },
          {
            name: "shippingDeliveries",
            url: "",
          },
        ],
        indexRoute: "shipping",
        permissions: ["shipping_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/shipping-deliveries",
      name: "ShippingDeliveriesen",
      component: ShippingDeliveries,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shipping",
            url: "/shipping",
          },
          {
            name: "shippingDeliveries",
            url: "",
          },
        ],
        indexRoute: "shipping",
        permissions: ["shipping_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/shipping-ritiri",
      name: "ShippingPickUpit",
      component: ShippingPickUp,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shipping",
            url: "/shipping",
          },
          {
            name: "shippingPickUp",
            url: "",
          },
        ],
        indexRoute: "shipping",
        permissions: ["shipping_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/shipping-pickup",
      name: "ShippingPickUpen",
      component: ShippingPickUp,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "shipping",
            url: "/shipping",
          },
          {
            name: "shippingPickUp",
            url: "",
          },
        ],
        indexRoute: "shipping",
        permissions: ["shipping_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}