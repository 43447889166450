<template>
  <div id="MyModal" class="ModalSolutions" v-if="viewModal">
    <div class="contentModal success" :class="{ open: viewModal }">
      <span class="material-icons closeOrder" @click="closeModal()">close</span>
      <div id="Shipping" class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="typeShipping">
            <div class="card">
              <div class="cardContent">
                <h3>{{ $t("nav.checkCoverage") }}</h3>
                <div class="contentButton">
                  <div
                    class="cdlButton"
                    id="takeShipping"
                    @click="setPage($t('nav.checkCoverageUrl'))"
                    style="width: 87%"
                  >
                    {{ $t("modalSolutions.btn") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="cardContent">
                <h3>Cross Connect</h3>
                <div class="contentButton">
                  <div
                    class="cdlButton"
                    id="takePickUp"
                    @click="setPage('cross-connects')"
                    style="width: 87%"
                  >
                    {{ $t("modalSolutions.btn") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/MyModal.scss";
import "@/assets/scss/Shipping.scss"

export default {
  name: "ModalSolutions",
  props: ["viewModal"],
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    setPage( url ) {
      return this.$router.push(url);
    }
  }
};
</script>
