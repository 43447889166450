<template>
  <div id="ChangeRequest">
    <MyHeaderPage
      :title="$t('ticketsList.ChangeRequest.title')"
      :subtitle="$t('ticketsList.ChangeRequest.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket Incident">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--4-col">
            <TextSearchInput
              @search="this.searchByString"
              @reset="this.resetSearchByString"
            />
          </div>
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--4-offset helpText alignRight"
          >
            <span>{{ $t("help.openTicket") }} 
              <router-link :to="'/' + $t('nav.listLinesUrl')">{{ $t("nav.accessLine") }}</router-link>, 
              <router-link :to="'/' + $t('nav.crossConnectUrl')">{{ $t("nav.crossConnect") }}</router-link>, 
              <router-link :to="'/' + $t('nav.colocationUrl')">{{ $t("nav.colocation") }}</router-link>, 
              <router-link :to="'/cloud'">{{ $t("cloud.title") }}</router-link>,
              <router-link :to="'/voip'">{{ $t("voip.title") }}</router-link>
            </span>
          </div>
        </div>
        <List
          :list="this.items"
          :view-loader="this.viewLoader"
          :view-error="this.viewError"
          :total-count="this.total_count"
          @change-page="this.onChangePage"
          @refresh="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import TextSearchInput from "@/components/Search/TextSearch/TextSearchInput.vue";
import List from "@/components/ChangeRequest/List.vue";
export default {
  name: "ChangeRequestPage",
  components: {
    MyHeaderPage,
    TextSearchInput,
    List,
  },
  data() {
    return {
      items: [],
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      viewLoader: true,
      viewError: false,
      page_size: 10,
      total_page: 1,
      total_count: 0,
      current_page: 0,
      search_by_string_key: "",
    };
  },
  methods: {
    searchByString(keyword) {
      this.search_by_string_key = keyword;
      this.getList();
    },
    resetSearchByString() {
      this.search_by_string_key = "";
      this.getList();
    },
    onChangePage(page) {
      this.getList(page);
    },
    getList() {
      const URL = "tickets/v1/ticket-list";
      const TICKET_TYPE = "ChangeRequest";

      let params = {
        page_size: this.page_size,
        service: TICKET_TYPE,
        page: this.current_page,
      };

      // set loader and hide paginator
      this.viewLoader = false;
      this.total_count = 0;
      const self = this;

      if (this.search_by_string_key.length > 3) {
        params = {
          ...params,
          search_by: this.search_by_string_key,
          filter_by: this.columns,
        };
      }

      this.axios
        .post(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + URL, params, {
          headers: this.he,
        })
        .then((response) => {
          self.items = response.data.data;
          self.total_count = response.data.count;
          self.viewLoader = true;
        })
        .catch(function (err) {
          //TODO: FIX THIS IN BE
          // manage 404 code if no tickets are found
          if (err.response.status === 404) {
            self.viewLoader = true;
            self.items = [];
            return;
          }
          self.viewLoader = true;
          self.viewError = true;
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$IsLoggedIn();
    this.getList();
  },
};
</script>
