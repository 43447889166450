
export function SetNavigator(path, indexRoute = '') {
  let list = "";
  if( indexRoute != '' ) {
    path = indexRoute;
  }
  else {
    path = path.substr(1);
  }
  
  if (!localStorage.getItem("cdlSetups")) {
    list = { mylastUrl: path };
  }
  else {
    list = JSON.parse(localStorage.getItem("cdlSetups"));
    list.mylastUrl = path;
  }
  localStorage.setItem('cdlSetups', JSON.stringify(list));
}