<script>
export default {
  name: "HorisontalArrow"
}
</script>

<template>
  <svg v-if="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="393.421" height="20.387" viewBox="0 0 393.421 20.387">
    <defs>
      <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fae29f"/>
        <stop offset="1" stop-color="#6ed7d3"/>
      </linearGradient>
    </defs>
    <path id="fraccia" d="M306.48,2.126h0a2.122,2.122,0,0,1-2.122,2.122H-69.878A2.122,2.122,0,0,0-72,6.369v7.649a2.122,2.122,0,0,0,2.122,2.122H304.358a2.122,2.122,0,0,1,2.122,2.122,2.121,2.121,0,0,0,3.4,1.693l10.7-8.068a2.122,2.122,0,0,0,0-3.388L309.879.432a2.122,2.122,0,0,0-3.4,1.694" transform="translate(72 -0.001)" fill="url(#linear-gradient)"/>
  </svg>
</template>

<style scoped lang="scss">
.arrow-container {
  display: flex;
  align-items: center;  // Vertically center the arrow
  margin: 0 1rem;  // Adjust margin to position the arrow
}

.arrow-icon {
  width: auto;  // Adjust width as needed
  height: 2rem;  // Adjust height as needed
}
</style>
