<template>
  <div id="Paginator" :class="{ overflow: isAllPage || IsMobile }">
    <div
      @click="this.$emit('prev-page')"
      class="arrow"
      :class="{ disabled: currentPage == 1 }"
    >
      <span class="material-icons"> chevron_left </span>
    </div>
    <div
      v-for="page in totalPage"
      :key="page"
      @click="this.$emit('change-page', page)"
      :class="{ current: currentPage == page, hide: getCtrlPage(page) }"
      class="ctrlPoints"
    >
      <span
        :id="'page-' + page"
        :class="{
          viewPoints: (totalPage > 10 && page != 4) || totalPage <= 10,
          hide: !isAllPage,
        }"
        >{{ page }}</span
      >
      <span
        class="hide ctrlMorePage"
        :class="{ viewPoints: totalPage > 10 && page == 4 && !isAllPage }"
        @click="viewAllPage()"
        >...</span
      >
    </div>
    <div
      @click="this.$emit('next-page')"
      class="arrow"
      :class="{ disabled: currentPage == totalPage }"
    >
      <span class="material-icons"> chevron_right </span>
    </div>
  </div>
</template>

<style>
#Paginator {
  margin-top: 40px;
}
</style>

<script>
// import "@/assets/scss/Solutions.scss";
import "@/assets/scss/Paginator.scss";
export default {
  name: "TablePaginator",
  props: {
    totalPage: Number,
    currentPage: Number,
  },
  data() {
    return {
      isAllPage: false,
      IsMobile: this.$IsMobile()
    };
  },
  methods: {
    getCtrlPage(page) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if( this.currentPage >= 4 && this.currentPage < this.totalPage - 3 ) {
        this.viewAllPage();
        return;
      }

      if (
        this.totalPage > 10 &&
        page > 4 &&
        page < this.totalPage - 3 &&
        !this.isAllPage
      ) {
        return "hide";
      }
    },
    viewAllPage() {
      this.isAllPage = true;
      let elms = document.getElementsByClassName("ctrlPoints");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("hide");
      });
    },
  },
};
</script>
