<!-- CrossCard.vue -->
<template>
  <div :class="isMobile ? `card-mobile card-second` : `card card-second`">
    <div class="radio-label-container">
      <input
        type="radio"
        :id="id"
        :name="name"
        :value="value"
        class="radioBtn"
        @change="handleRadioSelect"
      />
      <div class="radio-text-alignement">
        <label :for="id" class="cardTitle">{{ label }}</label>
        <div class="cardSubtitle">
          <span>{{ subtitle }}</span
          ><br />
          <span>{{ address }}</span>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div>{{ description }}</div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, getCurrentInstance, ref } from "vue";

const props = defineProps({
  id: String,
  name: String,
  value: String,
  label: String,
  subtitle: String,
  address: String,
  description: String,
});
const isMobile = ref(false);
const { config } = getCurrentInstance().appContext;

isMobile.value = config.globalProperties.$IsMobile();

const emit = defineEmits(["radio-selected"]);

const handleRadioSelect = () => {
  emit("radio-selected", props.value);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";

.radio-text-alignement {
  display: flex;
  flex-direction: column;
}
</style>
