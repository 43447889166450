<template>
  <div id="MyModal" v-if="viewModal">
    <div class="contentModal open success"><span class="material-icons closeOrder" id="closeModalX"
        @click="closeModal()">close</span>
      <h3>{{ $t(title) }}</h3>
      <p class="sub">{{ $t(sub) }}</p>
      <p class="text">{{ $t(text) }}</p>
      <div class="contentButton"><button id="btnModal" class="cdlButtonGreen" @click="goToList()">{{
        $t(btn) }}</button><br><br>
        <div>
          <p class="deleteOrder" id="deleteOrder" @click="closeModal()">{{ $t(link)
          }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import "@/assets/scss/MyModal.scss"

export default {
  name: 'ModalAccessDcSuccess',
  props: ['viewModal', 'title', 'sub', 'text', 'btn', 'link'],
  data() {
    return {

    }
  },
  methods: {
    goToList() {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      // setTimeout(() => {
      this.$emit('cancel');
      // }, 500);
    },
    closeModal() {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      return this.$router.push({ name: 'AccessRequest' + this.$i18n.locale });
    }
  }
}
</script>