<template>
  <div id="OrderNetworkCoverage" class="accessRequest">
    <div class="contentSteps">
      <div class="steps" style="width: auto">
        <div
          class="step"
          :class="{
            active: getStep() == key + 1,
            complete: getStep() > key + 1,
          }"
          v-for="(listStep, key) in getSteps()"
          :key="key"
          @click="getStep() > key + 1 ? setStep(key + 1) : null"
        >
          <div>
            <component :is="listStep.icon"></component>
          </div>
          <div>
            <p class="nameStep">{{ key + 1 }}. {{ $t(listStep.title) }}</p>
            <p class="statusStep" v-if="getStep() == key + 1 && false">
              {{ $t("checkCoverage.statusStep1") }}
            </p>
            <p class="statusStep" v-else-if="getStep() > key + 1 && false">
              {{ $t("checkCoverage.statusStep2") }}
            </p>
            <p class="statusStep" v-else-if="getStep() < key + 1 && false">
              {{ $t("checkCoverage.statusStep3") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="contentForm">
      <div class="contentPrimary contentPrimaryMobile pink" v-if="false">
        <h3 @click="openInfoMobile()">
          <img src="aaa.jpg" alt="icon" width="25" />
          {{ $t("checkCoverage.detailLabelOffer") }}
          <span class="material-icons" v-if="!infoMobile">expand_more</span>
          <span class="material-icons" v-if="infoMobile">expand_less</span>
        </h3>
        <div v-if="infoMobile">
          <table class="contentInfoMobile">
            <tr>
              <td><img src="aaaa.png" alt="icon" width="25" /></td>
              <td>
                <span class="labelS">{{
                  $t("checkCoverage.detailLabelTech")
                }}</span>
                <span class="textS">###</span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <span class="labelS">Carrier</span>
                <span class="textS">{{ kit.carrier }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="material-icons locationIcon">location_on</span>
              </td>
              <td>
                <span class="labelS">{{
                  $t("checkCoverage.detailLabelAddress")
                }}</span>
                <span class="textS">####</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="contentPrimary" v-if="!IsMobile" style="padding: 0">
        <div class="contentAccess pink">
          <p class="text">
            {{ $t("accessDc.detailLabelDc") }}
            <span class="material-icons floatRight viewDc" @click="setViewDc()"
              >expand_more</span
            >
          </p>
          <div>
            <hr />
            <div class="contentFlex">
              <div>
                <component :is="'MyStep0'"></component>
              </div>
              <div class="contentElmPrimary">
                <small class="label">{{ $t("accessDc.datacenter") }}</small>
                <p class="text">
                  {{
                    accessStore.dc && accessStore.dc.dc_name
                      ? accessStore.dc.dc_name.replace("Data Center ", "")
                      : null
                  }}
                </p>
              </div>
            </div>
            <div v-if="accessStore.typeAccessName.length > 0">
              <hr />
              <div class="contentFlex">
                <div>
                  <component :is="'MyStep1'"></component>
                </div>
                <div class="contentElmPrimary">
                  <small class="label">{{
                    $t("accessDc.typeAccesslabel")
                  }}</small>
                  <p class="text">
                    <span
                      v-for="(name, k) in accessStore.typeAccessName"
                      :key="k"
                      >{{ $t(name) }}<br
                    /></span>
                  </p>
                </div>
              </div>
            </div>
            <div v-if="accessStore.accessWhen != '' && accessStore.dc.fromTime">
              <hr />
              <div class="contentFlex">
                <div>
                  <component :is="'MyStep2'"></component>
                </div>
                <div class="contentElmPrimary">
                  <small class="label">{{ $t("accessDc.titleStep2") }}</small>
                  <p class="text">
                    {{ accessStore.accessWhen }}<br /><span>{{
                      $t("accessDc.accessWhenTime", {
                        from: accessStore.dc.fromTime,
                        to: accessStore.dc.toTime,
                      })
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div v-if="accessStore.racksHtml != ''">
              <hr />
              <div class="contentFlex">
                <div>
                  <component :is="'MyStep3'"></component>
                </div>
                <div class="contentElmPrimary">
                  <small class="label">{{ $t("accessDc.titleStep3") }}</small>
                  <div v-html="accessStore.racksHtml"></div>
                </div>
              </div>
            </div>
            <div v-if="accessStore.dc.users.length > 0">
              <hr />
              <div class="contentFlex">
                <div>
                  <component :is="'MyStep4'"></component>
                </div>
                <div class="contentElmPrimary">
                  <small class="label">{{ $t("accessDc.titleStep4") }}</small>
                  <div
                    v-for="(user, index) in accessStore.dc.users"
                    :key="index"
                    class="text"
                  >
                    <span v-if="user.name"
                      >{{ user.name }} {{ user.surname }}</span
                    >
                    <span v-else-if="user.in_lenel"
                      >{{ user.nome }} {{ user.cognome }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div v-if="accessStore.dc.ref.ref_name">
              <hr />
              <div class="contentFlex">
                <div>
                  <component :is="'MyStep5'"></component>
                </div>
                <div class="contentElmPrimary">
                  <small class="label">{{ $t("accessDc.titleStep5") }}</small>
                  <div class="text">
                    {{ accessStore.dc.ref.ref_name }}
                    {{ accessStore.dc.ref.ref_surname }}
                  </div>
                  <div class="text">{{ accessStore.dc.ref.ref_phone }}</div>
                  <div class="text">{{ accessStore.dc.ref.ref_email }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br />
        <!-- Coworking -->
        <div
          class="contentAccess yellow"
          v-if="accessStore.coworking && accessStore.coworking.desks > 0"
        >
          <p class="text">
            {{ $t("accessDc.detailLabelRequest") }}
            <span
              class="material-icons floatRight viewCoworking"
              v-if="accessStore.parking.cars.length > 0"
              @click="setViewCoworking()"
              >expand_more</span
            >
          </p>
          <div v-if="accessStore.parking.cars.length == 0 || viewCoworking">
            <hr />
            <div class="contentFlex">
              <div>
                <component :is="'MyStep7'"></component>
              </div>
              <div class="contentElmPrimary">
                <small class="label">{{ $t("accessDc.nDeskLabel") }}</small>
                <div class="text">{{ accessStore.coworking.desks }}</div>
              </div>
            </div>
            <hr />
            <div class="contentFlex">
              <div>
                <component :is="'MyStep2'"></component>
              </div>
              <div class="contentElmPrimary">
                <small class="label">{{ $t("accessDc.whenDeskLabel") }}</small>
                <div class="text">
                  {{ accessStore.accessWhen }}<br /><span>{{
                    $t("accessDc.accessWhenTime", {
                      from: accessStore.coworking.fromTime,
                      to: accessStore.coworking.toTime,
                    })
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="accessStore.coworking.users.length > 0">
              <hr />
              <div class="contentFlex">
                <div>
                  <component :is="'MyStep4'"></component>
                </div>
                <div class="contentElmPrimary">
                  <small class="label">{{ $t("accessDc.titleStep4") }}</small>
                  <div
                    v-for="(user, index) in accessStore.coworking.users"
                    :key="index"
                    class="text"
                  >
                    <span v-if="user.name"
                      >{{ user.name }} {{ user.surname }}</span
                    >
                    <span v-else-if="user.in_lenel"
                      >{{ user.nome }} {{ user.cognome }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br />
        <!-- Cars -->
        <div class="contentAccess orange" v-if="accessStore.parking.length > 0">
          <p class="text">
            {{ $t("accessDc.detailLabelRequestCars") }}
          </p>
          <div>
            <hr />
            <div class="contentFlex">
              <div>
                <component :is="'MyStep0'"></component>
              </div>
              <div class="contentElmPrimary">
                <small class="label">{{ $t("accessDc.datacenter") }}</small>
                <p class="text">{{ form.dc ? form.dc.dc_name : null }}</p>
              </div>
            </div>
            <hr />
            <div class="contentFlex">
              <div>
                <component :is="'MyStep1'"></component>
              </div>
              <div class="contentElmPrimary">
                <small class="label">{{
                  $t("accessDc.typeAccesslabel")
                }}</small>
                <p class="text">{{ $t("accessDc.accessTypeThree") }}</p>
              </div>
            </div>
            <hr />
            <div class="contentFlex">
              <div>
                <component :is="'MyStep8'"></component>
              </div>
              <div class="contentElmPrimary">
                <small class="label">{{ $t("accessDc.carSelected") }}</small>
                <p
                  class="text"
                  v-for="(car, carKey) in accessStore.parking.cars"
                  :key="carKey"
                >
                  {{ car.name }} ({{ car.license }})
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentSecondary">
        <keep-alive>
          <component
            :is="steps[step - 1]"
            @goBack="goBack"
            @goNext="goNext"
            @close="ctrlModalAccessDcRemove"
            :wizard-data="accessStore.all"
            :step-data="step"
            @update="update"
          ></component>
        </keep-alive>
      </div>

      <ModalAccessDcRemove
        :viewModal="modalAccessDcRemoveView"
        @close="ctrlModalAccessDcRemove"
        @cancel="cancelAccessRequest"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useAccessDcStore } from "@/stores/AccessDcStore";

let accessStore = computed(() => {
  let accessDcStore = useAccessDcStore();
  let htmlRacks = "";
  if (accessDcStore.form.dc.racks && accessDcStore.form.dc.racks.length > 0) {
    let roomName = "SALA";
    if (accessDcStore.lang != "it") {
      roomName = "ROOM";
    }
    accessDcStore.form.dc.racks.forEach((elm) => {
      htmlRacks +=
        '<p class="text">' + roomName + " " + elm.room.name + " <span>";
      elm.racks.forEach((rack) => {
        htmlRacks += "Rack " + rack.name + ";";
      });
      htmlRacks += "</span></p>";
    });
  }

  accessDcStore.getGammaId();

  return {
    all: accessDcStore,
    typeAccessName: accessDcStore.getTypeAccessName(),
    accessWhen: accessDcStore.getAccessWhen(),
    dc: accessDcStore.form.dc,
    coworking: accessDcStore.form.coworking,
    parking: accessDcStore.form.parking,
    racksHtml: htmlRacks,
    dateAccess: accessDcStore.dateAccess,
  };
});
</script>

<script>
import MyStep0 from "@/components/AccessDc/svg/Step0.vue";
import MyStep1 from "@/components/AccessDc/svg/Step1.vue";
import MyStep2 from "@/components/AccessDc/svg/Step2.vue";
import MyStep3 from "@/components/AccessDc/svg/Step3.vue";
import MyStep4 from "@/components/AccessDc/svg/Step4.vue";
import MyStep5 from "@/components/AccessDc/svg/Step5.vue";
import MyStep6 from "@/components/AccessDc/svg/Step6.vue";
import MyStep7 from "@/components/AccessDc/svg/Step7.vue";
import MyStep8 from "@/components/AccessDc/svg/Step8.vue";

import AccessType from "@/components/AccessDc/AccessType.vue";
import When from "@/components/AccessDc/When.vue";
import WhereAndWhat from "@/components/AccessDc/WhereAndWhat.vue";
import WhoShouldEnter from "@/components/AccessDc/WhoShouldEnter.vue";
import DeskAndHours from "@/components/AccessDc/DeskAndHours.vue";
import BookParking from "@/components/AccessDc/BookParking.vue";

import "@/assets/scss/OrderNetworkCoverage.scss";
import "@/assets/scss/AccessRequest.scss";

import ModalAccessDcRemove from "@/components/AccessDc/ModalAccessDcRemove.vue";

export default {
  name: "MyAccessRequestForm",
  props: ["openModal"],
  components: {
    MyStep0,
    MyStep1,
    MyStep2,
    MyStep3,
    MyStep4,
    MyStep5,
    MyStep6,
    MyStep7,
    MyStep8,
    AccessType,
    When,
    WhereAndWhat,
    WhoShouldEnter,
    DeskAndHours,
    BookParking,
    ModalAccessDcRemove,
  },
  data() {
    return {
      step: 1,
      listSteps: [],
      steps: [],
      listStepsScenarioAll: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep3",
          title: "accessDc.titleStep3", // Dove e Cosa
        },
        {
          icon: "MyStep7",
          title: "accessDc.titleStep7", // Desk e Orari
        },
        {
          icon: "MyStep8",
          title: "accessDc.titleStep8",
        },
      ],
      listComponentScenarioAll: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
        "WhereAndWhat", // Dove e Cosa
        "DeskAndHours", // Desk e Orari
        "BookParking",
      ],
      listStepsScenarioCoworkingParking: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep7",
          title: "accessDc.titleStep7", // Desk e Orari
        },
        {
          icon: "MyStep8",
          title: "accessDc.titleStep8",
        },
      ],
      listComponentScenarioCoworkingParking: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
        "DeskAndHours", // Desk e Orari
        "BookParking",
      ],
      listStepsScenarioDcCoworking: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep3",
          title: "accessDc.titleStep3", // Dove e Cosa
        },
        {
          icon: "MyStep7",
          title: "accessDc.titleStep7", // Desk e Orari
        },
      ],
      listComponentScenarioDcCoworking: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
        "WhereAndWhat", // Dove e Cosa
        "DeskAndHours", // Desk e Orari
      ],
      listStepsScenarioDcParking: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep3",
          title: "accessDc.titleStep3", // Dove e Cosa
        },
        {
          icon: "MyStep8",
          title: "accessDc.titleStep8",
        },
      ],
      listComponentScenarioDcParking: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
        "WhereAndWhat", // Dove e Cosa
        "BookParking",
      ],
      listStepsScenarioDc: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep3",
          title: "accessDc.titleStep3", // Dove e Cosa
        },
      ],
      listComponentScenarioDc: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
        "WhereAndWhat", // Dove e Cosa
      ],
      listStepsScenarioCoworking: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep7",
          title: "accessDc.titleStep7", // Desk e Orari
        },
      ],
      listComponentScenarioCoworking: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
        "DeskAndHours", // Desk e Orari
      ],
      listStepsScenarioParking: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep8",
          title: "accessDc.titleStep8",
        },
      ],
      listComponentScenarioParking: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
        "BookParking",
      ],
      listStepsScenarioRoma: [
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
        {
          icon: "MyStep3",
          title: "accessDc.titleStep3", // Dove e Cosa
        },
      ],
      listComponentScenarioRoma: [
        "When", // Quando
        "WhereAndWhat", // Dove e Cosa
      ],
      listStepsC21: [
        {
          icon: "MyStep1",
          title: "accessDc.titleStep1", // Tipo di ingresso
        },
        {
          icon: "MyStep2",
          title: "accessDc.titleStep2", // Quando
        },
      ],
      stepsC21: [
        "AccessType", // Tipo di ingresso
        "When", // Quando
      ],
      form: {},
      IsMobile: this.$IsMobile(),
      infoMobile: false,
      viewDc: false,
      modalAccessDcRemoveView: false,
      viewCoworking: false,
      accessOnlyCar: false,
      bio: false,
      noBio: false,
    };
  },
  methods: {
    goBack() {
      if (this.step == 1) {
        return this.$router.push({ name: "AccessRequest" + this.$i18n.locale });
      }
      this.step--;
      window.scrollTo(0, 0);
    },
    goNext(goSummary = null) {
      let IbuildForm = this.steps[this.step - 1] == "AccessType";

      let accessStore = useAccessDcStore();

      if (goSummary.type) {
        this.step = 1;
        accessStore.steps = this.step;
        IbuildForm = true;
        accessStore.form.typeAccess = [goSummary.type, "parking"];
      }

      if (IbuildForm) {
        this.buildForm();
      }

      this.step++;
      accessStore.steps = this.step;

      window.scrollTo(0, 0);
      if (goSummary.goSummary) {
        setTimeout(() => {
          accessStore.isCompleted = true;
          return this.$router.push({
            name: "AccessRequestSummary" + this.$i18n.locale,
          });
        }, 200);
      }

      if (this.step > this.listSteps.length) {
        localStorage.setItem(
          "cdlAccessDc",
          JSON.stringify({ accessDc: accessStore.form })
        );
        accessStore.isCompleted = true;
        return this.$router.push({
          name: "AccessRequestSummary" + this.$i18n.locale,
        });
      }
    },
    getStep() {
      return this.step;
    },
    getSteps() {
      return this.listSteps;
    },
    cancelAccessRequest() {
      this.ctrlModalAccessDcRemove();
      localStorage.removeItem("cdlAccessDc");
      localStorage.removeItem("cdlAccessDcSteps");
      localStorage.removeItem("cdlAccessDcStepsGoTo");
      localStorage.removeItem("accessDc");
      localStorage.removeItem("accessCoWo");
      return this.$router.push({
        name: "AccessRequestList" + this.$i18n.locale,
      });
    },
    openInfoMobile() {
      this.infoMobile = !this.infoMobile;
    },
    setStep(value) {
      this.step = value;
    },
    setViewDc() {
      this.viewDc = !this.viewDc;
      document.getElementsByClassName("viewDc")[0].classList.remove("rotate");
      if (this.viewDc) {
        document.getElementsByClassName("viewDc")[0].classList.add("rotate");
      }
    },
    setViewCoworking() {
      this.viewCoworking = !this.viewCoworking;
      document
        .getElementsByClassName("viewCoworking")[0]
        .classList.remove("rotate");
      if (this.viewCoworking) {
        document
          .getElementsByClassName("viewCoworking")[0]
          .classList.add("rotate");
      }
    },
    buildForm() {
      let accessStore = useAccessDcStore();

      if (accessStore.form.typeAccess.length == 0) {
        if (accessStore.form.dc.dc_id == 7) {
          // MILANO
          this.listSteps = this.listStepsC21;
          this.steps = this.stepsC21;
        }

        if (accessStore.form.dc.dc_id == 4) {
          // ROMA
          this.listSteps = this.listStepsScenarioRoma;
          this.steps = this.listComponentScenarioRoma;
          accessStore.form.typeAccess.push("dc");
        }
      }

      if (
        accessStore.form.typeAccess.length > 0 &&
        accessStore.form.dc.dc_id == 7
      ) {
        //   // Ho scelto i tipi di ingresso

        if (accessStore.form.typeAccess.length == 3) {
          this.steps = this.listComponentScenarioAll;
          this.listSteps = this.listStepsScenarioAll;
        } else if (
          accessStore.form.typeAccess.indexOf("coworking") > -1 &&
          accessStore.form.typeAccess.indexOf("parking") > -1
        ) {
          // Coworking & Parking
          this.steps = this.listComponentScenarioCoworkingParking;
          this.listSteps = this.listStepsScenarioCoworkingParking;
        } else if (
          accessStore.form.typeAccess.indexOf("coworking") > -1 &&
          accessStore.form.typeAccess.indexOf("dc") > -1
        ) {
          // DC & Coworking
          this.steps = this.listComponentScenarioDcCoworking;
          this.listSteps = this.listStepsScenarioDcCoworking;
        } else if (
          accessStore.form.typeAccess.indexOf("parking") > -1 &&
          accessStore.form.typeAccess.indexOf("dc") > -1
        ) {
          // DC & Parking
          this.steps = this.listComponentScenarioDcParking;
          this.listSteps = this.listStepsScenarioDcParking;
        } else if (accessStore.form.typeAccess.indexOf("dc") > -1) {
          // DC
          this.steps = this.listComponentScenarioDc;
          this.listSteps = this.listStepsScenarioDc;
        } else if (accessStore.form.typeAccess.indexOf("coworking") > -1) {
          // Coworking
          this.steps = this.listComponentScenarioCoworking;
          this.listSteps = this.listStepsScenarioCoworking;
        } else if (accessStore.form.typeAccess.indexOf("parking") > -1) {
          // Parking
          this.steps = this.listComponentScenarioParking;
          this.listSteps = this.listStepsScenarioParking;
        }
      }

      // if( accessStore.form.typeAccess.length > 0 && accessStore.form.dc.dc_id != 7 ) {

      // }

      this.saveListSteps();
    },
    saveListSteps() {
      localStorage.setItem(
        "cdlAccessDcSteps",
        JSON.stringify({ steps: this.steps, listSteps: this.listSteps })
      );
    },
    ctrlModalAccessDcRemove() {
      window.scrollTo(0, 0);
      this.modalAccessDcRemoveView = !this.modalAccessDcRemoveView;
      if (this.modalAccessDcRemoveView) {
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
    },
    update() {
      let accessStore = new useAccessDcStore();

      Object.assign(this.form, accessStore.form);

      if (localStorage.getItem("cdlAccessDc")) {
        localStorage.setItem(
          "cdlAccessDc",
          JSON.stringify({ accessDc: accessStore.form })
        );
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    let accessStore = useAccessDcStore();
    accessStore.lang = this.$i18n.locale;
    this.form = accessStore.form;

    if (localStorage.getItem("cdlAccessDcStepsGoTo")) {
      if (!this.form.dateAccess) {
        accessStore.isCompleted = true;
        return this.$router.push({
          name: "AccessRequestSummary" + this.$i18n.locale,
        });
      }
      let myMenu = JSON.parse(localStorage.getItem("cdlAccessDcSteps"));
      this.steps = myMenu.steps;
      this.listSteps = myMenu.listSteps;
      this.step =
        myMenu.steps.indexOf(localStorage.getItem("cdlAccessDcStepsGoTo")) + 1;
    } else {
      if (!accessStore.form.dc.dc_id) {
        return this.$router.push({ name: "AccessRequest" + this.$i18n.locale });
      } else {
        this.step = accessStore.steps;
      }

      this.buildForm();

      if (document.getElementsByTagName("body")[0].classList.contains("overflow")) {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }

    }
  },
};
</script>
