import Incident from "@/pages/Incident/Incident.vue";
import ChangeRequest from "@/pages/ChangeRequest/ChangeRequest.vue";
import IncidentDetails from "@/pages/Incident/IncidentDetails.vue";
import HelpRequestDetails from "@/pages/Help/HelpRequestDetails.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesSupports() {
  return [
    {
      path: "/report/lista-incident",
      name: "ReportIncidentListit",
      component: Incident,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "incidentList",
            url: "",
          },
        ],
        indexRoute: "report/lista-incident",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/report/incident-list",
      name: "ReportIncidentListen",
      component: Incident,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "incidentList",
            url: "",
          },
        ],
        indexRoute: "report/incident-list",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/report/lista-change-request",
      name: "ReportChangeRequestListit",
      component: ChangeRequest,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "changeRequestList",
            url: "",
          },
        ],
        indexRoute: "lista-change-request",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/report/change-request-list",
      name: "ReportChangeRequestListen",
      component: ChangeRequest,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "changeRequestList",
            url: "",
          },
        ],
        indexRoute: "change-request-list",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/report/lista-incident/dettaglio/:id",
      name: "ReportIncidentDetailit",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "incidentList",
            url: "/report/lista-incident",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "report/lista-incident",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/report/incident-list/detail/:id",
      name: "ReportIncidentDetailen",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "incident",
            url: "/report/incident-list",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "report/lista-incident",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/report/lista-change-request/dettaglio/:id",
      name: "ReportChangeRequestDetailit",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "changeRequestList",
            url: "/report/lista-change-request",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "voip",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/report/change-request-list/detail/:id",
      name: "ReportChangeRequestDetailen",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "reporting",
            url: "",
          },
          {
            name: "requestSupport",
            url: "",
          },
          {
            name: "changeRequestList",
            url: "/report/lista-change-request",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "cloud",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}