<template>
  <div class="card mdl-grid">
    <div class="label-container mdl-cell mdl-cell--12-col">
      <label for="C21" class="cardTitle mt-3">A - END</label>
    </div>
    <div class="divider mdl-cell mdl-cell--12-col"></div>
    <div class="mdl-grid" style="padding: 0">
      <div class="field mdl-cell mdl-cell--12-col sm:mdl-cell--6-col">
        <div class="field-title second">{{ $t("crossConnects.place") }}*</div>
        <select
          name="room-option-a"
          id="room-option-a"
          v-model="localFormContent.cage"
          class="form-element select-grey"
          @change="updateRoom"
        >
          <option value="Seleziona" selected>
            {{ $t("crossConnects.select") }}
          </option>
          <option
            v-for="room in selectedDatacenterRoomsRA"
            :key="room.id"
            :value="room.id"
          >
            {{ room.name }}
          </option>
        </select>
        <div
          v-if="validationInitiated && !localFormContent.cage"
          class="error-message"
        >
          <span>{{ $t("crossConnects.requiredRoom") }}</span>
        </div>
      </div>
      <div class="field mdl-cell mdl-cell--12-col sm:mdl-cell--6-col">
        <div class="field-title second">Rack*</div>
        <select
          name="rack-option-a"
          id="rack-option-a"
          v-model="localFormContent.rack"
          class="form-element select-grey"
          @change="updateRack"
        >
          <option value="Seleziona">{{ $t("crossConnects.select") }}</option>
          <option v-for="rack in racks" :key="rack.id" :value="rack.id">
            {{ rack.name }}
          </option>
        </select>
        <div
          v-if="validationInitiated && !localFormContent.rack"
          class="error-message"
        >
          <span>{{ $t("crossConnects.requiredRack") }}</span>
        </div>
      </div>
      <div class="field mdl-cell mdl-cell--12-col">
        <div class="field-title second">
          {{ $t("crossConnects.deviceAndPort") }}
        </div>
        <input
          type="text"
          name="device-port-option-a"
          v-model="localFormContent.device_port"
          id="device-port-option-a"
          class="form-element"
        />
      </div>
      <div class="field mdl-cell mdl-cell--12-col">
        <div class="field-title second">
          {{ $t("crossConnects.patchPannelMMR") }}
        </div>
        <input
          type="text"
          name="mmr-option-a"
          v-model="localFormContent.patch_panel_to_mmr"
          id="mmr-option-a"
          class="form-element"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";

const datacenterStore = useDatacenterStore();
const props = defineProps({
  formContent: Object,
  selectedDatacenterRoomsRA: Array,
  validationInitiated: Boolean,
  redundant: Boolean,
});
const localFormContent = ref(props.formContent);
let racks = ref([]);

onMounted(() => {
  console.log(racks.value);
  console.log("localFormContent", localFormContent);
  if (props.formContent.rack) {
    datacenterStore.fetchRacksA(props.formContent.cage).then((res) => {
      racks.value = res.data;
    });
  }
});

const updateRoom = (event) => {
  datacenterStore.fetchRacksA(event.target.value).then((res) => {
    racks.value = res.data;
  });
  localFormContent.value.rack = "";
  if (!props.redundant) {
    datacenterStore.roomAName = event.target.selectedOptions[0].label;
  } else {
    datacenterStore.roomANameR = event.target.selectedOptions[0].label;
  }
};

const updateRack = (event) => {
  if (!props.redundant) {
    datacenterStore.rackAName = event.target.selectedOptions[0].label;
  } else {
    datacenterStore.rackANameR = event.target.selectedOptions[0].label;
  }
};
</script>
