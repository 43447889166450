import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/pages/HomePage.vue";
import MyLogin from "@/pages/MyLogin.vue";
import Cart from "@/pages/Cart.vue";
import NoPermissionPage from "@/pages/NoPermission.vue";
import { routesNetworkCoverage } from "@/router/solutions/NetworkGenerate";
import { routesCrossConnects } from "@/router/solutions/CrossConnects";
import { routesColocation } from "@/router/solutions/Colocation";
import { routesActiveLines } from "@/router/solutions/ActiveLines";
import { routesCloud } from "@/router/solutions/Cloud";
import { routesVoip } from "@/router/solutions/Voip";

import { routesRemoteHands } from "@/router/services/RemoteHands";
import { routesShipping } from "@/router/services/Shipping";
import { routesAccessDC } from "@/router/services/AccessDC";

import { routesOrders } from "@/router/management/Orders";
import { routesInvoices } from "@/router/management/Invoices";

import { routesSupports } from "@/router/report/Supports";
import { routesSolutionsReport } from "@/router/report/SolutionsReport";

import { routesUsers } from "@/router/settings/Users";

import { routesPartners } from "@/router/partners/Partners";

import NotFound404 from "@/pages/NotFound404.vue";

import Fonts from "@/pages/Fonts.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      breadcrumbs: [],
      indexRoute: "",
      permissions: [],
    },
    beforeEnter: [createBreadCrumbs],
  },
  {
    path: "/login",
    name: "MyLogin",
    component: MyLogin,
  },
  {
    path: "/carrello",
    name: "CartPageit",
    component: Cart,
    meta: {
      breadcrumbs: [
        {
          name: "cart",
          url: "",
        },
      ],
      indexRoute: "carrello",
      permissions: ["verifica_copertura", "cross_connect_nuovo"],
    },
    beforeEnter: [createBreadCrumbs, setNavigator],
  },
  {
    path: "/cart",
    name: "CartPageen",
    component: Cart,
    meta: {
      breadcrumbs: [
        {
          name: "cart",
          url: "",
        },
      ],
      indexRoute: "cart",
      permissions: ["verifica_copertura", "cross_connect_nuovo"],
    },
    beforeEnter: [createBreadCrumbs, setNavigator],
  },
  {
    path: "/fonts",
    name: "CdlFonts",
    component: Fonts,
    meta: {
      breadcrumbs: [],
      indexRoute: "fonts",
      permissions: [],
    },
    beforeEnter: [createBreadCrumbs, setNavigator],
  },
  {
    path: "/no-permission",
    name: "NoPermission",
    component: NoPermissionPage,
    meta: {
      breadcrumbs: [],
      indexRoute: "no-permission",
      permissions: [],
    },
    beforeEnter: [createBreadCrumbs],
  },
  { path: "/:pathMatch(.*)*", component: NotFound404 },
]
  .concat(routesNetworkCoverage())
  .concat(routesRemoteHands())
  .concat(routesShipping())
  .concat(routesAccessDC())
  .concat(routesCrossConnects())
  .concat(routesColocation())
  .concat(routesActiveLines())
  .concat(routesOrders())
  .concat(routesInvoices())
  .concat(routesSupports())
  .concat(routesSolutionsReport())
  .concat(routesUsers())
  .concat(routesPartners())
  .concat(routesCloud())
  .concat(routesVoip());

const router = createRouter({
  history: createWebHistory(window.BASE_URL),
  routes,
  mode: "hash",
});

export default router;
