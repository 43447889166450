<script>
export default {
  name: "CrossStep4Completato"
}
</script>

<template>
  <svg id="step_04_completato" data-name="step 04 completato" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
    <circle id="bg" cx="17.5" cy="17.5" r="17.5" fill="#e7fffe"/>
    <g id="icon" transform="translate(8 8)">
      <rect id="Rettangolo_2409" data-name="Rettangolo 2409" width="19" height="19" fill="none"/>
      <path id="Tracciato_1357" data-name="Tracciato 1357" d="M3.942,3.786A1.59,1.59,0,0,0,3,5.235V8.956a9.852,9.852,0,0,0,7.125,9.5,9.852,9.852,0,0,0,7.125-9.5V5.235a1.59,1.59,0,0,0-.942-1.449L10.766,1.324a1.574,1.574,0,0,0-1.282,0Zm6.183,2h0a.794.794,0,0,1,.792.792h0a.794.794,0,0,1-.792.792h0a.794.794,0,0,1-.792-.792h0A.794.794,0,0,1,10.125,5.789Zm0,3.167h0a.794.794,0,0,1,.792.792v3.167a.794.794,0,0,1-.792.792h0a.794.794,0,0,1-.792-.792V9.747A.794.794,0,0,1,10.125,8.956Z" transform="translate(-0.625 -0.247)" fill="#3caea3"/>
    </g>
  </svg>

</template>

<style scoped lang="scss">

</style>
