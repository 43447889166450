<template>
  <div>
    <h2>{{ $t('accessDc.titleWhoShouldEnter') }}</h2>
    <p class="subTitle" v-if="false">{{ $t('accessDc.subTitleWhoShouldEnter') }}</p>
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <p class="labelFour marginTop20">{{ $t('accessDc.chooseUsers') }}</p>
        <span class="error" v-if="errorNoUsers">
          {{ $t('accessDc.errorNoUsers') }}
        </span>
        <span class="error" v-if="errorNoUsersBio">
          {{ $t('accessDc.errorNoUsersBio') }}
        </span>
      </div>
      <div class="mdl-cell mdl-cell--12-col contentUsersSearch">
        <div v-for="(userSel, index) in usersSelected" :key="index" class="singleUser viewRead active"
             v-tooltip:bottom.tooltip="$t('accessDc.removeUser')" @click="removeUser(userSel.id, userSel.email)"
             :id="'user-sel-' + index">
          <div v-if="userSel.name">{{ userSel.surname }} {{ userSel.name }}</div>
          <div v-else-if="userSel.in_lenel">{{ userSel.cognome }} {{ userSel.nome }}</div>
          <div class="material-icons">done</div>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--12-col" v-if="wizardData.typeAccess == 3">
        <p class="sub labelFour">{{ $t('accessDc.InfoAccessCarBio') }}</p>
      </div>
      <div class="mdl-cell mdl-cell--3-col">
        <MyLoading :viewLoader="viewLoaderUsers" type=""/>
        <button class="cdlButton" id="btn-add-users" @click="openModal()" v-if="viewLoaderUsers">
          {{ $t('accessDc.listUsersBtn') }}
        </button>
      </div>
      <div class="mdl-cell mdl-cell--9-col"></div>
    </div>

    <hr v-if="(wizardData.form.racks.length > 0 || wizardData.typeAccess != 3)"/>
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col" v-if="(wizardData.form.racks.length > 0 || wizardData.typeAccess != 3)">
        <p class="labelFour marginTop20">{{ $t('accessDc.addNewUsers') }}</p>
      </div>
      <div class="mdl-cell mdl-cell--3-col" v-if="(wizardData.form.racks.length > 0 || wizardData.typeAccess != 3)">
        <button class="cdlButton" @click="openModalNewUser()">
          {{ $t('accessDc.newUserBtn') }}
        </button>
      </div>
      <div class="mdl-cell mdl-cell--9-col"></div>
    </div>

    <div class="cdlContentButtonDouble">
      <div class="cdlButtonSingle width170" @click="goBack()">
        {{ $t('checkCoverage.stepsButtonTwo') }}
      </div>
      <div id="goNext" class="cdlButtonSingle width170" @click="goNext(false)">
        {{ $t('checkCoverage.stepsButtonOne') }}
      </div>
      <div class="cdlButtonSingle" @click="goNext(true)" v-if="wizardData.isCompleted">
        {{ $t('accessDc.goEnd') }}
      </div>
    </div>
    <p class="deleteOrder" v-if="!wizardData.isCompleted" @click="ctrlModalAccessDcRemove()">{{
        $t('checkCoverage.stepsButtonThree')
      }}</p>
  </div>

  <!-- MODALE LISTA UTENTI -->
  <div id="MyModal" class="ModalAccessUsers" v-if="viewModal">
    <div class="contentModal" :class="{ open: viewModal }">
      <span class="material-icons closeOrder" @click="openModal()">close</span>
      <h3>{{ $t('accessDc.modalAccessUsersTitle') }}</h3>
      <p class="subTitle" v-if="wizardData.form.racks.length > 0 || wizardData.typeAccess != 3">
        {{ $t('accessDc.modalAccessUsersSubTitle') }}</p>

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="contentInputSearch">
            <div><span class="material-icons searchIcon">search</span></div>
            <div class="contentInput">
              <input type="text" :placeholder="$t('accessDc.searchUser')" @keyup="searchByString"
                     v-model="searchString"/>
            </div>
          </div>
        </div>

        <div class="mdl-cell mdl-cell--12-col" v-if="!viewBtnModal">
          <p class="labelFour">{{ $t('accessDc.noUsers') }}</p>
        </div>

        <div class="mdl-cell mdl-cell--12-col contentUsersSearch">
          <div class="mdl-grid" id="listUsersBio" v-if="viewUsersBio">
            <div class="mdl-cell mdl-cell--3-col singleUser" v-for="(userBio, index) in usersBio" :key="index"
                 @click="setUserBio(userBio)" :id="['userBio-' + userBio.email]" :class="{ view: userBio.in_lenel }">
              <div>{{ userBio.cognome }} {{ userBio.nome }}</div>
              <div class="material-icons">done</div>
            </div>
          </div>
          <div class="mdl-grid"
               v-if="(wizardData.form.racks.length > 0 || wizardData.typeAccess != 3) && viewUsersNoBio"
               id="listUsersNoBio">
            <div class="mdl-cell mdl-cell--3-col singleUser view" v-for="(userNorm, index) in users" :key="index"
                 @click="setUser(userNorm.id)" :id="['user-' + userNorm.id]">
              <div>{{ userNorm.surname }} {{ userNorm.name }}</div>
              <div class="material-icons">done</div>
            </div>
          </div>
        </div>

        <div class="mdl-cell mdl-cell--12-col" v-if="viewBtnModal">
          <div class="contentButton">
            <button id="submitCustomerEnd" class="cdlButtonGreen" @click="openModal()">
              {{ $t('accessDc.modalUsersSubmit') }}
            </button>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col" v-else>
          <div class="contentButton">
            <button id="submitCustomerEnd" class="cdlButton" @click="openModal()">
              {{ $t('accessDc.close') }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- FINE MODALE LISTA UTENTI -->

  <!-- MODALE NUOVO UTENTE -->
  <div id="MyModal" class="ModalAccessUsers" v-if="viewModalNewUser">
    <div class="contentModal" :class="{ open: viewModalNewUser }">
      <span class="material-icons closeOrder" @click="openModalNewUser()">close</span>
      <h3>{{ $t('accessDc.modalAccessNewUserTitle') }}</h3>
      <p class="subTitle">{{ $t('accessDc.modalAccessNewUserSubTitle') }}</p>

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <p class="labelTwo">{{ $t('accessDc.modalAccessNewUserLabelOne') }}*</p>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <div class="cdl-input" style="margin-top: 0;">
            <input type="text" name="name" v-model="user.name" @input="label($event);" maxlength="50"/>
            <label>{{ $t('accessDc.name') }}*</label>
            <span class="error" v-if="(this.v$ && this.v$.user.name.$invalid && this.v$.user.name.$dirty)">
              {{ $t('accessDc.name') }} {{ $t('checkCoverage.isRequired') }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <div class="cdl-input" style="margin-top: 0;">
            <input type="text" name="surname" v-model="user.surname" @input="label($event);" maxlength="50"/>
            <label>{{ $t('accessDc.surname') }}*</label>
            <span class="error" v-if="(this.v$ && this.v$.user.surname.$invalid && this.v$.user.surname.$dirty)">
              {{ $t('accessDc.surname') }} {{ $t('checkCoverage.isRequired') }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <div class="cdl-input">
            <input type="text" name="company" v-model="user.company" @input="label($event);" maxlength="50"/>
            <label>{{ $t('accessDc.company') }}*</label>
            <span class="error" v-if="(this.v$ && this.v$.user.company.$invalid && this.v$.user.company.$dirty)">
              {{ $t('accessDc.company') }} {{ $t('checkCoverage.isRequired') }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <div class="cdl-input">
            <input type="text" name="email" v-model="user.email" @input="label($event);" maxlength="100"/>
            <label>{{ $t('accessDc.email') }}*</label>
            <span class="error" v-if="(this.v$ && this.v$.user.email.$invalid && this.v$.user.email.$dirty)">
              {{ $t('accessDc.email') }} {{ $t('checkCoverage.isRequired') }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <hr/>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t('accessDc.modalAccessNewUserLabelTwo') }}*</span>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <div class="cdl-input" style="margin-top: 0;">
            <span class="labelOne">{{ $t('accessDc.doc_type') }}*</span>
            <select name="doc_type" v-model="user.doc_type" @input="label($event);"
                    style="margin-left: 0; width: 104%;">
              <option :value="$t('accessDc.identityCard')">{{
                  $t('accessDc.identityCard')
                }}
              </option>
              <option :value="$t('accessDc.passport')">{{
                  $t('accessDc.passport')
                }}
              </option>
              <option :value="$t('accessDc.licenseDrive')">{{
                  $t('accessDc.licenseDrive')
                }}
              </option>
            </select>
            <span class="error" v-if="(this.v$ && this.v$.user.doc_type.$invalid && this.v$.user.doc_type.$dirty)">
              {{ $t('accessDc.doc_type') }} {{ $t('checkCoverage.isRequired') }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col"><br/>
          <div class="cdl-input" style="margin-top: 0;">
            <input type="text" name="doc_number" v-model="user.doc_number" @input="label($event);" maxlength="50"/>
            <label>{{ $t('accessDc.doc_number') }}*</label>
            <span class="error" v-if="(this.v$ && this.v$.user.doc_number.$invalid && this.v$.user.doc_number.$dirty)">
              {{ $t('accessDc.doc_number') }} {{ $t('checkCoverage.isRequired') }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <div class="cdl-input">
            <input type="date" name="doc_expiry" v-model="user.doc_expiry" @input="label($event);" class="not-empty"
                   :min="getDate()"/>
            <label>{{ $t('accessDc.doc_expiry') }}*</label>
            <span class="error" v-if="(this.v$ && this.v$.user.doc_expiry.$invalid && this.v$.user.doc_expiry.$dirty)">
              {{ $t('accessDc.doc_expiry') }} {{ $t('checkCoverage.isRequired') }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col"></div>
        <div class="mdl-cell mdl-cell--12-col">
          <div class="contentButton">
            <button id="submitCustomerEnd" class="cdlButtonGreen" @click="saveUser()">
              {{ $t('checkCoverage.modalSubmit') }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- FINE MODALE NUOVO UTENTE -->

  <!-- MODALE INFO -->
  <div id="MyModal" v-if="vieModalInfo">
    <div class="contentModal open"><span class="material-icons closeOrder" id="closeModalX"
                                         @click="closeModalInfo()">close</span>
      <h3>{{ $t('accessDc.modalAccessDcInfo.title') }}</h3>
      <p class="sub">{{ $t('accessDc.modalAccessDcInfo.sub') }}</p>
      <p class="text">{{ $t('accessDc.modalAccessDcInfo.text') }}</p>
      <div class="contentButton">
        <button id="btnModal" class="cdlButton" @click="continueSteps()">{{
            $t('accessDc.modalAccessDcInfo.btnContinue')
          }}
        </button>
        <br><br>
        <div>
          <p class="deleteOrder" id="deleteOrder" @click="closeModalInfo()">{{
              $t('accessDc.modalAccessDcInfo.btnRemove')
            }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- FINE MODALE INFO -->
</template>

<script>
import {useUserStore} from '@/stores/UserStore'
import {useVuelidate} from '@vuelidate/core'
import {email, required} from '@vuelidate/validators'
import {useAccessDcStore} from '@/stores/AccessDcStore'
import MyLoading from '@/components/MyLoading.vue'

export default {
  name: 'WhoShouldEnter',
  components: {
    MyLoading
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    if (!this.viewModalNewUser) {
      return {};
    }
    return {
      user: {
        name: {required},
        surname: {required},
        company: {required},
        email: {required, email},
        doc_type: {required},
        doc_number: {required},
        doc_expiry: {required}
      },
      viewBtnModal: true
    }
  },
  props: {
    wizardData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      users: [],
      // usersBio: [],
      viewModal: false,
      viewModalNewUser: false,
      usersSelected: [],
      searchString: '',
      user: {
        name: null,
        surname: null,
        company: null,
        email: null,
        doc_type: null,
        doc_number: null,
        doc_expiry: null
      },
      he: this.getHeaders(),
      errorNoUsers: false,
      limitUser: 0,
      errorNoUsersBio: false,
      usersBio: [],
      isBio: false,
      viewLoaderUsers: false,
      nPaxBio: 0,
      nPax: 0,
      vieModalInfo: false,
      iWantGo: false,
      goSummary: null,
      viewUsersBio: true,
      viewUsersNoBio: true
    }
  },
  methods: {
    getHeaders() {
      return {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.$keycloak.idToken
      }
    },
    openModal() {
      this.viewModal = !this.viewModal;
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      if (this.viewModal) {
        this.searchString = '';
        this.viewUsersBio = true;
        this.viewUsersNoBio = true;
        this.viewAll();
        window.scrollTo(0, 0);
        document.getElementsByTagName("body")[0].classList.add("overflow");
        if (this.usersSelected.length > 0) {
          setTimeout(() => {
            this.usersSelected.forEach(elm => {
              if (elm.in_lenel == true) {
                if (elm.email && document.getElementById("userBio-" + elm.email)) {
                  document.getElementById("userBio-" + elm.email).classList.add('active');
                }
              } else {
                if (elm.id && document.getElementById("user-" + elm.id)) {
                  document.getElementById("user-" + elm.id).classList.add('active');
                }
              }
            });
          }, 500);

        }
      }
    },
    openModalNewUser() {
      this.viewModalNewUser = !this.viewModalNewUser;
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      if (this.viewModalNewUser) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        document.getElementsByTagName("body")[0].classList.add("overflow");
      }
    },
    closeModalInfo() {
      this.vieModalInfo = false;
      document.getElementsByTagName("body")[0].classList.remove("overflow");
    },
    goBack() {
      this.$emit('goBack');
    },
    goNext(goSummary = null) {
      this.errorNoUsers = false;
      this.errorNoUsersBio = false;
      this.goSummary = goSummary;
      if (this.usersSelected.length == 0) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.errorNoUsers = true;
        return;
      } else if ((this.wizardData.form.coworking.desks > 0 &&
          (this.limitUser != this.maxSeatsDeskCoworking && this.usersSelected.length != this.limitUser))) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.errorNoUsersBio = true;
        return;
      }

      // if ((this.wizardData.form.coworking.desks > 0 &&
      //   (this.limitUser != this.maxSeatsDeskCoworking && this.usersSelected.length != this.limitUser)) &&
      //   !this.wizardData.form.fromTime) {
      //   window.scrollTo({ top: 0, behavior: 'smooth' })
      //   this.errorNoUsersBio = true;
      //   return;
      // }

      if (this.wizardData.form.typeAccess == 1 && this.nPax == 0 && this.nPaxBio > 0) {
        // Modale
        this.vieModalInfo = true;
        window.scrollTo({top: 0, behavior: 'smooth'})
        document.getElementsByTagName("body")[0].classList.add("overflow");
        return;
      }

      this.continueSteps();
    },
    continueSteps() {
      this.closeModalInfo();
      let goSummary = this.goSummary;
      let form = {
        users: this.usersSelected
      }
      this.$emit('update', form);
      this.$emit('goNext', {goSummary});
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    ctrlModalAccessDcRemove() {
      this.$emit('close');
    },
    setUser(id) {
      this.errorNoUsers = false;
      this.errorNoUsersBio = false;
      if (document.getElementById("user-" + id).classList.contains('active')) {
        document.getElementById("user-" + id).classList.remove('active');
        if (this.usersSelected.length == 1) {
          this.usersSelected = [];
          this.nPax = 0;
        } else {
          this.usersSelected.forEach((el, i) => {
            if (el.id == id) {
              this.nPax--;
              this.usersSelected.splice(i, 1);
            }
          });
        }
      } else {
        document.getElementById("user-" + id).classList.add('active');
        this.users.forEach(user => {
          if (user.id == id) {
            this.nPax++;
            this.usersSelected.push(user);
          }
        })
      }
    },
    removeUser(id, email) {
      this.errorNoUsers = false;
      this.errorNoUsersBio = false;
      this.usersSelected.forEach((el, i) => {
        if (id) {
          if (el.id == id) {
            this.usersSelected.splice(i, 1);
            if (el.in_lenel) {
              this.nPaxBio--;
            } else {
              this.nPax--;
            }
          }
        } else {
          if (el.email == email) {
            this.usersSelected.splice(i, 1);
            if (el.in_lenel) {
              this.nPaxBio--;
            } else {
              this.nPax--;
            }
          }
        }

      });
    },
    searchByString() {
      if (this.searchString.length == 0) {
        this.viewUsersBio = true;
        this.viewUsersNoBio = true;
        this.viewAll();
        return;
      }

      this.hideAll();

      this.viewUsersBio = false;
      this.viewUsersNoBio = false;

      this.viewBtnModal = false;

      this.users.forEach(elm => {
        if (
            elm.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
            elm.surname.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
          this.viewUsersNoBio = true;
          if (document.getElementById("user-" + elm.id)) {
            document.getElementById("user-" + elm.id).classList.add('view');
            this.viewBtnModal = true;
          }
        }
      });

      this.usersBio.forEach(elm => {
        if (
            elm.nome.toLowerCase().includes(this.searchString.toLowerCase()) ||
            elm.cognome.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
          this.viewUsersBio = true;
          if (document.getElementById("userBio-" + elm.email)) {
            document.getElementById("userBio-" + elm.email).classList.add('view');
            this.viewBtnModal = true;
          }
        }
      });

    },
    viewAll() {
      let allInputs = document.getElementsByClassName("singleUser");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.add('view');
      }
      this.viewBtnModal = true;
    },
    hideAll() {
      let allInputs = document.getElementsByClassName("singleUser");
      for (let i = 0; i < allInputs.length; i++) {
        allInputs[i].classList.remove('view');
      }
    },
    async saveUser() {
      const result = await this.v$.$validate()
      if (result) {
        this.v$.$reset();
        let userStore = useUserStore();
        let data = this.user;
        data.gamma_id = this.wizardData.gammaId;
        data.language = this.wizardData.lang;
        data.mod_by = "ASSENZIO - " + userStore.user.first_name + " " + userStore.user.last_name;

        this.axios.post(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'front/v1/user_new', data, {headers: this.he}).then((rls) => {
          if (rls.data.status == 'ok') {
            this.usersSelected.push(rls.data.data);
            this.getAllUsers();
            this.openModalNewUser();
          }
        });
      }
    },
    getAllUsers() {
      let userStore = useUserStore();
      const self = this;
      let ctrl = false;
      this.axios.get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'front/v1/get-users/' + this.wizardData.gammaId, {headers: this.he}).then((rls) => {
        this.users = rls.data.data;
        if (this.usersSelected.length == 0) {
          if (this.isBio) {
            this.usersBio.forEach(bio => {
              if (bio.email == userStore.user.email && !ctrl) {
                bio.in_lenel = true;
                this.usersSelected.push(bio);
                ctrl = true;
                this.nPaxBio = 1;
              }
            });
            // this.usersSelected.push({
            //   name: userStore.user.first_name,
            //   surname: userStore.user.last_name,
            //   email: userStore.user.email
            // });
          } else {
            this.users.forEach((elm) => {
              if (elm.email == userStore.user.email && !ctrl) {
                this.usersSelected.push(elm);
                ctrl = true;
                this.nPax = 1;
              }
            });
          }
        }
        this.viewLoaderUsers = true;

      }).catch(() => {
        return self.$router.push({name: 'AccessRequest' + self.$i18n.locale});
      });
    },
    getDate() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth() + parseInt(1);
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      return date.getFullYear() + "-" + mm + "-" + dd;
    },
    setUserBio(user) {
      this.errorNoUsers = false;
      this.errorNoUsersBio = false;
      if (document.getElementById("userBio-" + user.email).classList.contains('active')) {
        document.getElementById("userBio-" + user.email).classList.remove('active');
        if (this.usersSelected.length == 1) {
          this.nPaxBio = 0;
          this.usersSelected = [];
        } else {
          this.usersSelected.forEach((el, i) => {
            if (el.email == user.email) {
              this.nPaxBio--;
              this.usersSelected.splice(i, 1);
            }
          });
        }
      } else {
        document.getElementById("userBio-" + user.email).classList.add('active');
        this.nPaxBio++;
        this.usersSelected.push(user);
      }
    }
  },
  activated() {
    this.errorNoUsersBio = false;
    if (this.wizardData.form.typeAccess == 2 && this.usersSelected == 0) {
      this.limitUser = this.wizardData.form.coworking.desks;
      this.maxSeatsDeskCoworking = this.wizardData.maxSeatsDeskCoworking;
    } else {
      if (this.wizardData.form.users.length > 0) {
        this.usersSelected = this.wizardData.form.users;
      }
      if (this.wizardData.form.coworking.desks > 0) {
        this.limitUser = this.wizardData.form.coworking.desks;
        this.maxSeatsDeskCoworking = this.wizardData.maxSeatsDeskCoworking;
      }
    }
  },
  mounted() {
    if (this.users.length == 0) {
      let accessDcStore = useAccessDcStore();
      this.usersBio = accessDcStore.usersBio;
      this.isBio = accessDcStore.isBio;
      this.getAllUsers();
    }
  }
}
</script>
