import axios from "axios";

export const deleteCartItem = (cartId, headers) => {
  return axios.delete(
    `${this.$MyCrypto(
      window.VUE_APP_MISTRA_URL,
      1
    )}sales/v1/cart-item/${cartId}`,
    { headers: headers }
  );
};

export const setLocalStorageItems = (keys) => {
  keys.forEach((key) => {
    localStorage.setItem(key[0], localStorage.getItem(key[1]));
  });
};
export const deleteRequest = async (url, headers) => {
  try {
    await axios.delete(url, { headers: headers });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const performActionAndClose = (action, emit) => {
  action();
  emit("close");
};
