<template>
  <div id="ManagementPanel" :class="{ active: view, close: !view }">
    <MyLoading :viewLoader="viewLoader" type="" />
    <div class="mdl-grid" v-if="viewLoader">
      <div
        class="mdl-cell mdl-cell--1-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <span class="material-icons closePanel" @click="closePanel()"
          >close</span
        >
      </div>
      <div
        class="mdl-cell mdl-cell--11-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <h3 v-if="form.id">
          {{ $t("users.titleEditUser") }} {{ form.first_name }}
          {{ form.last_name }}
        </h3>
        <h3 v-else>{{ $t("users.titleNewUser") }}</h3>
        <p class="subTitle" v-if="form.id">
          {{ $t("users.subTitleEditUser") }}
        </p>
        <p class="subTitle" v-else>{{ $t("users.subTitleNewUser") }}</p>

        <div class="space"></div>

        <div class="mdl-grid noMargin">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("users.sectionOne") }}:</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="text"
                name="first_name"
                v-model="form.first_name"
                @input="label($event)"
              />
              <label>{{ $t("users.nameUser") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$.form.first_name.$invalid &&
                  this.v$.form.first_name.$dirty
                "
              >
                {{ $t("users.nameUser") }} {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="text"
                name="last_name"
                v-model="form.last_name"
                @input="label($event)"
              />
              <label>{{ $t("users.surNameUser") }}*</label>
              <span
                class="error"
                v-if="
                  this.v$.form.last_name.$invalid &&
                  this.v$.form.last_name.$dirty
                "
              >
                {{ $t("users.surNameUser") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="text"
                name="email"
                v-model="form.email"
                @input="
                  label($event);
                  ctrlIdExists();
                "
                :disabled="form.id ? true : false"
              />
              <label>{{ $t("users.emailUser") }}*</label>
              <span
                class="error"
                v-if="this.v$.form.email.$invalid && this.v$.form.email.$dirty"
              >
                {{ $t("users.emailUser") }} {{ $t("checkCoverage.isRequired") }}
              </span>
              <span class="error" v-if="this.errorEmailSame">
                {{ $t("users.errorEmailSame") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="text"
                name="phone"
                v-model="form.phone"
                @input="label($event)"
              />
              <label>{{ $t("users.phoneUser") }}*</label>
              <span
                ><i>{{ $t("users.exPhoneUser") }}</i></span
              ><br />
              <span
                class="error"
                v-if="this.v$.form.phone.$invalid && this.v$.form.phone.$dirty"
              >
                {{ $t("users.phoneUser") }} {{ $t("checkCoverage.isRequired") }}
              </span>
              <span class="error" v-else-if="this.errorPhoneRegex">
                {{ $t("users.phoneUser") }} {{ $t("users.notFormat") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <p style="margin-top: 20px">
              <input
                type="checkbox"
                @change="checkMaintenance($event)"
                class="check"
                id="checkMaintenance"
                v-model="form.maintenance_on_primary_email"
              />
              <span
                class="emailTwoCheck"
                v-html="$t('users.maintenanceCheck')"
              ></span>
            </p>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                type="text"
                name="secondary_email"
                v-model="form.secondary_email"
                @input="label($event)"
              />
              <label
                >{{ $t("users.emailTwoUser") }}
                {{
                  form.maintenance_notification_use_secondary_email == true
                    ? "*"
                    : ""
                }}</label
              >
              <span
                class="error"
                v-if="
                  this.v$ &&
                  this.v$.form.secondary_email &&
                  this.v$.form.secondary_email.$invalid &&
                  this.v$.form.secondary_email.$dirty
                "
                >{{ $t("users.emailTwoUser") }}
                {{ $t("checkCoverage.isRequired") }}</span
              >
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col"></div>
          <div class="mdl-cell mdl-cell--12-col">
            <p style="margin-top: 20px">
              <input
                type="checkbox"
                @change="check($event)"
                class="check"
                id="check"
                ref="checkSecondEmailMaintenance"
                v-model="form.maintenance_notification_use_secondary_email"
              />
              <span
                class="emailTwoCheck"
                v-html="$t('users.emailTwoCheck')"
              ></span>
            </p>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <hr />
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("users.sectionTwo") }}</span>
            <p class="subSectionTwo">{{ $t("users.subSectionTwo") }}</p>
            <span
              class="error"
              v-if="
                this.v$.form.group_id.$invalid && this.v$.form.group_id.$dirty
              "
            >
              {{ $t("users.sectionTwo") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
          <div class="mdl-cell mdl-cell--12-col contentGroupUser">
            <div
              v-for="(group, groupKey) in groups"
              :key="groupKey"
              class="groupName"
              :id="['group-' + groupKey]"
              :class="[
                getClassGroup(group),
                'groupColor-' + getColorRole(group.color),
                'group-' + group.id,
              ]"
              @click="setGroup(group)"
            >
              <div class="buttonRadio"></div>
              <div>{{ group.name }}</div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <p class="textNewRole">{{ $t("users.newRole") }}</p>
            <div class="contentButton">
              <button class="cdlButton" @click="newRole" style="width: 100%">
                {{ $t("users.btnNewRole") }}
              </button>
            </div>
            <div class="space"></div>
            <hr />
            <div class="space"></div>
            <div class="contentButton">
              <button class="cdlButtonGreen" @click="submit" id="submitUser">
                {{
                  form.id ? $t("users.subTitleEditUser") : $t("users.saveUser")
                }}
              </button>
            </div>
            <p class="cancel" id="closeTab" @click="closePanel()">
              {{ $t("users.cancel") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <MyError :viewError="viewError" :message="messageError" />
  </div>
</template>

<script>
import "@/assets/scss/ManagementPanel.scss";
import { useVuelidate } from "@vuelidate/core";
import { email, integer, required } from "@vuelidate/validators";
import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "MyManagementUser",
  // props: ['view', 'user'],
  components: {
    MyLoading,
    MyError,
  },
  props: {
    view: {
      type: Boolean,
      required: true,
    },
    singleUser: {
      type: Object,
      required: true,
    },
    groups: {
      type: Object,
      required: true,
    },
    allUsers: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let myValidate = {
      form: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        secondary_email: { email },
        phone: { required, integer },
        group_id: { required, integer },
      },
    };

    if (this.form.maintenance_notification_use_secondary_email === true) {
      myValidate.form.secondary_email = { required, email };
    }
    return myValidate;
  },
  data() {
    return {
      viewLoader: true,
      form: {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        secondary_email: null,
        phone: null,
        maintenance_on_primary_email: new Boolean(false),
        marketing_on_primary_email: new Boolean(false),
        maintenance_notification_use_secondary_email: new Boolean(false),
        group_id: null,
      },
      viewError: false,
      messageError: "",
      errorPhoneRegex: false,
      errorEmailSame: false,
      if_I: false,
    };
  },
  methods: {
    closePanel(whoUpdate = 0) {
      this.$emit("close-panel", { whichPanel: 0, whoUpdate: whoUpdate });
    },
    regexPhone() {
      return /^\+?\d+$/.test(this.form.phone);
    },
    async submit() {
      const result = await this.v$.$validate();
      if (result && !this.errorEmailSame) {
        this.v$.$reset();
        // this.viewLoader = false;

        // Controllo telefono
        let isphone = this.regexPhone();
        this.errorPhoneRegex = false;

        if (!isphone) {
          this.viewLoader = true;
          this.errorPhoneRegex = true;
          return;
        }

        let he = {
          Accept: "application/json",
          Authorization: "Bearer " + this.$keycloak.idToken,
        };

        const self = this;

        // this.form.maintenance_on_primary_email = true;
        if (!this.form.maintenance_on_primary_email) {
          this.form.maintenance_on_primary_email = new Boolean(false);
        }

        this.form.marketing_on_primary_email = new Boolean(false);

        if (!this.form.secondary_email) {
          delete this.form.secondary_email;
        }

        if (!this.form.maintenance_notification_use_secondary_email) {
          this.form.maintenance_notification_use_secondary_email = new Boolean(
            false
          );
        }

        // console.log(this.form);

        if (this.form.id) {
          let url = "users/v1/user/" + this.form.id;
          if (this.if_I) {
            url = "base/v1/user-info/";
          }
          this.axios
            .put(
              this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + url,
              this.form,
              { headers: he }
            )
            .then(() => {
              this.viewLoader = true;

              const userStore = useUserStore();
              if (userStore.user.email == this.form.email) {
                userStore.setUser();
              }

              this.closePanel(1);
            })
            .catch(function (err) {
              console.log(err);
              self.viewError = true;
              self.viewLoader = true;
              self.messageError = err.message;
              self.resetLabel();
              setTimeout(() => {
                self.viewError = false;
              }, 5000);
            });
        } else {
          this.form.enabled = true;
          this.axios
            .post(
              this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "users/v1/user/",
              this.form,
              { headers: he }
            )
            .then(() => {
              this.viewLoader = true;
              this.closePanel(1);
            })
            .catch(function (err) {
              console.log(err);
              self.viewError = true;
              self.viewLoader = true;
              self.messageError = err.message;
              self.resetLabel();
              setTimeout(() => {
                self.viewError = false;
              }, 5000);
            });
        }
      }
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    check(ev) {
      if (ev.currentTarget.checked) {
        this.maintenance_notification_use_secondary_email =
          ev.currentTarget.checked;
        if (document.getElementById("checkMaintenance").checked) {
          document.getElementById("checkMaintenance").click();
        }
      }
    },
    checkMaintenance(ev) {
      if (ev.currentTarget.checked) {
        this.maintenance_on_primary_email = ev.currentTarget.checked;
        if (document.getElementById("check").checked) {
          document.getElementById("check").click();
        }
      }
    },
    newRole() {
      this.$emit("open-role", this.form);
    },
    getClassGroup(groupSelected) {
      if (
        this.singleUser.group &&
        groupSelected.id == this.singleUser.group.id
      ) {
        return "active";
      }
      return "";
    },
    setGroup(groupSelected) {
      this.form.group_id = groupSelected.id;
      this.resetGroups();
      if (document.getElementsByClassName("group-" + this.form.group_id)[0]) {
        document
          .getElementsByClassName("group-" + this.form.group_id)[0]
          .classList.add("active");
      }
    },
    resetGroups() {
      let allGroups = document.getElementsByClassName("groupName");
      for (let i = 0; i < allGroups.length; i++) {
        allGroups[i].classList.remove("active");
      }
    },
    resetLabel() {
      let allInputs = document.getElementsByTagName("input");
      setTimeout(() => {
        for (let i = 0; i < allInputs.length; i++) {
          if (allInputs[i].value != "") {
            allInputs[i].classList.add("not-empty");
          }
        }
      }, 500);
    },
    ctrlIdExists() {
      this.errorEmailSame = false;
      this.allUsers.forEach((user) => {
        if (user.email == this.form.email) {
          this.errorEmailSame = true;
        }
      });
    },
    getColorRole(color) {
      return color != "" ? color : "6585a7";
    },
  },
  watch: {
    singleUser: {
      handler(newValue, oldValue) {
        // console.log(newValue.secondary_email, oldValue.secondary_email);
        this.if_I = false;
        this.errorPhoneRegex = false;
        if (Object.keys(newValue).length == 0) {
          for (var key in this.form) {
            this.form[key] = null;
          }
          this.resetGroups();
        } else {
          this.resetLabel();
          if (newValue.id != oldValue.id) {
            Object.assign(this.form, newValue);
            if (this.form.phone) {
              this.form.phone = this.form.phone
                .replace("+", "")
                .replace(" ", "");
            }

            this.form.secondary_email = newValue.secondary_email;

            this.setGroup(newValue.group);
          }
          let userStore = useUserStore();
          if (userStore.user.email == this.form.email) {
            this.if_I = true;
          }
        }
      },
      deep: true,
    },
  },
};
</script>
