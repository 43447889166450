<template>
  <div>
    <RedundancyComponent
      :header-text="$t('crossConnects.muffolaOfYourOwnership')"
      :sub-text="''"
      :togled="localBend?.is_my_rack"
      @toggle-changed="handleToggleChangedInsideCard"
    />
    <div class="selectable-fields">
      <div class="field">
        <div class="field-title">
          {{ $t("crossConnects.building") + (localBend.is_my_rack ? "*" : "") }}
        </div>
        <select
          name="building-option"
          id="building-option"
          class="form-element"
          v-model="building"
          @change="setSelectedBuilding($event)"
        >
          <option value="" :disabled="localBend.is_my_rack">
            {{ $t("crossConnects.select") }}
          </option>
          <option v-for="b in buildings" :key="b.id" :value="b.id">
            {{ b.name }}
          </option>
        </select>
        <div
          v-if="
            props.isValidationInitiated && localBend.is_my_rack && !buildingName
          "
          class="error-message"
        >
          <span>{{ $t("crossConnects.requiredBuilding") }}</span>
        </div>
      </div>
      <div class="field">
        <div class="field-title">
          {{ $t("crossConnects.wing") + (localBend.is_my_rack ? "*" : "") }}
        </div>
        <select
          name="wing-option"
          id="wing-option"
          class="form-element"
          v-model="wing"
          @change="setSelectedWing($event)"
        >
          <option value="" :disabled="localBend.is_my_rack">
            {{ $t("crossConnects.select") }}
          </option>
          <option v-for="wing in wings" :key="wing.id" :value="wing.id">
            {{ wing.name }}
          </option>
        </select>
        <div
          v-if="
            props.isValidationInitiated && localBend.is_my_rack && !wingName
          "
          class="error-message"
        >
          <span>{{ $t("crossConnects.requiredWing") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";
import RedundancyComponent from "@/components/CrossConnect/ToggleSwitch.vue";
import {useDatacenterStore} from "@/stores/DataCenterStore";

const props = defineProps({
  datacenterStore: Object,
  buildings: Array,
  wings: Array,
  isRedundant: Boolean,
  isValidationInitiated: Boolean,
});
const datacenterStore = useDatacenterStore();

const emits = defineEmits(["update-building", "update-wing", "toggle-changed"]);

const localBend = computed(() => {
  return props.isRedundant ? datacenterStore.rbend : datacenterStore.bend;
});

const building = computed(() => {
  return props.isRedundant
    ? datacenterStore.rBuilding
    : datacenterStore.building;
});

const wing = computed(() => {
  return props.isRedundant ? datacenterStore.rWing : datacenterStore.wing;
});
const buildingName = computed(() => {
  return props.isRedundant
    ? datacenterStore.rBuildingName
    : datacenterStore.buildingName;
});

const wingName = computed(() => {
  return props.isRedundant
    ? datacenterStore.rWingName
    : datacenterStore.wingName;
});

const setSelectedBuilding = (value) => {
  emits("update-building", value);
};

const setSelectedWing = (value) => {
  emits("update-wing", value);
};

const handleToggleChangedInsideCard = (value) => {
  emits("toggle-changed", value);
};
</script>

<style>
/* Add your styles here */
</style>
