<template>
  <div id="Shipping">
    <div class="contentSteps">
      <div class="steps">
        <div class="step" :class="{ active: getStep() == key + 1, complete: getStep() > key + 1 }"
          v-for="(listStep, key) in getSteps()" :key="key">
          <div>
            <component :is="listStep.icon"></component>
          </div>
          <div>
            <p class="nameStep"><span v-if="!IsMobile">{{ key + 1 }}.</span> {{ $t(listStep.title) }}</p>
            <p class="statusStep" v-if="getStep() == key + 1 && false">{{ $t('checkCoverage.statusStep1') }}</p>
            <p class="statusStep" v-else-if="getStep() > key + 1 && false">{{ $t('checkCoverage.statusStep2') }}</p>
            <p class="statusStep" v-else-if="getStep() < key + 1 && false">{{ $t('checkCoverage.statusStep3') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <keep-alive>
        <component :is="steps[step - 1]" @update="processStep" @goBack="goBack" @goNext="goNext" @close="cancelTicket"
          :wizard-data="form" :step-data="step" @openTicket="openTicket" ref="ShippingDeliveries" :type="'Shipping'"></component>
      </keep-alive>

    </div>
    <div>
      <MyError :viewError="viewError" :message="messageError" />
    </div>

  </div>
</template>

<script>
import "@/assets/scss/Shipping.scss"

import MyStep1 from '@/components/ShippingDeliveries/svg/Step1.vue'
import MyStep2 from '@/components/ShippingDeliveries/svg/Step2.vue'
import MyStep3 from '@/components/ShippingDeliveries/svg/Step3.vue'

import Location from '@/components/ShippingDeliveries/Location.vue'
import InfoShipping from '@/components/ShippingDeliveries/InfoShipping.vue'
import InfoReference from '@/components/ShippingDeliveries/InfoReference.vue'
import MyError from '@/components/MyError.vue'

export default {
  name: 'ShippingDeliveries',
  components: {
    MyStep1,
    MyStep2,
    MyStep3,
    Location,
    InfoShipping,
    InfoReference,
    MyError
  },
  data() {
    return {
      step: 1,
      listSteps: [
        {
          icon: "MyStep1",
          title: "shipping.deliveriesTitleStep1"
        },
        {
          icon: "MyStep2",
          title: "shipping.deliveriesTitleStep2"
        },
        {
          icon: "MyStep3",
          title: "shipping.deliveriesTitleStep3"
        }
      ],
      steps: [
        'Location',
        'InfoShipping',
        'InfoReference'
      ],
      form: {
        target_datacenter: null,
        arrival_date: null,
        transporter: null,
        shipping_code: null,
        pallet_number: 0,
        packages_number: 0,
        weight: 0,
        volume: null,
        description: null,
        additional_information: null,
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
        attachments: [],
        dc_address: null
      },
      viewError: false,
      messageError: "",
      IsMobile: this.$IsMobile()
    }
  },
  methods: {
    getStep() {
      return this.step;
    },
    getSteps() {
      return this.listSteps;
    },
    processStep(stepData) {
      Object.assign(this.form, stepData);
    },
    goBack() {
      this.step--;
      window.scrollTo(0, 0);
    },
    goNext() {
      this.step++;
      window.scrollTo(0, 0);
    },
    cancelTicket() {
      window.scrollTo(0, 0);
      let params = { type: 'deleteShippingTicket' };
      this.$emit('openModal', params);
    },
    openTicket() {
      console.log(this.form);

      let he = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.$keycloak.idToken,
        'Content-Type': 'multipart/form-data'
      };

      const self = this;

      this.axios
        .post(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'tickets/v1/shipping', this.form, { headers: he })
        .then(() => {
          this.$emit('openModal', { type: 'confirmShippingDeliveries', destination: this.form.target_datacenter });
        }).catch(function (err) {
          console.log(err);
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.messageError = err.message;
          self.$refs.ShippingDeliveries.viwBtnSubmit();
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });

    }
  },
  mounted() {
    this.$IsLoggedIn();
    const self = this;
    let steps = document.getElementsByClassName("step");
    let newElm = "";
    for (let i = 0; i < steps.length; i++) {
      if (i != steps.length - 1) {
        newElm = document.createElement("div");
        newElm.className = "spacing";
        steps[i].after(newElm);
      }
      if (i == steps.length - 1) {
        newElm = document.createElement("span");
        newElm.className = "material-icons closeOrder";
        newElm.innerHTML = "close";
        steps[i].after(newElm);
        newElm.addEventListener('click', function handleClick() {
          self.cancelTicket();
        });
      }
    }
  }
}
</script>