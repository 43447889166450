<template>
  <div id="MyModal" v-if="viewModal">
    <div class="contentModal open"><span class="material-icons closeOrder" id="closeModalX"
                                         @click="closeModal()">close</span>
      <h3>{{ $t('accessDc.modalAccessDcRemove.title') }}</h3>
      <p class="sub">{{ $t('accessDc.modalAccessDcRemove.sub') }}</p>
      <!--      <p class="text">{{ $t('accessDc.modalAccessDcRemove.text') }}</p>-->
      <div class="contentButton widthButtonModal">
        <button id="btnModal" class="cdlButton" @click="closeModal()" style="width: 100%;">{{
            $t('accessDc.modalAccessDcRemove.btnContinue')
          }}
        </button>
        <br><br>
        <div>
          <p class="deleteOrder" id="deleteOrder" @click="removeAccessRequest()">{{
              $t('accessDc.modalAccessDcRemove.btnRemove')
            }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import "@/assets/scss/MyModal.scss"

export default {
  name: 'ModalUserDisabled',
  props: ['viewModal'],
  data() {
    return {}
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    removeAccessRequest() {
      this.$emit('cancel');
    }
  }
}
</script>
