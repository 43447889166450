<template>
  <tr>
    <td>
      <span>{{ this.getDate(item.createdTime) }}</span>
    </td>
    <td>
      <span>{{ item.cf_short_title }}</span>
    </td>
    <td>
      <span v-if="item.cf_asset_type">{{
        $t("ticketsList.default." + item.cf_asset_type)
      }}</span>
    </td>
    <td>
      <span>{{ item?.cf_sold_solution }}</span>
    </td>
    <td>
      <span>{{ item.cf_nome_referente }}</span>
    </td>
    <td v-if="item.cf_severity_level_picklist">
      <span>{{ item.cf_severity_level_picklist }}</span>
    </td>
    <td>
      <span :class="`statusTickets status${getClassStatus(item.statusType)}`">
        {{ $t("tickets." + getClassStatus(item.statusType)) }}</span
      >
    </td>

    <td>
      <div style="width: 150px; margin: 0 auto">
        <router-link :to="`/${url}/${$t('nav.detail')}/${item.ticketNumber}`">
          <div class="cdlButton">{{ $t("tickets.details") }}</div>
        </router-link>
      </div>
    </td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  name: "AssistanceTableBody",
  props: {
    item: Object,
    url: String,
  },
  methods: {
    getDate(myTime) {
      return moment(myTime).format("YYYY-MM-DD", this.$i18n.locale, true);
    },
    getClassStatus(string) {
      return typeof string == "string" ? string.replace(" ", "") : "";
    },
  },
};
</script>
