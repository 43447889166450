<template>
    <svg id="Step3" data-name="icon step 03 in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle id="Ellisse_153" class="setCircle" data-name="Ellisse 153" cx="17.5" cy="17.5" r="17" fill="#e4effc"/>
        <g id="inventory_black_24dp" transform="translate(8 8)">
            <g id="Raggruppa_1636" data-name="Raggruppa 1636">
            <rect id="Rettangolo_2404" data-name="Rettangolo 2404" width="19" height="19" fill="none"/>
            </g>
            <g id="Raggruppa_1638" data-name="Raggruppa 1638" transform="translate(2.375 0.792)">
            <g id="Raggruppa_1637" data-name="Raggruppa 1637">
                <path id="Tracciato_1352" class="setColor" data-name="Tracciato 1352" d="M4.583,4.167H6.167v.792A1.588,1.588,0,0,0,7.75,6.542H12.5a1.588,1.588,0,0,0,1.583-1.583V4.167h1.583V8.125H17.25V4.167a1.588,1.588,0,0,0-1.583-1.583H12.358a2.366,2.366,0,0,0-4.465,0H4.583A1.588,1.588,0,0,0,3,4.167V15.25a1.588,1.588,0,0,0,1.583,1.583h4.75V15.25H4.583Zm5.542-1.583a.792.792,0,1,1-.792.792A.794.794,0,0,1,10.125,2.583Z" transform="translate(-3 -1)" fill="#134c7a"/>
                <path id="Tracciato_1353" class="setColor" data-name="Tracciato 1353" d="M19.6,12.186a.846.846,0,0,0-1.187,0l-3.752,3.76-1.789-1.781a.846.846,0,0,0-1.187,0h0a.846.846,0,0,0,0,1.188L14.1,17.759a.788.788,0,0,0,1.116,0l4.378-4.386A.839.839,0,0,0,19.6,12.186Z" transform="translate(-4.759 -3.28)" fill="#134c7a"/>
            </g>
            </g>
        </g>
    </svg>
</template>

<script>

export default {
  name: 'MyStep3'
}
</script>