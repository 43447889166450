function createAnchorForDownload(data, openInNewTab, fileName = false) {
  // create anchor element
  const href = window.URL.createObjectURL(data),
    anchorElement = document.createElement("a");

  // set anchor element attributes
  anchorElement.href = href;
  if( fileName ) {
    anchorElement.setAttribute("download", fileName);
  }

  if (openInNewTab) {
    anchorElement.setAttribute("target", "_blank");
  }

  // append child to body and simulate click
  document.body.appendChild(anchorElement);

  return anchorElement;
}

function removeAnchor(anchorElement) {
  // clean the mess
  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(anchorElement.href);
}

export { createAnchorForDownload, removeAnchor };
