<template>
  <div id="WarningModal" v-if="isOpen" class="modal-overlay">
    <div class="modal contendModal" style="width: 410px; height: 456px; box-sizing: unset">
      <span @click="toggleModal" class="material-icons closeOrder" id="closeModalX" style="    border-radius: 25px;
    opacity: 50%;
    float: right;">close</span>
      <div class="modal-content-warn">
        <h2 class="monserrat">{{ $t('crossConnects.modal.warning') }}!</h2>
        <p class="subtitle-modal" style="text-align: center">{{ $t('crossConnects.modal.confirmCancel') }}<br></p>
        <br/>
      </div>
      <div class="contentButton">
        <div class="cdlButton" @click="toggleModal">
          {{ $t('crossConnects.modal.backToRequest') }}
        </div>
        <br/><br>
        <router-link class="deleteOrder" style="color: rgba(0, 0, 0, 0.87);" to="/">{{
            $t('crossConnects.cancel')
          }}
        </router-link>
      </div>
    </div>
  </div>
</template>


<script setup>
import {defineEmits, defineProps} from 'vue';

const props = defineProps({
  isOpen: Boolean
});

const emit = defineEmits(['update:isOpen']);

const toggleModal = () => {
  emit('update:isOpen', !props.isOpen);
};
</script>


<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";
@import "@/assets/scss/MyModal.scss";

#WarningModal {
  font-size: 1.125rem;
  text-align: center;
  font-family: OpenSansRegular;

  .contentButton {
    width: 280px;
    margin: 0 auto;
  }

  .monserrat {
    font-family: MontserratBold;
  }


  .subtitle-modal {
    text-align: center;
    font-size: 1.125rem;
    color: #5f6b7a;
    font-family: OpensansRegular;

  }

  .sub-text-modal {
    margin-top: 0.5rem;
    font-family: OpenSansSemiBold !important;

  }
}


.deleteOrder {
  text-align: center;
  text-decoration: underline;
  font-family: OpenSansRegular;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    font-family: OpenSansBold;
  }
}

.modal-content-warn {
  display: flex;
  flex-direction: column;
  align-items: center; /* centers items horizontally */
  justify-content: center; /* centers items vertically */
  padding: 20px; /* add some spacing around the content */
  margin: 0 auto; /* center the content block if it's not taking full width */
}

.value {
  font-family: OpenSansBold;
}


.modal-content h2,
.modal-content p,
.modal-content ul {
  text-align: center; /* centers text within each element */
  width: 100%; /* take full width to ensure center alignment works */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: OpenSansRegular;
  font-size: 16px
}

.modal {
  background-color: #fff;
  background-image: url('@/assets/img/card_order.png');
  width: 770px;
  height: 897px;
  max-width: 90%; /* Responsive */
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: #cfd6de;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  font-weight: bold;
  margin-bottom: 15px;
}

p {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

ul li {
  margin: 5px 0;
  font-size: 14px;
}

.request-btn, .cart-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.request-btn {
  background-color: #3498db; /* Sample blue color */
  color: #fff;
  margin-right: 10px;
}

.cart-btn {
  background-color: #2ecc71; /* Sample green color */
  color: #fff;
}

.request-btn:hover, .cart-btn:hover {
  background-color: darken(#3498db, 10%); /* You can adjust the percentage for a darker shade on hover */
}

/* Add the styles for the highlighted emails or links if any special styles are needed */
ul li strong {
  font-weight: bold;
}
</style>
