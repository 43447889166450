<script>

export default {
  name: 'MyLogin',
  mounted() {
    const keyK = this.$keycloak;
    keyK.init({ onLoad: 'login-required' });
  },
}
</script>
