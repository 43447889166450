<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal">
      <div class="modal-content">
        <h2>{{ $t("crossConnects.modal.requestConfirmed") }}</h2>
        <p class="subtitle" style="text-align: center">
        </p>
        <br/><br/>
      </div>
      <div class="button-container">
        <div style="display: flex">
          <router-link
              :to="{ name: 'CrossConnect' }"
              class="cdlButtonGreen"
              style="align-content: center"
              @click="toggleModal"
          >Fai una nuova richiesta
          </router-link>
        </div>
        <br/>
        <router-link
            :to="{ name: 'CartPageit' }"
            style="align-content: center; color: #002e5f"
        >
          {{ $t("crossConnects.modal.goToCart") }}
        </router-link>
      </div>
      <div class="bg-modal"></div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps} from "vue";

const props = defineProps({
  isOpen: Boolean,
});

const emit = defineEmits(["update:isOpen"]);

const toggleModal = () => {
  emit("update:isOpen", !props.isOpen);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";

.bg-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2; /* This ensures the background is behind the modal content */
}

.bg-illustrazione {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* This ensures the background is behind the modal content */
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* centers items horizontally */
  justify-content: center; /* centers items vertically */
  padding: 20px; /* add some spacing around the content */
  max-width: 80%; /* limit the width if needed */
  margin: 0 auto; /* center the content block if it's not taking full width */
}

.value {
  font-family: OpenSansBold;
}

.text-area-modal-element {
  width: 90%;
  height: 107px;
  background: #eef1f4 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  border: 1px none #b8c4ce;
}

.modal-content h2,
.modal-content p,
.modal-content ul {
  text-align: center; /* centers text within each element */
  width: 100%; /* take full width to ensure center alignment works */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: OpenSansRegular;
  font-size: 16px;
}

.modal {
  width: 700px;
  height: 700px;
  max-width: 90%; // Responsive
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  background-size: cover;
  background: #ffffff url("@/assets/img/modal_success.png") no-repeat center;
  background-size: 100% 100%; // Stretch the image to cover the entire modal
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  font-weight: bold;
  margin-bottom: 15px;
}

p {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding-left: 4rem;
  margin-bottom: 20px;
}

ul li {
  margin: 5px 0;
  font-size: 16px;
}

.request-btn,
.cart-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.request-btn {
  background-color: #3498db; /* Sample blue color */
  color: #fff;
  margin-right: 10px;
}

.cart-btn {
  background-color: #2ecc71; /* Sample green color */
  color: #fff;
}

.request-btn:hover,
.cart-btn:hover {
  background-color: darken(
          #3498db,
          10%
  ); /* You can adjust the percentage for a darker shade on hover */
}

/* Add the styles for the highlighted emails or links if any special styles are needed */
ul li strong {
  font-weight: bold;
}
</style>
