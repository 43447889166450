<template>
  <div class="helpRequestBtn" :class="elementClass">
    <span @click="goToPage()">
      <div>
        <span class="material-icons">{{ icon }}</span>
      </div>
      <div>
        <span style="margin-left: 5px">{{ label }}</span>
      </div>
    </span>
  </div>
</template>

<style scoped>
.helpRequestBtn {
  font-family: OpenSansBold;
  min-width: 100px;
  text-align: center;
  font-size: 1rem;
  padding: 12px;
  border-radius: 4px;
  margin: 0 auto;
  cursor: pointer;
}

.helpRequestBtn:hover {
  background-color: #cfd6de;
  opacity: 0.8;
}
.helpRequestBtn a {
  color: #002e5f;
}

@media screen and (max-width: 960px) {
  .helpRequestBtn {
    margin: 0;
    width: 33%;
  }
}
</style>

<script>
//import "@/assets/scss/variables.scss";
export default {
  name: "helpRequestBtn",
  props: {
    url: String,
    icon: String,
    label: String,
    elementClass: String,
    componentName: String,
    assetType: String,
    itemId: String
  },
  methods: {
    goToPage() {
      let params = {
        asset_type: this.assetType
      }
      if( this.itemId ) {
        params.asset_id = this.itemId;
      }
      return this.$router.push({ name: this.componentName + this.$i18n.locale, params: params });
    }
  }
};
</script>
