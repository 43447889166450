<template>
  <div id="ChangeRequestList">
    <table>
      <TableHead v-if="!IsMobile" @refresh="getTickets" />
      <tbody v-if="!IsMobile && this.viewLoader && this.list">
        <TableRow
          v-for="(item, keyItem) in this.list"
          :key="keyItem"
          :class="{ odd: keyItem % 2 }"
          :url="$t('nav.reportCrListUrl')"
          :item="item"
        >
        </TableRow>
      </tbody>
      <tbody v-else-if="IsMobile && this.viewLoader && this.list.length">
        <MobileCard
          v-for="(item, keyItem) in this.list"
          :key="keyItem"
          :titles="['cf_sold_solution', 'cf_nome_referente']"
          :item="item"
          translation-path="ticketsList.ActiveLines"
        >
          <template #url-label>
            <router-link
              :to="
                `/` +
                $t('nav.reportCrListUrl') +
                `/${$t('nav.detail')}/${item.ticketNumber}`
              "
            >
              <div class="cdlButton">{{ $t("tickets.details") }}</div>
            </router-link>
          </template>
        </MobileCard>
      </tbody>

      <tbody v-else-if="this.viewLoader">
        <tr>
          <td colspan="6" style="text-align: center">
            <span>{{ $t("tickets.noTickets") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <MyLoading :viewLoader="this.viewLoader" type="" />
      <MyError :viewError="viewError" :message="$t('tickets.error')" />
    </div>
    <paginator
      v-if="totalPage > 1"
      :total-page="totalPage"
      :current-page="currentPage"
      @change-page="this.changePage"
      @prev-page="this.prevPage"
      @next-page="this.nextPage"
    ></paginator>
  </div>
</template>

<script>
import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import TableHead from "@/components/Tables/Assistance/TableHead.vue";
import TableRow from "@/components/Tables/Assistance/TableBody.vue";
import MobileCard from "@/components/Cards/MobileCard.vue";

export default {
  name: "ChangeRequestList",
  components: {
    MobileCard,
    MyLoading,
    MyError,
    Paginator,
    TableHead,
    TableRow,
  },
  props: {
    list: Array,
    totalCount: Number,
    viewLoader: Boolean,
    viewError: Boolean,
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      messageError: "",
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.$emit("change-page", page);
    },
    nextPage() {
      if (this.currentPage == this.totalPage) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) + 1;
      this.$emit("change-page", this.currentPage);
    },
    prevPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) - 1;
      this.$emit("change-page", this.currentPage);
    },
    getTickets() {
      this.$emit("refresh");
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalCount / this.pageSize) > 1
        ? Math.ceil(this.totalCount / this.pageSize)
        : 1;
    },
  },
};
</script>
