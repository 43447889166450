<template>
  <div id="Shipping">
    <MyBanner :text="$t('banner.shippingPage')" />
    <MyHeaderPage :title="$t('shipping.title')" button="sp" />

    <br /><br />
    <TicketsList ticket-type="Shipping" details-url="listShippingUrl" />
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import TicketsList from "@/components/TicketsList.vue";
import "@/assets/scss/Shipping.scss";

export default {
  name: "MyShipping",
  components: {
    MyBanner,
    MyHeaderPage,
    TicketsList,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
    this.$IsLoggedIn();
  },
};
</script>
