<template>
  <div id="inputReference" v-if="viewLoader">
    <div class="rowRef">
      <div>
        <div class="contentSelect">
          <span class="labelOne">{{ $t('refTicket.listUsers') }}</span>
          <select name="datacenter" id="selectedUser" class="align" @change="submit($event)" @input="label($event);"
                  v-model="userSelected" style="margin-left: 0;">
            <option value="-1" selected>{{ $t('remoteHands.valueDefault') }}</option>
            <option :value="key" v-for="(user, key) in users" :key="key">
              {{ user.last_name }} {{ user.first_name }}
            </option>
          </select>
        </div>
      </div>
      <div></div>
    </div>
    <div class="rowRef">
      <div>
        <div class="cdl-input">
          <input type="text" name="nameRef" v-model="fields.ref_name" @change="submit" @input="label($event)"
                 class="not-empty"/>
          <label>{{ $t('refTicket.nameRef') }}*</label>
          <span class="error" v-if="(this.v$.fields.ref_name.$invalid && this.v$.fields.ref_name.$dirty)">
            {{ $t('refTicket.nameRef') }} {{ $t('checkCoverage.isRequired') }}
          </span>
        </div>
      </div>
      <div>
        <div class="cdl-input">
          <input type="text" name="surnameRef" v-model="fields.ref_surname" @change="submit" @input="label($event)"
                 class="not-empty"/>
          <label>{{ $t('refTicket.surnameRef') }}*</label>
          <span class="error" v-if="(this.v$.fields.ref_surname.$invalid && this.v$.fields.ref_surname.$dirty)">
            {{ $t('refTicket.surnameRef') }} {{ $t('checkCoverage.isRequired') }}
          </span>
        </div>
      </div>
    </div>
    <div class="rowRef">
      <div>
        <div class="cdl-input">
          <input type="text" name="ref_phone" v-model="fields.ref_phone" @change="submit" @input="label($event)"
                 class="not-empty"/>
          <label>{{ $t('refTicket.phoneRef') }}*</label>
          <span class="error" v-if="(this.v$.fields.ref_phone.$invalid && this.v$.fields.ref_phone.$dirty)">
            {{ $t('refTicket.phoneRef') }} {{ $t('checkCoverage.isRequired') }}
          </span>
          <span class="error" v-else-if="(this.errorPhoneRegex)">
            {{ $t('refTicket.phoneRef') }} {{ $t('users.notFormat') }}
          </span>
        </div>
      </div>
      <div>
        <div class="cdl-input">
          <input type="text" name="ref_email" v-model="fields.ref_email" @change="submit" @input="label($event)"
                 class="not-empty"/>
          <label>{{ $t('refTicket.emailRef') }}*</label>
          <span class="error" v-if="(this.v$.fields.ref_email.$invalid && this.v$.fields.ref_email.$dirty)">
            {{ $t('refTicket.emailRef') }} {{ $t('checkCoverage.isRequired') }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <MyLoading :viewLoader="viewLoader" type=""/>
</template>

<script>

import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {useUserStore} from '@/stores/UserStore'
import MyLoading from '@/components/MyLoading.vue'

import "@/assets/scss/RefTicket.scss"

export default {
  name: 'MyRefTicket',
  components: {
    MyLoading
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      fields: {
        ref_name: {required},
        ref_surname: {required},
        ref_phone: {required},
        ref_email: {required}
      },
    }
  },
  props: [],
  data() {
    return {
      fields: {
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null
      },
      users: [],
      userSelected: 0,
      viewLoader: false,
      noRepeat: 0,
      errorPhoneRegex: false
    }
  },
  methods: {
    label(ev) {
      this.$LabelInput(ev);
    },
    async submit(ev) {
      if (ev && ev.target.id == 'selectedUser') {
        if (ev.target.value == "") {
          this.fields.ref_name = null;
          this.fields.ref_surname = null;
          this.fields.ref_phone = null;
          this.fields.ref_email = null;
        } else {
          this.buildFieldsRef(ev.target.value);
        }
      }

      // Controllo telefono
      let isphone = this.regexPhone();
      this.errorPhoneRegex = false;
      if (!isphone) {
        this.errorPhoneRegex = true;
        this.$emit('validate', false);
        return;
      }

      this.$emit('update', this.fields);

      const result = await this.v$.$validate();
      this.$emit('validate', result);
    },
    async changeUser(index) {
      this.userSelected = index;
      this.buildFieldsRef(index);

      const result = await this.v$.$validate();
      this.$emit('validate', result);
    },
    buildFieldsRef(index) {
      if (index == -1) {
        this.fields.ref_name = null;
        this.fields.ref_surname = null;
        this.fields.ref_phone = null;
        this.fields.ref_email = null;
        return;
      }
      this.fields.ref_name = this.users[index].first_name;
      this.fields.ref_surname = this.users[index].last_name;
      this.fields.ref_email = this.users[index].email;
      this.fields.ref_phone = this.users[index].phone;

      this.$emit('update', this.fields);
      this.viewLoader = true;
    },
    regexPhone() {
      return /1?-?\.?\(?\d{3}[-).\s]?\d{3}[-.\s]?\d{4}/.test(this.fields.ref_phone);
    }
  },
  activated() {
    if (this.noRepeat == 0) {
      let userInfo = useUserStore();
      this.fields.ref_name = userInfo.user.first_name;
      this.fields.ref_surname = userInfo.user.last_name;
      this.fields.ref_email = userInfo.user.email;
      this.fields.ref_phone = userInfo.user.phone;

      let he = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.$keycloak.idToken
      };
      this.axios
          .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'users/v1/user', {headers: he})
          .then(response => {
            this.users = response.data;
            let getIndex = this.users.findIndex((element) => element.first_name == this.fields.ref_name && element.last_name == this.fields.ref_surname && element.email == this.fields.ref_email)
            this.changeUser(getIndex);
            this.submit();
            this.noRepeat = 1;
          }).catch(function (err) {
        console.log(err);
      });
    }

  }
}
</script>
