<template>
    <svg id="Step1" data-name="icona location in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle id="Ellisse_153" data-name="Ellisse 153" class="setCircle" cx="17.5" cy="17.5" r="17.5" fill="#e4effc"/>
        <g id="location_city_black_24dp" transform="translate(8.925 10.086)">
            <path id="Tracciato_1530" data-name="Tracciato 1530" class="setColor" d="M15.27,11.193V5.907a2.01,2.01,0,0,0-.6-1.442L12.918,2.717a1.018,1.018,0,0,0-1.442,0L9.738,4.455a2.051,2.051,0,0,0-.6,1.452V7.1H5.045A2.051,2.051,0,0,0,3,9.148V19.373a2.051,2.051,0,0,0,2.045,2.045H19.359A2.051,2.051,0,0,0,21.4,19.373V13.238a2.051,2.051,0,0,0-2.045-2.045Zm-8.18,8.18H5.045V17.328H7.09Zm0-4.09H5.045V13.238H7.09Zm0-4.09H5.045V9.148H7.09Zm6.135,8.18H11.18V17.328h2.045Zm0-4.09H11.18V13.238h2.045Zm0-4.09H11.18V9.148h2.045Zm0-4.09H11.18V5.058h2.045Zm6.135,12.27H17.315V17.328h2.045Zm0-4.09H17.315V13.238h2.045Z" transform="translate(-3.625 -4.504)" fill="#134c7a" stroke="rgba(0,0,0,0)" stroke-width="1"/>
        </g>
    </svg>
</template>

<script>

export default {
  name: 'MyStep1'
}
</script>