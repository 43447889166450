<script setup>
import { defineProps } from "vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";
import InfoRectangle from "@/components/CrossConnect/InfoRectangle.vue";

const props = defineProps({
  title: String,
  currentStep: Number,
  showRedundancyComponent: Boolean,
  isDataCenterCardSelected: Boolean,
  isInfra: Boolean,
});

const datacenterStore = useDatacenterStore();
</script>
<template>
  <div class="mdl-grid info-title-container">
    <div class="mdl-cell mdl-cell--12-col-phone mdl-cell mdl-cell--7-col">
      <h2 v-if="currentStep === 1" class="this-title">
        {{
          isDataCenterCardSelected
            ? $t("crossConnects.tipologia")
            : $t("crossConnects.dataCenter")
        }}
      </h2>
      <h2 v-else class="this-title">{{ props.title }}</h2>
      <div v-if="currentStep === 1" class="subtitle">
        {{
          isDataCenterCardSelected
            ? isInfra
              ? $t("crossConnects.subtitleTipologia")
              : $t("crossConnects.subtitleTipologiaLocal")
            : isInfra
            ? $t("crossConnects.locationInfra")
            : $t("crossConnects.locationLocal")
        }}
      </div>
      <div v-else-if="currentStep === 3" class="subtitle">
        {{ $t("crossConnects.carefullyReadBothData") }}
      </div>
    </div>
    <div
      class="mdl-cell mdl-cell--12-col-phone mdl-cell--4-col info-rectangle-container"
    >
      <InfoRectangle
        class="info-rectangle"
        :line2="datacenterStore.boxData.name"
        :line3="
          datacenterStore.boxData.address ? datacenterStore.boxData.address : ''
        "
        :isInfra="props.isInfra"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-title-container {
  justify-content: space-between;
  padding-top: 0px;
  padding-right: 0px;
  margin-left: 90px;
  .info-rectangle-container {
    margin-top: 0px;
    margin-right: 0px;
  }
}

//  Media queries for responsiveness
@media (max-width: 768px) {
  .info-title-container {
    justify-content: space-between;
    padding: 8px;
    margin-left: 0px;
  }
}
</style>
