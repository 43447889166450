<script>
export default {
  name: "CrossStep2Completato"
}
</script>

<template>
  <svg id="step_02_completato" data-name="step 02 completato" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
    <circle id="bg" cx="17.5" cy="17.5" r="17.5" fill="#e7fffe"/>
    <g id="icon" transform="translate(8 8)">
      <g id="Raggruppa_3075" >
        <path id="Tracciato_1561"  d="M0,0H19V19H0Z" fill="none"/>
      </g>
      <g id="Raggruppa_3077"  transform="translate(2.375 2.375)">
        <g id="Raggruppa_3076" >
          <path id="Tracciato_1562"  d="M16.458,4.583V3.792A.794.794,0,0,0,15.667,3H14.083a.794.794,0,0,0-.792.792v.792h0a.794.794,0,0,0-.792.792V7.75a.794.794,0,0,0,.792.792h.792v5.47a1.632,1.632,0,0,1-1.417,1.647,1.586,1.586,0,0,1-1.75-1.575V6.278a3.243,3.243,0,0,0-2.961-3.27A3.161,3.161,0,0,0,4.583,6.167v5.542H3.792A.794.794,0,0,0,3,12.5v2.375a.794.794,0,0,0,.792.792h0v.792a.794.794,0,0,0,.792.792H6.167a.794.794,0,0,0,.792-.792v-.792h0a.794.794,0,0,0,.792-.792V12.5a.794.794,0,0,0-.792-.792H6.167V6.238A1.632,1.632,0,0,1,7.584,4.591a1.586,1.586,0,0,1,1.75,1.575v7.806a3.243,3.243,0,0,0,2.961,3.27,3.161,3.161,0,0,0,3.373-3.159V8.542h.792a.794.794,0,0,0,.792-.792V5.375a.794.794,0,0,0-.792-.792Z" transform="translate(-3 -3)" fill="#3caea3"/>
        </g>
      </g>
    </g>
  </svg>

</template>

<style scoped lang="scss">

</style>
