<template>
  <div id="Breadcrumbs">
    <div v-for="(bread, key) in breads" :key="key">
      <router-link :to="bread.url" class="breadUrl" v-if="bread.url != ''">
        {{ $t('breadcrumbs.' + bread.name) }}
      </router-link>
      <span :class="{ last: breads.length - 1 == key }" v-else>{{ $t('breadcrumbs.' + bread.name, bread.ticket_id ? { ticket_id: bread.ticket_id } : {}) }}</span> <span
        class="material-icons" v-if="breads.length - 1 != key">chevron_right</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useBreadCrumbsStore } from '@/stores/BreadCrumbsStore'

const breads = computed(() => {
  return useBreadCrumbsStore().bread;
});
</script>

<script>
import "@/assets/scss/Breadcrumbs.scss"

export default {
  name: 'MyBreadcrumbs',
}
</script>