<template>
  <div id="ManagementPanel" :class="{ active: view, close: !view }">
    <MyLoading :viewLoader="dontViewLoader" type=""/>
    <div class="mdl-grid" v-if="dontViewLoader">
      <div
          class="mdl-cell mdl-cell--1-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <span class="material-icons closePanel" @click="closePanel()"
        >close</span
        >
      </div>
      <div
          class="mdl-cell mdl-cell--11-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <h3 v-if="form.id">
          {{ $t("users.titleEditUser") }} {{ form.company_name }}
        </h3>
        <h3 v-else>{{ $t("companies.newUser") }}</h3>
        <p class="subTitle" v-if="form.id">
          {{ $t("users.subTitleEditUser") }}
        </p>
        <p class="subTitle" v-else>{{ $t("users.subTitleNewUser") }}</p>

        <div class="space"></div>

        <div class="mdl-grid noMargin">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("users.sectionOne") }}:</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                  type="text"
                  class="not-empty"
                  name="company_name"
                  v-model="form.company_name"
                  @input="label($event)"
              />
              <label>{{ $t("companies.tableNameSurname") }}*</label>
              <span
                  class="error"
                  v-if="
                  this.v$.form.company_name.$invalid &&
                  this.v$.form.company_name.$dirty
                "
              >
                {{ $t("companies.tableNameSurname") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                  type="text"
                  class="not-empty"
                  name="last_name"
                  v-model="form.vat_id"
                  @input="label($event)"
              />
              <label>{{ $t("companies.tableVat") }}</label>
              <span
                  class="error"
                  v-if="
                  this.v$.form.vat_id.$invalid &&
                  this.v$.form.vat_id.$dirty &&
                  this.v$.form.cf.$invalid &&
                  this.v$.form.cf.$dirty
                "
              >
                {{ $t("checkCoverage.customerEndPIvaRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <input
                  type="text"
                  class="not-empty"
                  name="email"
                  v-model="form.cf"
                  @input="label($event)"
              />
              <label>{{ $t("companies.tableCf") }}</label>
              <span
                  class="error"
                  v-if="
                  this.v$.form.vat_id.$invalid &&
                  this.v$.form.vat_id.$dirty &&
                  this.v$.form.cf.$invalid &&
                  this.v$.form.cf.$dirty
                "
              >
                {{ $t("checkCoverage.customerEndFiscalCodeRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <hr/>
          </div>
          <div
              class="mdl-cell mdl-cell--12-col"
              v-for="(contact, index) in form.contacts"
              :key="index"
          >
            <div v-if="this.v$.form.contacts">
              <span class="labelTwo"
              >{{ $t(`companies.` + contact.type) }}*
              </span>
              <div class="mdl-cell mdl-cell--6-col">
                <div class="cdl-input">
                  <input
                      class="not-empty"
                      type="text"
                      name="user_name"
                      v-model="contact.name"
                      @input="label($event)"
                  />
                  <label>{{ $t("companies.nameUser") }}</label>
                  <span
                      class="error"
                      v-if="
                      this.v$.form.contacts[index].name.$invalid &&
                      this.v$.form.contacts[index].name.$dirty
                    "
                  >{{ $t("companies.nameSurnameTechRefRequired") }}
                  </span>
                </div>
              </div>
              <div class="mdl-cell mdl-cell--6-col">
                <div class="cdl-input">
                  <input
                      class="not-empty"
                      type="text"
                      name="user_email"
                      v-model="contact.email"
                      @input="label($event)"
                  />
                  <label>Email</label>
                  <span
                      class="error"
                      v-if="
                      this.v$.form.contacts[index].email.$invalid &&
                      this.v$.form.contacts[index].email.$dirty
                    "
                  >
                    {{ $t("companies.emailTechRefRequired") }}
                  </span>
                </div>
              </div>
              <div class="mdl-cell mdl-cell--6-col">
                <div class="cdl-input">
                  <input
                      class="not-empty"
                      type="text"
                      name="user_phone"
                      v-model="contact.phone"
                      @input="label($event)"
                  />
                  <label>{{ $t("companies.phoneUser") }}</label>
                  <span
                      class="error"
                      v-if="
                      this.v$.form.contacts[index] &&
                      this.v$.form.contacts[index].phone.$invalid &&
                      this.v$.form.contacts[index].phone.$dirty
                    "
                  >
                    {{ $t("companies.mobileTechRefRequired") }}
                  </span>
                </div>
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <hr/>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="space"></div>
            <div class="contentButton">
              <button class="cdlButtonGreen" @click="submit()" id="submitUser">
                {{
                  form.id
                      ? $t("users.subTitleEditUser")
                      : $t("companies.saveUser")
                }}
              </button>
            </div>
            <p class="cancel" id="closeTab" @click="closePanel()">
              {{ $t("users.cancel") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <MyError :viewError="viewError" :message="messageError"/>
  </div>
</template>

<script>
import "@/assets/scss/ManagementPanel.scss";
import {useVuelidate} from "@vuelidate/core";
import {email, required} from "@vuelidate/validators";
import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import {useCompaniesStore} from "@/stores/CompaniesStore";

// const phoneNumber = helpers.regex(
//   "phoneNumber",
//   /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}$/im
// );

export default {
  name: "CManagementCompany",
  // props: ['view', 'user'],
  components: {
    MyLoading,
    MyError,
  },
  props: {
    view: {
      type: Boolean,
      required: true,
    },
    companyRecord: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    return {v$: useVuelidate()};
  },

  validations() {
    return {
      form: {
        company_name: {required},
        vat_id: {
          required: this.customRequired('cf'),
        },
        cf: {
          required: this.customRequired('vat_id'),
        },
        contacts: [
          {
            name: {required},
            email: {required, email},
            phone: {required},
          },
          {
            name: {required},
            email: {required, email},
            phone: {required},
          },
          {
            name: {required},
            email: {required, email},
            phone: {required},
          },
        ],
      },
    };
  },
  data() {
    return {
      dontViewLoader: true,
      form: {
        id: null,
        company_name: null,
        vat_id: null,
        cf: null,
        contacts: [
          {
            type: "technical",
            name: null,
            email: null,
            phone: null,
          },
          {
            type: "administrative",
            name: null,
            email: null,
            phone: null,
          },
          {
            type: "on-site",
            name: null,
            email: null,
            phone: null,
          },
        ],
      },
      viewError: false,
      messageError: "",
      errorPhoneRegex: false,
      errorEmailSame: false,
    };
  },
  methods: {
    customRequired(otherField) {
      return (value) => {
        if (!value && !this.form[otherField]) {
          return false;
        }
        return true;
      };
    },
    closePanel() {
      this.v$.$reset(); // Reset Vuelidate validation state
      this.$emit("close-panel");
      this.resetLabel();
      this.resetForm();
    },
    getDefaultContacts() {
      return [
        {type: "technical", name: null, email: null, phone: null},
        {type: "administrative", name: null, email: null, phone: null},
        {type: "on-site", name: null, email: null, phone: null},
        // Add more default contact objects as needed
      ];
    },
    fillInTheBlanks() {
      let defaultContacts = this.getDefaultContacts();
      if( this.form.contacts.length < defaultContacts.length ) {
        defaultContacts.forEach((elm) => {
          if(!this.form.contacts.find(o => o.type === elm.type)) {
            this.form.contacts.push(elm);
          }
        });
      }
    },
    resetForm() {
      this.form = {
        id: null,
        company_name: null,
        vat_id: null,
        cf: null,
        contacts: this.getDefaultContacts(),
      };
    },
    regexPhone() {
      // This regex checks for a string that contains only numbers, spaces, parentheses, hyphens, and optional leading '+'.
      const phoneRegex =
          /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}$/im;
      // Use Array.prototype.every() to test every phone number in the contacts array
      return this.form.contacts.every((contact) =>
          phoneRegex.test(contact.phone)
      );
    },
    async submit() {
      // console.log("entered");
      const companiesStore = useCompaniesStore();
      // First, validate the entire form
      const result = await this.v$.$validate();

      // Check if the validation passed (result is true) and no custom errors are present
      if (!result) {
        // console.log("entered");
        // If the validation failed, do not proceed further
        this.dontViewLoader = true; // Keep the loader if needed or handle it as per your UX
        return; // Stop the execution of the rest of the function
      }

      // At this point, validation has passed
      this.v$.$reset(); // Optionally reset the validation state
      this.dontViewLoader = true; // Assuming you want to hide the loader now

      // Set additional properties or handle the form data as needed
      this.form.maintenance_on_primary_email = true;
      this.form.marketing_on_primary_email = true;

      if (!this.form.secondary_email) {
        delete this.form.secondary_email;
      }

      this.form.maintenance_notification_use_secondary_email = Boolean(
          this.form.maintenance_notification_use_secondary_email
      );

      // Proceed with saving the form data
      try {
        if (this.form.id) {
          await companiesStore.editCompany(this.form.id, this.form);
        } else {
          await companiesStore.addCompany(this.form);
        }
        // If the operation is successful, fetch the latest data and close the panel
        await companiesStore.getCompanies();
        this.closePanel(); // Close the panel only after successful operations
      } catch (error) {
        // Handle any errors, such as displaying a message to the user
        console.error("Failed to save company data:", error);
        this.messageError = "An error occurred while saving the data.";
        this.viewError = true; // Show the error message if you have a mechanism to display it
      }
    },
    label(ev) {
      // console.log("event:", ev);
      let textElm = ev.srcElement;
      textElm.classList.add("not-empty");
    },
    check(ev) {
      this.maintenance_notification_use_secondary_email = false;
      if (ev.currentTarget.checked) {
        this.maintenance_notification_use_secondary_email =
            ev.currentTarget.checked;
      }
    },
    resetLabel() {
      let allInputs = document.getElementsByTagName("input");
      setTimeout(() => {
        for (let i = 0; i < allInputs.length; i++) {
          if (allInputs[i].value != "") {
            allInputs[i].classList.add("not-empty");
          }
        }
      }, 300);
    },
  },

  watch: {
    companyRecord: {
      handler(newValue, oldValue) {
        // console.log("compare", newValue, oldValue)
        if (newValue && oldValue && newValue.id === oldValue.id) {
          // console.log("compare", newValue, oldValue)
          this.form.id = newValue.id;
          this.form.company_name = newValue.company_name || "";
          this.form.vat_id = newValue.vat_id || "";
          this.form.cf = newValue.cf || "";
          this.form.contacts = newValue.contacts || this.getDefaultContacts();

          this.fillInTheBlanks();

        }
        if (newValue && Object.keys(newValue).length > 0) {
          // Update only if the new value for these fields is different from the old value
          if (!oldValue || newValue.id !== oldValue.id) {
            this.form.id = newValue.id;
          }
          if (!oldValue || newValue.company_name !== oldValue.company_name) {
            this.form.company_name = newValue.company_name || "";
          }
          if (!oldValue || newValue.vat_id !== oldValue.vat_id) {
            this.form.vat_id = newValue.vat_id || "";
          }
          if (!oldValue || newValue.cf !== oldValue.cf) {
            this.form.cf = newValue.cf || "";
          }
          // For contacts, check if there is a need to update based on some condition or logic
          if (
              !oldValue ||
              JSON.stringify(newValue.contacts) !==
              JSON.stringify(oldValue.contacts)
          ) {
            this.form.contacts = newValue.contacts || this.getDefaultContacts();

            this.fillInTheBlanks();
            
          }
        } else {
          this.resetForm();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
