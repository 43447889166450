<template>
  <tr :class="this.$.vnode.key % 2 === 0 ? 'odd' : ''">
    <td v-for="(col, idx) in cols" :key="idx">
      <span> {{ item[col] }}</span>
    </td>
  </tr>

  <tr :class="this.$.vnode.key % 2 === 0 ? 'odd' : ''">
    <td class="mobile-cta-container" colspan="2">
      <slot name="url-label"></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "MobileRow",
  props: {
    cols: Array,
    item: Object,
  },
};
</script>
