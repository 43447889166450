import axios from "axios";
import { MyCrypto } from "./MyCrypto";
const CLOUD_URL = "assets/v1/cloud";
const VOIP_URL = "assets/v1/voip";

export function getCloudItemList(headers, page = null) {
  let  url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + CLOUD_URL;
  if (page) {
    url += '?page=' + page;
  }

  return axios.get(url, { headers: headers });
}

export function getVoipItemList(headers, page = null) {
  let  url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + VOIP_URL;
  if (page) {
    url += '?page=' + page;
  }

  return axios.get(url, { headers: headers });
}
