<template>
  <div id="MyModal" v-if="viewModal">
    <div class="contentModal open success modalCrm"><span class="material-icons closeOrder" id="closeModalX"
        @click="closeModal()">close</span>
      <h3>{{ $t(title) }}</h3>
      <p class="sub">{{ $t(sub) }}</p>
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="label-container">
            <span class="labelTwo">{{ $t(text) }}</span>
          </div>
          <div class="cdl-textarea">
            <textarea
              maxlength="512"
              name="message"
              id="textarea"
              v-model="message"
            ></textarea>
          </div>
          <p class="error" v-if="error">{{ $t("homePage.requestCrm.error") }}</p>
        </div>
      </div>
      <div class="contentButton"><button id="btnModal" class="cdlButtonGreen" @click="sendMessage()">{{
        $t(btn) }}</button><br><br>
        <div>
          <p class="deleteOrder" id="deleteOrder" @click="closeModal()">{{ $t(link)
          }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import "@/assets/scss/MyModal.scss"

export default {
  name: 'ModalCrmRequest',
  props: ['viewModal', 'title', 'sub', 'text', 'btn', 'link'],
  data() {
    return {
      message: "",
      error: false,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": " multipart/form-data",
      },
    }
  },
  methods: {
    sendMessage() {
      this.error = false;
      if( this.message != '' ) {
        this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/crm-ticket", 
            { message: this.message }, 
            { headers: this.he }
        )
        .then((response) => {
          console.log(response);
          if( response.data.success == 'reply sent' ) {
            // email inviata
            this.closeModal(true);
          }
          else {
            this.error = true;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      }
    },
    closeModal( sent = false ) {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      this.$emit('cancel', sent );
    }
  },
  watch: {
    viewModal: {
      handler() {
        this.message = '';
      },
    },
  },
}
</script>