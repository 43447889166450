<script setup>
import { defineProps, defineEmits } from 'vue';

// Defining props and event emitters without destructuring
const props = defineProps(['step', 'listStep', 'index']);
const emit = defineEmits(['step-clicked']);

// Handling click event
const handleClick = (value) => {
  emit('step-clicked', value);
};

</script>

<template>
  <div
      class="step"
      :class="{ active: step === index + 1, complete: step > index + 1 }"
      @click="handleClick(index + 1)"
  >
    <div>
      <component :is="listStep.icon"></component>
    </div>
    <div>
      <p class="nameStep">{{ index + 1 }}. {{ $t(listStep.title) }}</p>
      <p class="statusStep" v-if="props.step === props.index + 1 && false">{{ $t('checkCoverage.statusStep1') }}</p>
      <p class="statusStep" v-else-if="props.step > props.index + 1 && false">{{ $t('checkCoverage.statusStep2') }}</p>
      <p class="statusStep" v-else-if="props.step < props.index + 1 && false">{{ $t('checkCoverage.statusStep3') }}</p>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";
</style>
