<template>
  <div class="contentFormTicket">
    <div class="contentForm">
      <h2 class="title">{{ $t("shipping.titleLocation") }}</h2>
      <p class="subTitle">
        {{
          type == "Shipping"
            ? $t("shipping.deliveriesSubTitleStep1")
            : $t("shipping.pickupSubTitleStep1")
        }}
      </p>

      <div class="space"></div>

      <span class="labelOne">{{ $t("shipping.titleDcDestination") }}</span>
      <p
        class="error"
        v-if="
          this.v$.dc_destination &&
          this.v$.dc_destination.$invalid &&
          this.v$.dc_destination.$dirty
        "
      >
        {{ $t("shipping.titleDcDestination") }}
        {{ $t("checkCoverage.isRequired") }}
      </p>

      <MyLoading :viewLoader="viewLoader" type="" />
      <div class="contentLocation">
        <div
          class="singleLocation cardLocation"
          :class="['cardLocation-' + indexDc, !dc.available ? 'noAvaible' : '']"
          v-for="(dc, indexDc) in dc_list"
          :key="indexDc"
        >
          <div class="mdl-grid">
            <div
              class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
            >
              <div
                style="margin: 10px auto"
                class="buttonRadio dc"
                :class="['dc-' + indexDc]"
                :id="['dc-' + indexDc]"
                @click="
                  submit(
                    $event,
                    dc.dc_name,
                    dc.dc_address,
                    indexDc,
                    dc.available
                  )
                "
              >
                <div></div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--10-col">
              <p class="nameDc">{{ dc.dc_name }}</p>
              <p class="addressDc">{{ dc.dc_address }}</p>
              <hr style="margin-bottom: 0px" />
            </div>
          </div>

          <div v-if="type == 'Shipping' && dc.dc_id == 7">
            <div class="mdl-grid">
              <div
                :class="[
                  IsMobile
                    ? 'mdl-cell mdl-cell--12-col'
                    : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
                ]"
              >
                <p class="textOne" style="padding-top: 0px">
                  <img src="@/assets/img/iconTwo.png" alt="icon" />
                  {{ $t("shipping.location.shipping.sectionOneTitle") }}
                </p>
                <span class="textTwo">{{
                  $t("shipping.location.shipping.sectionOneC21")
                }}</span>
              </div>
              <div
                :class="[
                  IsMobile
                    ? 'mdl-cell mdl-cell--12-col'
                    : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
                ]"
              >
                <p class="textOne" style="padding-top: 0px">
                  <img src="@/assets/img/iconTwo.png" alt="icon" />
                  {{ $t("shipping.location.shipping.sectionThreeTitleC21") }}
                </p>
                <div class="locationInfo" style="margin-top: 0px">
                  {{ $t("shipping.location.shipping.sectionThreeTextC21") }}
                </div>
                <hr style="margin-bottom: 0px" />
              </div>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="textFour">
                {{ $t("shipping.location.shipping.sectionTwoTitle") }}
              </p>
              <div class="row alignItems">
                <div class="condition1 icon mdl-cell--1-col">
                  <img src="@/assets/img/iconThree.png" alt="icon" />
                </div>
                <div class="condition1 mdl-cell--4-col">
                  <div>
                    <small class="textFive weightLabel">{{
                      $t("shipping.location.shipping.sectionTwoLabelOne")
                    }}</small>
                  </div>
                  <div>
                    <span class="textTwo"
                      >{{
                        $t(
                          "shipping.location.shipping.sectionTwoLabelOneWeight"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="condition2 mdl-cell--7-col">
                  <div>
                    <small class="textOne">{{
                      $t("shipping.location.shipping.sectionTwoLabelOneValue")
                    }}</small>
                  </div>
                  <div></div>
                </div>
              </div>
              <br />
              <div class="row alignItems">
                <div class="condition1 icon mdl-cell--1-col">
                  <img src="@/assets/img/iconThree.png" alt="icon" />
                </div>
                <div class="condition1 mdl-cell--4-col">
                  <div>
                    <small class="textFive weightLabel">{{
                      $t("shipping.location.shipping.sectionTwoLabelTwo")
                    }}</small>
                  </div>
                  <div>
                    <span class="textTwo"
                      >{{
                        $t(
                          "shipping.location.shipping.sectionTwoLabelTwoWeight"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="condition2 mdl-cell--7-col">
                  <div>
                    <small class="textOne">{{
                      $t("shipping.location.shipping.sectionTwoLabelTwoValue")
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="locationInfo" style="margin-bottom: 0px">
                <br />
                <i>{{
                  $t("shipping.location.shipping.sectionTwoLabelThree")
                }}</i
                ><br /><br />
              </p>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="textOne" style="padding-top: 0px">
                <img src="@/assets/img/iconOne.png" alt="icon" />
                {{ $t("shipping.location.shipping.sectionFourTitle") }}
              </p>
              <span class="locationInfo">
                {{ $t("shipping.location.shipping.sectionFourLabelOne") }}
                <strong>{{
                  $t("shipping.location.shipping.sectionFourValueOne")
                }}</strong
                ><br />
                {{ $t("shipping.location.shipping.sectionFourLabelTwo") }}
                <strong>{{
                  $t("shipping.location.shipping.sectionFourValueTwo")
                }}</strong>
              </span>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <hr style="margin-top: 0px" />
              <span class="locationInfo">{{
                $t("shipping.location.shipping.sectionFiveTextC21")
              }}</span>
              <br /><br />
            </div>
          </div>
          <div v-if="type == 'Shipping' && dc.dc_id == 4">
            <div class="mdl-grid">
              <div
                :class="[
                  IsMobile
                    ? 'mdl-cell mdl-cell--12-col'
                    : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
                ]"
              >
                <p class="textOne" style="padding-top: 0px">
                  <img src="@/assets/img/iconTwo.png" alt="icon" />
                  {{ $t("shipping.location.shipping.sectionOneTitle") }}
                </p>
                <span
                  class="textTwo"
                  v-html="$t('shipping.location.shipping.sectionOneE100')"
                ></span>
              </div>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <hr style="margin-bottom: 0px" />
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="textFour">
                {{ $t("shipping.location.shipping.sectionTwoTitle") }}
              </p>
              <div class="row alignItems">
                <div class="condition1 icon mdl-cell--1-col">
                  <img src="@/assets/img/iconThree.png" alt="icon" />
                </div>
                <div class="condition1 mdl-cell--4-col">
                  <div>
                    <small class="textFive weightLabel">{{
                      $t("shipping.location.shipping.sectionTwoLabelOne")
                    }}</small>
                  </div>
                  <div>
                    <span class="textTwo"
                      >{{
                        $t(
                          "shipping.location.shipping.sectionTwoLabelOneWeight"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="condition2 mdl-cell--7-col">
                  <div>
                    <small class="textOne">{{
                      $t("shipping.location.shipping.sectionTwoLabelOneValue")
                    }}</small>
                  </div>
                  <div></div>
                </div>
              </div>
              <br />
              <div class="row alignItems">
                <div class="condition1 icon mdl-cell--1-col">
                  <img src="@/assets/img/iconThree.png" alt="icon" />
                </div>
                <div class="condition1 mdl-cell--4-col">
                  <div>
                    <small class="textFive weightLabel">{{
                      $t("shipping.location.shipping.sectionTwoLabelTwo")
                    }}</small>
                  </div>
                  <div>
                    <span class="textTwo"
                      >{{
                        $t(
                          "shipping.location.shipping.sectionTwoLabelTwoWeight"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="condition2 mdl-cell--7-col">
                  <div>
                    <small class="textOne">{{
                      $t("shipping.location.shipping.sectionTwoLabelTwoValue")
                    }}</small>
                  </div>
                </div>
              </div>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <br />
              <p class="textOne" style="padding-top: 0px">
                <img src="@/assets/img/iconOne.png" alt="icon" />
                {{ $t("shipping.location.shipping.sectionFourTitle") }}
              </p>
              <span class="locationInfo">
                {{ $t("shipping.location.shipping.sectionFourLabelOne") }}
                <strong>{{
                  $t("shipping.location.shipping.sectionFourValueOne")
                }}</strong
                ><br />
                {{ $t("shipping.location.shipping.sectionFourLabelTwo") }}
                <strong>{{
                  $t("shipping.location.shipping.sectionFourValueTwo")
                }}</strong>
              </span>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <hr style="margin-top: 0px" />
              <span class="locationInfo">{{
                $t("shipping.location.shipping.sectionFiveTextE100")
              }}</span>
              <br /><br />
            </div>
          </div>

          <div v-if="type == 'Pickup' && dc.dc_id == 7">
            <div class="mdl-grid">
              <div
                :class="[
                  IsMobile
                    ? 'mdl-cell mdl-cell--12-col'
                    : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
                ]"
              >
                <p class="textOne" style="padding-top: 0px">
                  <img src="@/assets/img/iconTwo.png" alt="icon" />
                  {{ $t("shipping.location.pickup.sectionTwoTitle") }}
                </p>
                <span
                  class="textTwo"
                  v-html="$t('shipping.location.pickup.sectionTwoC21')"
                ></span>
              </div>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <br />
              <p class="textOne" style="padding-top: 0px">
                <img src="@/assets/img/iconOne.png" alt="icon" />
                {{ $t("shipping.location.pickup.sectionOneTitle") }}
              </p>
              <span class="locationInfo">
                <strong>{{
                  $t("shipping.location.pickup.sectionOneValue")
                }}</strong>
              </span>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="locationInfo">
                {{ $t("shipping.location.pickup.sectionFourText") }}
              </p>
              <hr style="margin-bottom: 0px" />
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="locationInfo" style="margin-bottom: 0px">
                <br />
                <i>{{ $t("shipping.location.pickup.sectionFiveText") }}</i
                ><br /><br />
              </p>
            </div>
          </div>

          <div v-if="type == 'Pickup' && dc.dc_id == 4">
            <div class="mdl-grid">
              <div
                :class="[
                  IsMobile
                    ? 'mdl-cell mdl-cell--12-col'
                    : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
                ]"
              >
                <p class="textOne" style="padding-top: 0px">
                  <img src="@/assets/img/iconTwo.png" alt="icon" />
                  {{ $t("shipping.location.pickup.sectionTwoTitle") }}
                </p>
                <span
                  class="textTwo"
                  v-html="$t('shipping.location.pickup.sectionTwoE100')"
                ></span>
              </div>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <br />
              <p class="textOne" style="padding-top: 0px">
                <img src="@/assets/img/iconOne.png" alt="icon" />
                {{ $t("shipping.location.pickup.sectionOneTitle") }}
              </p>
              <span class="locationInfo">
                <strong>{{
                  $t("shipping.location.pickup.sectionOneValue")
                }}</strong>
              </span>
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="locationInfo">
                {{ $t("shipping.location.pickup.sectionFourText") }}
              </p>
              <hr style="margin-bottom: 0px" />
            </div>

            <div
              :class="[
                IsMobile
                  ? 'mdl-cell mdl-cell--12-col'
                  : 'mdl-cell mdl-cell--10-col mdl-cell--2-offset',
              ]"
            >
              <p class="locationInfo" style="margin-bottom: 0px">
                <br />
                <i>{{ $t("shipping.location.pickup.sectionFiveText") }}</i
                ><br /><br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cdlContentButtonSingle">
        <div class="cdlButtonSingle green" id="nextStep" @click="goNext()">
          {{ $t("shipping.stepsButtonOne") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "MyLocation",
  components: {
    MyLoading,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dc_destination: { required },
    };
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dc_destination: null,
      dc_list: [],
      viewLoader: false,
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    submit($e, value, address, sel, isAvailable) {
      if (!isAvailable) {
        return;
      }
      let elms = document.getElementsByClassName("dc");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });

      elms = document.getElementsByClassName("cardLocation");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });

      document.getElementsByClassName("dc-" + sel)[0].classList.add("active");
      document
        .getElementsByClassName("cardLocation-" + sel)[0]
        .classList.add("active");

      this.dc_destination = value;

      this.$emit("update", {
        target_datacenter: value,
        dc_address: address,
      });
    },
    async goNext() {
      const result = await this.v$.$validate();
      window.scrollTo(0, 0);
      if (result) {
        this.v$.$reset();
        this.$emit("goNext");
      }
      return;
    },
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
  },
  activated() {
    window.scrollTo(0, 0);

    let he = {
      Accept: "application/json",
      Authorization: "Bearer " + this.$keycloak.idToken,
    };

    this.axios
      .get(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "tickets/v1/service-dc-details?service=" +
          this.type,
        { headers: he }
      )
      .then((response) => {
        this.dc_list = response.data;
        this.viewLoader = true;
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
