<template>
  <div id="MyHeaderPage">
    <div class="mdl-cell mdl-cell--8-col">
      <small v-if="calendar">{{ date }}</small>
      <h1>{{ title }}</h1>
      <p>{{ subtitle }}</p>
      <hr />
    </div>
    <div class="mdl-cell mdl-cell--4-col" v-if="button">
      <div
        class="contentButton"
        v-if="button == 'rh' && ctrlPerms(['remote_hands_nuovo'])"
      >
        <!-- New button for 'support' -->
        <div class="cdlButtonGreen" @click="newRh()">
          {{ $t("tickets.btnNew") }}
        </div>
      </div>

      <div
        class="contentButton"
        v-if="button == 'sp' && ctrlPerms(['shipping_nuovo'])"
      >
        <div class="cdlButtonGreen" @click="newSp()">
          {{ $t("tickets.btnNew") }}
        </div>
      </div>
      <!-- New button for 'support' -->
      <div class="contentButton" v-else-if="button == 'support'">
        <button @click="toggleModal()" class="cdlButtonGreen flex items-center">
          <span class="buttonText">{{ $t("crossConnects.btnNew") }}</span>
        </button>
      </div>
      <!-- New button for 'ingressi' -->
      <div
        class="contentBtnAccessDcAddressBook"
        v-if="button == 'adc' && ctrlPerms(['ingressi_nuovo'])"
      >
        <router-link
          :to="'/' + $t('nav.accessDcAddressBook')"
          class="mdl-navigation__link contentButton"
        >
          <div class="cdlButtonGreen" style="padding: 17px">
            <span class="material-icons iconAddressBook">auto_stories</span>
            {{ $t("accessDc.btnAddressBook") }}
          </div>
        </router-link>
        <div class="contentButton">
          <div class="cdlButtonGreen" @click="newAdc()">
            {{ $t("tickets.btnNew") }}
          </div>
        </div>
      </div>
    </div>
    <SupportTicketModal
      v-if="showModal"
      :isOpen="showModal"
      @update:isOpen="handleToggleModal"
    />
  </div>
</template>

<script>
import "@/assets/scss/MyHeaderPage.scss";
import SupportTicketModal from "@/components/CrossConnect/SupportTicketModal.vue";

export default {
  name: "MyHeaderPage",
  components: { SupportTicketModal },
  props: ["title", "subtitle", "button", "calendar", "showModal", "date"],
  methods: {
    handleToggleModal(newValue) {
      this.$emit("update:showModal", newValue);
    },

    toggleModal() {
      this.$emit("update:showModal", !this.showModal);
    },
    newRh() {
      return this.$router.push({ name: "RemoteHands" });
    },
    newSp() {
      return this.$router.push({ name: "ShippingType" });
    },
    newAdc() {
      return this.$router.push({ name: "AccessRequest" + this.$i18n.locale });
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
  },
};
</script>
