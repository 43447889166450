<template>
  <div
    class="secondaryLink"
    style="display: inline-flex; align-items: center; margin-left: 3rem"
  >
    <div>
      <span class="material-icons" style="font-size: 18px; padding-top: 5px"
        >arrow_back</span
      >
    </div>
    <div>
      <span @click="$router.back()">{{ $t("tickets.backToList") }} </span>
    </div>
  </div>
</template>
