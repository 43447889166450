<template>
  <div id="ManagementPanel" :class="{ active: view, close: !view }">
    <div class="mdl-grid noMargin">
      <div class="mdl-cell mdl-cell--1-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
        <span class="material-icons closePanel" @click="closePanel()">close</span>
      </div>
      <div class="mdl-cell mdl-cell--11-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
        <h3>{{ $t('group.titleSearchUser') }}</h3>
        <p class="subTitle">{{ $t('group.subTitleSearchUser') }}</p>

        <div class="space"></div>
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentInputFilter">
              <div class="contentIcon"><span class="material-icons">search</span></div>
              <div class="contentInput"><input type="text" :placeholder="$t('users.searchUsers')"
                  @keyup="searchByString" /></div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentUsersRole">
              <div class="mdl-grid noMargin">
                <div class="mdl-cell mdl-cell--4-col" v-for="n in 100" :key="n">
                  <div class="cardUserRole" :class="{ selected: n == 2, active: n == 10 || n == 15 }">
                    <div class="image">
                      <img width="25" src="https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png-768x805.jpg" alt="profile" />
                    </div>
                    <div class="nameUser">Nome Cognome utente</div>
                    <div class="check"><img width="25" src="@/assets/img/check.png" alt="check" /></div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="contentButton">
            <button class="cdlButtonGreen" @click="submit">
              {{ $t('group.confirmUsers') }}
            </button>
        </div>
        <p class="cancel" @click="closePanel()">{{ $t('users.cancel') }}</p>

      </div>
    </div>




  </div>
</template>

<script>
import "@/assets/scss/ManagementPanel.scss"

export default {
  name: 'MyManagementUser',
  props: ['view'],
  data() {
    return {
    }
  },
  methods: {
    closePanel() {
      this.$emit('close-users-role')
    }
  }
}
</script>