<template>
  <div id="MyModal" v-if="viewModal">
    <div class="contentModal success" :class="{ open: viewModal }">
      <span class="material-icons closeOrder" id="closeModalX" @click="closeModal()">close</span>
      <h3>{{ $t('modalUserDisabled.title') }}</h3>
      <p class="sub">{{ $t('modalUserDisabled.sub') }}</p>
      <p class="text">{{ $t('modalUserDisabled.text') }}</p>

      <div class="contentButton">
          <button id="submitCustomerEnd" class="cdlButton green" @click="closeModal()" 
          style="width: 100%">
            {{ $t('modalUserDisabled.accept') }}
          </button>
        </div>

    </div>
  </div>
</template>

<script>

import "@/assets/scss/MyModal.scss"

export default {
  name: 'ModalUserDisabled',
  props: ['viewModal'],
  data() {
    return {

    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>