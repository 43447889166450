import { defineStore } from 'pinia'

export const useNetworkCoverageKitStore = defineStore('NetworkCoverageKitStore', {
  state: () => {
    return {
      address: {},
      kit: {},
      color: 0,
      lang: "it"
    }
  },
  getters: {},
  actions: {
    async setAddress(address) {
      this.address = address;
      return;
    },
    async setKit(kit, lang) {
      this.kit = kit;
      this.lang = lang;
      return;
    }
  }
})