<template>
  <div>
    <h2>{{ $t("accessDc.titleAccessType") }}</h2>
    <p class="subTitle">{{ $t("accessDc.subTitleAccessType") }}</p>
    <span class="error" v-if="errorNoType">
      {{ $t("accessDc.errorNoType") }}
    </span>
    <div class="mdl-grid">
      <div
        class="mdl-cell mdl-cell--6-col boxAccessType"
        :class="{ active: typeAccess.indexOf('dc') > -1 }"
      >
        <div class="mdl-grid contentTypeTicketAccess">
          <div class="mdl-cell mdl-cell--12-col">
            <div
              class="buttonCheck"
              @click="setTypeAccess('dc')"
              :class="{ active: typeAccess.indexOf('dc') > -1 }"
            >
              <div id="type-1" class="check">
                <span class="material-icons">done</span>
              </div>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          ></div>
          <div class="mdl-cell mdl-cell--10-col">
            <p class="nameDc">{{ $t("accessDc.accessTypeOne") }}</p>
            <p class="nameDcDescription" v-if="!wizardData.isBio">
              {{ $t("accessDc.accessTypeOneDescription") }}
            </p>
            <p class="nameDcDescription" v-else>
              {{ $t("accessDc.accessTypeOneDescriptionIsBio") }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="mdl-cell mdl-cell--6-col boxAccessType"
        :class="{ active: typeAccess.indexOf('coworking') > -1 }"
        v-if="dc_id == 7"
      >
        <div class="mdl-grid contentTypeTicketAccess">
          <div class="mdl-cell mdl-cell--12-col">
            <div
              class="buttonCheck"
              @click="setTypeAccess('coworking')"
              :class="{ active: typeAccess.indexOf('coworking') > -1 }"
            >
              <div id="type-2" class="check">
                <span class="material-icons">done</span>
              </div>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          ></div>
          <div class="mdl-cell mdl-cell--10-col">
            <p class="nameDc">{{ $t("accessDc.accessTypeTwo") }}</p>
            <p class="nameDcDescription">
              {{ $t("accessDc.accessTypeTwoDescription") }}
            </p>

            <!-- ON HOLD -->

            <!-- <p class="nameDcDescription">
              {{ $t("accessDc.coworkingAccessDisabled") }}
            </p> -->

            <!-- ON HOLD -->
          </div>
        </div>
      </div>
      <div
        class="mdl-cell mdl-cell--6-col boxAccessType"
        :class="{ active: typeAccess.indexOf('parking') > -1 }"
        v-if="dc_id == 7"
        @mouseover="getOpacity(true)"
        @mouseleave="getOpacity(false)"
      >
        <div
          class="mdl-grid contentTypeTicketAccess"
          :class="{ noAvaible: !wizardData.isBio, opacity: getOpacityHover }"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <div
              class="buttonCheck"
              @click="setTypeAccess('parking')"
              :class="{ active: typeAccess.indexOf('parking') > -1 }"
            >
              <div id="type-3" class="check">
                <span class="material-icons">done</span>
              </div>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          ></div>
          <div class="mdl-cell mdl-cell--10-col">
            <p class="nameDc">{{ $t("accessDc.accessTypeThree") }}</p>
            <p class="nameDcDescription" v-if="!wizardData.isBio">
              {{ $t("accessDc.accessTypeThreeDescription") }}
            </p>
            <p class="nameDcDescription" v-else>
              {{ $t("accessDc.accessTypeThreeDescriptionIsBio") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="cdlContentButtonDouble">
      <div class="cdlButtonSingle width170" @click="goBack()">
        {{ $t("checkCoverage.stepsButtonTwo") }}
      </div>
      <div id="goNext" class="cdlButtonSingle width170" @click="goNext(false)">
        {{ $t("checkCoverage.stepsButtonOne") }}
      </div>
      <div
        class="cdlButtonSingle"
        @click="goNext(true)"
        v-if="wizardData.isCompleted"
      >
        {{ $t("accessDc.goEnd") }}
      </div>
    </div>
    <p
      class="deleteOrder"
      v-if="!wizardData.isCompleted"
      @click="ctrlModalAccessDcRemove()"
    >
      {{ $t("checkCoverage.stepsButtonThree") }}
    </p>
  </div>
</template>

<script>
import { useAccessDcStore } from "@/stores/AccessDcStore";

export default {
  name: "AccessType",
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      typeAccess: [],
      dc_id: 0,
      errorNoType: false,
      isBio: null,
      getOpacityHover: false,
    };
  },
  methods: {
    setTypeAccess(accessSelected) {
      this.errorNoType = false;

      let getElmIndex = this.typeAccess.indexOf(accessSelected);
      if (this.typeAccess.indexOf(accessSelected) > -1) {
        this.typeAccess.splice(getElmIndex, 1);
      } else {
        if (
          this.typeAccess.length == 0 &&
          accessSelected == "parking" &&
          !this.wizardData.isBio
        ) {
          return;
        }
        this.typeAccess.push(accessSelected);
      }

      if (
        this.typeAccess.length == 1 &&
        this.typeAccess[0] == "parking" &&
        !this.wizardData.isBio
      ) {
        this.typeAccess = [];
      }

      // this.typeAccess = this.typeAccess.filter((item, index) => this.typeAccess.indexOf(item) === index);
    },
    goBack() {
      this.$emit("goBack");
    },
    goNext(goSummary = null) {
      this.errorNoType = false;
      this.errorNoBio = false;
      if (this.typeAccess.length == 0) {
        this.errorNoType = true;
        return;
      }

      let accessStore = new useAccessDcStore();
      accessStore.form.typeAccess = this.typeAccess;

      this.$emit("update");
      this.$emit("goNext", { goSummary, typeAccess: this.typeAccess });
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    ctrlModalAccessDcRemove() {
      this.$emit("close");
    },
    getOpacity(value) {
      this.getOpacityHover = value;
    },
  },
  mounted() {
    this.dc_id = this.wizardData.form.dc.dc_id;
    if (this.wizardData.form.typeAccess.length > 0) {
      this.setTypeAccess(this.wizardData.form.typeAccess);
    }
  },
};
</script>
