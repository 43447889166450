import CryptoJS from 'crypto-js';

export function MyCrypto(string, ctrl) {
    const key = 'QuarantaQuattroG';
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    if (ctrl == 0) {
        // encrypt
        const enc = CryptoJS.AES.encrypt(string, keyutf, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.ECB
        });
        const encStr = enc.toString();
        return encStr;
    }
    else {
        // decrypt
        var decStr = CryptoJS.AES.decrypt(
            string,
            keyutf,
            {
                iv: iv,
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.ECB
            }
        ).toString(CryptoJS.enc.Utf8);
        return decStr;
    }
}