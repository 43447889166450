import Orders from "@/pages/Orders/Orders.vue";
import SubmittedOrderSummary from "@/components/Order/SubmittedOrderSummary.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesOrders() {
  return [
    {
      path: "/soluzioni-richieste",
      name: "Ordersit",
      component: Orders,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "orders",
            url: "",
          },
        ],
        indexRoute: "soluzioni-richieste",
        permissions: [
          "ordini"
        ],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/solutions-requested",
      name: "Ordersen",
      component: Orders,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "orders",
            url: "",
          },
        ],
        indexRoute: "solutions-requested",
        permissions: [
          "ordini"
        ],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/soluzione-dettaglio/:order_id/:item_id",
      name: "OrderDetailsit",
      component: SubmittedOrderSummary,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "orders",
            url: "/soluzioni-richieste",
          },
          {
            name: "summaryOrderLine",
            url: "",
          },
        ],
        indexRoute: "soluzioni-richieste",
        permissions: [
          "verifica_copertura",
          "stato_linee",
          "cross_connect_lista",
          "cloud",
          "voip"
        ],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/solutions-detail/:order_id/:item_id",
      name: "OrderDetailsen",
      component: SubmittedOrderSummary,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "orders",
            url: "/solutions-requested",
          },
          {
            name: "summaryOrderLine",
            url: "",
          },
        ],
        indexRoute: "solutions-detail",
        permissions: [
          "verifica_copertura",
          "stato_linee",
          "cross_connect_lista",
          "cloud",
          "voip"
        ],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}