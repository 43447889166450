<template>
  <div class="">
    <div class="contentForm">
      <h2 class="title">{{ $t("remoteHands.titleStep1") }}</h2>
      <p class="subTitle">{{ $t("remoteHands.subTitleStep1") }}</p>

      <p class="error" v-if="error">
        {{ $t("remoteHands.titleStep1") }} {{ $t("checkCoverage.isRequired") }}
      </p>

      <div class="space"></div>

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col" style="margin-left: 0">
          <div v-for="(act, keyAct) in activity" :key="keyAct">
            <p class="labelTwo">{{ act.name_group }}</p>
            <div class="contentActivity">
              <div
                v-for="(item, keyItem) in act.list"
                :key="keyItem"
                :class="['itemActivity-' + keyAct + '-' + keyItem]"
                class="itemActivity"
                @click="
                  setActivity(item, keyAct + '-' + keyItem, act.name_group)
                "
                :style="[
                  'background: transparent linear-gradient(121deg, ' +
                    act.color[0] +
                    ' 0%, ' +
                    act.color[1] +
                    ' 100%) 0% 0% no-repeat padding-box;',
                ]"
              >
                <p>{{ item.name }}</p>
              </div>
            </div>
            <br /><br />
          </div>
        </div>
      </div>

      <MyLoading :viewLoader="this.viewLoader" type="" />

      <div class="cdlContentButtonSingle">
        <div class="cdlButtonSingle green" id="nextStep" @click="goNext()">
          {{ $t("checkCoverage.stepsButtonOne") }}
        </div>
      </div>
      <p class="deleteOrder" @click="cancelOrder()">
        {{ $t("remoteHands.deleteTicket") }}
      </p>
    </div>
  </div>
</template>

<script>
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "ActivityRh",
  components: {
    MyLoading,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        activity_type: "",
        activity_group: "",
      },
      activity: [],
      error: false,
      viewLoader: false,
    };
  },
  methods: {
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
    async goNext() {
      window.scrollTo(0, 0);
      if (this.fields.activity_type != "") {
        this.$emit("goNext");
        return;
      }
      this.error = true;
      return;
    },
    setActivity(value, index, nameGroup) {
      this.error = false;
      Object.assign(this.fields, {
        activity_type: value,
        activity_group: nameGroup,
      });
      this.$emit("update", this.fields);

      let itemActivity = document.getElementsByClassName("itemActivity");
      Object.entries(itemActivity).forEach((elm) => {
        elm[1].classList.remove("active");
      });

      if( document.getElementsByClassName("itemActivity-" + index)[0] ) {
        document
          .getElementsByClassName("itemActivity-" + index)[0]
          .classList.add("active");
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      }

    },
    cancelOrder() {
      return this.$router.push({ name: "RemoteHandsList" + this.$i18n.locale });
    },
  },
  activated() {
    window.scrollTo(0, 0);

    Object.assign(this.fields, {
      activity_type: this.wizardData.activity_type,
    });

    if (this.fields.activity_type == "") {
      let itemActivity = document.getElementsByClassName("itemActivity");
      Object.entries(itemActivity).forEach((elm) => {
        elm[1].classList.remove("active");
      });
    }

    let he = {
      Accept: "application/json",
      Authorization: "Bearer " + this.$keycloak.idToken,
    };

    this.axios
      .get(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "tickets/v1/service-rh-details",
        { headers: he }
      )
      .then((response) => {
        this.activity = response.data;
        if( localStorage.getItem("DcAccessRemoteHands") ) {
          let activity = this.activity.filter(item => { return item.name_group === "B - Basic" } )[0];

          let indexOne = this.activity.map(item => item.name_group).indexOf("B - Basic");

          let string = "Access to the warehouse accompanied by CDLAN";
          if( this.$i18n.locale == 'it' ) {
            string = "Accesso al deposito merce accompagnato da CDLAN";
          }

          let indexTwo = activity.list.map(item => item.name).indexOf(string);

          if( activity && activity.list.length > 0 ) {
            this.setActivity(
              activity.list.filter((item) => item.name == string )[0],
              indexOne + '-' + indexTwo,
              activity.name_group
            );
            this.viewLoader = true;
            this.$emit("goNext");
            return;
          }
        }
        this.viewLoader = true;
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
