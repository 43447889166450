<template>
  <div id="LinesDetail" class="detailContainer">
    <div class="contentSummary">
      <div :class="{ padding60: !IsMobile() }">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <MyHeaderPage
              :title="$t('lines.detailTitle')"
              :subtitle="$t('lines.detailSubTitle')"
            />
          </div>
        </div>
        <div class="infoKit orange" style="padding: 40px 0px">
          <div class="mdl-grid">
            <div
              class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
            >
              <div class="mdl-cell mdl-cell--12-col">
                <div class="summaryItem">
                  <div class="contentImage">
                    <img :src="getIcon(item.tipo_conn)" alt="icon" width="45" />
                  </div>
                  <div style="flex-direction: column">
                    <div>
                      <span class="labelTwo"
                        >{{ $t("checkCoverage.detailLabelTech") }}
                        <span class="textTwo"> {{ item.tipo_conn }}</span></span
                      >
                    </div>
                    <div>
                      <span class="labelTwo"
                        >{{ $t("lines.provider") }}
                        <span class="textTwo"> {{ item.fornitore }}</span></span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <hr />
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <div class="summaryItem">
                  <div class="contentImage">
                    <img
                      src="@/assets/img/icon_pin.png"
                      alt="location"
                      width="45"
                    />
                  </div>
                  <div v-if="item.indirizzo">
                    <div>
                      <span class="labelOne">{{
                        $t("checkCoverage.detailLabelAddress")
                      }}</span>
                    </div>
                    <div>
                      <span class="textOne">
                        {{ item.particella }} {{ item.indirizzo }},
                        {{ item.civico }}
                      </span>
                    </div>
                    <span class="textTwo">
                      {{ item.cap }} {{ item.comune }} ({{ item.provincia }})
                    </span>
                  </div>
                </div>
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <hr />
              </div>
            </div>
            <div class="mdl-cell mdl-cell--2-col"></div>
            <div
              class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
            >
              <div class="mdl-cell mdl-cell--12-col">
                <div
                  class="mdl-grid"
                  style="align-items: center; justify-content: space-between"
                >
                  <div class="mdl-cell--col-10">
                    <span class="detailOne">
                      <img
                        src="@/assets/img/icon_kit_five.png"
                        alt="icon"
                        width="43"
                      />{{ $t("checkCoverage.speedDownload") }}</span
                    >
                  </div>

                  <div class="mdl-cell--col-2">
                    <small class="detailTwo">Mbps</small><br />
                    <span class="detailThree">{{ item.banda_down }}</span>
                  </div>
                </div>
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <hr />
              </div>
              <div class="mdl-cell mdl-cell--12-col">
                <div
                  class="mdl-grid"
                  style="align-items: center; justify-content: space-between"
                >
                  <div class="mdl-cell--col-10">
                    <span class="detailOne"
                      ><img
                        src="@/assets/img/icon_kit_four.png"
                        alt="icon"
                        width="43"
                      />{{ $t("checkCoverage.speedUpload") }}</span
                    >
                  </div>

                  <div class="text-right">
                    <small class="detailTwo">Mbps</small><br />
                    <span class="detailThree">{{ item.banda_up }}</span>
                  </div>
                </div>
              </div>
              <div class="mdl-cell mdl-cell--12-col" style="margin-top: 25px">
                <hr />
              </div>
            </div>
          </div>
        </div>

        <div class="mdl-grid" v-if="false">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>{{ $t("lines.additionalDetails") }}</h2>
          </div>
        </div>

        <div class="mdl-grid" v-if="false">
          <div class="mdl-cell mdl-cell--6-col">
            <span class="title">{{ $t("lines.detail.sla") }}</span>
            <span class="value"> {{ item.sla }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <span class="title">{{ $t("lines.detail.device") }}</span>
            <span class="value"> {{ item.apparato }}</span>
          </div>
        </div>
        <br v-if="false" />
        <hr v-if="false" />

        <div class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>{{ $t("lines.detail.titleReferences") }}</h2>
          </div>
        </div>

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{
              $t("checkCoverageSummary.customerDetails")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <span class="title">{{ $t("companies.tableNameSurname") }}</span>
            <span class="value brMobile">&nbsp;{{ item.cliente }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col" v-if="item.piva">
            <span class="title">{{ $t("checkCoverage.customerEndPIva") }}</span>
            <span class="value brMobile">&nbsp;{{ item.piva }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col" v-if="item.codfisc">
            <span class="title">{{
              $t("checkCoverage.customerEndFiscalCode")
            }}</span>
            <span class="value brMobile">&nbsp;{{ item.codfisc }}</span>
          </div>
        </div>

        <hr v-if="false" />

        <div class="mdl-grid" v-if="false">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>{{ $t("lines.detail.orderInformationTitle") }}</h2>
          </div>
        </div>

        <div class="mdl-grid" v-if="false">
          <div class="mdl-cell mdl-cell--6-col">
            <span class="title">{{ $t("lines.detail.orderCode") }}</span>
            <span class="value brMobile">{{ item.codice_ordine }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <span class="title">{{ $t("lines.detail.orderDate") }}</span>
            <span class="value brMobile">{{ item.data_ord_fornitore }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <span class="title">{{
              $t("lines.detail.cdLanActivationDate")
            }}</span>
            <span class="value brMobile">{{ item.data_att_cdlan }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <span class="title">{{
              $t("lines.detail.telecomActivationDate")
            }}</span>
            <span class="value brMobile">{{ item.data_att_telecom }}</span>
          </div>
        </div>

        <hr />
      </div>

      <div>
        <div
          class="contentButton"
          style="margin: 25px auto; text-align: center"
        >
          <router-link :to="'/' + $t('nav.listLinesUrl')">
            <button class="cdlButton">{{ $t("lines.backToLines") }}</button>
          </router-link>
        </div>
      </div>

      <MyLoading :viewLoader="viewSubmit" v-if="!this.item" />
      <MyError
        :viewError="viewError"
        :message="$t('checkCoverageSummary.errorGeneric')"
      />
    </div>
  </div>
</template>

<script>
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/LinesDetailSummary.scss";
import MyError from "@/components/MyError.vue";

export default {
  name: "LinesDetail",
  components: {
    MyHeaderPage,
    MyLoading,
    MyError,
  },
  data() {
    return {
      item: {},
      IsMobile: this.$IsMobile,
      viewLoader: false,
      viewSubmit: true,
      viewError: false,
      messageError: "",
      total: 0,
      totalMonths: 0,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
    };
  },
  methods: {
    getIcon(technology) {
      let rls = this.$KitStyle(technology, "icon");
      return rls;
    },
    getLineDetail() {
      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}assets/v1/lines/${
            this.$route.params.id
          }`,
          { headers: this.he }
        )
        .then((response) => {
          this.item = response.data[0];
          this.viewLoader = true;
        });
    },
    getDownUp(download, upload) {
      let down = download + "Mb";
      if (download >= 1000) {
        down = download / 1000 + "Gb";
      }

      let up = upload + "Mb";
      if (upload >= 1000) {
        up = upload / 1000 + "Gb";
      }

      return down + "/" + up;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getLineDetail();
  },
};
</script>
