<template>
  <div id="MyModal" v-if="viewModal">
    <div class="contentModal open success modalCrm"><span class="material-icons closeOrder" id="closeModalX"
        @click="closeModal()">close</span>
      <h3 class="titleModal">{{ $t("accessDc.modalWarehouse.title") }}</h3>
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col sub" style="font-size: 1.4rem; line-height: normal;">
          {{ $t("accessDc.modalWarehouse.text") }}
        </div>
      </div>
      <div class="contentButton"><button id="btnModal" class="cdlButtonGreen" @click="closeModal()">{{ $t("accessDc.modalWarehouse.btn") }}</button><br><br>
      </div>
    </div>
  </div>
</template>

<script>

import "@/assets/scss/MyModal.scss"

export default {
  name: 'ModalWarehouse',
  props: ['viewModal'],
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
      this.$emit('cancel');
    }
  }
}
</script>