<template>
  <div id="ColocationList">
    <table>
      <thead>
        <tr>
          <th>{{ $t("colocation.detail.dc") }}</th>
          <th>{{ $t("colocation.list.room") }}</th>
          <th v-if="!IsMobile">{{ $t("colocation.list.rack") }}</th>
          <th v-if="!IsMobile"></th>
          <th v-if="!IsMobile"></th>
        </tr>
      </thead>
      <tbody v-if="this.viewLoader && this.list && this.list.length">
        <tr></tr>
      </tbody>
      <tbody v-else-if="this.viewLoader">
        <tr>
          <td colspan="6" style="text-align: center">
            <span>{{ $t("crossConnects.TBD") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <br /><br />
      <MyLoading :viewLoader="this.viewLoader" type="" />
      <MyError :viewError="viewError" :message="$t('tickets.error')" />
    </div>
    <paginator
      v-if="totalPage > 1"
      :total-page="totalPage"
      :current-page="currentPage"
      @change-page="this.changePage"
      @prev-page="this.prevPage"
      @next-page="this.nextPage"
    ></paginator>
  </div>
</template>

<script>
import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import "@/assets/scss/Solutions.scss";

export default {
  name: "CrossConnectList",
  components: {
    MyLoading,
    MyError,
    Paginator,
  },
  props: {
    list: Array,
    viewLoader: Boolean,
    viewError: Boolean,
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      messageError: "",
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage == this.totalPage) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) + 1;
    },
    prevPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) - 1;
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.list.length / this.pageSize) > 1
        ? Math.ceil(this.list.length / this.pageSize)
        : 1;
    },
  },
};
</script>
