<template>
  <thead>
    <tr>
      <th v-if="!this.$IsMobile()">
        {{ $t("ticketsList.default.created_at") }}
      </th>
      <th v-if="!this.$IsMobile()">
        {{ $t("ticketsList.default.title") }}
      </th>
      <th v-if="!this.$IsMobile()">
        {{ $t("ticketsList.default.asset_type") }}
      </th>
      <th>{{ $t("ticketsList.default.solution") }}</th>
      <th>{{ $t("ticketsList.default.reference") }}</th>
      <slot name="more"></slot>
      <th v-if="!this.$IsMobile()">{{ $t("ticketsList.default.status") }}</th>
      <th v-if="!this.$IsMobile()" class="refreshList">
        <span
          class="material-icons"
          @click="refresh()"
          v-tooltip:top.tooltip="$t('ticketsList.tooltip')"
          >autorenew</span
        >
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "AssistanceTableHead",
  data() {
    return {
      IsMobile: this.$IsMobile,
    };
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>
