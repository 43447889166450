<template>
  <div id="InvoiceList" class="tableList">
    <table>
      <thead>
        <tr>
          <th>{{ $t("invoices.nDoc") }}</th>
          <th>{{ $t("invoices.typeDoc") }}</th>
          <th v-if="!IsMobile()">{{ $t("invoices.dateDoc") }}</th>
          <th v-if="!IsMobile()"></th>
        </tr>
      </thead>
      <tbody
        v-if="
          !IsMobile() && viewLoader && list_paginate && list_paginate.length
        "
      >
        <tr
          v-for="(invoice, keyItem) in list_paginate"
          :key="keyItem"
          :class="{ odd: keyItem % 2 }"
        >
          <td class="mdl-cell mdl-cell--2-col contentTable">
            {{ getNDoc(invoice.nome_file) }}
          </td>
          <td class="mdl-cell mdl-cell--3-col contentTable">
            {{ invoice.type }}
          </td>
          <td class="mdl-cell mdl-cell--3-col contentTable">
            {{ getDateOrder(invoice.data_file) }}
          </td>
          <td>
            <div
              class="cdlButton"
              @click="dowloadFile(invoice.download, gamma)"
            >
              {{ $t("invoices.downDoc") }}
            </div>
          </td>
        </tr>
      </tbody>
      <tbody
        v-else-if="
          IsMobile() && viewLoader && list_paginate && list_paginate.length
        "
      >
        <MobileRow v-for="(item, keyItem) in list_paginate" :key="keyItem">
          <template #first-col>{{ getNDoc(item.nome_file) }}</template>
          <template #second-col>{{ item.type }}</template>
          <template #url-label>
            <div class="cdlButton" @click="dowloadFile(item.download, gamma)">
              {{ $t("invoices.downDoc") }}
            </div></template
          >
        </MobileRow>
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="5" style="text-align: center">
            <MyLoading
              :viewLoader="viewLoader"
              type=""
              :fixSmallTable="false"
            />
            <span v-if="viewLoader">{{ $t("invoices.noDoc") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <Paginator
      v-if="totalCount > 1 && viewLoader"
      :total-page="totalCount"
      :current-page="current_page"
      @change-page="changePage"
      @prev-page="beforePage"
      @next-page="nextPage"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const gamma = computed(() => {
  return useUserStore().user.account.id;
});
</script>

<script>
import "@/assets/scss/Tables.scss";
import moment from "moment";
import Paginator from "@/components/Paginator.vue";
import MyLoading from "@/components/MyLoading.vue";
import MobileRow from "@/components/Tables/TicketsMobileRow.vue";

export default {
  name: "InvoiceList",
  props: {
    list: Array,
    listPaginate: Array,
    viewLoader: Boolean,
    viewError: Boolean,
    totalCount: Number,
  },
  components: {
    MyLoading,
    Paginator,
    MobileRow,
  },
  data() {
    return {
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      current_page: 1,
      list_paginate: [],
      itemForPage: 10,
      IsMobile: this.$IsMobile,
    };
  },
  methods: {
    getNDoc(value) {
      let nameFile = value.split(".")[0];
      let nameExplode = nameFile.split("_");
      return nameExplode[1] ?? nameFile;
    },
    dowloadFile(fileId, gammaId) {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "invoices/v1/dl-invoice?system_id=" +
            fileId +
            "&account_id=" +
            gammaId,
          { headers: this.he }
        )
        .then((rls) => {
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + rls.data.content; //Image Base64 Goes here
          a.download = rls.data.file_name; //File name Here
          a.click(); //Downloaded file
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getDateOrder(dateOrder) {
      let nameDay = moment(
        new Date(dateOrder),
        "YYYY-MM-DD",
        this.$i18n.locale,
        true
      ).format("dddd");
      let date = moment(
        new Date(dateOrder),
        "YYYY-MM-DD, hh:mm:ss",
        this.$i18n.locale,
        true
      ).format("LL");
      return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
    },
    changePage(page) {
      this.current_page = parseInt(page);
      let beforeIndex = parseInt(page) - 1;
      this.list_paginate = this.list.slice(
        beforeIndex * this.itemForPage,
        page * this.itemForPage
      );
    },
    nextPage() {
      if (this.current_page == this.totalCount) {
        return;
      }
      this.current_page = parseInt(this.current_page) + 1;
    },
    beforePage() {
      if (this.current_page == 1) {
        return;
      }
      this.current_page = parseInt(this.current_page) - 1;
    },
  },
  watch: {
    listPaginate: {
      handler(newValue) {
        this.list_paginate = newValue;
      },
      deep: true,
    },
  },
};
</script>
