<template>
  <div>
    <span class="required draftSaved" v-if="!this.wizardData.isCompleted">
      <span class="material-icons">done</span>
      <span>{{ $t("checkCoverage.draftSaved") }}</span>
    </span>

    <h2>{{ $t("checkCoverage.titleRelatedServices") }}</h2>
    <p class="subTitle" v-if="false">
      {{ $t("checkCoverage.subTitleRelatedServices") }}
    </p>

    <div class="space"></div>

    <div v-for="(elems, index) in kit.related_products" :key="index">
      <div>
        <span class="labelOne"
          >{{ elems.group_name }}{{ elems.required ? "*" : "" }}</span
        >
        <p
          class="error errorExc"
          :class="['error-' + buildNameGroup(elems.group_name)]"
        >
          {{ elems.group_name }} {{ $t("checkCoverage.isRequired") }}
        </p>
        <div v-for="(product, keyProduct) in elems.products" :key="keyProduct">
          <div class="contentOptional">
            <div class="contentInputInline container">
              <div class="circle contentInputRadioLabel">
                <div
                  class="buttonRadio product"
                  @click="
                    submit(
                      $event,
                      product,
                      index,
                      keyProduct,
                      elems.group_name,
                      this.wizardData
                    )
                  "
                  :class="[
                    'product-' + index,
                    'product-' + index + '-' + keyProduct,
                    'product-' + product.id,
                  ]"
                  :id="['product-' + index + '-' + keyProduct]"
                >
                  <div></div>
                </div>
                <span class="labelInputRadio">{{ product.title }}</span>
              </div>
              <div class="setQty" :class="['qty-' + index + '-' + keyProduct]">
                <div class="icon">
                  <span
                    class="material-icons"
                    @click="
                      ServiceQty(
                        elems.group_name,
                        'remove',
                        this.wizardData,
                        product.min_qty,
                        product.max_qty == -1 ? 10000 : product.max_qty
                      )
                    "
                    >remove</span
                  >
                </div>
                <div class="valueQty">
                  {{
                    getQty(elems.group_name, product.min_qty, this.wizardData)
                  }}
                </div>
                <div class="icon">
                  <span
                    class="material-icons"
                    @click="
                      ServiceQty(
                        elems.group_name,
                        'add',
                        this.wizardData,
                        product.min_qty,
                        product.max_qty == -1 ? 10000 : product.max_qty
                      )
                    "
                    >add</span
                  >
                </div>
              </div>
            </div>
            <hr />
            <div class="contentMiddle">
              <table class="contentPriceTable">
                <tr>
                  <td>
                    <span class="labelThree">{{
                      $t("checkCoverage.priceTantum")
                    }}</span>
                  </td>
                  <td>
                    <p class="pValue">
                      €{{ this.$PriceClean(product.price.nrc ?? 0) }}
                    </p>
                    <p class="pInfo" v-if="false">
                      {{ $t("checkCoverage.tantum") }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="labelThree">{{
                      $t("checkCoverage.priceMonth")
                    }}</span>
                  </td>
                  <td>
                    <p class="pValue">
                      €{{ this.$PriceClean(product.price.mrc ?? 0) }}
                    </p>
                    <p class="pInfo" v-if="false">
                      {{ $t("checkCoverage.forMonth") }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>

    <div
      :class="{
        cdlContentButtonSingle: !this.wizardData.isCompleted,
        cdlContentButtonDouble: this.wizardData.isCompleted,
      }"
    >
      <div
        id="buttonRelatedServices"
        class="cdlButtonSingle"
        :class="{ width170: this.wizardData.isCompleted }"
        @click="goNext(false)"
      >
        {{ $t("checkCoverage.stepsButtonOne") }}
      </div>
      <div
        id="buttonCompleteRelatedServices"
        class="cdlButtonSingle"
        @click="goNext(true)"
        v-if="this.wizardData.isCompleted"
      >
        {{ $t("checkCoverage.goEnd") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyRelatedServices",
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
    kit: Object,
    stepData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueRelatedServices: {},
      required: this.kit.related_products
        ? this.kit.related_products.filter((el) => el.required)
        : false
      // note: this.wizardData.notes,
    };
  },
  methods: {
    submit($e, value = null, index, indexProduct, group, wizardData) {
      let myObj = {
        group_name: group,
        products: value
      }

      if( document.getElementsByClassName('product-' + value.id)[0].classList.contains("active") && ($e.pointerType == 'mouse' || $e.pointerType == 'touch') ) {
        // Ri-click
        document.getElementsByClassName('product-' + value.id)[0].classList.remove("active");
        if (value.min_qty != value.max_qty) {
          document
            .getElementsByClassName("qty-" + index + "-" + indexProduct)[0]
            .classList.remove("active");
        }
        wizardData.relatedServices.forEach((elm, i) => {
          if( elm.group_name ==  myObj.group_name ) {
            wizardData.relatedServices.splice(i, 1);
          }
        });
        this.$emit('update', {
          relatedServices: wizardData.relatedServices
        });

      }
      else {
        let elms = document.getElementsByClassName('product-' + index);
        Object.entries(elms).forEach(elm => {
          elm[1].classList.remove("active");
        });

        document.getElementsByClassName('product-' + value.id)[0].classList.add("active");
        if (value.min_qty != value.max_qty) {
          document.getElementsByClassName('qty-' + index + '-' + indexProduct)[0].classList.add("active");
        }

        if (wizardData.relatedServices.length > 0) {
          wizardData.relatedServices = wizardData.relatedServices.filter((el) => {
            if (el.group_name != myObj.group_name) {
              return el;
            }
          });
        }

        wizardData.relatedServices.push(myObj);

        this.$emit('update', {
          relatedServices: wizardData.relatedServices
        });
      }

    },
    // submitNote() {
    //   this.$emit("update", {
    //     notes: this.note,
    //   });
    // },
    goBack() {
      this.$emit("goBack");
    },
    goNext(goSummary = null) {
      let elms = document.getElementsByClassName("error");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });

      let rls;
      let ctrlError = 0;
      if (this.required.length > 0) {
        this.required.forEach((el) => {
          rls = this.wizardData.relatedServices.find(
            (element) => element.group_name == el.group_name
          );
          if (!rls) {
            document
              .getElementsByClassName(
                "error-" + this.buildNameGroup(el.group_name)
              )[0]
              .classList.add("active");
            ctrlError++;
          }
        });
      }

      if (ctrlError == 0) {
        this.$emit("goNext", { goSummary });
      }
      window.scrollTo(0, 0);
    },
    cancelOrder() {
      this.$emit("close");
    },
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
    ServiceQty(group_name, what, wizardData, min, max) {
      let index = wizardData.relatedServices.findIndex(
        (el) => el.group_name == group_name
      );
      if (index > -1) {
        let elm = wizardData.relatedServices[index];
        if (what == "add") {
          if (elm.quantity) {
            if (elm.quantity < max) {
              elm.quantity++;
            }
          } else {
            elm.quantity = parseInt(min);
            elm.quantity++;
          }
        } else {
          if (elm.quantity) {
            if (elm.quantity > min) {
              elm.quantity--;
            }
          } else {
            elm.quantity = parseInt(min);
          }
        }
        this.$emit("update", {
          relatedServices: wizardData.relatedServices,
        });
      }
    },
    getQty(group_name, min, wizardData) {
      let index = wizardData.relatedServices.findIndex(
        (el) => el.group_name == group_name
      );
      if (index > -1) {
        if (!wizardData.relatedServices[index].quantity) {
          wizardData.relatedServices[index].quantity = parseInt(min);
          this.$emit("update", {
            relatedServices: wizardData.relatedServices,
          });
        }
        return this.wizardData.relatedServices[index].quantity;
      }
    },
    goEnd() {
      localStorage.setItem(
        "cdlOrderNetworkCoverage",
        JSON.stringify({ form: this.form, step: 7 })
      );
      return this.$router.push({
        name: "OrderNetworkCoverageSummary" + this.$i18n.locale,
      });
    },
    buildNameGroup(groupName) {
      return groupName.replaceAll(" ", "-");
    },
    label(ev) {
      this.$LabelInput(ev);
    },
  },
  activated() {
    if (localStorage.getItem("cdlOrderNetworkCoverage")) {
      let recoveryValue = JSON.parse(
        localStorage.getItem("cdlOrderNetworkCoverage")
      );

      if (recoveryValue.form.relatedServices) {
        recoveryValue.form.relatedServices.forEach((el) => {
          if (document.getElementsByClassName("product-" + el.products.id)[0]) {
            document
              .getElementsByClassName("product-" + el.products.id)[0]
              .click();
          }
        });
        this.$emit("update", {
          relatedServices: recoveryValue.form.relatedServices,
        });
      }

      // if (recoveryValue.form.notes != null) {
      //   this.note = recoveryValue.form.notes;
      //   let allInputs = document.getElementsByTagName("textarea");
      //   setTimeout(() => {
      //     for (let i = 0; i < allInputs.length; i++) {
      //       if (allInputs[i].value != "") {
      //         allInputs[i].classList.add("not-empty");
      //       }
      //     }
      //   }, 500);
      // }
    }
  },
};
</script>
