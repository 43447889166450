
export function KitStyle(technology, type) {
  if (type == 'class') {
    switch (technology) {
      case "FTTH":
        return { pink: true };
      case "ADSL":
        return { purple: true };
      case "FTTC 100":
        return { green: true };
      case "FTTC 200":
        return { yellow: true };
      case "SHDSL":
        return { blu: true };
      default:
        return { orange: true };
    }
  }
  else {
    // icon
    var images = require.context('../assets/img/', false, /\.png$/);
    if (!technology) {
      technology = "FTTH";
    }
    try {
      return images('./kit_icon_' + technology.replace(new RegExp(" ", 'g'), "_") + ".png");
    } catch (error) {
      console.log(error);
      return images('./kit_icon_ADSL_ETH.png');
    }
  }
}