<template>
    <svg id="Step2" data-name="icona info  in corso" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle id="Ellisse_153" data-name="Ellisse 153" class="setCircle" cx="17.5" cy="17.5" r="17.5" fill="#e4effc"/>
        <g id="info_black_24dp" transform="translate(8 8)">
            <path id="Tracciato_1354" data-name="Tracciato 1354" d="M0,0H19V19H0Z" fill="none"/>
            <path id="Tracciato_1355" data-name="Tracciato 1355" class="setColor" d="M9.917,2a7.917,7.917,0,1,0,7.917,7.917A7.92,7.92,0,0,0,9.917,2Zm0,11.875a.794.794,0,0,1-.792-.792V9.917a.792.792,0,0,1,1.583,0v3.167A.794.794,0,0,1,9.917,13.875Zm.792-6.333H9.125V5.958h1.583Z" transform="translate(-0.417 -0.417)" fill="#134c7a"/>
        </g>
    </svg>
</template>

<script>

export default {
  name: 'MyStep2'
}
</script>