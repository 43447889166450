import Users from "@/pages/Users.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesUsers() {
  return [
    {
      path: "/gestione-utenti",
      name: "Usersit",
      component: Users,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "users",
            url: "",
          },
        ],
        indexRoute: "gestione-utenti",
        permissions: ["utenti", "ruoli"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/user-management",
      name: "Usersen",
      component: Users,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "users",
            url: "",
          },
        ],
        indexRoute: "user-management",
        permissions: ["utenti", "ruoli"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}