<template>
  <div id="solutionService">
    <div v-html="$t(text?? 'noSolutionsServices.default')"></div>
    <div class="contentButton" style="margin-left: 0;" v-if="viewBtn">
      <div class="cdlButtonGreen">{{ $t('noSolutionsServices.btn') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CNoSolutionsService',
  props: ['text', 'viewBtn'],
}
</script>