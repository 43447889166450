import Invoice from "@/pages/Invoice/Invoice.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesInvoices() {
  return [
    {
      path: "/fatture",
      name: "Invoiceit",
      component: Invoice,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "invoice",
            url: "",
          },
        ],
        indexRoute: "fatture",
        permissions: ["fatture"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/invoices",
      name: "Invoiceen",
      component: Invoice,
      meta: {
        breadcrumbs: [
          {
            name: "management",
            url: "",
          },
          {
            name: "invoice",
            url: "",
          },
        ],
        indexRoute: "invoices",
        permissions: ["fatture"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}