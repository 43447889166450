<template>
  <div class="contentPrimary" v-show="name">
    <img src="@/assets/img/icon_info.png" alt="icon" width="45" />
    <p class="label">{{ $t("help.solutionLabel") }}</p>
    <p class="text">{{ name }}</p>
    <div v-show="address">
      <hr />
      <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
      <p class="label">{{ $t("checkCoverage.detailLabelAddress") }}</p>
      <p class="text">{{ dc }}</p>
      <p class="text">{{ address }}</p>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    name: String,
    address: String,
    dc: String,
  },
};
</script>
