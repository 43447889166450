<template>
  <div>
    <h2>{{ $t('accessDc.titleWhen') }}</h2>
    <p class="subTitle" v-if="false">{{ $t('accessDc.subTitleWhen') }}</p>
    <div class="mdl-grid" style="margin: 0; padding: 0">
      <div class="mdl-cell mdl-cell--4-col">
        <span class="labelFour">{{ $t('accessDc.dateAccess') }}</span>
        <div class="cdl-input" style="margin-top: 0;">
          <input type="date" name="dateAccess" v-model="form.dateAccess" class="not-empty" :min="minDate" />
          <span class="error" v-if="(this.v$.form.dateAccess.$invalid && this.v$.form.dateAccess.$dirty)">
            {{ $t('accessDc.dateAccess') }} {{ $t('checkCoverage.isRequired') }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--4-col" :class="{ opacity: typeAccess && (typeAccess.indexOf('dc') == -1 && typeAccess.indexOf('coworking') > -1) }">
        <span class="labelFour">{{ $t('accessDc.fromTime') }}</span>
        <div class="cdl-input" style="margin-top: 0;">
          <input type="time" name="fromTime" class="not-empty" v-model="form.fromTime" :min="form.fromTime" @change="getDiffTime()" />
          <span class="error" v-if="(this.v$.form.fromTime.$invalid && this.v$.form.fromTime.$dirty)">
            {{ $t('accessDc.fromTime') }} {{ $t('checkCoverage.isRequired') }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--4-col" :class="{ opacity: typeAccess && (typeAccess.indexOf('dc') == -1 && typeAccess.indexOf('coworking') > -1) }">
        <span class="labelFour">{{ $t('accessDc.toTime') }}</span>
        <div class="cdl-input" style="margin-top: 0;">
          <input type="time" name="toTime" class="not-empty" v-model="form.toTime" :min="form.toTime"
            @change="getDiffTime()" />
          <span class="error" v-if="(this.v$.form.toTime.$invalid && this.v$.form.toTime.$dirty)">
            {{ $t('accessDc.toTime') }} {{ $t('checkCoverage.isRequired') }}
          </span>
        </div>
      </div>
    </div>

    <div class="cdlContentButtonDouble">
      <div class="cdlButtonSingle width170" @click="goBack()">
        {{ $t('checkCoverage.stepsButtonTwo') }}
      </div>
      <div id="goNext" class="cdlButtonSingle width170" @click="goNext(false)">
        {{ $t('checkCoverage.stepsButtonOne') }}
      </div>
      <div class="cdlButtonSingle" @click="goNext(true)" v-if="wizardData.isCompleted">
        {{ $t('accessDc.goEnd') }}
      </div>
    </div>
    <p class="deleteOrder" v-if="!wizardData.isCompleted" @click="ctrlModalAccessDcRemove()">{{
      $t('checkCoverage.stepsButtonThree') }}</p>
  </div>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import moment from 'moment';
import { useAccessDcStore } from '@/stores/AccessDcStore'

export default {
  name: 'AccessDcWhen',
  props: {
    wizardData: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      form: {
        dateAccess: { required },
        fromTime: { required },
        toTime: { required }
      }
    }
  },
  data() {
    return {
      form: {
        dateAccess: null,
        fromTime: null,
        toTime: null
      },
      minDate: null,
      minTime: null,
      typeAccess: null
    }
  },
  methods: {
    goBack() {
      this.$emit('goBack');
    },
    async goNext(goSummary = null) {

      const result = await this.v$.$validate()
      if (result) {
        this.v$.$reset();

        let accessStore = new useAccessDcStore();

        accessStore.form.dateAccess = this.form.dateAccess;
        accessStore.form.dc.fromTime = this.form.fromTime;
        accessStore.form.dc.toTime = this.form.toTime;
        accessStore.form.coworking.fromTime = this.form.fromTime;
        accessStore.form.coworking.toTime = this.form.toTime;
        accessStore.form.parking.fromTime = this.form.fromTime;
        accessStore.form.parking.toTime = this.form.toTime;

        this.$emit('update');
        this.$emit('goNext', { goSummary });
      }
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    ctrlModalAccessDcRemove() {
      this.$emit('close');
    },
    getDate() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth() + parseInt(1);
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      this.form.dateAccess = date.getFullYear() + "-" + mm + "-" + dd;
      this.minDate = date.getFullYear() + "-" + mm + "-" + dd;
    },
    getTime() {
      let date = new Date();
      let hh = date.getHours();
      if (hh < 10) {
        hh = '0' + hh;
      }
      this.form.fromTime = hh + ':00';
      this.minTime = this.form.fromTime;
      let end = parseInt(hh);
      if (end != 24) {
        end++;
        if (end < 10) {
          end = '0' + end;
        }
      }
      this.form.toTime = end + ':00';
      return hh + ':00'
    },
    getDiffTime() {
      var duration = moment.duration(moment(this.form.dateAccess + "T" + this.form.toTime).diff(this.form.dateAccess + "T" + this.form.fromTime));
      if (duration._milliseconds < 0 || duration._milliseconds == 0) {
        this.form.toTime = null;
        return;
      }

      if( this.form.dateAccess == this.minDate ) {
        duration = moment.duration(moment(this.form.dateAccess + "T" + this.form.fromTime).diff(this.form.dateAccess + "T" + this.minTime));
        if (duration._milliseconds < 0) {
        this.form.fromTime = null;
        return;
      }
      }
    }
  },
  mounted() {
    this.getTime();
    this.getDate();
    if (this.wizardData.form.dateAccess) {
      this.form.dateAccess = this.wizardData.form.dateAccess;
    }
    if (this.wizardData.form.dc.fromTime) {
      this.form.fromTime = this.wizardData.form.dc.fromTime;
    }
    if (this.wizardData.form.dc.toTime) {
      this.form.toTime = this.wizardData.form.dc.toTime;
    }
    this.typeAccess = this.wizardData.form.typeAccess;
  }
}
</script>