<template>
  <div id="Shipping" v-if="isColocation">
    <MyBanner :text="$t('banner.shippingPage')" />
    <MyHeaderPage :title="$t('shipping.title')" subtitle="" />
    <div class="typeShipping">
      <div class="card">
        <div class="cardContent">
          <h3>{{ $t('shipping.cardOne') }}</h3>
          <p class="text" v-html="$t('shipping.cardOneText')"></p>
          <div class="contentButton">
            <div class="cdlButton" id="takeShipping" @click="setShipping('ShippingDeliveries')" style="width: 87%;">
              {{ $t('shipping.deliveries') }}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="cardContent">
          <h3>{{ $t('shipping.cardTwo') }}</h3>
          <p class="text">{{ $t('shipping.cardTwoText') }}</p>
          <div class="contentButton">
            <div class="cdlButton" id="takePickUp" @click="setShipping('ShippingPickUp')" style="width: 87%;">
              {{ $t('shipping.pickup') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <MyHeaderPage :title="$t('shipping.title')" />
    <CNoSolutionsService :viewBtn="false" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const isColocation = computed(() => {
  let colo = false;
  let user = useUserStore().user;
  if( user.isColocation ) {
    colo = user.isColocation;
  }
  return colo;
});
</script>

<script>
import MyBanner from '@/components/MyBanner.vue'
import MyHeaderPage from '@/components/MyHeaderPage.vue'
import "@/assets/scss/Shipping.scss"
import CNoSolutionsService from "@/components/NoSolutionsService.vue";

export default {
  name: 'MyShippingType',
  components: {
    MyBanner,
    MyHeaderPage,
    CNoSolutionsService
  },
  methods: {
    setShipping(type) {
      return this.$router.push({ name: type + this.$i18n.locale });
    }
  },
  mounted() {

  }
}
</script>