<template>
  <div class="solutions-ticket-list">
    <table>
      <thead v-if="!IsMobile">
        <tr>
          <th v-if="!IsMobile">{{ $t("ticketsList.default.created_at") }}</th>
          <th>{{ $t("ticketsList.default.title") }}</th>
          <th>{{ $t("ticketsList.default.reference") }}</th>
          <th v-if="!IsMobile">{{ $t("ticketsList.default.status") }}</th>
          <th v-if="!IsMobile" class="refreshList">
            <span
              class="material-icons"
              @click="getTickets()"
              v-tooltip:top.tooltip="$t('ticketsList.tooltip')"
              >autorenew</span
            >
          </th>
        </tr>
      </thead>
      <tbody v-if="IsMobile && this.viewLoader && this.list.length">
        <MobileCard
          v-for="(item, keyItem) in this.list"
          :key="keyItem"
          :titles="['subject', 'cf_email_referente']"
          :item="item"
          translation-path="ticketsList.CrossConnect"
        >
          <template #url-label>
            <router-link
              :to="`${
                $t('nav.crossConnectsActiveUrl') +
                '/' +
                $t('nav.detail') +
                '/' +
                item.ticketNumber
              }?crossconnect=true`"
            >
              <div class="cdlButton">{{ $t("tickets.details") }}</div>
            </router-link></template
          >
        </MobileCard>
      </tbody>
      <tbody v-else-if="!IsMobile && this.viewLoader && this.list.length">
        <tr
          v-for="(item, keyItem) in this.list"
          :key="keyItem"
          :class="{ odd: keyItem % 2 }"
        >
          <td>
            <span>{{ this.getDate(item.createdTime) }}</span>
          </td>
          <td>
            <span>{{ item?.subject }}</span>
          </td>
          <td>
            <span>{{ item.cf_email_referente }}</span>
          </td>
          <td>
            <span
              :class="`statusTickets status${getClassStatus(item.statusType)}`"
            >
              {{ $t("tickets." + getClassStatus(item.statusType)) }}</span
            >
          </td>

          <td>
            <div style="width: 150px; margin: 0 auto">
              <router-link
                :to="`${
                  $t('nav.crossConnectsActiveUrl') +
                  '/' +
                  $t('nav.detail') +
                  '/' +
                  item.ticketNumber
                }?crossconnect=true`"
              >
                <div class="cdlButton">{{ $t("tickets.details") }}</div>
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="this.viewLoader">
        <tr>
          <td colspan="7" style="text-align: center">
            <span>{{ $t("tickets.noTickets") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <br /><br />
      <MyError :viewError="viewError" :message="$t('tickets.error')" />
    </div>
    <paginator
      v-if="totalPage > 1"
      :total-page="totalPage"
      :current-page="currentPage"
      @change-page="this.changePage"
      @prev-page="this.prevPage"
      @next-page="this.nextPage"
    ></paginator>
  </div>
</template>

<script>
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import "@/assets/scss/Solutions.scss";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import MobileCard from "@/components/Cards/MobileCard.vue";

export default {
  name: "CrossConnectTicketList",
  components: {
    MobileCard,
    MyError,
    Paginator,
  },
  props: {
    list: Array,
    totalCount: Number,
    viewLoader: Boolean,
    viewError: Boolean,
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      messageError: "",
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    getClassStatus(string) {
      return typeof string == "string" ? string.replace(" ", "") : "";
    },
    getDate(myTime) {
      return moment(myTime).format("YYYY-MM-DD", this.$i18n.locale, true);
    },
    changePage(page) {
      this.currentPage = page;
      this.$emit("change-page", page);
    },
    nextPage() {
      if (this.currentPage == this.totalPage) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) + 1;
      this.$emit("change-page", this.currentPage);
    },
    prevPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) - 1;
      this.$emit("change-page", this.currentPage);
    },
    getTickets() {
      this.$emit("refresh");
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalCount / this.pageSize) > 1
        ? Math.ceil(this.totalCount / this.pageSize)
        : 1;
    },
  },
};
</script>
